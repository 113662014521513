/* eslint-disable */
// the following code are generated by src/guimacro/genmacro
const macros = {};
macros.ERR = {
  'ERR_NO_READ_PERMIT':512,
  'ERR_NO_WRITE_PERMIT':514,
  'ERR_NO_POST_DATA':516,
  'ERR_INVALID_DATA':518,
  'ERR_UPDATE':520,
  'ERR_ADDNEW':522,
  'ERR_DELETE':524,
  'ERR_NOT_SUPPORT':526,
  'ERR_WORKSPACE_DIRTY':550,
  'FDSM_ER_PARAM':-1,
  'FDSM_ER_INTERNAL':-2,
  'FDSM_ER_TIMEOUT':-3,
  'FDSM_ER_FMG_ENABLED':-4,
  'FDSM_ER_NO_REGISTER':-5,
  'FDSM_ER_NETWORK':-6,
  'FDSM_ER_INVD_PKT':-7,
  'FDSM_ER_FAIL_AUTH':-8,
  'ERR_CODE_NO_PERM_PROFILE':-10,
  'ERR_CODE_MAX_FAILED_LOGIN':-2
};
macros.SYS = {
  'IMG_TYPE':2,
  'PRODUCT_FAZ':1,
  'PRODUCT_FMG':2,
  'CONFIG_GUI_NO':'9629b81e046726ba2d3c4fbfb598bc5f',
  'CONFIG_PRODUCT':'FMG',
  'CONFIG_PROD_NAME':'FortiManager-VM64-KVM',
  'CONFIG_MAJOR_NUM':7,
  'CONFIG_MINOR_NUM':6,
  'CONFIG_PATCH_NUM':2,
  'CONFIG_BUILD_TIME':'241212',
  'CONFIG_BUILD_LABEL':'GA.F',
  'CONFIG_BUILD_NUMBER':3415,
  'CONFIG_BRANCH_POINT':3415,
  'IMG_SIG_LEVEL_0':0,
  'IMG_SIG_LEVEL_1':1,
  'IMG_SIG_LEVEL_2':2,
  'IMG_SIG_OK':0,
  'IMG_SIG_WARNING':1,
  'CONFIG_DEBUG':0,
  'HAVE_UPD_WEBSPAM':1,
  'BACKUP_OK':0,
  'BACKUP_ERROR':-1,
  'BACKUP_IN_PROGRESS':-2,
  'BACKUP_HISTORY_UPD_FAILED':-3,
  'FDS_UPD_EVENT_ALL':0,
  'FDS_UPD_EVENT_PUSH':1,
  'FDS_UPD_EVENT_POLL':2,
  'FDS_UPD_EVENT_MANUAL':3,
  'FDS_UPD_EVENT_UNKNOWN':4,
  'FDS_UPD_SUCCESS':0,
  'FDS_UPD_UP_TO_DATE':1,
  'FDS_UPD_CONN_FAILED':2,
  'FDS_UPD_MERGE_FAILED':3,
  'FDS_UPD_FAILED':4,
  'FDS_UPD_UNKNOWN':5,
  'FDS_SERVICE_FDS':0,
  'FDS_SERVICE_FGD':1,
  'FDS_SERVICE_FCT':2,
  'FAZ_LIC_VALID':0,
  'FAZ_LIC_INVALID':1,
  'FAZ_LIC_NO_LICENSE':3,
  'FAZ_LIC_EXPIRED':2,
  'CERT_OK':0,
  'CERT_PENDING':1,
  'CONFIG_SOAR':0,
  'CONFIG_SIEM':0,
  'HAVE_LINK_AGGREGATE':1,
  'ADOM_M_NORMAL':1,
  'ADOM_M_ADVANCED':2,
  'ADOM_S_DISABLED':0,
  'ADOM_S_ENABLED':1,
  'TFA_DISABLE':0,
  'TFA_PASSWORD':1,
  'TFA_FTC_FTM':2,
  'TFA_FTC_EMAIL':3,
  'TFA_FTC_SMS':4,
  'METADATA_IMPORTANCE_OPTIONAL':0,
  'METADATA_IMPORTANCE_REQUIRED':1,
  'METADATA_STATUS_DISABLED':0,
  'METADATA_STATUS_ENABLED':1,
  'LOGIN_MAX':32,
  'ADMIN_LOGIN_MAX':256,
  'FAZ_S_DISABLED':0,
  'FAZ_S_ENABLED':1,
  'WORKSPACE_M_DISABLED':0,
  'WORKSPACE_M_NORMAL':1,
  'WORKSPACE_M_WORKFLOW':2,
  'WORKSPACE_M_PERADOM':3,
  'WORKSPACE_ADOM':'v1-workspace-adom',
  'WORKSPACE_DEVICE':'v1-workspace-device',
  'WORKSPACE_PKG':'v1-workspace-pkg',
  'WORKSPACE_PKG_CATE':'v1-workspace-pkg-cate',
  'WORKSPACE_PKG_CATE_SEQUENCE':'v1-workspace-pkg-cate-sequence',
  'WORKSPACE_PKG_CATE_RECORD':'v1-workspace-pkg-cate-record',
  'WORKSPACE_CATE_OBJECT':'v1-workspace-cate-object',
  'ADMACCESS_HTTPS':1,
  'ADMACCESS_PING':2,
  'ADMACCESS_SSH':4,
  'ADMACCESS_HTTP':16,
  'ADMACCESS_SNMP':32,
  'ADMACCESS_WEB':64,
  'ADMACCESS_FGFM':256,
  'ADMACCESS_SOC':2048,
  'ADMACCESS_FABRIC':4096,
  'ADMACCESS_FGT_UPDATES':1,
  'ADMACCESS_WEBFITER_ANTISPAM':2,
  'ADMACCESS_FORTICLIENT_UPDATES':4,
  'HA_MODE_STANDALONE':0,
  'HA_MODE_MASTER':1,
  'HA_MODE_SLAVE':2,
  'HA_MAX_PEER_NUM':4,
  'DIS_MODULE_FVNOC':1,
  'DIS_MODULE_SIEM':4,
  'DIS_MODULE_SOAR':8,
  'DIS_MODULE_OTVIEW':32,
  'DIS_MODULE_ALL':109,
  'HA_ST_DOWN':0,
  'HA_ST_HANDSHAKE':1,
  'HA_ST_INPROGRESS':2,
  'HA_ST_SYNC':3,
  'ERR_NO_PERMIT':510,
  'LOCK_STATE_UNLOCKED':0,
  'LOCK_STATE_LOCKED':1,
  'LOCK_STATE_BY_OTHER':2,
  'VM_MIN_VCPU_NUM':4,
  'VM_MIN_MEMORY_BYTES_FMG':16000000000,
  'VM_MIN_MEMORY_BYTES_FAZ':16000000000,
  'UINT32_MAX':4294967295,
  'INT32_MAX':2147483647,
  'PROFILE_TYPE_SYSTEM':0,
  'PROFILE_TYPE_RESTRICTED':1,
  'ADMIN_USER_TYPE_LOCAL':0,
  'ADMIN_USER_TYPE_RADIUS':1,
  'ADMIN_USER_TYPE_LDAP':2,
  'ADMIN_USER_TYPE_TAC_PLUS':3,
  'ADMIN_USER_TYPE_PKI_AUTH':4,
  'ADMIN_USER_TYPE_GROUP':5,
  'ADMIN_USER_TYPE_SSO':6,
  'ADMIN_USER_TYPE_CLOUD_IAM':7,
  'ADMIN_USER_TYPE_API_USER':8,
  'ROOT_VF_NAME':'root',
  'WEB_ADMIN_LANGUAGE_AUTO_DETECT':0,
  'WEB_ADMIN_LANGUAGE_ENGLISH':1,
  'WEB_ADMIN_LANGUAGE_SIMPLIFIED_CHINESE':2,
  'WEB_ADMIN_LANGUAGE_TRADITIONAL_CHINESE':3,
  'WEB_ADMIN_LANGUAGE_JAPANESE':4,
  'WEB_ADMIN_LANGUAGE_KOREAN':5,
  'WEB_ADMIN_LANGUAGE_SPANISH':6,
  'WEB_ADMIN_LANGUAGE_FRENCH':7,
  'RADIUS_AUTH_TYPE_ANY':0,
  'RADIUS_AUTH_TYPE_PAP':1,
  'RADIUS_AUTH_TYPE_CHAP':2,
  'RADIUS_AUTH_TYPE_MSv2':3,
  'TAC_PLUS_AUTHEN_TYPE_AUTO':0,
  'TAC_PLUS_AUTHEN_TYPE_ASCII':1,
  'TAC_PLUS_AUTHEN_TYPE_PAP':2,
  'TAC_PLUS_AUTHEN_TYPE_CHAP':3,
  'TAC_PLUS_AUTHEN_TYPE_MSCHAP':5,
  'LDAP_AUTH_SIMPLE_TYPE':0,
  'LDAP_AUTH_ANONYMOUS_TYPE':1,
  'LDAP_AUTH_REGULAR_TYPE':2,
  'LDAP_SECURE_LDAPS':2,
  'LDAP_SECURE_STARTTLS':1,
  'USERID_LEN':64,
  'N_HOURS':0,
  'N_DAYS':1,
  'N_WEEKS':2,
  'N_MONTHS':3,
  'UPLD_SVR_FTP':0,
  'UPLD_SVR_SFTP':1,
  'UPLD_SVR_SCP':2,
  'UPLD_SVR_FAZ':3,
  'LOCALLOG_TO_FAZ_ST_DISABLE':0,
  'LOCALLOG_TO_FAZ_ST_REALTIME':1,
  'LOCALLOG_TO_FAZ_ST_UPLOAD':2,
  'LOG_LEVEL_EMERG':0,
  'LOG_LEVEL_ALERT':1,
  'LOG_LEVEL_CRITICAL':2,
  'LOG_LEVEL_ERROR':3,
  'LOG_LEVEL_WARNING':4,
  'LOG_LEVEL_NOTIF':5,
  'LOG_LEVEL_INFO':6,
  'LOG_LEVEL_DEBUG':7,
  'MIN_LOGFILE_SZ':10,
  'MAX_LOGFILE_SZ':1000,
  'PASSWDLEN':128,
  'NO_ROLLING_SCH':0,
  'ROLLING_DAILY':1,
  'ROLLING_WEEKLY':2,
  'FTPNAMELEN':36,
  'FTPFQDNLEN':255,
  'FTPDIRLEN':128,
  'GUI_FEATURE_VISIBILITY_PER_ADOM':0,
  'GUI_FEATURE_VISIBILITY_PER_ADMIN':1,
  'SNMP_RFC_STR_LEN':255,
  'AUTH_TEST_OK':0,
  'AUTH_TEST_AUTH_FAIL':1,
  'AUTH_TEST_INVALID_SECRET':2,
  'AUTH_TEST_UNREACHABLE':3,
  'AUTH_TEST_ERROR':4,
  'SMTP_AUTH_PSK':0,
  'SMTP_AUTH_CERTIFICATE':1,
  'IS_VM':1,
  'WIDGET_TYPE_LOG_RATE':8,
  'WIDGET_TYPE_RESOURCE':9,
  'WIDGET_TYPE_SYSINFO':10,
  'WIDGET_TYPE_LICINFO':11,
  'WIDGET_TYPE_JSCONSOLE':12,
  'WIDGET_TYPE_SYSOP':13,
  'WIDGET_TYPE_ALERT':14,
  'WIDGET_TYPE_STATISTICS':15,
  'WIDGET_TYPE_RPTENG':16,
  'WIDGET_TYPE_RAID':17,
  'WIDGET_TYPE_LOGRECV':18,
  'WIDGET_TYPE_DEV_SUMMARY':19,
  'WIDGET_TYPE_CURRENT_ADMIN':20,
  'WIDGET_TYPE_LOGDB_PERF':21,
  'WIDGET_TYPE_LOGDB_LAG':22,
  'WIDGET_TYPE_DISK_IO':23,
  'WIDGET_TYPE_LOGRCVD_FWD':24,
  'TIME_PERIOD_1HOUR':3600,
  'TIME_PERIOD_8HOUR':28800,
  'TIME_PERIOD_24HOUR':86400,
  'LR_PERIOD_HOUR':3600,
  'LR_PERIOD_6HOUR':21600,
  'LR_PERIOD_2MIN':120,
  'SYSRES_CPU':0,
  'SYSRES_MEM':1,
  'SYSRES_LOGRATE':4,
  'SYSRES_LOGDATA':5,
  'SYSRES_DISK_IO_UTIL':0,
  'SYSRES_DISK_IO_TPS':1,
  'SYSRES_DISK_IO_BLKS':2,
  'SYSRES_10MIN':0,
  'SYSRES_HOUR':1,
  'SYSRES_24HOUR':2,
  'SYSRES_REALTIME':0,
  'SYSRES_HISTORIC':1,
  'SYSRES_CPU_DISPLAY_TYPE_AVG':0,
  'SYSRES_CPU_DISPLAY_TYPE_EACH':1,
  'SUPER_USER_PROFILE':'Super_User',
  'STANDARD_USER_PROFILE':'Standard_User',
  'RESTRICTED_USER_PROFILE':'Restricted_User',
  'PACKAGE_USER_PROFILE':'Package_User',
  'PASSWORD_CHANGE_USER_PROFILE':'Password_Change_User',
  'NO_PERMISSION_USER_PROFILE':'No_Permission_User',
  'DOCKER_CFG_DISABLE':0,
  'DOCKER_CFG_ENABLE':1,
  'DOCKER_CFG_QA':2,
  'DOCKER_CFG_DEV':3,
  'DOCKER_ST_DISABLED':0,
  'DOCKER_ST_PULLING':1,
  'DOCKER_ST_FAILED':2,
  'DOCKER_ST_RUNNING':3,
  'CFG_ER_FORTICLOUD_UNREGISTERED':-920,
  'CFG_ER_DOCKER_NET_CONFLICT':-950,
  'CFG_ER_DOCKER_MISSING':-951,
  'CFG_ER_DOCKER_INVALID':-952,
  'CFG_ER_DOCKER_DISABLED':-953,
  'CFG_ER_DOCKER_INTERNAL':-954,
  'CFG_ER_DOCKER_PULL':-955,
  'CFG_ER_DOCKER_START':-956,
  'CFG_ER_ABORT':-9997,
  'CFG_ER_VALUE_CONFLICT':-7,
  'CFG_ER_INPUT_UNEXPECT':-61,
  'DEFAULT_SERVER_CERT_NAME':'server.crt',
  'ADMINPRIV_PERM_NONE':0,
  'ADMINPRIV_PERM_READ':1,
  'ADMINPRIV_PERM_RDWR':2,
  'RPC_PERMIT_READ_WRITE':0,
  'RPC_PERMIT_NONE':1,
  'RPC_PERMIT_READONLY':2,
  'RPC_PERMIT_FROMPROFILE':3,
  'OFFLINE_MODE_ENABLED':1,
  'OFFLINE_MODE_DISABLED':0,
  'DEV_AUTOUPDATE_ENABLED':1,
  'DEV_AUTOUPDATE_DISABLED':0,
  'FAZ_LOG_REBUILD_ADOM_ONLY':0,
  'FAZ_LOG_UPG_PHASE_ADQU':1,
  'FAZ_LOG_UPG_PHASE_RBDB':2,
  'FAZ_LOG_UPG_PHASE_LAST':2,
  'PG_UPG_STAGE_INIT':0,
  'PG_UPG_STAGE_MAX':7,
  'LOG_FETCH_SESSION_ST_NEW':0,
  'LOG_FETCH_SESSION_ST_SVR_LISTFILE':3,
  'LOG_FETCH_SESSION_ST_STOPPING':6,
  'LIC_TYPE_FAZ_MGT_MODE':0,
  'LIC_TYPE_SDNS':1,
  'VM_LIC_STATUS_FILE':0,
  'VM_LIC_STATUS_VALID':1,
  'VM_LIC_STATUS_EXPIRED':2,
  'VM_LIC_STATUS_MGMT_IP_NOMATCH':3,
  'VM_LIC_STATUS_OLD_VERSION':4,
  'VM_LIC_STATUS_LICENSE_DUPLICATE':5,
  'VM_LIC_LICTYPE_BASE':1,
  'VM_LIC_LICTYPE_10UG':2,
  'VM_LIC_LICTYPE_100UG':3,
  'VM_LIC_LICTYPE_1000UG':4,
  'VM_LIC_LICTYPE_5000UG':5,
  'VM_LIC_LICTYPE_UUG':6,
  'VM_LIC_LICTYPE_10K_UG':7,
  'VM_LIC_LICTYPE_TRIAL':8,
  'VM_LIC_LICTYPE_VMS':9,
  'VM_LIC_LICTYPE_MAX':10,
  'FAZ_LIC_BASE_DEV_NUM':20,
  'MAX_SEARCH_INPUT_LEN':100,
  'LONG_SEARCH_INPUT_LEN':1024,
  'CMDB_CFG_SYS_METADATA_ADMINS':21,
  'MAX_LEN_LOCAL_CERT_PKCS_CERTIFICATE_NAME':35,
  'RAID_DISK_LAYOUT_ROWS':1,
  'RAID_DISK_LAYOUT_COLS':2,
  'RAID_DISK_STATUS_NONE':0,
  'RAID_DISK_STATUS_FAILED':1,
  'RAID_DISK_STATUS_UNUSED':2,
  'RAID_DISK_STATUS_OK':3,
  'RAID_DISK_STATUS_REBUILDING':4,
  'RAID_DISK_STATUS_SPARE':5,
  'INTF_T_VLAN':0,
  'INTF_T_PHYSICAL':1,
  'INTF_T_AGGREGATE':2,
  'VLAN_8021Q':0,
  'VLAN_8021AD':1,
  'WEEK_DAY_SUNDAY':1,
  'WEEK_DAY_MONDAY':2,
  'WEEK_DAY_TUESDAY':4,
  'WEEK_DAY_WEDNESDAY':8,
  'WEEK_DAY_THURSDAY':16,
  'WEEK_DAY_FRIDAY':32,
  'WEEK_DAY_SATURDAY':64,
  'BACKUP_PROT_SFTP':0,
  'BACKUP_PROT_FTP':1,
  'BACKUP_PROT_SCP':2,
  'WEB_PROXY_MODE_PROXY':0,
  'WEB_PROXY_MODE_TUNNEL':1,
  'FMG_CLUSTER_STANDALONE':0,
  'FMG_CLUSTER_PRIMARY':1,
  'FMG_CLUSTER_WORKER':2,
  'HA_PASSWD_LEN':19,
  'UPG_OK':0,
  'UPG_ERR':1,
  'UPG_ERR_INVALID':2,
  'UPG_ERR_TOO_BIG':3,
  'UPG_ERR_INVALID_SIG':4,
  'DEF_SEARCH_FILTER':0,
  'DEF_SEARCH_ADVANCED':1,
  'FDSDB_FOSVER_64':1,
  'FDSDB_FOSVER_70':2,
  'FDSDB_FOSVER_72':4,
  'FDSDB_FOSVER_74':8,
  'UPDATE_SERVER_ANY':0,
  'UPDATE_SERVER_USA':1,
  'UPDATE_SERVER_EU':2
};
macros.ADOM = {
  'GLB_ADOM_NAME':'Global Database',
  'DVM_ADOM_FLAG_MIGRATION_MODE':1,
  'DVM_ADOM_FLAG_DB_EXPORT':4,
  'DVM_ADOM_FLAG_NO_VPN_CONSOLE':8,
  'DVM_ADOM_FLAG_BACKUP_MODE':16,
  'DVM_ADOM_FLAG_IS_AUTOSYNC':512,
  'DVM_ADOM_FLAG_OTHER_DEVICES':32,
  'DVM_ADOM_FLAG_INSTALL_TO_SPECIFY_SCOPE':64,
  'DVM_ADOM_FLAG_IS_DEFAULT':128,
  'DVM_ADOM_FLAG_FMGVMS_ISOLATED':2147483648,
  'KEEP_DB_ONLINE_DEFAULT_DAYS':60,
  'KEEP_LOG_OFFLINE_DEFAULT_DAYS':365,
  'DISK_USAGE_DB_PERCENTAGE_DEFAULT':70,
  'DISK_USAGE_DB_PERCENTAGE_MIN':5,
  'DISK_USAGE_DB_PERCENTAGE_MAX':95,
  'DISK_USAGE_ALERT_PERCENTAGE_DEFAULT':90,
  'ADOM_LOG_DISK_QUOTA_DEFAULT':51200,
  'FDSDB_IPS_RULE_DB_F_REGULAR':1,
  'FDSDB_IPS_RULE_DB_F_EXTEND':2,
  'FDSDB_IPS_RULE_DB_F_INDUSTRIAL':4,
  'FDSDB_APP_NAME_DB_F_APDB':1,
  'FDSDB_APP_NAME_DB_F_INDUSTRIAL':2,
  'DIFF_F_CREATE_CMD':16,
  'ROOT_ADOM_ID':'root'
};
macros.DVM = {
  'PM2_VDOM_STATUS_MODIFIED':1,
  'CDB_DEFAULT_GLOBAL_OID':1,
  'CDB_DEFAULT_ROOT_OID':3,
  'CDB_OBJ_DOM_GLOBAL':1,
  'CDB_OBJ_DOM_VDOM':2,
  'CDB_OBJ_DOM_DOUBLE':3,
  'DVM_NAME_SZ':48,
  'DVM_DESC_SZ':128,
  'DVM_DETAIL_SZ':512,
  'DVM_USER_SZ':36,
  'DVM_SN_SZ':16,
  'DVM_SCRIPT_NAME_SZ':71,
  'DVM_MAX_MR_NUM':99,
  'DVM_TIMESTAMP_SZ':32,
  'DVM_MAX_INTF':29,
  'DVM_MAX_SLOT':5,
  'DVM_DEV_SRC_FMG':1,
  'DVM_DEV_SRC_FAZ':2,
  'DVM_DEV_SRC_UM':4,
  'DVM_OS_VER_START':0,
  'DVM_OS_VER_MAX':40,
  'DVM_CONF_STATUS_UNKNOWN':0,
  'DVM_CONF_STATUS_IN_SYNC':1,
  'DVM_CONF_STATUS_OUT_OF_SYNC':2,
  'DVM_MGMT_MODE_UNREG':0,
  'DVM_MGMT_MODE_FMG':1,
  'DVM_MGMT_MODE_FAZ':2,
  'DVM_MGMT_MODE_FMG_FAZ':3,
  'DVM_CONN_STATUS_UNKNOWN':0,
  'DVM_CONN_STATUS_UP':1,
  'DVM_CONN_STATUS_DOWN':2,
  'DVM_OBJ_T_DEV':0,
  'DVM_OBJ_T_GRP':2,
  'DVM_OBJ_T_ADOM':3,
  'DVM_OBJ_T_CHS':21,
  'DVM_OBJ_T_VDOM':22,
  'DM_RCS_COMMENT_LEN':128,
  'DVM_RESTRICTED_PRD_FOS':1,
  'DVM_RESTRICTED_PRD_FOC':2,
  'DVM_RESTRICTED_PRD_FAZ':128,
  'DVM_RESTRICTED_PRD_FML':4,
  'DVM_RESTRICTED_PRD_FSW':512,
  'DVM_RESTRICTED_PRD_FWB':16,
  'DVM_RESTRICTED_PRD_FCH':8,
  'DVM_RESTRICTED_PRD_FPX':8192,
  'DVM_RESTRICTED_PRD_LOG':32,
  'DVM_RESTRICTED_PRD_FCT':64,
  'DVM_RESTRICTED_PRD_FMG':1024,
  'DVM_RESTRICTED_PRD_FSA':256,
  'DVM_RESTRICTED_PRD_FDD':2048,
  'DVM_RESTRICTED_PRD_FAC':4096,
  'DVM_RESTRICTED_PRD_FNA':16384,
  'DVM_RESTRICTED_PRD_FDC':262144,
  'DVM_RESTRICTED_PRD_FSR':65536,
  'DVM_RESTRICTED_PRD_FAD':131072,
  'DVM_RESTRICTED_PRD_FTS':2097152,
  'DVM_RESTRICTED_PRD_FFW':32768,
  'DVM_RESTRICTED_PRD_FWC':8388608,
  'DVM_RESTRICTED_PRD_FAI':4194304,
  'DVM_RESTRICTED_PRD_FIS':16777216,
  'DVM_RESTRICTED_PRD_FED':33554432,
  'DVM_RESTRICTED_PRD_FPA':67108864,
  'DVM_RESTRICTED_PRD_FCA':134217728,
  'DVM_RESTRICTED_PRD_FTC':268435456,
  'DVM_RESTRICTED_PRD_FSS':536870912,
  'DVM_RESTRICTED_PRD_FRA':1073741824,
  'DVM_RESTRICTED_PRD_FAP':524288,
  'DVM_RESTRICTED_PRD_SIM':2147483648,
    'DVM_RESTRICTED_PRD_FSF':4503599627370495,
  'DVM_ADOM_FOS_ROOT_NAME':'root',
  'DVM_ADOM_FOC_ROOT_NAME':'FortiCarrier',
  'DVM_ADOM_FML_ROOT_NAME':'FortiMail',
  'DVM_ADOM_FAZ_ROOT_NAME':'FortiAnalyzer',
  'DVM_ADOM_FWB_ROOT_NAME':'FortiWeb',
  'DVM_ADOM_FCH_ROOT_NAME':'FortiCache',
  'DVM_ADOM_FPX_ROOT_NAME':'FortiProxy',
  'DVM_ADOM_FCT_ROOT_NAME':'FortiClient',
  'DVM_ADOM_LOG_ROOT_NAME':'Syslog',
  'DVM_ADOM_FMG_ROOT_NAME':'FortiManager',
  'DVM_ADOM_FSA_ROOT_NAME':'FortiSandbox',
  'DVM_ADOM_FDD_ROOT_NAME':'FortiDDoS',
  'DVM_ADOM_FAC_ROOT_NAME':'FortiAuthenticator',
  'DVM_ADOM_FNA_ROOT_NAME':'Unmanaged_Devices',
  'DVM_ADOM_FDC_ROOT_NAME':'FortiDeceptor',
  'DVM_ADOM_FSR_ROOT_NAME':'Unmanaged_Devices',
  'DVM_ADOM_FAD_ROOT_NAME':'Unmanaged_Devices',
  'DVM_ADOM_FFW_ROOT_NAME':'FortiFirewall',
  'DVM_ADOM_FWC_ROOT_NAME':'FortiFirewallCarrier',
  'DVM_ADOM_FAI_ROOT_NAME':'Unmanaged_Devices',
  'DVM_ADOM_FTS_ROOT_NAME':'Unmanaged_Devices',
  'DVM_ADOM_FIS_ROOT_NAME':'Unmanaged_Devices',
  'DVM_ADOM_FED_ROOT_NAME':'Unmanaged_Devices',
  'DVM_ADOM_FPA_ROOT_NAME':'Unmanaged_Devices',
  'DVM_ADOM_FCA_ROOT_NAME':'Unmanaged_Devices',
  'DVM_ADOM_FTC_ROOT_NAME':'Unmanaged_Devices',
  'DVM_ADOM_FRA_ROOT_NAME':'Unmanaged_Devices',
  'DVM_ADOM_SIM_ROOT_NAME':'Unmanaged_Devices',
  'PM2_GUI_GLOBAL_ADOM_NAME':'Global',
  'DVM_ADOM_FSW_ROOT_NAME':'FortiSwitch',
  'DVM_ADOM_FABRIC_NAME':'Fabric',
  'DVM_ADOM_ROOT_NAME':'root',
  'DVM_ADOM_OTHERS_NAME':'others',
  'DVM_UNMANAGED_DEVICES_NAME':'Unmanaged_Devices',
  'LOCALHOST_ELOG':'_self_locallog_',
  'DVM_PLATFORM_FOS_CONTAINER':0,
  'DVM_PLATFORM_FGT_40F':8,
  'DVM_PLATFORM_FGT_40F_3G4G':9,
  'DVM_PLATFORM_FGT_41F':10,
  'DVM_PLATFORM_FGT_41F_3G4G':11,
  'DVM_PLATFORM_FGT_60D':23,
  'DVM_PLATFORM_FGT_60D_POE':24,
  'DVM_PLATFORM_FGT_60E':25,
  'DVM_PLATFORM_FGT_60E_DSL':26,
  'DVM_PLATFORM_FGT_60E_DSLJ':27,
  'DVM_PLATFORM_FGT_60E_POE':28,
  'DVM_PLATFORM_FGT_60F':29,
  'DVM_PLATFORM_FGT_61E':30,
  'DVM_PLATFORM_FGT_61F':31,
  'DVM_PLATFORM_FGT_80C':40,
  'DVM_PLATFORM_FGT_80CM':41,
  'DVM_PLATFORM_FGT_80D':42,
  'DVM_PLATFORM_FGT_80E':43,
  'DVM_PLATFORM_FGT_80E_POE':44,
  'DVM_PLATFORM_FGT_80F':45,
  'DVM_PLATFORM_FGT_80F_BYPASS':46,
  'DVM_PLATFORM_FGT_80F_POE':48,
  'DVM_PLATFORM_FGT_81E':49,
  'DVM_PLATFORM_FGT_81E_POE':50,
  'DVM_PLATFORM_FGT_81F':51,
  'DVM_PLATFORM_FGT_81F_POE':52,
  'DVM_PLATFORM_FGT_90D':53,
  'DVM_PLATFORM_FGT_90D_POE':54,
  'DVM_PLATFORM_FGT_90E':55,
  'DVM_PLATFORM_FGT_91E':57,
  'DVM_PLATFORM_FGT_92D':59,
  'DVM_PLATFORM_FGT_94D_POE':60,
  'DVM_PLATFORM_FGT_98D_POE':61,
  'DVM_PLATFORM_FGT_100D':62,
  'DVM_PLATFORM_FGT_100E':63,
  'DVM_PLATFORM_FGT_100EF':64,
  'DVM_PLATFORM_FGT_100F':65,
  'DVM_PLATFORM_FGT_101E':66,
  'DVM_PLATFORM_FGT_101F':67,
  'DVM_PLATFORM_FGT_7000E':164,
  'DVM_PLATFORM_FGC_7000E':39,
  'DVM_PLATFORM_FGT_6000F':163,
  'DVM_PLATFORM_FGC_6000F':38,
  'DVM_PLATFORM_FGR_60F':188,
  'DVM_PLATFORM_FGR_60F_3G4G':189,
  'DVM_PLATFORM_FWF_40F':204,
  'DVM_PLATFORM_FWF_40F_3G4G':205,
  'DVM_PLATFORM_FWF_60E':219,
  'DVM_PLATFORM_FWF_60E_DSL':220,
  'DVM_PLATFORM_FWF_60E_DSLJ':221,
  'DVM_PLATFORM_FWF_60F':222,
  'DVM_PLATFORM_FWF_61E':223,
  'DVM_PLATFORM_FWF_61F':224,
  'DVM_OS_TYPE_INVALID':-1,
  'DVM_OS_TYPE_FOS':0,
  'DVM_OS_TYPE_FSW':1,
  'DVM_OS_TYPE_FOC':2,
  'DVM_OS_TYPE_FML':3,
  'DVM_OS_TYPE_FAZ':4,
  'DVM_OS_TYPE_FWB':5,
  'DVM_OS_TYPE_FCH':6,
  'DVM_OS_TYPE_FCT':7,
  'DVM_OS_TYPE_LOG':8,
  'DVM_OS_TYPE_FMG':9,
  'DVM_OS_TYPE_FSA':10,
  'DVM_OS_TYPE_FDD':11,
  'DVM_OS_TYPE_FAC':12,
  'DVM_OS_TYPE_FNA':14,
  'DVM_OS_TYPE_FDC':18,
  'DVM_OS_TYPE_FPX':13,
  'DVM_OS_TYPE_FAD':17,
  'DVM_OS_TYPE_FSR':16,
  'DVM_OS_TYPE_SIM':31,
  'DVM_OS_TYPE_FFW':15,
  'DVM_OS_TYPE_FWC':23,
  'DVM_OS_TYPE_FAI':22,
  'DVM_OS_TYPE_FIS':24,
  'DVM_OS_TYPE_FED':25,
  'DVM_OS_TYPE_FPA':26,
  'DVM_OS_TYPE_FCA':27,
  'DVM_OS_TYPE_FTC':28,
  'DVM_OS_TYPE_FSS':29,
  'DVM_OS_TYPE_FRA':30,
  'DVM_OS_TYPE_FAP':19,
  'DVM_OPT_IMG_TYPE_MATURE':256,
  'DVM_OPT_IMG_TYPE_FEATURE':512,
  'DVM_DEV_FLAG_DISCOVER':8,
  'DVM_DEV_FLAG_HAS_HDD':1,
  'DVM_DEV_FLAG_VDOM_ENABLED':2,
  'DVM_DEV_FLAG_IPS_LOAD_FAIL':4,
  'DVM_DEV_FLAG_RELOAD':16,
  'DVM_DEV_FLAG_FORCE_PROBE':32,
  'DVM_DEV_FLAG_FROM_UNREG':64,
  'DVM_DEV_FLAG_FG_REACHABLE':128,
  'DVM_DEV_FLAG_HAS_START_TUN':256,
  'DVM_DEV_FLAG_FAZ_SUPPORTED':512,
  'DVM_DEV_FLAG_INTERIM_BUILD':1024,
  'DVM_DEV_FLAG_FIRMWARE_UPD':2048,
  'DVM_DEV_FLAG_OFFLINE_MODE':4096,
  'DVM_DEV_FLAG_CHS_MEMB':8192,
  'DVM_DEV_FLAG_DATA_LOAD_FAIL':16384,
  'DVM_DEV_FLAG_IN_BACKUP_MODE':131072,
  'DVM_DEV_FLAG_IS_MODEL':262144,
  'DVM_DEV_FLAG_FIPS_MODE':524288,
  'DVM_DEV_FLAG_VM_MODEL':2097152,
  'DVM_DEV_FLAG_ADOM_APMGMT':4194304,
  'DVM_DEV_FLAG_ADOM_ENABLED':8388608,
  'DVM_DEV_FLAG_INST_FAILED':16777216,
  'DVM_DEV_FLAG_EXTENDER_MGMT':33554432,
  'DVM_DEV_FLAG_LINKED_TO_MODEL':67108864,
  'DVM_DEV_FLAG_FORCE_UNREG_TUN':134217728,
  'DVM_DEV_FLAG_FAZ_AUTOSYNC':536870912,
  'DVM_DEV_FLAG_VDOM_MODE_SPLIT':32768,
  'DVM_DEV_FLAG_INST_LIC':4294967296,
  'DVM_DEV_FLAG_AZURE_VWAN_NVA':8589934592,
  'DVM_DEV_FLAG_FGSP_CONFIGURED':17179869184,
  'DVM_DEV_FLAG_NEED_RESET':34359738368,
  'DVM_DEV_FLAG_CLOUD_MODE_CNF':68719476736,
  'DVM_DEV_FLAG_AUTOLINK_FAILED':274877906944,
  'DVM_DEV_FLAG_OVERRIDE_MGTIF':1099511627776,
  'DVM_DEV_FLAG_SDWAN_MGMT':2199023255552,
  'DVM_DEV_FLAG_DENY_API_ACCESS':4398046511104,
  'DVM_DEV_FLAG_FAZ_API_IS_BLOCKED':8796093022208,
  'DVM_DEV_FLAG_IS_HIDDEN':1048576,
  'DVM_HA_MODE_STANDALONE':0,
  'DVM_HA_MODE_AP':1,
  'DVM_HA_MODE_AA':2,
  'DVM_HA_MODE_ELBC':3,
  'DVM_HA_MODE_DUAL':4,
  'DVM_HA_MODE_FMG':5,
  'DVM_HA_MODE_AUTOSCALE':6,
  'DVM_HA_ROLE_SLAVE':0,
  'DVM_HA_ROLE_MASTER':1,
  'DVM_PRIVATE_KEY_DISABLED':0,
  'DVM_PRIVATE_KEY_ENABLED':1,
  'DVM_PRIVATE_KEY_VERIFIED':2,
  'DVM_LOG_FULL_OVERWRITE':0,
  'DVM_LOG_FULL_STOP':1,
  'DVM_PERM_LOGS':1,
  'DVM_PERM_DLP_ARCHIVE':2,
  'DVM_PERM_QUARANTINE':4,
  'DVM_PERM_IPS_PKT_LOG':8,
  'DVM_FOSLIC_T_TEMPORARY':0,
  'DVM_FOSLIC_T_TRIAL':1,
  'DVM_FOSLIC_T_REGULAR':2,
  'DVM_FOSLIC_T_TRIAL_EXPIRED':3,
  'DVM_FOSLIC_UTM_T_FW':1,
  'DVM_FOSLIC_UTM_T_AV':2,
  'DVM_FOSLIC_UTM_T_IPS':4,
  'DVM_FOSLIC_UTM_T_APP':8,
  'DVM_FOSLIC_UTM_T_URL':16,
  'DVM_FOSLIC_UTM_T_UTM':32,
  'DVM_FOSLIC_UTM_T_FWB':64,
  'DVM_ONBOARD_RULE_T_MAINT':1,
  'DVM_ONBOARD_RULE_T_TEMPLATE':2,
  'DVM_ONBOARD_RULE_F_SPECIFY_OLD_SN':1,
  'DVM_ONBOARD_RULE_F_RETRIEVE':2,
  'DVM_LIC_STATUS_UNKNOWN':0,
  'DVM_LIC_STATUS_NO_LICENSE':1,
  'DVM_LIC_STATUS_STARTUP':2,
  'DVM_LIC_STATUS_VALID':3,
  'DVM_LIC_STATUS_PENDING':4,
  'DVM_LIC_STATUS_WARNING':5,
  'DVM_LIC_STATUS_INVALID':6,
  'DVM_LIC_STATUS_INVALID_COPY':7,
  'DVM_LIC_STATUS_EVAL_EXPIRED':8,
  'DVM_LIC_STATUS_EVAL':9,
  'DVM_LIC_STATUS_EXPIRES_SOON':10,
  'DVM_LIC_STATUS_EXPIRED':11,
  'DVM_LIC_STATUS_GRACE_PERIOD':12,
  'DVM_LIC_STATUS_VALIDATION_OVERDUE':13,
  'DVM_GRP_T_NORMAL':0,
  'DVM_GRP_T_DEFAULT':1,
  'DVM_GRP_T_AUTO':2,
  'DVM_GRP_T_CLUSTER':3,
  'DVM_GRP_T_FABRIC':4,
  'DVM_GRP_CLUSTER_T_UNKNOWN':-1,
  'DVM_GRP_CLUSTER_T_VWAN':0,
  'DVM_GRP_CLUSTER_T_UMS_AWS':2,
  'DVM_GRP_CLUSTER_T_UMS_AZURE':3,
  'DVM_GRP_CLUSTER_T_UMS_GCP':4,
  'DM_ST_NONE':0,
  'DM_ST_UNKNOWN':1,
  'DM_ST_CHECKED_IN':2,
  'DM_ST_INPROGRESS':3,
  'DM_ST_INSTALLED':4,
  'DM_ST_ABORTED':5,
  'DM_ST_SCHED':6,
  'DM_ST_RETRY':7,
  'DM_ST_CANCELED':8,
  'DM_ST_PENDING':9,
  'DM_ST_RETRIEVED':10,
  'DM_ST_CHANGED_CONF':11,
  'DM_ST_SYNC_FAIL':12,
  'DM_ST_TIMEOUT':13,
  'DM_ST_REV_REVERT':14,
  'DM_ST_AUTO_UPDATED':15,
  'DM_ST_MAX':16,
  'DVM_COND_UNKNOWN':0,
  'DVM_COND_OK':1,
  'DVM_COND_OUT_OF_SYNC':2,
  'DVM_COND_PEND_CONF':3,
  'DVM_COND_CONFLICT':4,
  'DVM_COND_UNREG':5,
  'DVM_COND_AUTO_INSTALL':6,
  'DVM_COND_OFFLINE':7,
  'DVM_OP_FLAG_CREATE_TASK':1,
  'DVM_OP_FLAG_NONBLOCKING':2,
  'DVM_OP_FLAG_ADD_LOG':4,
  'DVM_FILTER_OP_START':0,
  'DVM_FILTER_OP_GT':0,
  'DVM_FILTER_OP_GE':1,
  'DVM_FILTER_OP_LT':2,
  'DVM_FILTER_OP_LE':3,
  'DVM_FILTER_OP_NE':4,
  'DVM_FILTER_OP_EQ':5,
  'DVM_FILTER_OP_BETWEEN':6,
  'DVM_FILTER_OP_NOT_BETWEEN':7,
  'DVM_FILTER_OP_AND':8,
  'DVM_FILTER_OP_OR':9,
  'DVM_FILTER_OP_LIKE':10,
  'DVM_FILTER_OP_NOT_LIKE':11,
  'DVM_FILTER_OP_GLOB':12,
  'DVM_FILTER_OP_NOT_GLOB':13,
  'DVM_FILTER_OP_MAX':14,
  'DVM_SCRIPT_TARGET_DEVICE_DB':0,
  'DVM_SCRIPT_TARGET_DEVICE_REMOTE':1,
  'DVM_SCRIPT_TARGET_GLOBAL':2,
  'PM2_WIFI_MODE_AC':0,
  'PM2_WIFI_MODE_CLIENT':1,
  'PM2_WIFI_MODE_WTP':2,
  'PM2_WIFI_MODE_FWFAP':3,
  'UM_IMAGE_UPGRADE_FLAG_BOOL_ALT_PARTITION':1,
  'UM_IMAGE_UPGRADE_FLAG_SKIP_RETRIEVE':2,
  'UM_IMAGE_UPGRADE_FLAG_SKIP_MULTI_STEPS':4,
  'UM_IMAGE_UPGRADE_FLAG_FORTIGUARD_IMG':8,
  'UM_IMAGE_UPGRADE_FLAG_PREVIEW':16,
  'UM_IMAGE_UPGRADE_FLAG_SKIP_WHEN_SECONDARY_DOWN':128,
  'UM_IMAGE_UPGRADE_FLAG_SKIP_CHECK_DISK':256,
  'UM_IMAGE_UPGRADE_FLAG_SKIP_AUTO_SCAN_DISK':512,
  'UM2_IMAGE_UPGRADE_REPORT_SUMMARY':1,
  'UM2_IMAGE_UPGRADE_REPORT_SUB_TASK_RESULT_OK':0,
  'UM2_IMAGE_UPGRADE_REPORT_SUB_TASK_RESULT_FAILED':-1,
  'UM2_IMAGE_UPGRADE_REPORT_SUB_TASK_RESULT_FAILED_IMAGE':-2,
  'UM2_IMAGE_UPGRADE_REPORT_SUB_TASK_RESULT_FAILED_UPGRADE':-3,
  'UM2_IMAGE_UPGRADE_REPORT_SUB_TASK_RESULT_FAILED_RETRIEVE':-4,
  'FWM_FLAG_BOOT_ALT_PARTITION':1,
  'FWM_FLAG_SKIP_RETRIEVE':2,
  'FWM_FLAG_SKIP_UPGRADE_PATH':4,
  'FWM_FLAG_SKIP_WHEN_SECONDARY_DOWN':8,
  'FWM_FLAG_SKIP_CHECK_DISK':32,
  'FWM_FLAG_SKIP_AUTO_SCAN_DISK':64,
  'DVM_GRP_UNREG_OID':-1,
  'DVM_GRP_LOGGING_OID':-2,
  'DVM_GRP_MANAGED_OID':-3,
  'DVM_GRP_REMOTE_FAZ_OID':-4,
  'DVM_GRP_FORTISASE_OID':-5,
  'DEV_CSF_PREFIX':'csf:',
  'DEV_GRP_PREFIX':'devgrp:',
  'FAZ_GRP_PREFIX':'fazgrp:',
  'DEFAULT_RETRY_INTERVAL':2,
  'DEFAULT_RETRY_TIMES':2,
  'INTRAZONE_DENY_DISABLE':0,
  'INTRAZONE_DENY_UNCHANGED':-1,
  'INTRAZONE_DENY_ENABLE':1,
  'ZM_FLAG_REPLACE':0,
  'ZM_FLAG_ADD':1,
  'ZM_FLAG_DEL':2,
  'FEX_AUTH_EXTENDER':0,
  'FEX_DE_AUTH_EXTENDER':1,
  'FEX_REBOOT_EXTENDER':2,
  'FEX_SET_PRIMARY_EXTENDER':3,
  'FEX_GET_STATUS_EXTENDER':4,
  'FEX_SET_SECONDARY_EXTENDER':5,
  'SECURITY_CONSOLE_INST_F_CP_ALL_OBJS':1,
  'SECURITY_CONSOLE_INST_F_PREVIEW':2,
  'SECURITY_CONSOLE_INST_F_GENERATE_REV':8,
  'SECURITY_CONSOLE_INST_F_COPY_ASSIGNED_PKG':16,
  'SECURITY_CONSOLE_INST_F_UNASSIGN':128,
  'SECURITY_CONSOLE_INST_F_IFPOLICY_ONLY':256,
  'SECURITY_CONSOLE_INST_F_NO_IFPOLICY':512,
  'SECURITY_CONSOLE_INST_F_OBJS_ONLY':1024,
  'SECURITY_CONSOLE_INST_F_SCHEDULED':2048,
  'SECURITY_CONSOLE_INST_F_AUTO_LOCK_WS':4096,
  'SECURITY_CONSOLE_INST_F_CHECK_PKG_ST':8192,
  'SECURITY_CONSOLE_INST_F_COPY_ONLY':16384,
  'SECURITY_CONSOLE_INST_F_PERF_HA_MASTER':32768,
  'SECURITY_CONSOLE_INST_F_PERF_HA_SLAVE':65536,
  'SECURITY_CONSOLE_INST_F_ONLY_INSTALLED_DEV':131072,
  'SECURITY_CONSOLE_INST_F_DEL_UNUSED':524288,
  'SECURITY_CONSOLE_INST_F_AUTO_UNLOCK_WS':2097152,
  'SECURITY_CONSOLE_INST_F_SKIP_PROVISION_CLIPROF':4194304,
  'SECURITY_CONSOLE_INST_F_INST_DEV':8388608,
  'SECURITY_CONSOLE_PREVIEW_F_JSON':1,
  'SECURITY_CONSOLE_PREVIEW_F_DEFAULT_CFG':2,
  '__SC_IMPORT_ACTION_NONE':0,
  '__SC_IMPORT_ACTION_OVERRIDE':1,
  '__SC_IMPORT_ACTION_SKIP':2,
  '__SC_IMPORT_ACTION_IMPORT':3,
  '__SC_IMPORT_ACTION_RENAME':4,
  '__SC_IMPORT_ACTION_DEV_MAPPING':5,
  'SC_POL_VAL_ERROR':0,
  'SC_POL_VAL_WARN_DENY_ALL':1,
  'SC_POL_VAL_WARN_FW_ADDR':2,
  'SC_POL_VAL_WARN_CFG_SAVE':3,
  'MAX_VDOM_SHORTNAME':12,
  'DMSVC_CONTROLLER_OPT_UPD_RESYNC':1,
  'DMSVC_CONTROLLER_OPT_UPD_SAVEDB':2,
  'DMSVC_CONTROLLER_OPT_CREATE_TASK':4,
  'DMSVC_CONTROLLER_OPT_NEED_TOKEN':8,
  'PM3_CONTROLLER_TYPE_F_FAP':1,
  'PM3_CONTROLLER_TYPE_F_FEX':2,
  'PM3_CONTROLLER_TYPE_F_FSW':4,
  'PM3_CONTROLLER_TYPE_F_ALL':255,
  'DVM_CHS_AUTHTYPE_NONE':0,
  'DVM_CHS_AUTHTYPE_MD5':2,
  'DVM_CHS_AUTHTYPE_PASSWD':4,
  'DVM_CHS_T_5050':0,
  'DVM_CHS_T_5060':1,
  'DVM_CHS_T_5140':2,
  'DVM_CHS_T_5140B':3,
  'DVM_CHS_T_5144C':4,
  'DVM_CHS_SLOT_S_EMPTY':0,
  'DVM_CHS_SLOT_S_INSTALLED':1,
  'DVM_CHS_SLOT_S_RUNNING':2,
  'DVM_ENTITY_UNKNOWN':0,
  'DVM_ENTITY_ABSENT':1,
  'DVM_ENTITY_DISABLED':2,
  'DVM_ENTITY_PRESENT':3,
  'DVM_SENSOR_S_BELOW_LNR':-4,
  'DVM_SENSOR_S_BELOW_LC':-3,
  'DVM_SENSOR_S_BELOW_LNC':-2,
  'DVM_SENSOR_S_UNKNOWN':0,
  'DVM_SENSOR_S_OK':1,
  'DVM_SENSOR_S_ABOVE_UNC':2,
  'DVM_SENSOR_S_ABOVE_UC':3,
  'DVM_SENSOR_S_ABOVE_UNR':4,
  'DVM_SENSOR_TYPE_VOLTAGE':0,
  'DVM_SENSOR_TYPE_CURRENT':1,
  'DVM_SENSOR_TYPE_TEMPERATURE':2,
  'DVM_SHM_S_UNKNOWN':0,
  'DVM_SHM_S_ACTIVE':1,
  'DVM_SHM_S_PASSIVE':2,
  'DVM_TELCO_ALARM_MINOR':1,
  'DVM_TELCO_ALARM_MAJOR':2,
  'DVM_TELCO_ALARM_CRITICAL':4,
  'DVM_TELCO_ALARM_CUTOFF':8,
  'DB_MIN_REFRESH_INT':10,
  'DB_MAX_REFRESH_INT':1200,
  '__DYN_MAPPING_BY_DEVICE':1,
  '__DYN_MAPPING_BY_PLATFORM':2,
  '__DYN_MAPPING_BY_DEFAULT':3,
  'PM3_TMPLGRP_TYPE_DEVPROF':1,
  'PM3_TMPLGRP_TYPE_ECPROF':2,
  'PM3_TMPLGRP_TYPE_CRPROF':3,
  'PM3_TMPLGRP_TYPE_WANPROF':5,
  'PM3_TMPLGRP_TYPE_TEMPLATE':4,
  'PM3_TMPLGRP_TYPE_CERT':6,
  'PM3_TMPLGRP_TYPE_CLIPROF':7,
  'PM3_TMPLGRP_TYPE_MAX':8,
  'PM3_TEMPLATE_CUSTOMIZE_STYPE_IPSEC':1,
  'PM3_TEMPLATE_CUSTOMIZE_STYPE_ROUTER':2,
  'PM3_TEMPLATE_CUSTOMIZE_STYPE_CST':3,
  'PM3_TEMPLATE_CUSTOMIZE_STYPE_IPS_SETTING':5,
  'PM3_TEMPLATE_DEFAULT_STYPE_BASE':1000,
  'PM3_TEMPLATE_CUSTOMIZE_STYPE_BGP':1240,
  'LOGSTAT_DEVST_UNKNOWN':0,
  'LOGSTAT_DEVST_DOWN':1,
  'LOGSTAT_DEVST_UP':2,
  'LOGSTAT_DEVST_FWD':3,
  'LOGSTAT_DEVST_HASYNC':4,
  'FGT_ACCESS_OTHER_ERROR':-1,
  'FGT_ACCESS_NO_PERMISSION':-3,
  'FGT_ACCESS_FEATURE_DISABLED':-4,
  'FGT_ACCESS_ROOT_NOT_IN_ADOM':-5,
  'VDOM_F_SSLVPN_ENABLE':2
};
macros.FABRIC = {
  'SERVER_SERVICE_ADGRP':0,
  'SERVER_SERVICE_USER':1,
  'SERVER_SERVICE_USER_SUBSCRIBER':2
};
macros.PM2CAT = {
  'PM2_CAT_SYS_VDOM':1,
  'PM2_CAT_SYS_GLOBAL':2,
  'PM2_CAT_SYS_GLOBAL_SPLIT_PORT_MODE':3242,
  'PM2_CAT_SYS_ACCPROF':3,
  'PM2_CAT_SYS_ACCPROF_FWGRP_PERM':4,
  'PM2_CAT_SYS_ACCPROF_LOGGRP_PERM':283,
  'PM2_CAT_SYS_ACCPROF_UTMGRP_PERM':1009,
  'PM2_CAT_SYS_ACCPROF_NETGRP_PERMISSION':2072,
  'PM2_CAT_SYS_ACCPROF_SYSGRP_PERMISSION':2073,
  'PM2_CAT_SYS_ADMIN':5,
  'PM2_CAT_SYS_ADMIN_DASHBOARD':6,
  'PM2_CAT_SYS_ADMIN_DASHBOARD_TABS':1403,
  'PM2_CAT_SYS_ADMIN_LOGIN_TIME':1525,
  'PM2_CAT_SYS_ADMIN_GUI_DASHBOARD':1883,
  'PM2_CAT_SYS_ADMIN_GUI_DASHBOARD_WIDGET':1884,
  'PM2_CAT_SYS_ADMIN_GUI_DASHBOARD_WIDGET_FILTERS':1938,
  'PM2_CAT_SYSTEM_ADMIN_GUI_DASHBOARD_WIDGET_FORTIVIEW_FILTERS':2220,
  'PM2_CAT_SYS_ADMIN_TRUSTHOSTS':4049,
  'PM2_CAT_SYS_ADMIN_PWD_HISTORY':3366,
  'PM2_CAT_SYS_VDOM_LINK':7,
  'PM2_CAT_SYS_INTF':8,
  'PM2_CAT_SYS_INTF_SECIP':10,
  'PM2_CAT_SYS_INTF_IPV6':11,
  'PM2_CAT_SYS_INTF_IPV6_IP6_PREFIX_LIST':12,
  'PM2_CAT_SYS_INTF_IPV6_IP6_EXTRA_ADDR':1404,
  'PM2_CAT_SYS_INTF_IPV6_IP6_DELEGATED_PREFIX_LIST':1633,
  'PM2_CAT_SYS_INTF_IPV6_VRRP6':2006,
  'PM2_CAT_SYS_INTF_IPV6_DHCP6_IAPD_LIST':3121,
  'PM2_CAT_SYS_INTF_IPV6_CLIENT_OPTIONS':3365,
  'PM2_CAT_SYS_INTF_IPV6_IP6_ROUTE_LIST':3386,
  'PM2_CAT_SYS_INTF_IPV6_IP6_RDNSS_LIST':3387,
  'PM2_CAT_SYS_INTF_IPV6_IP6_DNSSL_LIST':3388,
  'PM2_CAT_SYS_INTF_WIFI_MAC_LIST':256,
  'PM2_CAT_SYS_INTF_L2TP_CLIENT_SETS':266,
  'PM2_CAT_SYS_INTF_T1E1_HDLC':1010,
  'PM2_CAT_SYS_INTF_VRRP':1406,
  'PM2_CAT_SYS_INTF_VRRP_PROXY_ARP':2005,
  'PM2_CAT_SYS_INTF_WIFI_NETWORKS':472,
  'PM2_CAT_SYS_INTF_MANAGED_DEVICE':1735,
  'PM2_CAT_SYS_INTF_TDM':1524,
  'PM2_CAT_SYS_INTF_TAGGING':2004,
  'PM2_CAT_SYS_INTF_OUT_QUEUES':2202,
  'PM2_CAT_SYS_INTF_EGRESS_QUEUES':2441,
  'PM2_CAT_SYS_INTF_CLIENT_OPTIONS':2750,
  'PM2_CAT_SYS_INTF_DHCP_SNOOPING_SVR_LIST':3087,
  'PM2_CAT_SYS_INTF_MIRRORING_FILTER':3312,
  'PM2_CAT_SYS_HA':13,
  'PM2_CAT_SYS_HA_SEC_VC':14,
  'PM2_CAT_SYS_HA_FRUP_SETTINGS':393,
  'PM2_CAT_SYS_HA_HA_MGMT_INTERFACES':1885,
  'PM2_CAT_SYS_HA_UNICAST_PEERS':3055,
  'PM2_CAT_SYS_HA_VC':2883,
  'PM2_CAT_SYS_DNS':15,
  'PM2_CAT_SYS_REPMSG_MAIL':16,
  'PM2_CAT_SYS_REPMSG_HTTP':17,
  'PM2_CAT_SYS_REPMSG_FTP':18,
  'PM2_CAT_SYS_REPMSG_NNTP':19,
  'PM2_CAT_SYS_REPMSG_ALERTMAIL':20,
  'PM2_CAT_SYS_REPMSG_FTGD_WF':21,
  'PM2_CAT_SYS_REPMSG_SPAM':22,
  'PM2_CAT_SYS_REPMSG_ADMIN':23,
  'PM2_CAT_SYS_REPMSG_AUTH':24,
  'PM2_CAT_SYS_REPMSG_IM':25,
  'PM2_CAT_SYS_REPMSG_SSLVPN':26,
  'PM2_CAT_SYS_SNMP_SYSINFO':27,
  'PM2_CAT_SYS_SNMP_COMM':28,
  'PM2_CAT_SYS_SNMP_COMM_HOSTS':29,
  'PM2_CAT_SYS_SNMP_COMM_HOSTS6':159,
  'PM2_CAT_SYS_AUTOUPD_OVERRIDE':30,
  'PM2_CAT_SYS_AUTOUPD_PUSH_UPDATE':31,
  'PM2_CAT_SYS_AUTOUPD_SCHED':32,
  'PM2_CAT_SYS_AUTOUPD_TUN':33,
  'PM2_CAT_SYS_AUTOUPD_CLTOVD':34,
  'PM2_CAT_SYS_FM':37,
  'PM2_CAT_WEBFILTER_FTGD_LOCAL_CAT':38,
  'PM2_CAT_WEBFILTER_URLFILTER':47,
  'PM2_CAT_WEBFILTER_URLFILTER_ENTRIES':48,
  'PM2_CAT_WEBFILTER_FTGD':49,
  'PM2_CAT_WEBFILTER_FTGD_OVRD':50,
  'PM2_CAT_WEBFILTER_FTGD_LOCAL_RATING':51,
  'PM2_CAT_VPN_CERT_CA':52,
  'PM2_CAT_VPN_CERT_LOCAL':53,
  'PM2_CAT_VPN_CERT_CRL':54,
  'PM2_CAT_VPN_CERT_REMOTE':55,
  'PM2_CAT_SPAMFILTER_BWORD':56,
  'PM2_CAT_SPAMFILTER_BWORD_ENTRIES':57,
  'PM2_CAT_SPAMFILTER_IPBWL':58,
  'PM2_CAT_SPAMFILTER_IPBWL_ENTRIES':59,
  'PM2_CAT_SPAMFILTER_RBL':60,
  'PM2_CAT_SPAMFILTER_RBL_ENTRIES':61,
  'PM2_CAT_SPAMFILTER_EMAILBWL':62,
  'PM2_CAT_SPAMFILTER_EMAILBWL_ENTRIES':63,
  'PM2_CAT_SPAMFILTER_MHEADER':64,
  'PM2_CAT_SPAMFILTER_MHEADER_ENTRIES':65,
  'PM2_CAT_SPAMFILTER_FORTISHIELD':66,
  'PM2_CAT_SPAMFILTER_IPTRUST':67,
  'PM2_CAT_SPAMFILTER_IPTRUST_ENTRIES':68,
  'PM2_CAT_SPAMFILTER_OPTIONS':69,
  'PM2_CAT_SYS_FTGD':70,
  'PM2_CAT_SYS_FTGD_SRV_OVRD_LIST':71,
  'PM2_CAT_IMP2P_POLICY':72,
  'PM2_CAT_IMP2P_OLD_VER':73,
  'PM2_CAT_IMP2P_AIM_USER':74,
  'PM2_CAT_IMP2P_ICQ_USER':75,
  'PM2_CAT_IMP2P_MSN_USER':76,
  'PM2_CAT_IMP2P_YAHOO_USER':77,
  'PM2_CAT_IPS_CUS':85,
  'PM2_CAT_IPS_GLOBAL':86,
  'PM2_CAT_IPS_GLOBAL_TLS_ACTIVE_PROBE':2965,
  'PM2_CAT_SYS_FA':87,
  'PM2_CAT_SYS_FA2':88,
  'PM2_CAT_SYS_FA3':89,
  'PM2_CAT_LOG_SYSLOGD_SET':90,
  'PM2_CAT_LOG_SYSLOGD_SET_CUSTOM_FIELD_NAME':1777,
  'PM2_CAT_LOG_SYSLOGD2_SET':91,
  'PM2_CAT_LOG_SYSLOGD2_SET_CUSTOM_FIELD_NAME':1778,
  'PM2_CAT_LOG_SYSLOGD3_SET':92,
  'PM2_CAT_LOG_SYSLOGD3_SET_CUSTOM_FIELD_NAME':1779,
  'PM2_CAT_LOG_WEBTRENDS_SET':93,
  'PM2_CAT_LOG_FA_SET':94,
  'PM2_CAT_LOG_FA2_SET':95,
  'PM2_CAT_LOG_FA3_SET':96,
  'PM2_CAT_LOG_SYSLOGD_FILTER':97,
  'PM2_CAT_LOG_SYSLOGD_FILTER_EXCLUDE_LIST':328,
  'PM2_CAT_LOG_SYSLOGD_FILTER_EXCLUDE_LIST_FIELDS':361,
  'PM2_CAT_LOG_SYSLOGD_FILTER_FREE_STYLE':2991,
  'PM2_CAT_LOG_SYSLOGD2_FILTER':98,
  'PM2_CAT_LOG_SYSLOGD2_FILTER_EXCLUDE_LIST':362,
  'PM2_CAT_LOG_SYSLOGD2_FILTER_EXCLUDE_LIST_FIELDS':363,
  'PM2_CAT_LOG_SYSLOGD2_FILTER_FREE_STYLE':2992,
  'PM2_CAT_LOG_SYSLOGD3_FILTER':99,
  'PM2_CAT_LOG_SYSLOGD3_FILTER_EXCLUDE_LIST':364,
  'PM2_CAT_LOG_SYSLOGD3_FILTER_EXCLUDE_LIST_FIELDS':365,
  'PM2_CAT_LOG_SYSLOGD3_FILTER_FREE_STYLE':2993,
  'PM2_CAT_LOG_WEBTRENDS_FILTER':100,
  'PM2_CAT_LOG_WEBTRENDS_FILTER_EXCLUDE_LIST':368,
  'PM2_CAT_LOG_WEBTRENDS_FILTER_EXCLUDE_LIST_FIELDS':369,
  'PM2_CAT_LOG_WEBTRENDS_FILTER_FREE_STYLE':2999,
  'PM2_CAT_LOG_FA_FILTER':101,
  'PM2_CAT_LOG_FA_FILTER_EXCLUDE_LIST':376,
  'PM2_CAT_LOG_FA_FILTER_EXCLUDE_LIST_FIELDS':377,
  'PM2_CAT_LOG_FA_FILTER_FREE_STYLE':3005,
  'PM2_CAT_LOG_FA2_FILTER':102,
  'PM2_CAT_LOG_FORTIANALYZER2_FILTER_EXCLUDE_LIST':378,
  'PM2_CAT_LOG_FORTIANALYZER2_FILTER_EXCLUDE_LIST_FIELDS':379,
  'PM2_CAT_LOG_FA2_FILTER_FREE_STYLE':3006,
  'PM2_CAT_LOG_FA3_FILTER':103,
  'PM2_CAT_LOG_FORTIANALYZER3_FILTER_EXCLUDE_LIST':380,
  'PM2_CAT_LOG_FORTIANALYZER3_FILTER_EXCLUDE_LIST_FIELDS':381,
  'PM2_CAT_LOG_FA3_FILTER_FREE_STYLE':3007,
  'PM2_CAT_SYS_AMAIL':115,
  'PM2_CAT_GUI_CONSOLE':116,
  'PM2_CAT_SYS_SESS_HELPER':117,
  'PM2_CAT_SYS_TOS_BASED_PRIO':118,
  'PM2_CAT_SYS_AUTO_INSTALL':119,
  'PM2_CAT_SYS_CONSOLE':120,
  'PM2_CAT_SYS_BUG_REPORT':121,
  'PM2_CAT_AV_FILEPAT':122,
  'PM2_CAT_AV_FILEPAT_ENTRIES':123,
  'PM2_CAT_AV_SRV':124,
  'PM2_CAT_AV_HEURISTIC':125,
  'PM2_CAT_AV_QUARANTINE':127,
  'PM2_CAT_AV_QUARPAT':128,
  'PM2_CAT_SYS_SETS':129,
  'PM2_CAT_SYS_IPV6_TUN':130,
  'PM2_CAT_SYS_SIT_TUN':314,
  'PM2_CAT_SYS_GRE_TUN':131,
  'PM2_CAT_SYS_ARP_TABLE':132,
  'PM2_CAT_SYS_SESS_TTL':133,
  'PM2_CAT_SYS_SESS_TTL_PORT':134,
  'PM2_CAT_SYS_DHCP_SVR':135,
  'PM2_CAT_SYS_DHCP_SVR_EXCLUDE_RANGE':136,
  'PM2_CAT_SYS_DHCP_SVR_IP_RANGE':1478,
  'PM2_CAT_SYS_DHCP_SVR_RESERVED_ADDRESS':313,
  'PM2_CAT_SYS_DHCP_SVR_OPTIONS':1736,
  'PM2_CAT_SYS_DHCP_RESERVED_ADDR':137,
  'PM2_CAT_SYS_ZONE':138,
  'PM2_CAT_SYS_ZONE_TAGGING':2007,
  'PM2_CAT_FW_ADDR':140,
  'PM2_CAT_FW_ADDR_DYNAMIC_MAPPING':1594,
  'PM2_CAT_FW_ADDR_LIST':1755,
  'PM2_CAT_FW_ADDR_PROFILE_LIST':1756,
  'PM2_CAT_FW_ADDR_TAGGING':2008,
  'PM2_CAT_FW_ADDR6':141,
  'PM2_CAT_FW_ADDR6_DYNAMIC_MAPPING':1601,
  'PM2_CAT_FW_ADDR6_DYNAMIC_MAPPING_SUBNET_SEGMENT':2506,
  'PM2_CAT_FW_ADDR6_LIST':1757,
  'PM2_CAT_FW_ADDR6_PROFILE_LIST':1758,
  'PM2_CAT_FW_ADDR6_TAGGING':2012,
  'PM2_CAT_FW_ADDR6_SUBNET_SEGMENT':2013,
  'PM2_CAT_FW_ADDRGRP':142,
  'PM2_CAT_FW_ADDRGRP_DYNAMIC_MAPPING':1595,
  'PM2_CAT_FW_ADDRGRP_TAGGING':2014,
  'PM2_CAT_USER_FSAE':144,
  'PM2_CAT_USER_ADGRP':255,
  'PM2_CAT_USER_RADIUS':145,
  'PM2_CAT_USER_RADIUS_ACCNT_SERVER':1523,
  'PM2_CAT_USER_RADIUS_DYNAMIC_MAPPING':1602,
  'PM2_CAT_USER_RADIUS_DYNAMIC_MAPPING_ACCNT_SERVER':1639,
  'PM2_CAT_USER_LDAP':146,
  'PM2_CAT_USER_LDAP_DYNAMIC_MAPPING':1621,
  'PM2_CAT_USER_LOCAL':147,
  'PM2_CAT_USER_LOCAL_PWD_HISTORY':3364,
  'PM2_CAT_USER_PEER':148,
  'PM2_CAT_USER_PEERGRP':149,
  'PM2_CAT_VPN_SSL_SETS':151,
  'PM2_CAT_VPN_SSL_SETS_AUTHENTICATION_RULE':1578,
  'PM2_CAT_USER_GROUP':152,
  'PM2_CAT_USER_GROUP_SSLVPN_OS_CHECK_LIST':280,
  'PM2_CAT_USER_GROUP_MATCH':9,
  'PM2_CAT_USER_GROUP_GUEST':411,
  'PM2_CAT_USER_GROUP_DYNAMIC_MAPPING':3116,
  'PM2_CAT_USER_GROUP_DYNAMIC_MAPPING_SSLVPN_OS_CHECK_LIST':3117,
  'PM2_CAT_USER_GROUP_DYNAMIC_MAPPING_MATCH':3118,
  'PM2_CAT_USER_GROUP_DYNAMIC_MAPPING_GUEST':3119,
  'PM2_CAT_VPN_CERT_OCSP':154,
  'PM2_CAT_VPN_IPSEC_P1':155,
  'PM2_CAT_VPN_IPSEC_P1_IPV4_EXCLUDE_RANGE':1637,
  'PM2_CAT_VPN_IPSEC_P1_IPV6_EXCLUDE_RANGE':1638,
  'PM2_CAT_VPN_IPSEC_P2':156,
  'PM2_CAT_VPN_IPSEC_MANKEY':157,
  'PM2_CAT_VPN_IPSEC_CON':158,
  'PM2_CAT_VPN_IPSEC_P1_INTF':160,
  'PM2_CAT_VPN_IPSEC_P1_INTF_IPV4_EXCLUDE_RANGE':1304,
  'PM2_CAT_VPN_IPSEC_P1_INTF_IPV6_EXCLUDE_RANGE':1305,
  'PM2_CAT_VPN_IPSEC_P2_INTF':161,
  'PM2_CAT_VPN_IPSEC_MANKEY_INTF':162,
  'PM2_CAT_VPN_PPTP':163,
  'PM2_CAT_VPN_L2TP':164,
  'PM2_CAT_VPN_IPSEC_FC':165,
  'PM2_CAT_VPN_SSL_WEB_FAVORITE':166,
  'PM2_CAT_FW_SRV_CUS':167,
  'PM2_CAT_FW_SRV_PREDEF':254,
  'PM2_CAT_FW_SRV_GROUP':168,
  'PM2_CAT_FW_SCHED_ONETIME':170,
  'PM2_CAT_FW_SCHED_RECUR':171,
  'PM2_CAT_FW_IPPOOL':172,
  'PM2_CAT_FW_IPPOOL_DYNAMIC_MAPPING':1600,
  'PM2_CAT_FW_VIP':173,
  'PM2_CAT_FW_VIP_REALSRV':174,
  'PM2_CAT_FW_VIP_SSL_CIPHER_SUITES':507,
  'PM2_CAT_FW_VIP_DYNAMIC_MAPPING':1608,
  'PM2_CAT_FW_VIP_DYNAMIC_MAPPING_REALSRV':1598,
  'PM2_CAT_FW_VIP_DYNAMIC_MAPPING_SSL_CIPHER_SUITES':1599,
  'PM2_CAT_FW_VIP_SSL_SERVER_CIPHER_SUITES':1741,
  'PM2_CAT_FW_VIP_QUIC':3284,
  'PM2_CAT_FW_VIP_GSLB_PUBLIC_IPS':3317,
  'PM2_CAT_FW_VIPGRP':175,
  'PM2_CAT_FW_VIPGRP_DYNAMIC_MAPPING':1650,
  'PM2_CAT_FW_IPMAC_SET':177,
  'PM2_CAT_FW_IPMAC_TABLE':178,
  'PM2_CAT_FW_ADDRGRP6':179,
  'PM2_CAT_FW_ADDRGRP6_DYNAMIC_MAPPING':1606,
  'PM2_CAT_FW_ADDRGRP6_TAGGING':2015,
  'PM2_CAT_FW_POLICY':181,
  'PM2_CAT_VPN_SRC_NODE':1007,
  'PM2_CAT_VPN_DST_NODE':1008,
  'PM2_CAT_FW_POLICY_IDENTITY_BASED_POLICY':1065,
  'PM2_CAT_GL_HDR_POLICY':1474,
  'PM2_CAT_GL_HDR_POLICY_IDENTITY_BASED_POLICY':1475,
  'PM2_CAT_GL_FOOTER_POLICY':1476,
  'PM2_CAT_GL_FOOTER_POLICY_IDENTITY_BASED_POLICY':1477,
  'PM2_CAT_FW_POLICY6':187,
  'PM2_CAT_FW_POLICY6_IDENTITY_BASED_POLICY':271,
  'PM2_CAT_GL_HDR_POLICY6':1500,
  'PM2_CAT_GL_HDR_POLICY_IDENTITY_BASED_POLICY6':1501,
  'PM2_CAT_GL_FOOTER_POLICY6':1502,
  'PM2_CAT_GL_FOOTER_POLICY_IDENTITY_BASED_POLICY6':1503,
  'PM2_CAT_FW_DNSTRANS':189,
  'PM2_CAT_FW_MC_POLICY':190,
  'PM2_CAT_LOG_TRAFILTER':191,
  'PM2_CAT_LOG_MEMORY_SET':193,
  'PM2_CAT_LOG_DISK_SET':194,
  'PM2_CAT_LOG_DISK_SET_SQL_LOGGING':1386,
  'PM2_CAT_LOG_MEMORY_FILTER':195,
  'PM2_CAT_LOG_MEMORY_FILTER_EXCLUDE_LIST':370,
  'PM2_CAT_LOG_MEMORY_FILTER_EXCLUDE_LIST_FIELDS':371,
  'PM2_CAT_LOG_MEMORY_FILTER_FREE_STYLE':3000,
  'PM2_CAT_LOG_DISK_FILTER':196,
  'PM2_CAT_LOG_DISK_FILTER_EXCLUDE_LIST':372,
  'PM2_CAT_LOG_DISK_FILTER_EXCLUDE_LIST_FIELDS':373,
  'PM2_CAT_LOG_DISK_FILTER_FREE_STYLE':3001,
  'PM2_CAT_AMAIL_SET':197,
  'PM2_CAT_SYS_MAC_ADDR_TABLE':199,
  'PM2_CAT_ROUTER_ACCESS_LIST':200,
  'PM2_CAT_ROUTER_ACCESS_LIST_RULE':201,
  'PM2_CAT_ROUTER_ASPATH_LIST':202,
  'PM2_CAT_ROUTER_ASPATH_LIST_RULE':203,
  'PM2_CAT_ROUTER_PREFIX_LIST':204,
  'PM2_CAT_ROUTER_PREFIX_LIST_RULE':205,
  'PM2_CAT_ROUTER_KEY_CHAIN':206,
  'PM2_CAT_ROUTER_KEY_CHAIN_KEY':207,
  'PM2_CAT_ROUTER_COMM_LIST':208,
  'PM2_CAT_ROUTER_COMM_LIST_RULE':209,
  'PM2_CAT_ROUTER_ROUTE_MAP':210,
  'PM2_CAT_ROUTER_ROUTE_MAP_RULE':211,
  'PM2_CAT_ROUTER_RIP':216,
  'PM2_CAT_ROUTER_RIP_DISTANCE':217,
  'PM2_CAT_ROUTER_RIP_DISTR_LIST':218,
  'PM2_CAT_ROUTER_RIP_NEIGHBOR':219,
  'PM2_CAT_ROUTER_RIP_NETWORK':220,
  'PM2_CAT_ROUTER_RIP_OFFSET_LIST':221,
  'PM2_CAT_ROUTER_RIP_REDISTR':223,
  'PM2_CAT_ROUTER_RIP_INTF':224,
  'PM2_CAT_ROUTER_STATIC':225,
  'PM2_CAT_ROUTER_POLICY':226,
  'PM2_CAT_ROUTER_STATIC6':227,
  'PM2_CAT_ROUTER_OSPF':228,
  'PM2_CAT_ROUTER_OSPF_AREA':229,
  'PM2_CAT_ROUTER_OSPF_AREA_RANGE':230,
  'PM2_CAT_ROUTER_OSPF_AREA_VIRTUAL_LINK':231,
  'PM2_CAT_ROUTER_OSPF_AREA_VIRTUAL_LINK_MD5_KEYS':2385,
  'PM2_CAT_ROUTER_OSPF_AREA_FILTER_LIST':232,
  'PM2_CAT_ROUTER_OSPF_OSPF_INTF':233,
  'PM2_CAT_ROUTER_OSPF_OSPF_INTERFACE_MD5_KEYS':2386,
  'PM2_CAT_ROUTER_OSPF_NETWORK':234,
  'PM2_CAT_ROUTER_OSPF_NEIGHBOR':235,
  'PM2_CAT_ROUTER_OSPF_SUM_ADDR':237,
  'PM2_CAT_ROUTER_OSPF_DISTR_LIST':238,
  'PM2_CAT_ROUTER_OSPF_REDISTR':239,
  'PM2_CAT_ROUTER_BGP':240,
  'PM2_CAT_ROUTER_BGP_AGGR_ADDR':242,
  'PM2_CAT_ROUTER_BGP_NEIGHBOR':243,
  'PM2_CAT_ROUTER_BGP_NEIGHBOR_CONDITIONAL_ADVERTISE':1579,
  'PM2_CAT_ROUTER_BGP_NEIGHBOR_CONDITIONAL_ADVERTISE6':3085,
  'PM2_CAT_ROUTER_BGP_NETWORK':244,
  'PM2_CAT_ROUTER_BGP_REDISTR':245,
  'PM2_CAT_ROUTER_BGP_ADMIN_DISTANCE':246,
  'PM2_CAT_ROUTER_BGP_AGGR_ADDR6':1307,
  'PM2_CAT_ROUTER_BGP_NETWORK6':1308,
  'PM2_CAT_ROUTER_BGP_REDISTR6':1309,
  'PM2_CAT_ROUTER_BGP_NEIGHBOR_GROUP':1591,
  'PM2_CAT_ROUTER_BGP_NEIGHBOR_RANGE':1592,
  'PM2_CAT_ROUTER_BGP_NEIGHBOR_RANGE6':2160,
  'PM2_CAT_ROUTER_BGP_VRF_LEAK':2765,
  'PM2_CAT_ROUTER_BGP_VRF_LEAK_TARGET':2766,
  'PM2_CAT_ROUTER_BGP_VRF_LEAK6':3096,
  'PM2_CAT_ROUTER_BGP_VRF_LEAK6_TARGET':3097,
  'PM2_CAT_ROUTER_BGP_VRF':2974,
  'PM2_CAT_ROUTER_BGP_VRF_LEAKTARGET':2975,
  'PM2_CAT_ROUTER_BGP_VRF6':2976,
  'PM2_CAT_ROUTER_BGP_VRF6_LEAK_TARGET':2977,
  'PM2_CAT_ROUTER_MC':247,
  'PM2_CAT_ROUTER_MC_PIM_SM_GLOBAL':248,
  'PM2_CAT_ROUTER_MC_PIM_SM_GLOBAL_RP_ADDR':249,
  'PM2_CAT_ROUTER_MC_INTF':250,
  'PM2_CAT_ROUTER_MC_INTF_JOIN_GROUP':251,
  'PM2_CAT_ROUTER_MC_INTF_IGMP':252,
  'PM2_CAT_ROUTER_MC_PIM_SM_GLOBAL_VRF':3382,
  'PM2_CAT_ROUTER_MC_PIM_SM_GLOBAL_VRF_RP_ADDR':3383,
  'PM2_CAT_SYS_PROXY_ARP':253,
  'PM2_CAT_SYS_MODEM':257,
  'PM2_CAT_SYS_FTGD_LOG':258,
  'PM2_CAT_LOG_FTGD_SET':259,
  'PM2_CAT_LOG_FTGD_FILTER':260,
  'PM2_CAT_LOG_FTGD_FILTER_EXCLUDE_LIST':374,
  'PM2_CAT_LOG_FTGD_FILTER_EXCLUDE_LIST_FIELDS':375,
  'PM2_CAT_LOG_FTGD_FILTER_FREE_STYLE':3002,
  'PM2_CAT_SYS_WIRELESS_SETS':261,
  'PM2_CAT_SYS_AUX':265,
  'PM2_CAT_VPN_SSL_WEB_BOOKMARKS':281,
  'PM2_CAT_VPN_SSL_WEB_BOOKMARKS_GROUP':282,
  'PM2_CAT_SYS_SWITCH_INTF':284,
  'PM2_CAT_SYS_SESS_SYNC':285,
  'PM2_CAT_SYS_SESS_SYNC_FILTER':286,
  'PM2_CAT_SYS_SESSION_SYNC_FILTER_CUSTOM_SERVICE':1528,
  'PM2_CAT_SYS_FORTIMANAGER':287,
  'PM2_CAT_IPS_SENSOR':288,
  'PM2_CAT_IPS_SENSOR_FILTER':289,
  'PM2_CAT_IPS_SENSOR_OVERRIDE':290,
  'PM2_CAT_IPS_SENSOR_OVERRIDE_EXEMPT_IP':291,
  'PM2_CAT_IPS_SENSOR_ENTRIES':357,
  'PM2_CAT_IPS_SENSOR_ENTRIES_EXEMPT_IP':358,
  'PM2_CAT_LOG_CUS_FIELD':292,
  'PM2_CAT_USER_TACACS':293,
  'PM2_CAT_USER_TACACS_DYNAMIC_MAPPING':1622,
  'PM2_CAT_USER_SET':294,
  'PM2_CAT_USER_SET_AUTH_PORTS':1068,
  'PM2_CAT_WEBFILTER_FTGD_OVRD_USER':295,
  'PM2_CAT_FW_LDB_MONITOR':296,
  'PM2_CAT_IPS_DECODER':297,
  'PM2_CAT_IPS_DECODER_PARA':298,
  'PM2_CAT_IPS_RULE':299,
  'PM2_CAT_IPS_DOS':300,
  'PM2_CAT_IPS_DOS_ANOMALY':301,
  'PM2_CAT_LOG_MEMORY_GLOBAL_SET':303,
  'PM2_CAT_SYS_ALARM':304,
  'PM2_CAT_SYS_ALARM_GROUPS':305,
  'PM2_CAT_SYS_ALARM_GROUPS_FW_POLICY_VIOLATIONS':306,
  'PM2_CAT_ROUTER_AUTH_PATH':307,
  'PM2_CAT_SYS_NPU':308,
  'PM2_CAT_SYS_NPU_PORT_NPU_MAP':1868,
  'PM2_CAT_SYS_NPU_PORT_CPU_MAP':1869,
  'PM2_CAT_SYS_NPU_SW_NP_IN_QUEUES':2221,
  'PM2_CAT_SYS_NPU_ISF_NP_QUEUES':2449,
  'PM2_CAT_SYS_NPU_FP_ANOMALY':2117,
  'PM2_CAT_SYS_NPU_PRIO_PROTOCOL':2653,
  'PM2_CAT_SYS_NPU_HPE':2880,
  'PM2_CAT_SYS_NPU_IP_REASSEMBLY':2881,
  'PM2_CAT_SYS_NPU_DOS_OPTIONS':2952,
  'PM2_CAT_SYS_NPU_PORT_PATH_OPTION':2953,
  'PM2_CAT_SYS_NPU_DSW_DTS_PROF':2966,
  'PM2_CAT_SYS_NPU_DSW_QUEUE_DTS_PROF':2967,
  'PM2_CAT_SYS_NPU_NP_QUEUES':2968,
  'PM2_CAT_SYS_NPU_NP_QUEUES_PROF':2969,
  'PM2_CAT_SYS_NPU_NP_QUEUES_ETHERNET_TYPE':2970,
  'PM2_CAT_SYS_NPU_NP_QUEUES_IP_PROTOCOL':2971,
  'PM2_CAT_SYS_NPU_NP_QUEUES_IP_SRV':2972,
  'PM2_CAT_SYS_NPU_NP_QUEUES_SCHEDR':2973,
  'PM2_CAT_SYS_NPU_TCP_TIMEOUT_PROF':3076,
  'PM2_CAT_SYS_NPU_UDP_TIMEOUT_PROF':3077,
  'PM2_CAT_SYS_NPU_SW_EH_HASH':2282,
  'PM2_CAT_SYS_NPU_BACKGROUND_SSE_SCAN':3170,
  'PM2_CAT_SYS_NPU_SSE_HA_SCAN':3213,
  'PM2_CAT_SYS_NPU_SW_TR_HASH':3277,
  'PM2_CAT_SYS_NPU_NPU_TCAM':3322,
  'PM2_CAT_SYS_NPU_NPU_TCAM_DATA':3323,
  'PM2_CAT_SYS_NPU_NPU_TCAM_MASK':3324,
  'PM2_CAT_SYS_NPU_NPU_TCAM_MIR_ACT':3325,
  'PM2_CAT_SYS_NPU_NPU_TCAM_PRI_ACT':3326,
  'PM2_CAT_SYS_NPU_NPU_TCAM_SACT':3327,
  'PM2_CAT_SYS_NPU_NPU_TCAM_TACT':3328,
  'PM2_CAT_SYS_NPU_ICMP_RATE_CTRL':3363,
  'PM2_CAT_SYS_NPU_ICMP_ERROR_RATE_CTRL':3380,
  'PM2_CAT_SYS_FIPS_CC':309,
  'PM2_CAT_SYS_MGMT_TUN':310,
  'PM2_CAT_SYS_NTP':311,
  'PM2_CAT_SYS_NTP_NTPSERVER':312,
  'PM2_CAT_SYS_AMC':315,
  'PM2_CAT_SYS_CENTMGMT':316,
  'PM2_CAT_SYS_CENTMGMT_SERVER_LIST':1609,
  'PM2_CAT_SYS_WCCP':317,
  'PM2_CAT_FW_INTF_POLICY':318,
  'PM2_CAT_FW_INTF_POLICY6':319,
  'PM2_CAT_SYS_RESOURCE_LIMITS':1018,
  'PM2_CAT_SYS_VDOM_PROPERTY':1019,
  'PM2_CAT_SYS_REPMSG_EC':1020,
  'PM2_CAT_SYS_REPMSG_NAC_QUAR':1021,
  'PM2_CAT_SYS_SNMP_USER':1022,
  'PM2_CAT_FW_TRAFFIC_SHAPER':1025,
  'PM2_CAT_IPS_SETS':1026,
  'PM2_CAT_APPLICATION_NAME':1027,
  'PM2_CAT_APPLICATION_LIST':1028,
  'PM2_CAT_APPLICATION_LIST_ENTRIES':1029,
  'PM2_CAT_APPLICATION_LIST_ENTRIES_PARAS':1030,
  'PM2_CAT_APPLICATION_LIST_ENTRIES_PARAS_MEMBERS':2850,
  'PM2_CAT_APPLICATION_LIST_DEFAULT_NETWORK_SERVICES':2442,
  'PM2_CAT_DLP_RULE':1031,
  'PM2_CAT_DLP_COMPOUND':1032,
  'PM2_CAT_DLP_SENSOR':1038,
  'PM2_CAT_DLP_SENSOR_RULE':1039,
  'PM2_CAT_DLP_SENSOR_COMPOUND_RULE':1040,
  'PM2_CAT_DLP_SENSOR_FILTER':339,
  'PM2_CAT_DLP_SENSOR_ENTRIES':3212,
  'PM2_CAT_USER_BAN':1041,
  'PM2_CAT_WANOPT_SETTINGS':1042,
  'PM2_CAT_WANOPT_PEER':1043,
  'PM2_CAT_WANOPT_AUTH_GROUP':1044,
  'PM2_CAT_WANOPT_SSL_SVR':1045,
  'PM2_CAT_WANOPT_RULE':1046,
  'PM2_CAT_WANOPT_STORAGE':1047,
  'PM2_CAT_ROUTER_SET':1053,
  'PM2_CAT_VPN_SSL_WEB_PORTAL':1054,
  'PM2_CAT_VPN_SSL_WEB_PORTAL_WIDGET':1055,
  'PM2_CAT_VPN_SSL_WEB_PORTAL_WIDGET_BOOKMARKS':1056,
  'PM2_CAT_VPN_SSL_WEB_PORTAL_WIDGET_BOOKMARKS_FORM_DATA':1310,
  'PM2_CAT_VPN_SSL_WEB_PORTAL_OS_CHECK_LIST':1057,
  'PM2_CAT_VPN_SSL_WEB_PORTAL_MAC_ADDR_CHECK_RULE':501,
  'PM2_CAT_VPN_SSL_WEB_PORTAL_BM_GRP':1575,
  'PM2_CAT_VPN_SSL_WEB_PORTAL_BM_GRP_BMS':1576,
  'PM2_CAT_VPN_SSL_WEB_PORTAL_BM_GRP_BMS_FORM_DATA':1577,
  'PM2_CAT_VPN_SSL_WEB_PORTAL_SPLIT_DNS':2025,
  'PM2_CAT_VPN_SSL_WEB_PORTAL_LANDING_PAGE':3256,
  'PM2_CAT_VPN_SSL_WEB_PORTAL_LANDING_PAGE_FORM_DATA':3257,
  'PM2_CAT_VPN_SSL_WEB_USER':1058,
  'PM2_CAT_VPN_SSL_WEB_USER_WIDGET':1059,
  'PM2_CAT_VPN_SSL_WEB_USER_WIDGET_BOOKMARKS':1060,
  'PM2_CAT_VPN_SSL_WEB_USER_WIDGET_BOOKMARKS_FORM_DATA':1314,
  'PM2_CAT_SYS_WIRELESS_AP_STATUS':1061,
  'PM2_CAT_SYS_DIALINSVR':1062,
  'PM2_CAT_FW_SSL_SET':1063,
  'PM2_CAT_WEB_PROXY_EXPLICIT':1066,
  'PM2_CAT_WEB_PROXY_EXPLICIT_PAC_POLICY':2062,
  'PM2_CAT_WEB_PROXY_GLOBAL':1067,
  'PM2_CAT_WANOPT_WEBCACHE':1071,
  'PM2_CAT_WANOPT_WEBCACHE_CACHE_EXEMPTION_LIST':1479,
  'PM2_CAT_NOTIFICATION':1073,
  'PM2_CAT_SYS_GI_GK':1074,
  'PM2_CAT_SYS_REPMSG_IMAGE':1075,
  'PM2_CAT_SYS_REPMSG_GROUP':1076,
  'PM2_CAT_SYS_REPMSG_GROUP_MAIL':1077,
  'PM2_CAT_SYS_REPMSG_GROUP_HTTP':1078,
  'PM2_CAT_SYS_REPMSG_GROUP_MMS':1079,
  'PM2_CAT_SYS_REPMSG_GROUP_MM1':1080,
  'PM2_CAT_SYS_REPMSG_GROUP_MM3':1081,
  'PM2_CAT_SYS_REPMSG_GROUP_MM4':1082,
  'PM2_CAT_SYS_REPMSG_GROUP_MM7':1083,
  'PM2_CAT_SYS_REPMSG_GROUP_FTP':1084,
  'PM2_CAT_SYS_REPMSG_GROUP_NNTP':1085,
  'PM2_CAT_SYS_REPMSG_GROUP_FTGD_WF':1086,
  'PM2_CAT_SYS_REPMSG_GROUP_SPAM':1087,
  'PM2_CAT_SYS_REPMSG_GROUP_IM':1088,
  'PM2_CAT_SYS_REPMSG_GROUP_ALERTMAIL':1315,
  'PM2_CAT_SYS_REPMSG_GROUP_ADMIN':1316,
  'PM2_CAT_SYS_REPMSG_GROUP_AUTH':1317,
  'PM2_CAT_SYS_REPMSG_GROUP_EC':1318,
  'PM2_CAT_SYS_REPMSG_GROUP_NAC_QUAR':1319,
  'PM2_CAT_SYS_REPMSG_GROUP_TRAFFIC_QUOTA':1320,
  'PM2_CAT_SYS_REPMSG_GROUP_SSLVPN':1321,
  'PM2_CAT_SYS_REPMSG_GROUP_WEBPROXY':1408,
  'PM2_CAT_SYS_REPLACEMSG_GROUP_CAPTIVE_PORTAL_DFLT':333,
  'PM2_CAT_SYS_REPLACEMSG_GROUP_UTM':395,
  'PM2_CAT_SYS_REPLACEMSG_GROUP_CUSTOM_MESSAGE':396,
  'PM2_CAT_SYS_REPLACEMSG_GROUP_DEVICE_DETECTION_PORTAL':480,
  'PM2_CAT_SYS_REPMSG_GROUP_ICAP':2057,
  'PM2_CAT_SYS_REPMSG_GROUP_AUTOMATION':2978,
  'PM2_CAT_SYS_DYNAMIC_PROF':1089,
  'PM2_CAT_SYS_CARRIER_ENDPOINT_TRANSLATION':1090,
  'PM2_CAT_SYS_MEM_MGR':1091,
  'PM2_CAT_SYS_REPMSG_MMS':1092,
  'PM2_CAT_SYS_REPMSG_MM1':1093,
  'PM2_CAT_SYS_REPMSG_MM3':1094,
  'PM2_CAT_SYS_REPMSG_MM4':1095,
  'PM2_CAT_SYS_REPMSG_MM7':1096,
  'PM2_CAT_FW_MMS_PROF':1097,
  'PM2_CAT_FW_MMS_PROF_NOTIFICATION':1099,
  'PM2_CAT_FW_MMS_PROF_NOTIF_MSISDN':1200,
  'PM2_CAT_FW_MMS_PROF_FLOOD':1201,
  'PM2_CAT_FW_MMS_PROF_DUPE':1202,
  'PM2_CAT_FW_MMS_PROF_OUTBREAK_PREVENTION':2087,
  'PM2_CAT_FW_GTP':1203,
  'PM2_CAT_FW_GTP_APN':1204,
  'PM2_CAT_FW_GTP_IMSI':1205,
  'PM2_CAT_FW_GTP_POLICY':1206,
  'PM2_CAT_FW_GTP_IE_REMOVE_POLICY':1207,
  'PM2_CAT_FW_GTP_IP_POLICY':1208,
  'PM2_CAT_FW_GTP_NOIP_POLICY':1209,
  'PM2_CAT_FW_GTP_MESSAGE_FILTER':1210,
  'PM2_CAT_FW_GTP_IE_VALIDATION':1211,
  'PM2_CAT_FW_GTP_MESSAGE_RATE_LIMIT':1212,
  'PM2_CAT_FW_GTP_PER_APN_SHAPER':1585,
  'PM2_CAT_FW_GTP_MESSAGE_RATE_LIMIT_V0':1586,
  'PM2_CAT_FW_GTP_MESSAGE_RATE_LIMIT_V1':1587,
  'PM2_CAT_FW_GTP_MESSAGE_RATE_LIMIT_V2':1588,
  'PM2_CAT_FW_GTP_POLICY_V2':2530,
  'PM2_CAT_FW_CARRIER_ENDPOINT_BWL':1213,
  'PM2_CAT_FW_CARRIER_ENDPOINT_BWL_ENTRIES':1214,
  'PM2_CAT_FW_CARRIER_ENDPOINT_IP_FILTER':1215,
  'PM2_CAT_AV_NOTIFICATION':1216,
  'PM2_CAT_AV_NOTIFICATION_ENTRIES':1217,
  'PM2_CAT_ENDPOINT_CONTROL_SETS':1222,
  'PM2_CAT_SYS_DNS_DATABASE':1322,
  'PM2_CAT_SYS_DNS_DATABASE_DNS_ENTRY':1323,
  'PM2_CAT_SYS_PASSWORD_POLICY':1324,
  'PM2_CAT_SYS_VDOM_DNS':1325,
  'PM2_CAT_SYS_REPMSG_TRAFFIC_QUOTA':1326,
  'PM2_CAT_WEBFILTER_CONTENT':1327,
  'PM2_CAT_WEBFILTER_CONTENT_ENTRIES':1328,
  'PM2_CAT_WEBFILTER_CONTENT_HEADER':1329,
  'PM2_CAT_WEBFILTER_CONTENT_HEADER_ENTRIES':1330,
  'PM2_CAT_ENDPOINT_CONTROL_APP_DETECT_PREDEF_SIGNATURE':1331,
  'PM2_CAT_ENDPOINT_CONTROL_APP_DETECT_PREDEF_CATEGORY':1332,
  'PM2_CAT_ENDPOINT_CONTROL_APP_DETECT_PREDEF_VENDOR':1333,
  'PM2_CAT_ENDPOINT_CONTROL_APP_DETECT_PREDEF_GROUP':1334,
  'PM2_CAT_ENDPOINT_CONTROL_APP_DETECT_RULE_LIST':1335,
  'PM2_CAT_ENDPOINT_CONTROL_APP_DETECT_RULE_LIST_ENTRIES':1336,
  'PM2_CAT_ENDPOINT_CONTROL_PROF':1337,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_WINMAC_SET':485,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_WINMAC_SET_FCT_VPN_SET':486,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_WINMAC_SET_EXTRA_BUFFER_ENTRIES':1628,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_WINMAC_SET_AV_SCAN_EXCLUSIONS':1702,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_WINMAC_SET_FORTICLIENT_OPERATING_SYSTEM':1737,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_WINMAC_SET_FORTICLIENT_RUNNING_APP':1738,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_WINMAC_SET_FORTICLIENT_REGISTRY_ENTRY':1739,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_WINMAC_SET_FORTICLIENT_OWN_FILE':1740,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_ANDROID_SET':487,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_ANDROID_SET_FCT_VPN_SET':488,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_IOS_SET':489,
  'PM2_CAT_ENDPOINT_CONTROL_PROF_FCT_IOS_SET_CLT_VPN_SET':504,
  'PM2_CAT_FW_SCHED_GROUP':1338,
  'PM2_CAT_LOG_SYSLOGD_OVERRIDE_SET':1340,
  'PM2_CAT_LOG_SYSLOGD_OVERRIDE_SET_CUSTOM_FIELD_NAME':1781,
  'PM2_CAT_LOG_SYSLOGD_OVERRIDE_FILTER':1341,
  'PM2_CAT_LOG_SYSLOGD_OVERRIDE_FILTER_EXCLUDE_LIST':366,
  'PM2_CAT_LOG_SYSLOGD_OVERRIDE_FILTER_EXCLUDE_LIST_FIELDS':367,
  'PM2_CAT_LOG_SYSLOGD_OVERRIDE_FILTER_FREE_STYLE':2995,
  'PM2_CAT_LOG_FA_OVERRIDE_SET':1342,
  'PM2_CAT_LOG_FA_OVERRIDE_FILTER':1343,
  'PM2_CAT_LOG_FA_OVERRIDE_FILTER_EXCLUDE_LIST':382,
  'PM2_CAT_LOG_FA_OVERRIDE_FILTER_EXCLUDE_LIST_FIELDS':383,
  'PM2_CAT_LOG_FA_OVERRIDE_FILTER_FREE_STYLE':3009,
  'PM2_CAT_ROUTER_ACCESS_LIST6':1344,
  'PM2_CAT_ROUTER_ACCESS_LIST6_RULE':1345,
  'PM2_CAT_ROUTER_PREFIX_LIST6':1346,
  'PM2_CAT_ROUTER_PREFIX_LIST6_RULE':1347,
  'PM2_CAT_ROUTER_RIPNG':1348,
  'PM2_CAT_ROUTER_RIPNG_DISTR_LIST':1349,
  'PM2_CAT_ROUTER_RIPNG_NEIGHBOR':1350,
  'PM2_CAT_ROUTER_RIPNG_AGGR_ADDR':1351,
  'PM2_CAT_ROUTER_RIPNG_OFFSET_LIST':1352,
  'PM2_CAT_ROUTER_RIPNG_REDISTR':1354,
  'PM2_CAT_ROUTER_RIPNG_INTF':1355,
  'PM2_CAT_ROUTER_RIPNG_DISTANCE':447,
  'PM2_CAT_ROUTER_RIPNG_NETWORK':1643,
  'PM2_CAT_ROUTER_OSPF6':1356,
  'PM2_CAT_ROUTER_OSPF6_AREA':1357,
  'PM2_CAT_ROUTER_OSPF6_AREA_RANGE':1358,
  'PM2_CAT_ROUTER_OSPF6_AREA_VIRTUAL_LINK':1359,
  'PM2_CAT_ROUTER_OSPF6_AREA_VIRTUAL_LINK_IPSEC_KEYS':2240,
  'PM2_CAT_ROUTER_OSPF6_AREA_IPSEC_KEYS':2241,
  'PM2_CAT_ROUTER_OSPF6_OSPF6_INTF':1360,
  'PM2_CAT_ROUTER_OSPF6_OSPF6_INTERFACE_NEIGHBOR':511,
  'PM2_CAT_ROUTER_OSPF6_OSPF6_INTF_IPSEC_KEYS':2242,
  'PM2_CAT_ROUTER_OSPF6_REDISTR':1362,
  'PM2_CAT_ROUTER_OSPF6_SUMMARY_ADDRESS':351,
  'PM2_CAT_FW_SHAPER_TRAFFIC_SHAPER':1364,
  'PM2_CAT_FW_SHAPER_PER_IP_SHAPER':1365,
  'PM2_CAT_VPN_SSL_WEB_VIRTUAL_DESKTOP_APP_LIST':1367,
  'PM2_CAT_VPN_SSL_WEB_VIRTUAL_DESKTOP_APP_LIST_APPS':1368,
  'PM2_CAT_VPN_SSL_WEB_HOST_CHECK_SOFTWARE':1370,
  'PM2_CAT_VPN_SSL_WEB_HOST_CHECK_SOFTWARE_CHECK_ITEM_LIST':1371,
  'PM2_CAT_REPORT_DATASET':1373,
  'PM2_CAT_REPORT_DATASET_FIELD':1374,
  'PM2_CAT_REPORT_DATASET_PARAMETERS':463,
  'PM2_CAT_REPORT_CHART':1375,
  'PM2_CAT_REPORT_CHART_X_SERIES':1376,
  'PM2_CAT_REPORT_CHART_Y_SERIES':1377,
  'PM2_CAT_REPORT_CHART_CATEGORY_SERIES':1378,
  'PM2_CAT_REPORT_CHART_VALUE_SERIES':1379,
  'PM2_CAT_REPORT_CHART_COLUMN':1380,
  'PM2_CAT_REPORT_CHART_COLUMN_MAPPING':1381,
  'PM2_CAT_REPORT_CHART_DRILL_DOWN_CHARTS':464,
  'PM2_CAT_REPORT_SUM':1382,
  'PM2_CAT_SYS_CHASSIS_LOADBALANCE':1383,
  'PM2_CAT_SYS_CHASSIS_LOADBALANCE_CONF_SYNC':1409,
  'PM2_CAT_WEBFILTER_COOKIE_OVRD':1385,
  'PM2_CAT_FW_SNIFF_INTF_POLICY':1387,
  'PM2_CAT_FW_SNIFF_INTF_POLICY6':1391,
  'PM2_CAT_AV_SETS':1392,
  'PM2_CAT_WIRELESS_CONTROLLER_GLOBAL':1393,
  'PM2_CAT_WIRELESS_CONTROLLER_GLOBAL_AC_LIST':1394,
  'PM2_CAT_WIRELESS_CONTROLLER_TIMERS':1395,
  'PM2_CAT_WIRELESS_CONTROLLER_VAP':1396,
  'PM2_CAT_WIRELESS_CONTROLLER_VAP_MAC_FILTER_LIST':329,
  'PM2_CAT_WIRELESS_CONTROLLER_VAP_PORTAL_MESSAGE_OVERRIDES':188,
  'PM2_CAT_WIRELESS_CONTROLLER_VAP_VLAN_POOL':1709,
  'PM2_CAT_WIRELESS_CONTROLLER_VAP_MPSK_KEY':1878,
  'PM2_CAT_WIRELESS_CONTROLLER_VAP_DYNAMIC_MAPPING':2140,
  'PM2_CAT_WIRELESS_CONTROLLER_VAP_VLAN_NAME':3164,
  'PM2_CAT_WIRELESS_CONTROLLER_VAP_GROUP':1397,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP':1399,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_DENY_MAC_LIST':1401,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_LAN':1533,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_SPLIT_TUNNELING_ACL':1590,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_RADIO_1':1706,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_RADIO_2':1707,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_RADIO_3':2621,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_RADIO_4':2711,
  'PM2_CAT_WIRELESS_CONTROLLER_AP_STATUS':1402,
  'PM2_CAT_SYS_SFLOW':1410,
  'PM2_CAT_SYS_SFLOW_COLLECTORS':3313,
  'PM2_CAT_SYS_VDOM_SFLOW':1411,
  'PM2_CAT_SYS_VDOM_SFLOW_COLLECTORS':3314,
  'PM2_CAT_SYS_REPMSG_WEBPROXY':1412,
  'PM2_CAT_WEBFILTER_PROF':1413,
  'PM2_CAT_WEBFILTER_PROF_HTTP':1414,
  'PM2_CAT_WEBFILTER_PROF_HTTPS':1415,
  'PM2_CAT_WEBFILTER_PROF_WEB':1416,
  'PM2_CAT_WEBFILTER_PROF_FTGD_WF':1417,
  'PM2_CAT_WEBFILTER_PROF_FTGD_WF_FILTERS':341,
  'PM2_CAT_WEBFILTER_PROF_FTGD_WF_QUOTA':236,
  'PM2_CAT_WEBFILTER_PROF_FTGD_WF_RISK':4068,
  'PM2_CAT_WEBFILTER_PROF_FTGD_QUOTA':1419,
  'PM2_CAT_WEBFILTER_PROF_URL_EXTRACTION':1471,
  'PM2_CAT_WEBFILTER_PROF_OVERRIDE':186,
  'PM2_CAT_WEBFILTER_PROF_YOUTUBE_CHANNEL_FILTER':2071,
  'PM2_CAT_WEBFILTER_PROF_FILE_FILTER':2300,
  'PM2_CAT_WEBFILTER_PROF_FILE_FILTER_ENTRIES':2381,
  'PM2_CAT_WEBFILTER_PROF_ANTIPHISH':2751,
  'PM2_CAT_WEBFILTER_PROF_ANTIPHISH_INSPECTION_ENTRIES':2752,
  'PM2_CAT_WEBFILTER_PROF_ANTIPHISH_CUSTOM_PATTERNS':2753,
  'PM2_CAT_AV_PROF':1420,
  'PM2_CAT_AV_PROF_HTTP':1421,
  'PM2_CAT_AV_PROF_FTP':1422,
  'PM2_CAT_AV_PROF_IMAP':1423,
  'PM2_CAT_AV_PROF_POP3':1424,
  'PM2_CAT_AV_PROF_SMTP':1425,
  'PM2_CAT_AV_PROF_NNTP':1426,
  'PM2_CAT_AV_PROF_IM':1427,
  'PM2_CAT_AV_PROF_NAC_QUAR':1428,
  'PM2_CAT_AV_PROF_HTTPS':1429,
  'PM2_CAT_AV_PROF_IMAPS':1430,
  'PM2_CAT_AV_PROF_POP3S':1431,
  'PM2_CAT_AV_PROF_SMTPS':1432,
  'PM2_CAT_AV_PROF_FTPS':214,
  'PM2_CAT_AV_PROF_MAPI':416,
  'PM2_CAT_AV_PROF_SMB':417,
  'PM2_CAT_AV_PROF_CONTENT_DISARM':2029,
  'PM2_CAT_AV_PROF_OUTBREAK_PREVENTION':1891,
  'PM2_CAT_AV_PROFILE_CIFS':1992,
  'PM2_CAT_AV_PROF_SSH':2594,
  'PM2_CAT_SPAMFILTER_PROF':1433,
  'PM2_CAT_SPAMFILTER_PROF_IMAP':1434,
  'PM2_CAT_SPAMFILTER_PROF_POP3':1435,
  'PM2_CAT_SPAMFILTER_PROF_SMTP':1436,
  'PM2_CAT_SPAMFILTER_PROF_IMAPS':1437,
  'PM2_CAT_SPAMFILTER_PROF_POP3S':1438,
  'PM2_CAT_SPAMFILTER_PROF_SMTPS':1439,
  'PM2_CAT_SPAMFILTER_PROF_MSN_HOTMAIL':346,
  'PM2_CAT_SPAMFILTER_PROF_YAHOO_MAIL':347,
  'PM2_CAT_SPAMFILTER_PROF_GMAIL':348,
  'PM2_CAT_SPAMFILTER_PROF_MAPI':418,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS':1440,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_HTTP':1441,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_HTTPS':1442,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_FTP':1443,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_IMAP':1444,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_POP3':1445,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_SMTP':1446,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_NNTP':1447,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_IM':1448,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_MAIL_SIGNATURE':1449,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_IMAPS':1450,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_POP3S':1451,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_SMTPS':1452,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_FTPS':241,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_SSL_SERVER':349,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_DNS':426,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_MAPI':427,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_CIFS':2093,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_CIFS_FILE_FILTER':2936,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_CIFS_FILE_FILTER_ENTRIES':2937,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_CIFS_SVR_KEYTAB':2938,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_SSH':425,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_RTMP':3040,
  'PM2_CAT_FW_PROF_PROTOCOL_OPTIONS_PROXY_REDIRECT':4059,
  'PM2_CAT_FW_PROF_GROUP':1453,
  'PM2_CAT_LOG_EVENTFILTER':1454,
  'PM2_CAT_ROUTER_ISIS':1455,
  'PM2_CAT_ROUTER_ISIS_ISIS_NET':1456,
  'PM2_CAT_ROUTER_ISIS_ISIS_INTF':1457,
  'PM2_CAT_ROUTER_ISIS_SUM_ADDR':1458,
  'PM2_CAT_ROUTER_ISIS_REDISTR':1459,
  'PM2_CAT_ROUTER_ISIS_SUMMARY_ADDRESS6':2046,
  'PM2_CAT_ROUTER_ISIS_REDISTRIBUTE6':2047,
  'PM2_CAT_SYS_AMC_SLOT':1460,
  'PM2_CAT_SYS_STORAGE':1461,
  'PM2_CAT_REPORT_STYLE':1462,
  'PM2_CAT_REPORT_LAYOUT':1463,
  'PM2_CAT_REPORT_LAYOUT_PAGE':1464,
  'PM2_CAT_REPORT_LAYOUT_PAGE_HEADER':1465,
  'PM2_CAT_REPORT_LAYOUT_PAGE_HEADER_HEADER_ITEM':1466,
  'PM2_CAT_REPORT_LAYOUT_PAGE_FOOTER':1467,
  'PM2_CAT_REPORT_LAYOUT_PAGE_FOOTER_FOOTER_ITEM':1468,
  'PM2_CAT_REPORT_LAYOUT_BODY_ITEM':1469,
  'PM2_CAT_REPORT_LAYOUT_BODY_ITEM_LIST':1481,
  'PM2_CAT_REPORT_LAYOUT_BODY_ITEM_PARAMETERS':465,
  'PM2_CAT_AV_MMS_CHECKSUM':1472,
  'PM2_CAT_AV_MMS_CHECKSUM_ENTRIES':1473,
  'PM2_CAT_VOIP_PROF':1482,
  'PM2_CAT_VOIP_PROF_SIP':1483,
  'PM2_CAT_VOIP_PROF_SCCP':1484,
  'PM2_CAT_VOIP_PROF_MSRP':3136,
  'PM2_CAT_NETSCAN_ASSETS':1485,
  'PM2_CAT_NETSCAN_SETS':1486,
  'PM2_CAT_FW_CENTRAL_NAT':1487,
  'PM2_CAT_REPORT_THEME':1490,
  'PM2_CAT_SYS_FMC':36,
  'PM2_CAT_SYS_FMC_SLOT':81,
  'PM2_CAT_DEV_DYNAMIC_INTF':1491,
  'PM2_CAT_DEV_DYNAMIC_RADIUS':1538,
  'PM2_CAT_DEV_DYNAMIC_ADDR':1492,
  'PM2_CAT_DEV_DYNAMIC_ADDR6':1493,
  'PM2_CAT_DEV_HA_NONHACONF':1001,
  'PM2_CAT_SYS_OBJ_TAG':150,
  'PM2_CAT_SYS_ELBC':153,
  'PM2_CAT_SYS_DHCP6_SVR':169,
  'PM2_CAT_SYS_DHCP6_SVR_IP_RANGE':176,
  'PM2_CAT_SYS_DHCP6_SVR_PREFIX_RANGE':2018,
  'PM2_CAT_SYS_DHCP6_SVR_OPTIONS':3368,
  'PM2_CAT_SYS_PORT_PAIR':180,
  'PM2_CAT_SYS_3G_MODEM_CUS':182,
  'PM2_CAT_APPLICATION_RULE_SET':183,
  'PM2_CAT_USER_FORTITOKEN':184,
  'PM2_CAT_USER_SMS_PRVD':185,
  'PM2_CAT_WEBFILTER_OVERRIDE':212,
  'PM2_CAT_WEBFILTER_OVRD_USER':213,
  'PM2_CAT_FW_SRV_EXP_WEB':215,
  'PM2_CAT_FW_SRV_GRPEXP_WEB':222,
  'PM2_CAT_FW_LN_POLICY':270,
  'PM2_CAT_FW_LN_POLICY6':272,
  'PM2_CAT_WEB_PROXY_FWD_SERV':273,
  'PM2_CAT_IPS_RULE_SET':274,
  'PM2_CAT_WIRELESS_CONTROLLER_SET':275,
  'PM2_CAT_WIRELESS_CONTROLLER_SET_OFFENDING_SSID':2225,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_PROF':276,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_PROF_PLATFORM':277,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_PROF_RADIO_1':278,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_PROF_RADIO_2':320,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_PROF_DENY_MAC_LIST':322,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_PROF_LAN':1532,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_PROF_SPLIT_TUNNELING_ACL':1589,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_PROF_LBS':1625,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_PROF_RADIO_3':2620,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_PROF_RADIO_4':2710,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_PROF_ESL_SES_DONGLE':3095,
  'PM2_CAT_LOG_REMOTE_SET':323,
  'PM2_CAT_ROUTER_MC_FLOW':324,
  'PM2_CAT_ROUTER_MC_FLOW_FLOWS':325,
  'PM2_CAT_VPN_FIPS_CC':326,
  'PM2_CAT_FTP_PROXY_EXPLICIT':327,
  'PM2_CAT_SYS_DDNS':330,
  'PM2_CAT_SYS_REPMSG_CAPTIVE_PORTAL_DFLT':331,
  'PM2_CAT_DLP_FILEPAT':335,
  'PM2_CAT_DLP_FILEPAT_ENTRIES':336,
  'PM2_CAT_DLP_FP_SENSITIVITY':337,
  'PM2_CAT_DLP_FP_DOC_SOURCE':338,
  'PM2_CAT_WEBFILTER_FTGD_WARNING':342,
  'PM2_CAT_ICAP_SVR':343,
  'PM2_CAT_ICAP_PROF':344,
  'PM2_CAT_ICAP_PROF_ICAP_HEADERS':2224,
  'PM2_CAT_ICAP_PROF_RESPMOD_FORWARD_RULES':2595,
  'PM2_CAT_ICAP_PROF_RESPMOD_FORWARD_RULES_HEADER_GROUP':2598,
  'PM2_CAT_ENDPOINT_CONTROL_APP_DETECT_SIG_SETTINGS':345,
  'PM2_CAT_LOG_GUI':350,
  'PM2_CAT_SYS_MONITORS':352,
  'PM2_CAT_DLP_SETTINGS':353,
  'PM2_CAT_SYS_DNS_SERVER':340,
  'PM2_CAT_SYS_SP':354,
  'PM2_CAT_USER_FSSO':321,
  'PM2_CAT_USER_FSSO_DYNAMIC_MAPPING':1708,
  'PM2_CAT_ROUTER_GWDETECT':355,
  'PM2_CAT_DEV_DYNAMIC_IPPOOL':1496,
  'PM2_CAT_DEV_DYNAMIC_VIP':1497,
  'PM2_CAT_DEV_DYNAMIC_VIP_REALSRV':1498,
  'PM2_CAT_SYS_NPU_CASCADE_CLUSTER':356,
  'PM2_CAT_DEV_DYNAMIC_CERT_LOCAL':1505,
  'PM2_CAT_DEV_DYNAMIC_VPNTUNNEL':1510,
  'PM2_CAT_SYS_BYPASS':359,
  'PM2_CAT_SYS_DEDICATED_MGMT':360,
  'PM2_CAT_SYS_STP':385,
  'PM2_CAT_SYS_PHYSICAL_SWITCH':386,
  'PM2_CAT_SYS_PHYSICAL_SWITCH_PORT':387,
  'PM2_CAT_SYS_VIRTUAL_SWITCH':388,
  'PM2_CAT_SYS_VIRTUAL_SWITCH_PORT':389,
  'PM2_CAT_SYS_SMS_SERVER':390,
  'PM2_CAT_SYS_FSSO_POLLING':392,
  'PM2_CAT_SYS_REPMSG_UTM':394,
  'PM2_CAT_SPAMFILTER_BWL':397,
  'PM2_CAT_SPAMFILTER_BWL_ENTRIES':398,
  'PM2_CAT_CLIENT_REPUTATION_PROF':399,
  'PM2_CAT_CLIENT_REPUTATION_PROF_LEVEL':400,
  'PM2_CAT_CLIENT_REPUTATION_PROF_IPS':401,
  'PM2_CAT_CLIENT_REPUTATION_PROF_WEB':402,
  'PM2_CAT_CLIENT_REPUTATION_PROF_GEOLOCATION':403,
  'PM2_CAT_CLIENT_REPUTATION_PROF_APPLICATION':404,
  'PM2_CAT_VPN_CERT_OCSP_SERVER':406,
  'PM2_CAT_VPN_CERT_SET':407,
  'PM2_CAT_VPN_CERT_SET_CRL_VERIFICATION':3090,
  'PM2_CAT_USER_PASSWORD_POLICY':408,
  'PM2_CAT_USER_FSSO_POLLING':409,
  'PM2_CAT_USER_FSSO_POLLING_ADGRP':410,
  'PM2_CAT_WEBFILTER_SEARCH_ENGINE':412,
  'PM2_CAT_SYS_NETWORK_VISIBILITY':413,
  'PM2_CAT_FW_IPPOOL6':420,
  'PM2_CAT_FW_IPPOOL6_DYNAMIC_MAPPING':1603,
  'PM2_CAT_FW_VIP6':421,
  'PM2_CAT_FW_VIP6_DYNAMIC_MAPPING':1604,
  'PM2_CAT_FW_VIP6_DYNAMIC_MAPPING_REALSRV':3145,
  'PM2_CAT_FW_VIP6_DYNAMIC_MAPPING_SSL_CIPHER_SUITES':3146,
  'PM2_CAT_FW_VIP6_REALSRV':1836,
  'PM2_CAT_FW_VIP6_SSL_CIPHER_SUITES':1837,
  'PM2_CAT_FW_VIP6_SSL_SERVER_CIPHER_SUITES':1838,
  'PM2_CAT_FW_VIP6_QUIC':3318,
  'PM2_CAT_FW_VIPGRP6':423,
  'PM2_CAT_CENTRAL_DNAT':1828,
  'PM2_CAT_CENTRAL_DNAT6':2942,
  'PM2_CAT_FW_IDENTITY_BASED_ROUTE':428,
  'PM2_CAT_FW_IDENTITY_BASED_ROUTE_RULE':429,
  'PM2_CAT_FW_AUTH_PORTAL':430,
  'PM2_CAT_WEB_PROXY_DEBUG_URL':431,
  'PM2_CAT_FW_TTL_POLICY':432,
  'PM2_CAT_FW_POLICY64':433,
  'PM2_CAT_FW_ISF_ACL':434,
  'PM2_CAT_FW_DOS_POLICY':435,
  'PM2_CAT_FW_DOS_POLICY_ANOMALY':436,
  'PM2_CAT_FW_SNIFFER':437,
  'PM2_CAT_FW_SNIFFER_ANOMALY':481,
  'PM2_CAT_WIRELESS_CONTROLLER_WIDS_PROFILE':438,
  'PM2_CAT_SWITCH_CONTROLLER_VLAN':439,
  'PM2_CAT_SWITCH_CONTROLLER_VLAN_PORTAL_MESSAGE_OVERRIDES':440,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH':441,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_PORTS':442,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_PORTS_DHCP_SNOOP_OPTION82_OVERRIDE':3263,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_SWITCH_STP_SETTINGS':1745,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_CUSTOM_COMMAND':1746,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_STP_SETTINGS':1855,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_SWITCH_LOG':1856,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_STORM_CONTROL':1857,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_IGMP_SNOOPING':1858,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_IGMP_SNOOPING_VLANS':3138,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_802_1X_SETTINGS':1859,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_MIRROR':2065,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_STATIC_MAC':2218,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_STP_INSTANCE':2350,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_SNMP_SYSINFO':2228,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_SNMP_TRAP_THRESHOLD':2229,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_SNMP_COMM':2230,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_SNMP_COMM_HOSTS':2231,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_SNMP_USER':2232,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_REMOTE_LOG':2233,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_IP_SOURCE_GUARD':2756,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_IP_SOURCE_GUARD_BINDING_ENTRY':2757,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_DHCP_SNOOPING_STATIC_CLIENT':3248,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_ROUTE_OFFLOAD_ROUTER':3282,
  'PM2_CAT_SWITCH_CONTROLLER_MANAGED_SWITCH_VLAN':3338,
  'PM2_CAT_LOG_FTGD_OVERRIDE_SETTING':443,
  'PM2_CAT_LOG_SET':444,
  'PM2_CAT_LOG_FTGD_OVERRIDE_FILTER':445,
  'PM2_CAT_LOG_FTGD_OVERRIDE_FILTER_EXCLUDE_LIST':1624,
  'PM2_CAT_LOG_FTGD_OVERRIDE_FILTER_EXCLUDE_LIST_FIELDS':1623,
  'PM2_CAT_LOG_FTGD_OVERRIDE_FILTER_FREE_STYLE':3003,
  'PM2_CAT_SYS_EMAIL_SERVER':446,
  'PM2_CAT_ROUTER_POLICY6':448,
  'PM2_CAT_ROUTER_BFD':449,
  'PM2_CAT_ROUTER_BFD_NEIGHBOR':450,
  'PM2_CAT_ROUTER_BFD_MULTIHOP_TEMPLATE':1894,
  'PM2_CAT_SYS_NAT64':451,
  'PM2_CAT_SYS_NAT64_SECONDARY_PREFIX':2050,
  'PM2_CAT_SYS_VDOM_RADIUS_SERVER':452,
  'PM2_CAT_FW_IP_TRANSLATION':453,
  'PM2_CAT_WANOPT_PROF':457,
  'PM2_CAT_WANOPT_PROF_HTTP':458,
  'PM2_CAT_WANOPT_PROF_CIFS':459,
  'PM2_CAT_WANOPT_PROF_MAPI':460,
  'PM2_CAT_WANOPT_PROF_FTP':461,
  'PM2_CAT_WANOPT_PROF_TCP':462,
  'PM2_CAT_FW_MULTICAST_ADDRESS':269,
  'PM2_CAT_FW_MULTICAST_ADDRESS_TAGGING':2019,
  'PM2_CAT_FW_SRV_CATEGORY':384,
  'PM2_CAT_WEBFILTER_IPS_URLFILTER_SETTING':391,
  'PM2_CAT_WEBFILTER_IPS_URLFILTER_CACHE_SETTING':422,
  'PM2_CAT_SYS_IPS_URLFILTER_DNS':466,
  'PM2_CAT_SYS_GEOIP_OVERRIDE':467,
  'PM2_CAT_SYS_GEOIP_OVERRIDE_IP_RANGE':468,
  'PM2_CAT_SYS_GEOIP_OVERRIDE_IP6_RANGE':2758,
  'PM2_CAT_APPLICATION_CUS':474,
  'PM2_CAT_USER_DEVICE_CATEGORY':475,
  'PM2_CAT_USER_DEVICE':476,
  'PM2_CAT_USER_DEVICE_DYNAMIC_MAPPING':1651,
  'PM2_CAT_USER_DEVICE_TAGGING':2002,
  'PM2_CAT_USER_DEVICE_GROUP':477,
  'PM2_CAT_USER_DEVICE_GROUP_DYNAMIC_MAPPING':1652,
  'PM2_CAT_USER_DEVICE_GROUP_TAGGING':2003,
  'PM2_CAT_USER_DEVICE_ACCESS_LIST':478,
  'PM2_CAT_USER_DEVICE_ACCESS_LIST_DEVICE_LIST':479,
  'PM2_CAT_SYS_SP3_PORT':482,
  'PM2_CAT_SYS_SERVER_PROBE':483,
  'PM2_CAT_SYS_PROBE_RESPONSE':484,
  'PM2_CAT_SYS_REPMSG_DEVICE_DETECTION_PORTAL':473,
  'PM2_CAT_IPS_DBINFO':491,
  'PM2_CAT_FW_DEEP_INSPECTION_OPTIONS':492,
  'PM2_CAT_FW_DEEP_INSPECTION_OPTIONS_SSL':493,
  'PM2_CAT_FW_DEEP_INSPECTION_OPTIONS_HTTPS':494,
  'PM2_CAT_FW_DEEP_INSPECTION_OPTIONS_FTPS':495,
  'PM2_CAT_FW_DEEP_INSPECTION_OPTIONS_IMAPS':496,
  'PM2_CAT_FW_DEEP_INSPECTION_OPTIONS_POP3S':497,
  'PM2_CAT_FW_DEEP_INSPECTION_OPTIONS_SMTPS':498,
  'PM2_CAT_FW_DEEP_INSPECTION_OPTIONS_SSH':499,
  'PM2_CAT_FW_DEEP_INSPECTION_OPTIONS_SSL_SERVER':500,
  'PM2_CAT_VPN_SSL_WEB_REALM':503,
  'PM2_CAT_EC_FCT_REG_SYNC':505,
  'PM2_CAT_EC_CLIENT':506,
  'PM2_CAT_FW_VIP64':508,
  'PM2_CAT_FW_VIP64_DYNAMIC_MAPPING':1605,
  'PM2_CAT_FW_VIP64_REALSRV':1839,
  'PM2_CAT_FW_VIP46':510,
  'PM2_CAT_FW_VIP46_DYNAMIC_MAPPING':1607,
  'PM2_CAT_FW_VIP46_REALSRV':1840,
  'PM2_CAT_FW_VIPGRP64':512,
  'PM2_CAT_FW_VIPGRP46':513,
  'PM2_CAT_FW_POLICY46':514,
  'PM2_CAT_ROUTER_MC6':515,
  'PM2_CAT_ROUTER_MC6_INTF':516,
  'PM2_CAT_ROUTER_MC6_PIM_SM_GLB':517,
  'PM2_CAT_ROUTER_MC6_PIM_SM_GLB_RP_ADDRESS':518,
  'PM2_CAT_FW_DOS_POLICY6':502,
  'PM2_CAT_FW_DOS_POLICY6_ANOMALY':509,
  'PM2_CAT_DEV_DYNAMIC_IPPOOL6':1514,
  'PM2_CAT_DEV_DYNAMIC_VIP6':1516,
  'PM2_CAT_DEV_DYNAMIC_VIP64':1518,
  'PM2_CAT_DEV_DYNAMIC_VIP46':1520,
  'PM2_CAT_SYS_IPIP_TUNNEL':519,
  'PM2_CAT_ENDPOINT_CONTROL_REG_FCT':520,
  'PM2_CAT_WEB_PROXY_URL_MATCH':521,
  'PM2_CAT_WEB_PROXY_FWD_SERV_GROUP':1526,
  'PM2_CAT_WEB_PROXY_FWD_SERV_GROUP_SERVER_LIST':1527,
  'PM2_CAT_SYS_FORTISANDBOX':1529,
  'PM2_CAT_FW_IPV6_EH_FILTER':1530,
  'PM2_CAT_SYS_IPV6_NEIGHBOR_CACHE':1531,
  'PM2_CAT_USER_POP3':1561,
  'PM2_CAT_WEB_PROXY_PROF':1560,
  'PM2_CAT_WEB_PROXY_PROF_HEADERS':1540,
  'PM2_CAT_SYS_NETFLOW':1541,
  'PM2_CAT_SYS_NETFLOW_COLLECTORS':3329,
  'PM2_CAT_SYS_NETFLOW_EXCLUSION_FILTERS':3310,
  'PM2_CAT_SYS_VDOM_NETFLOW':1542,
  'PM2_CAT_SYS_VDOM_NETFLOW_COLLECTORS':3330,
  'PM2_CAT_EXT_CTRL_EXTENDER_SIM_PROFILE':2954,
  'PM2_CAT_EXT_CTRL_EXTENDER_SIM_PROFILE_AUTO_SWITCH':2955,
  'PM2_CAT_EXT_CTRL_TEMPLATE':2964,
  'PM2_CAT_EXT_CTRL_EXTENDER':1543,
  'PM2_CAT_EXTENDER_GSM_PROFILE':1582,
  'PM2_CAT_EXTENDER_CDMA_PROFILE':1583,
  'PM2_CAT_EXT_CTRL_EXTENDER_CONTROLLER_REPORT':2930,
  'PM2_CAT_EXT_CTRL_EXTENDER_MODEM1':2931,
  'PM2_CAT_EXTENDER_MODEM1_CDMA_PROFILE':2956,
  'PM2_CAT_EXTENDER_MODEM1_GSM_PROFILE':2957,
  'PM2_CAT_EXTENDER_MODEM1_SIM1':2958,
  'PM2_CAT_EXTENDER_MODEM1_SIM2':2959,
  'PM2_CAT_EXT_CTRL_EXTENDER_MODEM1_AUTO_SWITCH':2932,
  'PM2_CAT_EXT_CTRL_EXTENDER_MODEM2':2933,
  'PM2_CAT_EXTENDER_MODEM2_CDMA_PROFILE':2960,
  'PM2_CAT_EXTENDER_MODEM2_GSM_PROFILE':2961,
  'PM2_CAT_EXTENDER_MODEM2_SIM1':2962,
  'PM2_CAT_EXTENDER_MODEM2_SIM2':2963,
  'PM2_CAT_EXT_CTRL_EXTENDER_MODEM2_AUTO_SWITCH':2934,
  'PM2_CAT_EXT_CTRL_EXTENDER_MODEM':4039,
  'PM2_CAT_EXTENDER_MODEM_CDMA_PROFILE':4041,
  'PM2_CAT_EXTENDER_MODEM_GSM_PROFILE':4042,
  'PM2_CAT_EXT_CTRL_EXTENDER_WAN_EXTENSION':3135,
  'PM2_CAT_VPN_SSL_WEB_USER_BM':1550,
  'PM2_CAT_VPN_SSL_WEB_USER_BM_BMS':1562,
  'PM2_CAT_VPN_SSL_WEB_USER_BM_BMS_FORM_DATA':1552,
  'PM2_CAT_USER_SEC_EXEMPT_LIST':1553,
  'PM2_CAT_USER_SEC_EXEMPT_LIST_RULE':1554,
  'PM2_CAT_LOG_GUI_DISPLAY':1556,
  'PM2_CAT_SYS_DSCP_BASED_PRI':1557,
  'PM2_CAT_SYS_VIRTUAL_WAN_LINK':1558,
  'PM2_CAT_SYS_VIRTUAL_WAN_LINK_MEMBERS':1559,
  'PM2_CAT_SYS_VIRTUAL_WAN_LINK_SRV':1580,
  'PM2_CAT_SYS_VIRTUAL_WAN_LINK_SRV_SLA':2039,
  'PM2_CAT_SYS_VIRTUAL_WAN_LINK_HEALTH_CHECK':1644,
  'PM2_CAT_SYS_VIRTUAL_WAN_LINK_HEALTH_CHECK_SLA':2038,
  'PM2_CAT_SYS_VIRTUAL_WAN_LINK_NEIGHBOR':2227,
  'PM2_CAT_SYS_VIRTUAL_WAN_LINK_HEALTH_PROFILE':1688,
  'PM2_CAT_SYS_NP6':1535,
  'PM2_CAT_SYS_NP6_FP_ANOMALY_V4':1536,
  'PM2_CAT_SYS_NP6_FP_ANOMALY_V6':1537,
  'PM2_CAT_SYS_NP6_HPE':1870,
  'PM2_CAT_SYS_NP6_FP_ANOMALY':1936,
  'PM2_CAT_FW_SSL_SSH_PROFILE':1563,
  'PM2_CAT_FW_SSL_SSH_PROFILE_SSL':1564,
  'PM2_CAT_FW_SSL_SSH_PROFILE_HTTPS':1565,
  'PM2_CAT_FW_SSL_SSH_PROFILE_FTPS':1566,
  'PM2_CAT_FW_SSL_SSH_PROFILE_IMAPS':1567,
  'PM2_CAT_FW_SSL_SSH_PROFILE_POP3S':1568,
  'PM2_CAT_FW_SSL_SSH_PROFILE_SMTPS':1569,
  'PM2_CAT_FW_SSL_SSH_PROFILE_SSH':1570,
  'PM2_CAT_FW_SSL_SSH_PROFILE_SSL_EXEMPT':1571,
  'PM2_CAT_FW_SSL_SSH_PROFILE_SSL_SERVER':1572,
  'PM2_CAT_FW_SSL_SSH_PROF_DOT':3054,
  'PM2_CAT_FW_SSL_SSH_PROFILE_SSL_CLIENT_CERTIFICATE':4020,
  'PM2_CAT_FW_SSL_SSH_PROF_ECH_OUTER_SNI':3346,
  'PM2_CAT_FW_EXPLICIT_PROXY_POLICY':1573,
  'PM2_CAT_FW_EXPLICIT_PROXY_POLICY_IDENTITY_BASED_POLICY':1574,
  'PM2_CAT_SYS_LINK_MONITOR':1581,
  'PM2_CAT_SYS_LINK_MONITOR_SVR_LIST':3099,
  'PM2_CAT_SYS_CUSTOM_LANGUAGE':1584,
  'PM2_CAT_REPORT_SET':1593,
  'PM2_CAT_LOG_THREAT_WEIGHT':1610,
  'PM2_CAT_LOG_THREAT_WEIGHT_LEVEL':1611,
  'PM2_CAT_LOG_THREAT_WEIGHT_IPS':1612,
  'PM2_CAT_LOG_THREAT_WEIGHT_WEB':1613,
  'PM2_CAT_LOG_THREAT_WEIGHT_GEOLOCATION':1614,
  'PM2_CAT_LOG_THREAT_WEIGHT_APPLICATION':1615,
  'PM2_CAT_LOG_THREAT_WEIGHT_MALWARE':2130,
  'PM2_CAT_SYS_LTE_MODEM':1616,
  'PM2_CAT_SYS_LTE_MODEM_SIM_SWITCH':3264,
  'PM2_CAT_SYS_LTE_MODEM_DATA_PLAN':3171,
  'PM2_CAT_SYS_MOBILE_TUNNEL':1617,
  'PM2_CAT_SYS_MOBILE_TUNNEL_NETWORK':1618,
  'PM2_CAT_SYS_DNP3_PROXY':1619,
  'PM2_CAT_SYS_CORE_2':1620,
  'PM2_CAT_SYS_GEOIP_COUNTRY':1629,
  'PM2_CAT_WEBFILTER_CATEGORIES':1630,
  'PM2_CAT_SYS_HA_MONITOR':1634,
  'PM2_CAT_SYS_VIRTUAL_WIRE_PAIR':1635,
  'PM2_CAT_FW_MULTICAST_ADDRESS6':1636,
  'PM2_CAT_FW_MULTICAST_ADDRESS6_TAGGING':2020,
  'PM2_CAT_FW_SHAPING_POLICY':1640,
  'PM2_CAT_GL_HDR_SHAPING_POLICY':1775,
  'PM2_CAT_GL_FOOTER_SHAPING_POLICY':1776,
  'PM2_CAT_FW_MULTICAST_POLICY6':1641,
  'PM2_CAT_FW_CENTRAL_SNAT_MAP':1642,
  'PM2_CAT_SYS_CLUSTER_SYNC':1645,
  'PM2_CAT_SYS_CLUSTER_SYNC_SESSION_SYNC_FILTER':1646,
  'PM2_CAT_SYS_CLUSTER_SYNC_SESSION_SYNC_FILTER_CUSTOM_SERVICE':1647,
  'PM2_CAT_SYS_AUTO_SCRIPT':1648,
  'PM2_CAT_FW_SSL_SERVER':1649,
  'PM2_CAT_SYS_VM':1653,
  'PM2_CAT_SYS_VM_DISK':1654,
  'PM2_CAT_SYS_VM_INTF':1655,
  'PM2_CAT_WAF_MAIN_CLASS':1656,
  'PM2_CAT_WAF_SUB_CLASS':1657,
  'PM2_CAT_WAF_SIGNATURE':1658,
  'PM2_CAT_WAF_PROF':1659,
  'PM2_CAT_WAF_PROF_SIGNATURE':1660,
  'PM2_CAT_WAF_PROF_SIGNATURE_MAIN_CLASS':1661,
  'PM2_CAT_WAF_PROF_SIGNATURE_CUSTOM_SIGNATURE':1662,
  'PM2_CAT_WAF_PROF_CONSTRAINT':1663,
  'PM2_CAT_WAF_PROF_CONSTRAINT_HEADER_LENGTH':1664,
  'PM2_CAT_WAF_PROF_CONSTRAINT_CONTENT_LENGTH':1665,
  'PM2_CAT_WAF_PROF_CONSTRAINT_PARAM_LENGTH':1666,
  'PM2_CAT_WAF_PROF_CONSTRAINT_LINE_LENGTH':1667,
  'PM2_CAT_WAF_PROF_CONSTRAINT_URL_PARAM_LENGTH':1668,
  'PM2_CAT_WAF_PROF_CONSTRAINT_VER':1669,
  'PM2_CAT_WAF_PROF_CONSTRAINT_METHOD':1670,
  'PM2_CAT_WAF_PROF_CONSTRAINT_HOSTNAME':1671,
  'PM2_CAT_WAF_PROF_CONSTRAINT_MALFORMED':1672,
  'PM2_CAT_WAF_PROF_CONSTRAINT_MAX_COOKIE':1673,
  'PM2_CAT_WAF_PROF_CONSTRAINT_MAX_HEADER_LINE':1674,
  'PM2_CAT_WAF_PROF_CONSTRAINT_MAX_URL_PARAM':1675,
  'PM2_CAT_WAF_PROF_CONSTRAINT_MAX_RANGE_SEGMENT':1676,
  'PM2_CAT_WAF_PROF_CONSTRAINT_EXCEPTION':1677,
  'PM2_CAT_WAF_PROF_METHOD':1678,
  'PM2_CAT_WAF_PROF_METHOD_METHOD_POLICY':1679,
  'PM2_CAT_WAF_PROF_ADDRESS_LIST':1680,
  'PM2_CAT_WAF_PROF_URL_ACCESS':1681,
  'PM2_CAT_WAF_PROF_URL_ACCESS_ACCESS_PATTERN':1682,
  'PM2_CAT_LOG_SYSLOGD4_SET':1683,
  'PM2_CAT_LOG_SYSLOGD4_SET_CUSTOM_FIELD_NAME':1780,
  'PM2_CAT_LOG_SYSLOGD4_FILTER':1684,
  'PM2_CAT_LOG_SYSLOGD4_FILTER_EXCLUDE_LIST':1730,
  'PM2_CAT_LOG_SYSLOGD4_FILTER_EXCLUDE_LIST_FIELDS':1731,
  'PM2_CAT_LOG_SYSLOGD4_FILTER_FREE_STYLE':2994,
  'PM2_CAT_CERT_CA':1689,
  'PM2_CAT_CERT_LOCAL':1690,
  'PM2_CAT_DNSFILTER_URLFILTER':1691,
  'PM2_CAT_DNSFILTER_URLFILTER_ENTRIES':1692,
  'PM2_CAT_DNSFILTER_PROF':1693,
  'PM2_CAT_DNSFILTER_PROF_URLFILTER':1694,
  'PM2_CAT_DNSFILTER_PROF_FTGD_DNS':1695,
  'PM2_CAT_DNSFILTER_PROF_FTGD_DNS_FILTERS':1696,
  'PM2_CAT_DNSFILTER_PROF_DOMAIN_FILTER':1881,
  'PM2_CAT_DNSFILTER_PROFILE_DNS_TRANSLATION':2500,
  'PM2_CAT_SYS_PASSWORD_POLICY_GUEST_ADMIN':1697,
  'PM2_CAT_CERT_CRL':1698,
  'PM2_CAT_VPN_SSL_WEB_USER_GROUP_BOOKMARK':1699,
  'PM2_CAT_VPN_SSL_WEB_USER_GROUP_BOOKMARK_BMS':1700,
  'PM2_CAT_VPN_SSL_WEB_USER_GROUP_BOOKMARK_BMS_FORM_DATA':1701,
  'PM2_CAT_FW_EXPLICIT_PROXY_ADDRESS':1703,
  'PM2_CAT_FW_EXPLICIT_PROXY_ADDRESS_HEADER_GROUP':1704,
  'PM2_CAT_FW_EXPLICIT_PROXY_ADDRGRP':1705,
  'PM2_CAT_WEB_PROXY_WISP':1712,
  'PM2_CAT_FW_ACL':1713,
  'PM2_CAT_FW_ACL6':1714,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_GROUP':1715,
  'PM2_CAT_WIRELESS_CONTROLLER_WTP_GROUP_WTP_LIST':1729,
  'PM2_CAT_LOG_NULL_DEVICE_SET':1717,
  'PM2_CAT_LOG_NULL_DEVICE_FILTER':1718,
  'PM2_CAT_LOG_NULL_DEVICE_FILTER_EXCLUDE_LIST':1732,
  'PM2_CAT_LOG_NULL_DEVICE_FILTER_EXCLUDE_LIST_FIELDS':1733,
  'PM2_CAT_LOG_NULL_DEVICE_FILTER_FREE_STYLE':3004,
  'PM2_CAT_APPLICATION_INTERNET_SERVICE_CUSTOM':1719,
  'PM2_CAT_APPLICATION_INTERNET_SERVICE_CUSTOM_ENTRY':1720,
  'PM2_CAT_APPLICATION_INTERNET_SERVICE_CUSTOM_ENTRY_PORT_RANGE':1721,
  'PM2_CAT_APPLICATION_INTERNET_SERVICE_CUSTOM_DISABLE_ENTRY':1722,
  'PM2_CAT_APPLICATION_INTERNET_SERVICE_CUSTOM_DISABLE_ENTRY_IP_RANGE':1723,
  'PM2_CAT_SYS_NST':1724,
  'PM2_CAT_APPLICATION_INTERNET_SERVICE':1725,
  'PM2_CAT_APPLICATION_INTERNET_SERVICE_ENTRY':1726,
  'PM2_CAT_APPLICATION_CASI_PROF':1727,
  'PM2_CAT_APPLICATION_CASI_PROF_ENTRIES':1728,
  'PM2_CAT_USER_KRB_KEYTAB':1734,
  'PM2_CAT_GTP_APN':1742,
  'PM2_CAT_GTP_APNGRP':1743,
  'PM2_CAT_SWITCH_CONTROLLER_CUSTOM_COMMAND':1744,
  'PM2_CAT_SWITCH_CONTROLLER_STP_SETTINGS':1747,
  'PM2_CAT_SWITCH_CONTROLLER_STORM_CONTROL':1748,
  'PM2_CAT_SWITCH_CONTROLLER_GLOBAL':1749,
  'PM2_CAT_SWITCH_CONTROLLER_GLOBAL_CUSTOM_COMMAND':2266,
  'PM2_CAT_SWITCH_CONTROLLER_MAC_SYNC_SETTINGS':1750,
  'PM2_CAT_SWITCH_CONTROLLER_SWITCH_LOG':1751,
  'PM2_CAT_SYS_CSF':1752,
  'PM2_CAT_SYS_CSF_TRUSTED_LIST':2052,
  'PM2_CAT_SYS_CSF_FABRIC_DEVICE':2053,
  'PM2_CAT_SYS_CSF_FABRIC_CONNECTOR':3074,
  'PM2_CAT_NSX_SET':1753,
  'PM2_CAT_NSX_PROF':1754,
  'PM2_CAT_APPLICATION_CATEGORIES':1759,
  'PM2_CAT_SWITCH_CONTROLLER_SWITCH_PROFILE':1760,
  'PM2_CAT_APPLICATION_INTERNET_SERVICE_CUSTOM_GROUP':1762,
  'PM2_CAT_APPLICATION_INTERNET_SERVICE_GROUP':1763,
  'PM2_CAT_APPLICATION_GROUP':1764,
  'PM2_CAT_FW_SHAPING_PROFILE':1765,
  'PM2_CAT_FW_SHAPING_PROFILE_CLASSES':1766,
  'PM2_CAT_FW_SHAPING_PROFILE_SHAPING_ENTRIES':2063,
  'PM2_CAT_LOG_AZURE_SECURITY_CENTER_SET':1782,
  'PM2_CAT_LOG_AZURE_SECURITY_CENTER_SET_CUSTOM_FIELD_NAME':1783,
  'PM2_CAT_LOG_AZURE_SECURITY_CENTER2_SET':1784,
  'PM2_CAT_LOG_AZURE_SECURITY_CENTER2_SET_CUSTOM_FIELD_NAME':1785,
  'PM2_CAT_LOG_AZURE_SECURITY_CENTER_FILTER':1786,
  'PM2_CAT_LOG_AZURE_SECURITY_CENTER_FILTER_FREE_STYLE':3038,
  'PM2_CAT_LOG_AZURE_SECURITY_CENTER2_FILTER':1787,
  'PM2_CAT_LOG_AZURE_SECURITY_CENTER2_FILTER_FREE_STYLE':3039,
  'PM2_CAT_WIRELESS_CONTROLLER_INTER_CONTROLLER':1876,
  'PM2_CAT_WIRELESS_CONTROLLER_INTER_CONTROLLER_INTER_CONTROLLER_PEER':1877,
  'PM2_CAT_DNSFILTER_DOMAIN_FILTER':1879,
  'PM2_CAT_DNSFILTER_DOMAIN_FILTER_ENTRIES':1880,
  'PM2_CAT_AWS_SET':1882,
  'PM2_CAT_FW_INTERNET_SERVICE':1886,
  'PM2_CAT_FW_INTERNET_SERVICE_ENTRY':1887,
  'PM2_CAT_FW_INTERNET_SERVICE_CUSTOM':1829,
  'PM2_CAT_FW_INTERNET_SERVICE_CUSTOM_ENTRY':1830,
  'PM2_CAT_FW_INTERNET_SERVICE_CUSTOM_ENTRY_PORT_RANGE':1831,
  'PM2_CAT_FW_INTERNET_SERVICE_CUSTOM_DISABLE_ENTRY':1832,
  'PM2_CAT_FW_INTERNET_SERVICE_CUSTOM_DISABLE_ENTRY_IP_RANGE':1833,
  'PM2_CAT_SYS_PPPOE_INTERFACE':1834,
  'PM2_CAT_SYS_VXLAN':1835,
  'PM2_CAT_FW_PROXY_ADDRESS':1841,
  'PM2_CAT_FW_PROXY_ADDRESS_HEADER_GROUP':1842,
  'PM2_CAT_FW_PROXY_ADDRESS_TAGGING':2040,
  'PM2_CAT_FW_PROXY_ADDRGRP':1843,
  'PM2_CAT_FW_PROXY_ADDRGRP_TAGGING':2041,
  'PM2_CAT_FW_PROXY_POLICY':1844,
  'PM2_CAT_WIRELESS_CONTROLLER_BONJOUR_PROFILE':1845,
  'PM2_CAT_WIRELESS_CONTROLLER_BONJOUR_PROFILE_POLICY_LIST':1846,
  'PM2_CAT_SWITCH_CONTROLLER_802_1X_SETTINGS':1847,
  'PM2_CAT_SWITCH_CONTROLLER_SECURITY_POLICY_802_1X':1848,
  'PM2_CAT_SWITCH_CONTROLLER_SECURITY_POLICY_CAPTIVE_PORTAL':1849,
  'PM2_CAT_SWITCH_CONTROLLER_LLDP_SETTINGS':1850,
  'PM2_CAT_SWITCH_CONTROLLER_LLDP_PROFILE':1851,
  'PM2_CAT_SWITCH_CONTROLLER_LLDP_PROFILE_MED_NETWORK_POLICY':1852,
  'PM2_CAT_SWITCH_CONTROLLER_LLDP_PROFILE_CUSTOM_TLVS':1853,
  'PM2_CAT_SWITCH_CONTROLLER_LLDP_PROFILE_MED_LOCATION_SERVICE':2265,
  'PM2_CAT_WIRELESS_CONTROLLER_QOS_PROFILE':1854,
  'PM2_CAT_SWITCH_CONTROLLER_SWITCH_GROUP':1860,
  'PM2_CAT_SWITCH_CONTROLLER_IGMP_SNOOPING':1861,
  'PM2_CAT_SYS_FTM_PUSH':1867,
  'PM2_CAT_AUTHENTICATION_SCHEME':1871,
  'PM2_CAT_AUTHENTICATION_RULE':1872,
  'PM2_CAT_AUTHENTICATION_SET':1873,
  'PM2_CAT_SYS_ALIAS':1874,
  'PM2_CAT_SYS_DATASOURCE':1875,
  'PM2_CAT_LOAD_BALANCE_SET':1895,
  'PM2_CAT_LOAD_BALANCE_SET_WORKERS':1896,
  'PM2_CAT_SYS_HA_OLD':1897,
  'PM2_CAT_SYS_HA_OLD_SECONDARY_VCLUSTER':1898,
  'PM2_CAT_LOAD_BALANCE_FLOW_RULE':1899,
  'PM2_CAT_SYS_API_USER':1920,
  'PM2_CAT_SYS_API_USER_TRUSTHOST':1921,
  'PM2_CAT_GTP_MESSAGE_FILTER_V0V1':1922,
  'PM2_CAT_GTP_MESSAGE_FILTER_V2':1923,
  'PM2_CAT_GTP_IE_WHITE_LIST':1924,
  'PM2_CAT_GTP_IE_WHITE_LIST_ENTRIES':1925,
  'PM2_CAT_GTP_APN_SHAPER':1926,
  'PM2_CAT_WIRELESS_CONTROLLER_BLE_PROFILE':1927,
  'PM2_CAT_SWITCH_CONTROLLER_QOS_DOT1P_MAP':1928,
  'PM2_CAT_SWITCH_CONTROLLER_QOS_IP_DSCP_MAP':1929,
  'PM2_CAT_SWITCH_CONTROLLER_QOS_IP_DSCP_MAP_MAP':1930,
  'PM2_CAT_SWITCH_CONTROLLER_QOS_QUEUE_POLICY':1931,
  'PM2_CAT_SWITCH_CONTROLLER_QOS_QUEUE_POLICY_COS_QUEUE':1932,
  'PM2_CAT_SWITCH_CONTROLLER_QOS_QOS_POLICY':1933,
  'PM2_CAT_SWITCH_CONTROLLER_QUARANTINE':1934,
  'PM2_CAT_SWITCH_CONTROLLER_QUARANTINE_TARGETS':1935,
  'PM2_CAT_WANOPT_FORTICACHE_SERVICE':1937,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_VENUE_NAME':1950,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_VENUE_NAME_VALUE_LIST':1951,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_NETWORK_AUTH_TYPE':1952,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_ROAMING_CONSORTIUM':1953,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_ROAMING_CONSORTIUM_OI_LIST':1954,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_NAI_REALM':1955,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_NAI_REALM_NAI_LIST':1956,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_NAI_REALM_NAI_LIST_EAP_METHOD':1957,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_NAI_REALM_NAI_LIST_EAP_METHOD_AUTH_PARAM':1958,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_3GPP_CELLULAR':1959,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_3GPP_CELLULAR_MCC_MNC_LIST':1960,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_IP_ADDRESS_TYPE':1961,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_OPERATOR_NAME':1962,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_OPERATOR_NAME_VALUE_LIST':1963,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_WAN_METRIC':1964,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_CONN_CAPABILITY':1965,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ICON':1966,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ICON_ICON_LIST':1967,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_OSU_PROVIDER':1968,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_OSU_PROVIDER_FRIENDLY_NAME':1969,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_OSU_PROVIDER_SERVICE_DESCRIPTION':1970,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_QOS_MAP':1971,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_QOS_MAP_DSCP_EXCEPT':1972,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_QOS_MAP_DSCP_RANGE':1973,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_HS_PROFILE':1974,
  'PM2_CAT_SYS_AFFINITY_INTERRUPT':1975,
  'PM2_CAT_SYS_AFFINITY_PACKET_REDISTRIBUTION':1976,
  'PM2_CAT_SYS_SDN_CONNECTOR':1888,
  'PM2_CAT_SYS_SDN_CONNECTOR_NIC':2078,
  'PM2_CAT_SYS_SDN_CONNECTOR_NIC_IP':2079,
  'PM2_CAT_SYS_SDN_CONNECTOR_ROUTE_TABLE':2080,
  'PM2_CAT_SYS_SDN_CONNECTOR_ROUTE_TABLE_ROUTE':2081,
  'PM2_CAT_SYS_SDN_CONNECTOR_EXTERNAL_IP':2082,
  'PM2_CAT_SYS_SDN_CONNECTOR_ROUTE':2083,
  'PM2_CAT_SYS_SDN_CONNECTOR_GCP_PROJECT_LIST':3120,
  'PM2_CAT_SYS_SDN_CONNECTOR_FORWARDING_RULE':3122,
  'PM2_CAT_SYS_SDN_CONNECTOR_EXTERNAL_ACCOUNT_LIST':3165,
  'PM2_CAT_SYS_SDN_CONNECTOR_COMPARTMENT_LIST':3274,
  'PM2_CAT_SYS_SDN_CONNECTOR_OCI_REGION_LIST':3275,
  'PM2_CAT_USER_DOMAIN_CONTROLLER':2000,
  'PM2_CAT_USER_DOMAIN_CONTROLLER_EXTRA_SERVER':2222,
  'PM2_CAT_SYS_OBJECT_TAGGING':2001,
  'PM2_CAT_FW_ADDRESS6_TEMPLATE':2009,
  'PM2_CAT_FW_ADDRESS6_TEMPLATE_SUBNET_SEGMENT':2010,
  'PM2_CAT_FW_ADDRESS6_TEMPLATE_SUBNET_SEGMENT_VALUES':2011,
  'PM2_CAT_FW_WILDCARD_FQDN_CUS':2016,
  'PM2_CAT_FW_WILDCARD_FQDN_GROUP':2017,
  'PM2_CAT_WEBFILTER_IPS_URLFILTER_SETTING6':2021,
  'PM2_CAT_SYS_IPS_URLFILTER_DNS6':2022,
  'PM2_CAT_USER_QUARANTINE':2023,
  'PM2_CAT_USER_QUARANTINE_TARGETS':2024,
  'PM2_CAT_USER_QUARANTINE_TARGETS_MACS':2100,
  'PM2_CAT_USER_QUARANTINE_TARGETS_MACS_MSW':3266,
  'PM2_CAT_FW_INTERNET_SERVICE_GROUP':2026,
  'PM2_CAT_FW_INTERNET_SERVICE_CUSTOM_GROUP':2027,
  'PM2_CAT_SYS_EXTERNAL_RESOURCE':2028,
  'PM2_CAT_SYS_EXTERNAL_RESOURCE_DYNAMIC_MAPPING':2085,
  'PM2_CAT_WANOPT_CONTENT_DELIVERY_NETWORK_RULE':2030,
  'PM2_CAT_WANOPT_CONTENT_DELIVERY_NETWORK_RULE_RULES':2031,
  'PM2_CAT_WANOPT_CONTENT_DELIVERY_NETWORK_RULE_RULES_MATCH_ENTRIES':2032,
  'PM2_CAT_WANOPT_CONTENT_DELIVERY_NETWORK_RULE_RULES_SKIP_ENTRIES':2033,
  'PM2_CAT_WANOPT_CONTENT_DELIVERY_NETWORK_RULE_RULES_CONTENT_ID':2034,
  'PM2_CAT_WANOPT_CACHE_SERVICE':2035,
  'PM2_CAT_WANOPT_CACHE_SERVICE_DST_PEER':2036,
  'PM2_CAT_WANOPT_CACHE_SERVICE_SRC_PEER':2037,
  'PM2_CAT_SWITCH_CONTROLLER_VIRTUAL_PORT_POOL':2042,
  'PM2_CAT_SWITCH_CONTROLLER_SWITCH_INTERFACE_TAG':2043,
  'PM2_CAT_SWITCH_CONTROLLER_SFLOW':2044,
  'PM2_CAT_SWITCH_CONTROLLER_NETWORK_MONITOR_SETTINGS':2045,
  'PM2_CAT_ROUTER_BFD6':2048,
  'PM2_CAT_ROUTER_BFD6_NEIGHBOR':2049,
  'PM2_CAT_ROUTER_BFD6_MULTIHOP_TEMPLATE':3086,
  'PM2_CAT_SYS_VDOM_EXCEPTION':2051,
  'PM2_CAT_SYS_AUTOMATION_TRIGGER':2054,
  'PM2_CAT_SYS_AUTOMATION_TRIGGER_FIELDS':2387,
  'PM2_CAT_SYS_AUTOMATION_ACTION':2055,
  'PM2_CAT_SYS_AUTOMATION_ACTION_HTTP_HEADERS':3214,
  'PM2_CAT_SYS_AUTOMATION_STITCH':2056,
  'PM2_CAT_SYS_AUTOMATION_STITCH_ACTIONS':3113,
  'PM2_CAT_SYS_REPMSG_ICAP':2058,
  'PM2_CAT_SSH_FILTER_PROF':2059,
  'PM2_CAT_SSH_FILTER_PROF_SHELL_COMMANDS':2060,
  'PM2_CAT_SSH_FILTER_PROF_FILE_FILTER':2592,
  'PM2_CAT_SSH_FILTER_PROF_FILE_FILTER_ENTRIES':2593,
  'PM2_CAT_GTP_TUNNEL_LIMIT':2061,
  'PM2_CAT_WIRELESS_CONTROLLER_UTM_PROFILE':2064,
  'PM2_CAT_SWITCH_CONTROLLER_SYS':2066,
  'PM2_CAT_FW_SSH_LOCAL_KEY':2067,
  'PM2_CAT_FW_SSH_LOCAL_CA':2068,
  'PM2_CAT_FW_SSH_SET':2069,
  'PM2_CAT_FW_SSH_HOST_KEY':2070,
  'PM2_CAT_WANOPT_REMOTE_STORAGE':2074,
  'PM2_CAT_VPN_OCVPN':2075,
  'PM2_CAT_VPN_OCVPN_SUBNETS':2076,
  'PM2_CAT_VPN_OCVPN_OVERLAYS':1990,
  'PM2_CAT_VPN_OCVPN_OVERLAYS_SUBNETS':1991,
  'PM2_CAT_VPN_OCVPN_FC_ACCESS':2851,
  'PM2_CAT_VPN_OCVPN_FC_ACCESS_AUTH_GROUPS':2852,
  'PM2_CAT_ENDPOINT_CONTROL_FORTICLIENT_EMS':2077,
  'PM2_CAT_SYS_AUTOMATION_DESTINATION':2084,
  'PM2_CAT_LOAD_BALANCE_HIDDEN_INTERFACE':1978,
  'PM2_CAT_SYS_CONSOLE_SERVER':1892,
  'PM2_CAT_SYS_CONSOLE_SERVER_ENTRIES':1893,
  'PM2_CAT_SYS_ND_PROXY':2101,
  'PM2_CAT_SYS_QUEUE_PROFILE':2201,
  'PM2_CAT_FW_INTERNET_SERVICE_EXTENSION':2203,
  'PM2_CAT_FW_INTERNET_SERVICE_EXTENSION_ENTRY':2204,
  'PM2_CAT_FW_INTERNET_SERVICE_EXTENSION_ENTRY_PORT_RANGE':2205,
  'PM2_CAT_FW_INTERNET_SERVICE_EXTENSION_DISABLE_ENTRY':2206,
  'PM2_CAT_FW_INTERNET_SERVICE_EXTENSION_DISABLE_ENTRY_IP_RANGE':2207,
  'PM2_CAT_FW_INTERNET_SERVICE_EXTENSION_DISABLE_ENTRY_PORT_RANGE':2410,
  'PM2_CAT_FW_INTERNET_SRV_EXTENSION_DISABLE_ENTRY_IP6_RANGE':3233,
  'PM2_CAT_FW_INTERNET_SERVICE_DEFINITION':2208,
  'PM2_CAT_FW_INTERNET_SERVICE_DEFINITION_ENTRY':2209,
  'PM2_CAT_FIREWALL_INTERNET_SERVICE_DEFINITION_ENTRY_PORT_RANGE':2411,
  'PM2_CAT_SYS_IPSEC_AGGREGATE':2210,
  'PM2_CAT_FW_CONSOLIDATED_POLICY':2213,
  'PM2_CAT_GL_HDR_CONSOLIDATED_POLICY':1904,
  'PM2_CAT_GL_FOOTER_CONSOLIDATED_POLICY':1905,
  'PM2_CAT_SWITCH_CONTROLLER_AUTO_CONFIG_POLICY':2214,
  'PM2_CAT_SWITCH_CONTROLLER_AUTO_CONFIG_DEFAULT':2215,
  'PM2_CAT_SWITCH_CONTROLLER_AUTO_CONFIG_CUS':2216,
  'PM2_CAT_SWITCH_CONTROLLER_AUTO_CONFIG_CUS_SWITCH_BINDING':2217,
  'PM2_CAT_SYS_PTP':2219,
  'PM2_CAT_SYS_PTP_SVR_INTF':3100,
  'PM2_CAT_MONITORING_NP6_IPSEC_ENGINE':2161,
  'PM2_CAT_SWITCH_CONTROLLER_TRAFFIC_POLICY':2223,
  'PM2_CAT_SYS_AUTO_SCALE':2190,
  'PM2_CAT_SYS_LLDP_NETWORK_POLICY':2251,
  'PM2_CAT_SYS_LLDP_NETWORK_POLICY_VOICE':2252,
  'PM2_CAT_SYS_LLDP_NETWORK_POLICY_VOICE_SIGNALING':2253,
  'PM2_CAT_SYS_LLDP_NETWORK_POLICY_GUEST':2254,
  'PM2_CAT_SYS_LLDP_NETWORK_POLICY_GUEST_VOICE_SIGNALING':2255,
  'PM2_CAT_SYS_LLDP_NETWORK_POLICY_SOFTPHONE':2256,
  'PM2_CAT_SYS_LLDP_NETWORK_POLICY_VIDEO_CONFERENCING':2257,
  'PM2_CAT_SYS_LLDP_NETWORK_POLICY_STREAMING_VIDEO':2258,
  'PM2_CAT_SYS_LLDP_NETWORK_POLICY_VIDEO_SIGNALING':2259,
  'PM2_CAT_WIRELESS_CONTROLLER_REGION':2260,
  'PM2_CAT_SWITCH_CONTROLLER_LOCATION':2261,
  'PM2_CAT_SWITCH_CONTROLLER_LOCATION_ADDRESS_CIVIC':2262,
  'PM2_CAT_SWITCH_CONTROLLER_LOCATION_COORDINATES':2263,
  'PM2_CAT_SWITCH_CONTROLLER_LOCATION_ELIN_NUMBER':2264,
  'PM2_CAT_LOG_SYSLOGD2_OVERRIDE_SETTING':2267,
  'PM2_CAT_LOG_SYSLOGD2_OVERRIDE_SETTING_CUSTOM_FIELD_NAME':2268,
  'PM2_CAT_LOG_SYSLOGD3_OVERRIDE_SETTING':2269,
  'PM2_CAT_LOG_SYSLOGD3_OVERRIDE_SETTING_CUSTOM_FIELD_NAME':2270,
  'PM2_CAT_LOG_SYSLOGD4_OVERRIDE_SETTING':2271,
  'PM2_CAT_LOG_SYSLOGD4_OVERRIDE_SETTING_CUSTOM_FIELD_NAME':2272,
  'PM2_CAT_LOG_SYSLOGD2_OVERRIDE_FILTER':2273,
  'PM2_CAT_LOG_SYSLOGD2_OVERRIDE_FILTER_FREE_STYLE':2996,
  'PM2_CAT_LOG_SYSLOGD3_OVERRIDE_FILTER':2274,
  'PM2_CAT_LOG_SYSLOGD3_OVERRIDE_FILTER_FREE_STYLE':2997,
  'PM2_CAT_LOG_SYSLOGD4_OVERRIDE_FILTER':2275,
  'PM2_CAT_LOG_SYSLOGD4_OVERRIDE_FILTER_FREE_STYLE':2998,
  'PM2_CAT_LOG_FORTIANALYZER2_OVERRIDE_SETTING':2276,
  'PM2_CAT_LOG_FORTIANALYZER3_OVERRIDE_SETTING':2277,
  'PM2_CAT_LOG_FORTIANALYZER2_OVERRIDE_FILTER':2278,
  'PM2_CAT_LOG_FA2_OVERRIDE_FILTER_FREE_STYLE':3010,
  'PM2_CAT_LOG_FORTIANALYZER3_OVERRIDE_FILTER':2279,
  'PM2_CAT_LOG_FA3_OVERRIDE_FILTER_FREE_STYLE':3011,
  'PM2_CAT_DLP_SENSITIVITY':2301,
  'PM2_CAT_EMAILFILTER_BWORD':2302,
  'PM2_CAT_EMAILFILTER_BWORD_ENTRIES':2303,
  'PM2_CAT_EMAILFILTER_BWL':2304,
  'PM2_CAT_EMAILFILTER_BWL_ENTRIES':2305,
  'PM2_CAT_EMAILFILTER_MHEADER':2306,
  'PM2_CAT_EMAILFILTER_MHEADER_ENTRIES':2307,
  'PM2_CAT_EMAILFILTER_DNSBL':2308,
  'PM2_CAT_EMAILFILTER_DNSBL_ENTRIES':2309,
  'PM2_CAT_EMAILFILTER_IPTRUST':2310,
  'PM2_CAT_EMAILFILTER_IPTRUST_ENTRIES':2311,
  'PM2_CAT_EMAILFILTER_PROFILE':2312,
  'PM2_CAT_EMAILFILTER_PROFILE_FILE_FILTER':2313,
  'PM2_CAT_EMAILFILTER_PROFILE_FILE_FILTER_ENTRIES':2382,
  'PM2_CAT_EMAILFILTER_PROFILE_IMAP':2314,
  'PM2_CAT_EMAILFILTER_PROFILE_POP3':2315,
  'PM2_CAT_EMAILFILTER_PROFILE_SMTP':2316,
  'PM2_CAT_EMAILFILTER_PROFILE_MAPI':2317,
  'PM2_CAT_EMAILFILTER_PROFILE_MSN_HOTMAIL':2318,
  'PM2_CAT_EMAILFILTER_PROFILE_YAHOO_MAIL':2319,
  'PM2_CAT_EMAILFILTER_PROFILE_GMAIL':2320,
  'PM2_CAT_EMAILFILTER_PROF_OTHER_WEBMAILS':2935,
  'PM2_CAT_ANTIVIRUS_FILETYPE':2321,
  'PM2_CAT_SWITCH_CONTROLLER_SECURITY_POLICY_LOCAL_ACCESS':2322,
  'PM2_CAT_SWITCH_CONTROLLER_FLOW_TRACKING':2323,
  'PM2_CAT_SWITCH_CONTROLLER_FLOW_TRACKING_AGGREGATES':2324,
  'PM2_CAT_SWITCH_CONTROLLER_FLOW_TRACKING_COLLECTORS':3204,
  'PM2_CAT_EMAILFILTER_FORTISHIELD':2325,
  'PM2_CAT_EMAILFILTER_OPTIONS':2326,
  'PM2_CAT_SWITCH_CONTROLLER_STP_INSTANCE':2351,
  'PM2_CAT_USER_EXCHANGE':2380,
  'PM2_CAT_WIRELESS_CONTROLLER_ADDRESS':2383,
  'PM2_CAT_WIRELESS_CONTROLLER_ADDRGRP':2384,
  'PM2_CAT_WIRELESS_CONTROLLER_LOG':2412,
  'PM2_CAT_SWITCH_CONTROLLER_TRAFFIC_SNIFFER':2413,
  'PM2_CAT_SWITCH_CONTROLLER_TRAFFIC_SNIFFER_TARGET_MAC':2414,
  'PM2_CAT_SWITCH_CONTROLLER_TRAFFIC_SNIFFER_TARGET_MAC_MSW':3271,
  'PM2_CAT_SWITCH_CONTROLLER_TRAFFIC_SNIFFER_TARGET_IP':2415,
  'PM2_CAT_SWITCH_CONTROLLER_TRAFFIC_SNIFFER_TARGET_IP_MSW':3272,
  'PM2_CAT_SWITCH_CONTROLLER_TRAFFIC_SNIFFER_TARGET_PORT':2416,
  'PM2_CAT_FSP_PKT_CAP':1002,
  'PM2_CAT_FSP_PKT_CAP_TARGET_MAC':1003,
  'PM2_CAT_FSP_PKT_CAP_TARGET_IP':1011,
  'PM2_CAT_FSP_PKT_CAP_TARGET_PORT':1015,
  'PM2_CAT_FSP_PKT_CAP_FW_SNIFFER':1016,
  'PM2_CAT_SYS_ISF_QUEUE_PROFILE':2440,
  'PM2_CAT_ENDPOINT_CONTROL_FCTEMS':2443,
  'PM2_CAT_SWITCH_CONTROLLER_STORM_CONTROL_POLICY':2444,
  'PM2_CAT_WIRELESS_CONTROLLER_SNMP':2445,
  'PM2_CAT_WIRELESS_CONTROLLER_SNMP_COMMUNITY':2446,
  'PM2_CAT_WIRELESS_CONTROLLER_SNMP_COMMUNITY_HOSTS':2447,
  'PM2_CAT_WIRELESS_CONTROLLER_SNMP_USER':2448,
  'PM2_CAT_SYS_ISCSI':2470,
  'PM2_CAT_SYS_REMOTE_ADMIN':2501,
  'PM2_CAT_SYS_SSO_ADMIN':2502,
  'PM2_CAT_SYS_SSO_ADMIN_GUI_DASHBOARD':2503,
  'PM2_CAT_SYS_SSO_ADMIN_GUI_DASHBOARD_WIDGET':2504,
  'PM2_CAT_SYS_SSO_ADMIN_GUI_DASHBOARD_WIDGET_FORTIVIEW_FILTERS':2505,
  'PM2_CAT_FW_INTERNET_SERVICE_ADDITION':1980,
  'PM2_CAT_FW_INTERNET_SERVICE_ADDITION_ENTRY':1981,
  'PM2_CAT_FW_INTERNET_SERVICE_ADDITION_ENTRY_PORT_RANGE':1982,
  'PM2_CAT_FIREWALL_INTERNET_SERVICE_CAT_DEFINITION':1983,
  'PM2_CAT_FIREWALL_INTERNET_SERVICE_CAT_DEFINITION_ENTRY':1984,
  'PM2_CAT_CIFS_DOMAIN_CONTROLLER':1985,
  'PM2_CAT_CIFS_PROFILE':1986,
  'PM2_CAT_CIFS_PROFILE_FILE_FILTER':1987,
  'PM2_CAT_CIFS_PROFILE_FILE_FILTER_ENTRIES':1988,
  'PM2_CAT_CIFS_PROFILE_SERVER_KEYTAB':1989,
  'PM2_CAT_SYS_SPEED_TEST_SERVER':2108,
  'PM2_CAT_SYS_SPEED_TEST_SERVER_HOST':2109,
  'PM2_CAT_LOG_FORTIANALYZER_CLOUD_SETTING':2110,
  'PM2_CAT_LOG_FORTIANALYZER_CLOUD_OVERRIDE_SETTING':2111,
  'PM2_CAT_LOG_FORTIANALYZER_CLOUD_FILTER':2112,
  'PM2_CAT_LOG_FA_CLOUD_FILTER_FREE_STYLE':3008,
  'PM2_CAT_LOG_FORTIANALYZER_CLOUD_OVERRIDE_FILTER':2113,
  'PM2_CAT_LOG_FA_CLOUD_OVERRIDE_FILTER_FREE_STYLE':3012,
  'PM2_CAT_SYS_SAML':2114,
  'PM2_CAT_SYS_SAML_SERVICE_PROVIDERS':2115,
  'PM2_CAT_SYS_SAML_SERVICE_PROVIDERS_ASSERTION_ATTRIBUTES':2116,
  'PM2_CAT_SYS_NP6XLITE':2250,
  'PM2_CAT_SYS_NP6XLITE_HPE':2280,
  'PM2_CAT_SYS_NP6XLITE_FP_ANOMALY':2281,
  'PM2_CAT_SWITCH_CONTROLLER_SNMP_SYSINFO':2234,
  'PM2_CAT_SWITCH_CONTROLLER_SNMP_TRAP_THRESHOLD':2235,
  'PM2_CAT_SWITCH_CONTROLLER_SNMP_COMM':2236,
  'PM2_CAT_SWITCH_CONTROLLER_SNMP_COMM_HOSTS':2237,
  'PM2_CAT_SWITCH_CONTROLLER_SNMP_USER':2238,
  'PM2_CAT_SWITCH_CONTROLLER_REMOTE_LOG':2239,
  'PM2_CAT_FW_SECURITY_POLICY':2560,
  'PM2_CAT_USER_SAML':2590,
  'PM2_CAT_USER_SAML_DYNAMIC_MAPPING':4022,
  'PM2_CAT_CERT_REMOTE':2591,
  'PM2_CAT_FW_TRAFFIC_CLASS':2596,
  'PM2_CAT_WIRELESS_CONTROLLER_WAG_PROF':2597,
  'PM2_CAT_SYS_GENEVE':2650,
  'PM2_CAT_SYS_SMC_NTP':2651,
  'PM2_CAT_SYS_SMC_NTP_NTPSVR':2652,
  'PM2_CAT_DPDK_GLOBAL':2680,
  'PM2_CAT_DPDK_CPUS':2681,
  'PM2_CAT_FW_VENDOR_MAC':2760,
  'PM2_CAT_CREDENTIAL_STORE_DOMAIN_CONTROLLER':2759,
  'PM2_CAT_SWITCH_CONTROLLER_VLAN_POLICY':2761,
  'PM2_CAT_SWITCH_CONTROLLER_DEVICE_PORT_POLICY':2762,
  'PM2_CAT_SWITCH_CONTROLLER_LLDP_IDENTITY':2763,
  'PM2_CAT_SWITCH_CONTROLLER_LLDP_DEVICE':2764,
  'PM2_CAT_WIRELESS_CONTROLLER_APCFG_PROF':2767,
  'PM2_CAT_WIRELESS_CONTROLLER_APCFG_PROF_COMMAND_LIST':2768,
  'PM2_CAT_FW_INTERNET_SRV_NAME':2790,
  'PM2_CAT_SWITCH_CONTROLLER_PORT_POLICY':2791,
  'PM2_CAT_SWITCH_CONTROLLER_MAC_POLICY':2792,
  'PM2_CAT_SWITCH_CONTROLLER_NAC_SETS':2793,
  'PM2_CAT_USER_NAC_POLICY':2794,
  'PM2_CAT_SWITCH_CONTROLLER_NAC_DEVICE':2795,
  'PM2_CAT_SYS_STANDALONE_CLUSTER':2796,
  'PM2_CAT_SYS_STANDALONE_CLUSTER_CLUSTER_PEER':3237,
  'PM2_CAT_SYS_STANDALONE_CLUSTER_CLUSTER_PEER_SESS_SYNC_FILTER':3238,
  'PM2_CAT_SYS_STANDALONE_CLUSTER_CLUSTER_PEER_SESS_SYNC_FILTER_CUS_SRV':3239,
  'PM2_CAT_SYS_STANDALONE_CLUSTER_MONITOR_PREFIX':3407,
  'PM2_CAT_FW_DECRYPTED_TRAFFIC_MIRROR':2820,
  'PM2_CAT_SWITCH_CONTROLLER_INITIAL_CONFIG_TEMPLATE':2821,
  'PM2_CAT_SWITCH_CONTROLLER_INITIAL_CONFIG_VLANS':2822,
  'PM2_CAT_WIRELESS_CONTROLLER_ACCESS_CONTROL_LIST':2712,
  'PM2_CAT_WIRELESS_CONTROLLER_ACCESS_CONTROL_LIST_LAYER3_IPV4_RULES':2713,
  'PM2_CAT_WIRELESS_CONTROLLER_ACCESS_CONTROL_LIST_LAYER3_IPV6_RULES':2714,
  'PM2_CAT_SYS_NPU_VLINK':2882,
  'PM2_CAT_DEV_TEMPLATE_ACTION':530,
  'PM2_CAT_DEV_TEMPLATE_ACTION_ATTRS':531,
  'PM2_CAT_DEV_TEMPLATE_ACTION_FIXED_ATTRS':532,
  'PM2_CAT_DEV_TEMPLATE_ACTION_ARGS':537,
  'PM2_CAT_DEV_TEMPLATE_WIDGET':533,
  'PM2_CAT_DEV_TEMPLATE_ACTION_LIST':534,
  'PM2_CAT_DEV_TEMPLATE_ACTION_VAR_LIST':535,
  'PM2_CAT_DEV_TEMPLATE_ACTION_MAPPING':536,
  'PM2_CAT_TEMPLATE_SETTING':550,
  'PM2_CAT_CLOUD_SERVICE_TEMPLATE':551,
  'PM2_CAT_CLOUD_SERVICE_TEMPLATE_VDOM':552,
  'PM2_CAT_CST_VDOM_GENEVE_INTF':553,
  'PM2_CAT_CST_VDOM_VIRTUAL_WIRE_PAIR':554,
  'PM2_CAT_CLOUD_SERVICE_TEMPLATE_SRV_CHAIN':555,
  'PM2_CAT_CLOUD_SERVICE_TEMPLATE_SRV_CHAIN_INDEX':556,
  'PM2_CAT_SDWAN_OVERLAY':557,
  'PM2_CAT_SDWAN_OVERLAY_NODES':558,
  'PM2_CAT_SDWAN_OVERLAY_NODES_UNDERLAY':559,
  'PM2_CAT_SDWAN_OVERLAY_NODES_NEIGHBOR':560,
  'PM2_CAT_SDWAN_OVERLAY_NODES_NEIGHBOR_GROUP':561,
  'PM2_CAT_SDWAN_OVERLAY_NODES_VRF':562,
  'PM2_CAT_FW_INTERNET_SRV_APPEND':2715,
  'PM2_CAT_FILE_FILTER_PROF':2910,
  'PM2_CAT_FILE_FILTER_PROF_RULES':2911,
  'PM2_CAT_SYS_SDWAN':2921,
  'PM2_CAT_SYS_SDWAN_ZONE':2922,
  'PM2_CAT_SYS_SDWAN_MEMBERS':2923,
  'PM2_CAT_SYS_SDWAN_HEALTH_CHECK':2926,
  'PM2_CAT_SYS_SDWAN_HEALTH_CHECK_SLA':2927,
  'PM2_CAT_SYS_SDWAN_NEIGHBOR':2928,
  'PM2_CAT_SYS_SDWAN_SRV':2924,
  'PM2_CAT_SYS_SDWAN_SRV_SLA':2925,
  'PM2_CAT_SYS_SDWAN_DUPLICATION':2943,
  'PM2_CAT_SYS_SDWAN_HEALTH_CHECK_FTGD':3369,
  'PM2_CAT_SYS_SDWAN_HEALTH_CHECK_FTGD_SLA':3370,
  'PM2_CAT_SYS_VNE_TUN':2920,
  'PM2_CAT_EXT_CTRL_DATAPLAN':2929,
  'PM2_CAT_WIRELESS_CONTROLLER_ARRP_PROF':2939,
  'PM2_CAT_SWITCH_CONTROLLER_PTP_SETS':2940,
  'PM2_CAT_SWITCH_CONTROLLER_PTP_POLICY':2941,
  'PM2_CAT_SYS_IPS':2944,
  'PM2_CAT_WIRELESS_CONTROLLER_MPSK_PROF':2945,
  'PM2_CAT_WIRELESS_CONTROLLER_MPSK_PROF_MPSK_GROUP':2946,
  'PM2_CAT_WIRELESS_CONTROLLER_MPSK_PROF_MPSK_GROUP_MPSK_KEY':2947,
  'PM2_CAT_FW_IPPOOL_GRP':2948,
  'PM2_CAT_LOG_NPU_SVR':2949,
  'PM2_CAT_LOG_NPU_SVR_SVR_INFO':2950,
  'PM2_CAT_LOG_NPU_SVR_SVR_GROUP':2951,
  'PM2_CAT_NSXT_SET':2912,
  'PM2_CAT_NSXT_SRV_CHAIN':2913,
  'PM2_CAT_NSXT_SRV_CHAIN_SRV_INDEX':2914,
  'PM2_CAT_VIDEOFILTER_YOUTUBE_KEY':2979,
  'PM2_CAT_VIDEOFILTER_YOUTUBE_CHANNEL_FILTER':2980,
  'PM2_CAT_VIDEOFILTER_YOUTUBE_CHANNEL_FILTER_ENTRIES':2981,
  'PM2_CAT_VIDEOFILTER_PROF':2982,
  'PM2_CAT_VIDEOFILTER_PROF_FTGD_CATEGORY':2983,
  'PM2_CAT_VIDEOFILTER_PROF_FTGD_CATEGORY_FILTERS':2984,
  'PM2_CAT_VIDEOFILTER_PROF_FILTERS':3335,
  'PM2_CAT_SYS_SSO_FORTICLOUD_ADMIN':3034,
  'PM2_CAT_SYS_SSO_FORTICLOUD_ADMIN_GUI_DASHBOARD':3035,
  'PM2_CAT_SYS_SSO_FORTICLOUD_ADMIN_GUI_DASHBOARD_WIDGET':3036,
  'PM2_CAT_SYS_SSO_FORTICLOUD_ADMIN_GUI_DASHBOARD_WIDGET_FORTIVIEW_FILTERS':3037,
  'PM2_CAT_SYS_REPMSG_AUTOMATION':2985,
  'PM2_CAT_EMAILFILTER_BLOCK_ALLOW_LIST':2986,
  'PM2_CAT_EMAILFILTER_BLOCK_ALLOW_LIST_ENTRIES':2987,
  'PM2_CAT_VPN_SSL_CLIENT':2988,
  'PM2_CAT_GTP_IE_ALLOW_LIST':2989,
  'PM2_CAT_GTP_IE_ALLOW_LIST_ENTRIES':2990,
  'PM2_CAT_SYS_FEDERATED_UPGRADE':3013,
  'PM2_CAT_SYS_FEDERATED_UPGRADE_NODE_LIST':3014,
  'PM2_CAT_SYS_FEDERATED_UPGRADE_KNOWN_HA_MEMBERS':3320,
  'PM2_CAT_SYS_IKE':3015,
  'PM2_CAT_SYS_IKE_DH_GROUP_1':3016,
  'PM2_CAT_SYS_IKE_DH_GROUP_2':3017,
  'PM2_CAT_SYS_IKE_DH_GROUP_5':3018,
  'PM2_CAT_SYS_IKE_DH_GROUP_14':3019,
  'PM2_CAT_SYS_IKE_DH_GROUP_15':3020,
  'PM2_CAT_SYS_IKE_DH_GROUP_16':3021,
  'PM2_CAT_SYS_IKE_DH_GROUP_17':3022,
  'PM2_CAT_SYS_IKE_DH_GROUP_18':3023,
  'PM2_CAT_SYS_IKE_DH_GROUP_19':3024,
  'PM2_CAT_SYS_IKE_DH_GROUP_20':3025,
  'PM2_CAT_SYS_IKE_DH_GROUP_21':3026,
  'PM2_CAT_SYS_IKE_DH_GROUP_27':3027,
  'PM2_CAT_SYS_IKE_DH_GROUP_28':3028,
  'PM2_CAT_SYS_IKE_DH_GROUP_29':3029,
  'PM2_CAT_SYS_IKE_DH_GROUP_30':3030,
  'PM2_CAT_SYS_IKE_DH_GROUP_31':3031,
  'PM2_CAT_SYS_IKE_DH_GROUP_32':3032,
  'PM2_CAT_SYS_SPEED_TEST_SCHED':3033,
  'PM2_CAT_WIRELESS_CONTROLLER_NAC_PROF':3056,
  'PM2_CAT_WIRELESS_CONTROLLER_SSID_POLICY':3057,
  'PM2_CAT_MONITORING_NPU_HPE':3058,
  'PM2_CAT_SWITCH_CONTROLLER_FORTILINK_SETS':3059,
  'PM2_CAT_SWITCH_CONTROLLER_FORTILINK_SETS_NAC_PORTS':3060,
  'PM2_CAT_SYS_NPU_SET_PRP':3061,
  'PM2_CAT_SWITCH_CONTROLLER_DYNAMIC_PORT_POLICY':3062,
  'PM2_CAT_SWITCH_CONTROLLER_DYNAMIC_PORT_POLICY_POLICY':3063,
  'PM2_CAT_SYS_ACME':3064,
  'PM2_CAT_SYS_ACME_ACCOUNTS':3065,
  'PM2_CAT_FW_ACCESS_PROXY_VIRTUAL_HOST':3066,
  'PM2_CAT_FW_ACCESS_PROXY':3067,
  'PM2_CAT_FW_ACCESS_PROXY_API_GATEWAY':3068,
  'PM2_CAT_FW_ACCESS_PROXY_API_GATEWAY_REALSRV':3069,
  'PM2_CAT_FW_ACCESS_PROXY_API_GATEWAY_SSL_CIPHER_SUITES':3070,
  'PM2_CAT_FW_ACCESS_PROXY_API_GATEWAY_QUIC':3300,
  'PM2_CAT_FW_ACCESS_PROXY_SVR_PUBKEY_AUTH_SETS':3071,
  'PM2_CAT_FW_ACCESS_PROXY_SVR_PUBKEY_AUTH_SETS_CERT_EXTENSION':3072,
  'PM2_CAT_FW_ACCESS_PROXY_REALSRV':3073,
  'PM2_CAT_FW_ACCESS_PROXY_API_GATEWAY6':3103,
  'PM2_CAT_FW_ACCESS_PROXY_API_GATEWAY6_REALSRV':3104,
  'PM2_CAT_FW_ACCESS_PROXY_API_GATEWAY6_SSL_CIPHER_SUITES':3105,
  'PM2_CAT_FW_ACCESS_PROXY_API_GATEWAY6_QUIC':3301,
  'PM2_CAT_FW_HYPERSCALE_POLICY':3078,
  'PM2_CAT_FW_HYPERSCALE_POLICY6':3079,
  'PM2_CAT_FW_HYPERSCALE_POLICY64':3080,
  'PM2_CAT_FW_HYPERSCALE_POLICY46':3081,
  'PM2_CAT_CERT_SET':3041,
  'PM2_CAT_WEB_PROXY_PAC_POLICY':3042,
  'PM2_CAT_WEB_PROXY_ISOLATOR_SERVER':3043,
  'PM2_CAT_IMAGE_ANALYZER_PROF':3044,
  'PM2_CAT_ICAP_REMOTE_SERVER':3045,
  'PM2_CAT_ICAP_LOCAL_SERVER':3046,
  'PM2_CAT_ICAP_LOCAL_SERVER_ICAP_SERVICE':3047,
  'PM2_CAT_WANOPT_REVERSE_CACHE_PREFETCH':3048,
  'PM2_CAT_WANOPT_REVERSE_CACHE_SERVER':3049,
  'PM2_CAT_WEBCACHE_SETTINGS':3050,
  'PM2_CAT_WEBCACHE_USER_AGENT':3051,
  'PM2_CAT_WEBCACHE_REVERSE_CACHE_PREFETCH_URL':3052,
  'PM2_CAT_WEBCACHE_REVERSE_CACHE_SERVER':3053,
  'PM2_CAT_SYS_SPAN_PORT':3083,
  'PM2_CAT_WEB_PROXY_DYNAMIC_BYPASS':4010,
  'PM2_CAT_SYS_NETHSM':4011,
  'PM2_CAT_SYS_NETHSM_SERVERS':4012,
  'PM2_CAT_SYS_NETHSM_SLOTS':4013,
  'PM2_CAT_SYS_NETHSM_HAGROUPS':4014,
  'PM2_CAT_LOG_SLBC_GLOBAL_SET':3084,
  'PM2_CAT_SCTP_FILTER_PROF':3088,
  'PM2_CAT_SCTP_FILTER_PROF_PPID_FILTERS':3089,
  'PM2_CAT_USER_CERT':3091,
  'PM2_CAT_GTP_RAT_TIMEOUT_PROF':3092,
  'PM2_CAT_PFCP_MESSAGE_FILTER':3093,
  'PM2_CAT_FW_PFCP':3094,
  'PM2_CAT_SYS_DNS64':3082,
  'PM2_CAT_SYS_FORTIAI':3098,
  'PM2_CAT_FW_ACCESS_PROXY_SSH_CLIENT_CERT':3101,
  'PM2_CAT_FW_ACCESS_PROXY_SSH_CLIENT_CERT_CERT_EXTENSION':3102,
  'PM2_CAT_FW_ACCESS_PROXY6':3106,
  'PM2_CAT_FW_ACCESS_PROXY6_API_GATEWAY':3107,
  'PM2_CAT_FW_ACCESS_PROXY6_API_GATEWAY_REALSRV':3108,
  'PM2_CAT_FW_ACCESS_PROXY6_API_GATEWAY_SSL_CIPHER_SUITES':3109,
  'PM2_CAT_FW_ACCESS_PROXY6_API_GATEWAY_QUIC':3302,
  'PM2_CAT_FW_ACCESS_PROXY6_API_GATEWAY6':3110,
  'PM2_CAT_FW_ACCESS_PROXY6_API_GATEWAY6_REALSRV':3111,
  'PM2_CAT_FW_ACCESS_PROXY6_API_GATEWAY6_SSL_CIPHER_SUITES':3112,
  'PM2_CAT_FW_ACCESS_PROXY6_API_GATEWAY6_QUIC':3303,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF':3123,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF_CELLULAR':3124,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF_CELLULAR_CONTROLLER_REPORT':3125,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF_CELLULAR_SMS_NOTIFICATION':3126,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF_CELLULAR_SMS_NOTIFICATION_ALERT':3127,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF_CELLULAR_SMS_NOTIFICATION_RECEIVER':3128,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF_CELLULAR_MODEM1':3129,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF_CELLULAR_MODEM1_AUTO_SWITCH':3130,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF_CELLULAR_MODEM2':3131,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF_CELLULAR_MODEM2_AUTO_SWITCH':3132,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF_LAN_EXTENSION':3133,
  'PM2_CAT_EXT_CTRL_EXTENDER_PROF_LAN_EXTENSION_BACKHAUL':3134,
  'PM2_CAT_WIRELESS_CONTROLLER_SYSLOG_PROF':3137,
  'PM2_CAT_LOG_TACACSACCOUNTING_SET':3139,
  'PM2_CAT_LOG_TACACSACCOUNTING2_SET':3140,
  'PM2_CAT_LOG_TACACSACCOUNTING3_SET':3141,
  'PM2_CAT_LOG_TACACSACCOUNTING_FILTER':3142,
  'PM2_CAT_LOG_TACACSACCOUNTING2_FILTER':3143,
  'PM2_CAT_LOG_TACACSACCOUNTING3_FILTER':3144,
  'PM2_CAT_WEB_PROXY_URL_LIST':4015,
  'PM2_CAT_WEB_PROXY_URL_LIST_ENTRIES':4016,
  'PM2_CAT_EMAILFILTER_FTGD':3158,
  'PM2_CAT_VPN_IPSEC_FEC':3159,
  'PM2_CAT_VPN_IPSEC_FEC_MAPPINGS':3147,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_VENUE_URL':3148,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_ANQP_VENUE_URL_VALUE_LIST':3149,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_ADVICE_OF_CHARGE':3150,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_ADVICE_OF_CHARGE_AOC_LIST':3151,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_ADVICE_OF_CHARGE_AOC_LIST_PLAN_INFO':3152,
  'PM2_CAT_ICAP_REMOTE_SERVER_GROUP':4017,
  'PM2_CAT_ICAP_REMOTE_SERVER_GROUP_SERVER_LIST':4018,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_OSU_PROVIDER_NAI':3153,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_OSU_PROVIDER_NAI_NAI_LIST':3154,
  'PM2_CAT_WIRELESS_CONTROLLER_HOTSPOT20_H2QP_TERMS_AND_CONDITIONS':3155,
  'PM2_CAT_SYS_IPAM':3157,
  'PM2_CAT_SYS_IPAM_POOLS':3234,
  'PM2_CAT_SYS_IPAM_POOLS_EXCLUDE':3352,
  'PM2_CAT_SYS_IPAM_RULES':3235,
  'PM2_CAT_SWITCH_CONTROLLER_DSL_POLICY':3160,
  'PM2_CAT_LOG_TAP_DEVICE':3161,
  'PM2_CAT_LOG_TAP_DEVICE_FILTER':3162,
  'PM2_CAT_LOG_TAP_DEVICE_FILTER_FREE_STYLE':3163,
  'PM2_CAT_FW_SSL_KEYRING_LIST':4019,
  'PM2_CAT_AWS_VPCE':3166,
  'PM2_CAT_WEB_PROXY_EXPLICIT_PROXY':4021,
  'PM2_CAT_SYS_SNMP_MIB_VIEW':3206,
  'PM2_CAT_ICAP_SVR_GROUP':3202,
  'PM2_CAT_ICAP_SVR_GROUP_SVR_LIST':3203,
  'PM2_CAT_AUTOMATION_SET':3205,
  'PM2_CAT_DLP_DATA_TYPE':3207,
  'PM2_CAT_DLP_DICTIONARY':3208,
  'PM2_CAT_DLP_DICTIONARY_ENTRIES':3209,
  'PM2_CAT_DLP_PROF':3210,
  'PM2_CAT_DLP_PROF_RULE':3211,
  'PM2_CAT_SYS_FORTINDR':3172,
  'PM2_CAT_EXTENSION_CONTROLLER_DATAPLAN':3215,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF':3216,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_CELLULAR':3217,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_CELLULAR_CONTROLLER_REPORT':3218,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_CELLULAR_SMS_NOTIFICATION':3219,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_CELLULAR_SMS_NOTIFICATION_ALERT':3220,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_CELLULAR_SMS_NOTIFICATION_RECEIVER':3221,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_CELLULAR_MODEM1':3222,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_CELLULAR_MODEM1_AUTO_SWITCH':3223,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_CELLULAR_MODEM2':3224,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_CELLULAR_MODEM2_AUTO_SWITCH':3225,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_LAN_EXTENSION':3226,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_LAN_EXTENSION_BACKHAUL':3227,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_LAN_EXTENSION_DOWNLINKS':3372,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_LAN_EXTENSION_TRAFFIC_SPLIT_SRVS':3389,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_WIFI':3348,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_WIFI_RADIO_1':3349,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_PROF_WIFI_RADIO_2':3350,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER':3228,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_WAN_EXTENSION':3229,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM1':4024,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM1_CDMA_PROFILE':4025,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM1_GSM_PROFILE':4026,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM1_SIM1':4027,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM1_SIM2':4028,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM1_AUTO_SWITCH':4029,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM2':4030,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM2_CDMA_PROFILE':4031,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM2_GSM_PROFILE':4032,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM2_SIM1':4033,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM2_SIM2':4034,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_MODEM2_AUTO_SWITCH':4035,
  'PM2_CAT_EXTENSION_CONTROLLER_FORTIGATE_PROF':3230,
  'PM2_CAT_EXTENSION_CONTROLLER_FORTIGATE_PROF_LAN_EXTENSION':3231,
  'PM2_CAT_EXTENSION_CONTROLLER_FORTIGATE':3232,
  'PM2_CAT_FW_GLOBAL':3236,
  'PM2_CAT_FW_NETWORK_SRV_DYNAMIC':3240,
  'PM2_CAT_EMS_TAG_DATASOURCE':3241,
  'PM2_CAT_SYS_REPMSG_CUS_MESSAGE':3173,
  'PM2_CAT_WEBCACHE_PREFETCH':4036,
  'PM2_CAT_FW_SSL_DEFAULT_CERTIFICATE':4037,
  'PM2_CAT_ISOLATOR_PROF':4038,
  'PM2_CAT_ISOLATOR_PROF_ENTRIES':4040,
  'PM2_CAT_SYS_SSO_FORTIGATE_CLOUD_ADMIN':3243,
  'PM2_CAT_SYS_SSO_FORTIGATE_CLOUD_ADMIN_GUI_DASHBOARD':3244,
  'PM2_CAT_SYS_SSO_FORTIGATE_CLOUD_ADMIN_GUI_DASHBOARD_WIDGET':3245,
  'PM2_CAT_SYS_SSO_FORTIGATE_CLOUD_ADMIN_GUI_DASHBOARD_WIDGET_FORTIVIEW_FILTERS':3246,
  'PM2_CAT_AV_EXEMPT_LIST':3247,
  'PM2_CAT_ROUTER_EXTCOMM_LIST':3253,
  'PM2_CAT_ROUTER_EXTCOMM_LIST_RULE':3254,
  'PM2_CAT_SYS_DEVICE_UPGRADE':3249,
  'PM2_CAT_SYS_DEVICE_UPGRADE_KNOWN_HA_MEMBERS':3321,
  'PM2_CAT_SYS_FABRIC_VPN':3250,
  'PM2_CAT_SYS_FABRIC_VPN_OVERLAYS':3251,
  'PM2_CAT_SYS_FABRIC_VPN_ADVERTISED_SUBNETS':3252,
  'PM2_CAT_SYS_VIN_ALARM':3255,
  'PM2_CAT_SYS_EVPN':3258,
  'PM2_CAT_VPN_KMIP_SVR':3259,
  'PM2_CAT_VPN_KMIP_SVR_SVR_LIST':3260,
  'PM2_CAT_SYS_PCP_SVR':3261,
  'PM2_CAT_SYS_PCP_SVR_POOLS':3262,
  'PM2_CAT_SYS_VPCE':3265,
  'PM2_CAT_SWITCH_CONTROLLER_ACL_INGRESS':3267,
  'PM2_CAT_SWITCH_CONTROLLER_ACL_INGRESS_ACTION':3268,
  'PM2_CAT_SWITCH_CONTROLLER_ACL_INGRESS_CLASSIFIER':3269,
  'PM2_CAT_SWITCH_CONTROLLER_ACL_GROUP':3270,
  'PM2_CAT_SYS_SDN_PROXY':3273,
  'PM2_CAT_ENDPOINT_CONTROL_FCTEMS_OVERRIDE':3276,
  'PM2_CAT_SYS_HA_LIC':4050,
  'PM2_CAT_WEBFILTER_URL_LIST':4051,
  'PM2_CAT_WEBFILTER_URL_LIST_ENTRIES':4052,
  'PM2_CAT_WEBFILTER_DOMAIN_LIST':4053,
  'PM2_CAT_WEBFILTER_DOMAIN_LIST_ENTRIES':4054,
  'PM2_CAT_VIRTUAL_PATCH_PROF':3278,
  'PM2_CAT_VIRTUAL_PATCH_PROF_EXEMPTION':3279,
  'PM2_CAT_WEB_PROXY_FAST_FALLBACK':3280,
  'PM2_CAT_RULE_OTVP':3281,
  'PM2_CAT_RULE_OTDT':3283,
  'PM2_CAT_WEB_PROXY_IMPLICIT_PROXY_RULE':4055,
  'PM2_CAT_WEB_PROXY_IMPLICIT_PROXY_RULE_PROXY_SERVERS':4056,
  'PM2_CAT_WEB_PROXY_IMPLICIT_PROXY_SETTING':4057,
  'PM2_CAT_ISOLATOR_SET':4058,
  'PM2_CAT_CASB_SAAS_APPLICATION':3285,
  'PM2_CAT_CASB_SAAS_APPLICATION_OUTPUT_ATTRIBUTES':3411,
  'PM2_CAT_CASB_SAAS_APPLICATION_INPUT_ATTRIBUTES':3412,
  'PM2_CAT_CASB_USER_ACTIVITY':3286,
  'PM2_CAT_CASB_USER_ACTIVITY_MATCH1':3287,
  'PM2_CAT_CASB_USER_ACTIVITY_MATCH1_RULES':3288,
  'PM2_CAT_CASB_USER_ACTIVITY_MATCH_TENANT_EXTRACTION':3413,
  'PM2_CAT_CASB_USER_ACTIVITY_MATCH_TENANT_EXTRACTION_FILTERS':3414,
  'PM2_CAT_CASB_USER_ACTIVITY_CONTROL_OPTIONS':3289,
  'PM2_CAT_CASB_USER_ACTIVITY_CONTROL_OPTIONS_OPERATIONS':3290,
  'PM2_CAT_FW_CASB_PROF':3291,
  'PM2_CAT_FW_CASB_PROF_SAAS_APPLICATION':3292,
  'PM2_CAT_FW_CASB_PROF_SAAS_APPLICATION_ACCESS_RULE':3293,
  'PM2_CAT_FW_CASB_PROF_SAAS_APPLICATION_CUS_CONTROL':3294,
  'PM2_CAT_FW_CASB_PROF_SAAS_APPLICATION_CUS_CONTROL_OPTION':3295,
  'PM2_CAT_SWITCH_CONTROLLER_PTP_PROF':3296,
  'PM2_CAT_SWITCH_CONTROLLER_PTP_INTF_POLICY':3297,
  'PM2_CAT_ETHERNET_OAM_CFM':3298,
  'PM2_CAT_ETHERNET_OAM_CFM_SRV':3299,
  'PM2_CAT_SYS_SPEED_TEST_SET':3304,
  'PM2_CAT_CASB_PROF':3305,
  'PM2_CAT_CASB_PROF_SAAS_APPLICATION':3306,
  'PM2_CAT_CASB_PROF_SAAS_APPLICATION_ACCESS_RULE':3307,
  'PM2_CAT_CASB_PROF_SAAS_APPLICATION_ACCESS_RULE_ATTRIBUTE_FILTER':3417,
  'PM2_CAT_CASB_PROF_SAAS_APPLICATION_CUS_CONTROL':3308,
  'PM2_CAT_CASB_PROF_SAAS_APPLICATION_CUS_CONTROL_OPTION':3309,
  'PM2_CAT_CASB_PROF_SAAS_APPLICATION_CUS_CONTROL_ATTRIBUTE_FILTER':3418,
  'PM2_CAT_CASB_PROF_SAAS_APPLICATION_ADVANCED_TENANT_CONTROL':3419,
  'PM2_CAT_CASB_PROF_SAAS_APPLICATION_ADVANCED_TENANT_CONTROL_ATTRIBUTE':3420,
  'PM2_CAT_SYS_TIMEZONE':3311,
  'PM2_CAT_DIAMETER_FILTER_PROF':3315,
  'PM2_CAT_VPN_QKD':3316,
  'PM2_CAT_RULE_FMWP':3319,
  'PM2_CAT_WEB_PROXY_REDIRECT_PROFILE':4060,
  'PM2_CAT_WEB_PROXY_REDIRECT_PROFILE_ENTRIES':4061,
  'PM2_CAT_USER_EXTERNAL_IDENTITY_PROVIDER':3332,
  'PM2_CAT_VIDEOFILTER_KEYWORD':3333,
  'PM2_CAT_VIDEOFILTER_KEYWORD_WORD':3334,
  'PM2_CAT_DLP_EXACT_DATA_MATCH':3336,
  'PM2_CAT_DLP_EXACT_DATA_MATCH_COLUMNS':3337,
  'PM2_CAT_SYS_5G_MODEM':3339,
  'PM2_CAT_SYS_5G_MODEM_DATA_PLAN':3340,
  'PM2_CAT_SYS_5G_MODEM_MODEM1':3341,
  'PM2_CAT_SYS_5G_MODEM_MODEM1_SIM_SWITCH':3342,
  'PM2_CAT_SYS_5G_MODEM_MODEM2':3343,
  'PM2_CAT_SYS_DIGITAL_IO':3331,
  'PM2_CAT_EXTENSION_CONTROLLER_EXTENDER_VAP':3347,
  'PM2_CAT_FW_ON_DEMAND_SNIFFER':3351,
  'PM2_CAT_AZURE_NVA':3353,
  'PM2_CAT_AZURE_VWAN_SLB':3354,
  'PM2_CAT_AZURE_VWAN_SLB_PERMANENT_SECURITY_RULES':3355,
  'PM2_CAT_AZURE_VWAN_SLB_PERMANENT_SECURITY_RULES_RULES':3356,
  'PM2_CAT_AZURE_VWAN_SLB_TEMPORARY_SECURITY_RULES':3357,
  'PM2_CAT_AZURE_VWAN_SLB_TEMPORARY_SECURITY_RULES_RULES':3358,
  'PM2_CAT_SYS_SSH_CONFIG':3359,
  'PM2_CAT_SYS_ICOND':3360,
  'PM2_CAT_SYS_NPU_POST':3361,
  'PM2_CAT_SYS_NPU_POST_PORT_NPU_MAP':3362,
  'PM2_CAT_FW_PROXY_ADDRESS6':4062,
  'PM2_CAT_FW_PROXY_ADDRESS6_HEADER_GROUP':4063,
  'PM2_CAT_FW_PROXY_ADDRESS6_TAGGING':4064,
  'PM2_CAT_FW_PROXY_ADDRGRP6':4065,
  'PM2_CAT_FW_PROXY_ADDRGRP6_TAGGING':4066,
  'PM2_CAT_SYS_SNMP_RMON_STAT':3367,
  'PM2_CAT_USER_SCIM':3371,
  'PM2_CAT_ZTNA_TRAFFIC_FORWARD_PROXY':3373,
  'PM2_CAT_ZTNA_TRAFFIC_FORWARD_PROXY_QUIC':3374,
  'PM2_CAT_ZTNA_TRAFFIC_FORWARD_PROXY_SSL_CIPHER_SUITES':3375,
  'PM2_CAT_ZTNA_TRAFFIC_FORWARD_PROXY_SSL_SVR_CIPHER_SUITES':3376,
  'PM2_CAT_ZTNA_TRAFFIC_FORWARD_PROXY_REVERSE_SRV':3377,
  'PM2_CAT_ZTNA_TRAFFIC_FORWARD_PROXY_REVERSE_SRV_REMOTE_SVRS':3378,
  'PM2_CAT_AZURE_VWAN_INGRESS_PUBLIC_IPS':3379,
  'PM2_CAT_SYS_VNE_INTF':3381,
  'PM2_CAT_WEBFILTER_FTGD_RISK_LEVEL':4067,
  'PM2_CAT_WEBFILTER_FTGD_LOCAL_RISK':4069,
  'PM2_CAT_ZTNA_REVERSE_CONNECTOR':3390,
  'PM2_CAT_ZTNA_WEB_PROXY':3391,
  'PM2_CAT_ZTNA_WEB_PROXY_API_GATEWAY':3392,
  'PM2_CAT_ZTNA_WEB_PROXY_API_GATEWAY_QUIC':3393,
  'PM2_CAT_ZTNA_WEB_PROXY_API_GATEWAY_REALSRV':3394,
  'PM2_CAT_ZTNA_WEB_PROXY_API_GATEWAY_SSL_CIPHER_SUITES':3395,
  'PM2_CAT_ZTNA_WEB_PROXY_API_GATEWAY6':3396,
  'PM2_CAT_ZTNA_WEB_PROXY_API_GATEWAY6_QUIC':3397,
  'PM2_CAT_ZTNA_WEB_PROXY_API_GATEWAY6_REALSRV':3398,
  'PM2_CAT_ZTNA_WEB_PROXY_API_GATEWAY6_SSL_CIPHER_SUITES':3399,
  'PM2_CAT_ZTNA_WEB_PORTAL':3400,
  'PM2_CAT_ZTNA_WEB_PORTAL_BOOKMARK':3401,
  'PM2_CAT_ZTNA_WEB_PORTAL_BOOKMARK_BMS':3402,
  'PM2_CAT_SYS_NGFW_SETS':3403,
  'PM2_CAT_SYS_SECURITY_RATING_SETS':3404,
  'PM2_CAT_SYS_SECURITY_RATING_CONTROLS':3405,
  'PM2_CAT_SYS_AUTOMATION_CONDITION':3406,
  'PM2_CAT_LOAD_BALANCE_WORKER_GROUP':3408,
  'PM2_CAT_SYS_SDN_VPN':3409,
  'PM2_CAT_SYS_HEALTH_CHECK_FTGD':3410,
  'PM2_CAT_CASB_ATTRIBUTE_MATCH':3415,
  'PM2_CAT_CASB_ATTRIBUTE_MATCH_ATTRIBUTE':3416,
  'PM2_CAT_DYNAMIC_MULTICAST_INTF':1631,
  'PM2_CAT_DYNAMIC_MULTICAST_INTF_MAPPING':1632,
  'PM2_CAT_DYNAMIC_INTF':800,
  'PM2_CAT_DYNAMIC_INTF_MAPPING':801,
  'PM2_CAT_DYNAMIC_INTF_PLATFORM_MAP':802,
  'PM2_CAT_DYNAMIC_ADDR':810,
  'PM2_CAT_DYNAMIC_ADDR_MAPPING':811,
  'PM2_CAT_VM_VPNTABLE':1004,
  'PM2_CAT_VM_NODE':1005,
  'PM2_CAT_PROTECTED_SUBNET':1006,
  'PM2_CAT_SUMMARY_ADDR':1499,
  'PM2_CAT_VM_NODE_IPV4_EXCLUDE_RANGE':1506,
  'PM2_CAT_VM_NODE_DHCP_SVR_IP_RANGE':1508,
  'PM2_CAT_SYS_META':1100,
  'PM2_CAT_SYS_META_FIELDS':1101,
  'PM2_CAT_POLICY_PACKAGE_SETTING':1103,
  'PM2_CAT_ADOM_OPTIONS':1104,
  'PM2_CAT_DYNAMIC_VIP':1494,
  'PM2_CAT_DYNAMIC_IPPOOL':1495,
  'PM2_CAT_DYNAMIC_CERT_LOCAL':1504,
  'PM2_CAT_DYNAMIC_CERT_MAPPING':1596,
  'PM2_CAT_DYNAMIC_VPNTUNNEL':1509,
  'PM2_CAT_DYNAMIC_VPNTUNNEL_MAPPING':1597,
  'PM2_CAT_DEV_PROFILE_SETTING':1511,
  'PM2_CAT_DEV_PROFILE_FAZ':1521,
  'PM2_CAT_DEV_PROFILE_FTGD':1522,
  'PM2_CAT_DEV_PROFILE_FSA':1539,
  'PM2_CAT_CERT_TEMPLATE':1512,
  'PM2_CAT_WANPROF_SETTING':1768,
  'PM2_CAT_DYNAMIC_VIRTUAL_WAN_LINK_MEMBERS':1769,
  'PM2_CAT_DYNAMIC_VIRTUAL_WAN_LINK_INTF_MAPPING':1770,
  'PM2_CAT_DYNAMIC_VIRTUAL_WAN_LINK_SERVER':1771,
  'PM2_CAT_DYNAMIC_VIRTUAL_WAN_LINK_SERVER_MAPPING':1772,
  'PM2_CAT_DYNAMIC_VIRTUAL_WAN_LINK_NEIGHBOR':1773,
  'PM2_CAT_DYNAMIC_VIRTUAL_WAN_LINK_NEIGHBOR_MAPPING':1774,
  'PM2_CAT_DYNAMIC_INPUT_INTF':1792,
  'PM2_CAT_DYNAMIC_INPUT_INTF_MAPPING':1793,
  'PM2_CAT_FSP_MANAGED_SWITCH':1800,
  'PM2_CAT_FAPMAP_SETTING':1900,
  'PM2_CAT_FAPMAP_SETTING_WTP_INFO':1901,
  'PM2_CAT_FWMPROF_SETTING':1906,
  'PM2_CAT_FWMPROF_SETTING_ENFORCED_VERSION':1907,
  'PM2_CAT_USER_CONNECTOR':3075,
  'PM2_CAT_USER_JSON':4023,
  'PM2_CAT_USER_PXGRID':1979,
  'PM2_CAT_USER_CLEARPASS':2507,
  'PM2_CAT_USER_NSX':2508,
  'PM2_CAT_USER_NSX_SERVICE':2624,
  'PM2_CAT_USER_VCENTER':2622,
  'PM2_CAT_USER_VCENTER_GROUP_RULE':2623,
  'PM2_CAT_USER_FLEXVM':2509,
  'PM2_CAT_CLI_TEMPLATE':1902,
  'PM2_CAT_CLI_TEMPLATE_GROUP':1903,
  'PM2_CAT_FSP_VLAN':1801,
  'PM2_CAT_FSP_VLAN_INTF':1803,
  'PM2_CAT_FSP_VLAN_INTF_SECIP':1805,
  'PM2_CAT_FSP_VLAN_INTF_IPV6':1806,
  'PM2_CAT_FSP_VLAN_INTF_IPV6_IP6_PREFIX_LIST':1941,
  'PM2_CAT_FSP_VLAN_INTF_IPV6_IP6_EXTRA_ADDR':1942,
  'PM2_CAT_FSP_VLAN_INTF_IPV6_IP6_DELEGATED_PREFIX_LIST':1943,
  'PM2_CAT_FSP_VLAN_INTF_IPV6_VRRP6':1944,
  'PM2_CAT_FSP_VLAN_INTF_VRRP':1808,
  'PM2_CAT_FSP_VLAN_INTF_VRRP_PROXY_ARP':1949,
  'PM2_CAT_FSP_VLAN_DHCP_SERVER':1811,
  'PM2_CAT_FSP_VLAN_DHCP_SVR_EXCLUDE_RANGE':1813,
  'PM2_CAT_FSP_VLAN_DHCP_SVR_IP_RANGE':1814,
  'PM2_CAT_FSP_VLAN_DHCP_SVR_RESERVED_ADDRESS':1815,
  'PM2_CAT_FSP_VLAN_DHCP_SVR_OPTIONS':1816,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING':1818,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_INTF':1819,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_INTF_SECIP':1820,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_INTF_IPV6':1940,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_INTF_IPV6_IP6_PREFIX_LIST':1945,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_INTF_IPV6_IP6_EXTRA_ADDR':1946,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_INTF_IPV6_IP6_DELEGATED_PREFIX_LIST':1947,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_INTF_IPV6_VRRP6':1948,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_INTF_VRRP':1809,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_INTF_VRRP_PROXY_ARP':1810,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_DHCP_SERVER':1821,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_DHCP_SVR_EXCLUDE_RANGE':1823,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_DHCP_SVR_IP_RANGE':1824,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_DHCP_SVR_RESERVED_ADDRESS':1825,
  'PM2_CAT_FSP_VLAN_DYNAMIC_MAPPING_DHCP_SVR_OPTIONS':1826,
  'PM2_CAT_GL_IPS_SENSOR':1908,
  'PM2_CAT_GL_IPS_SENSOR_FILTER':1909,
  'PM2_CAT_GL_IPS_SENSOR_OVERRIDE':1910,
  'PM2_CAT_GL_IPS_SENSOR_OVERRIDE_EXEMPT_IP':1911,
  'PM2_CAT_GL_IPS_SENSOR_ENTRIES':1912,
  'PM2_CAT_GL_IPS_SENSOR_ENTRIES_EXEMPT_IP':1913,
  'PM2_CAT_FSP_VDOM_SETS':1994,
  'PM2_CAT_FSP_VDOM_SETS_INTERFACE_SETS':1995,
  'PM2_CAT_DYNAMIC_LTE_MODEM':3114,
  'PM2_CAT_DYNAMIC_LTE_MODEM_STATUS':3115,
  'PM2_CAT_TEMPLATE_GROUP_SETTING':1914,
  'PM2_CAT_FMG_VARIABLE':3200,
  'PM2_CAT_FMG_VARIABLE_MAPPING':3201,
  'PM2_CAT_FMG_FABRIC_AUTH_TEMPLATE':1013,
  'PM2_CAT_FMG_FABRIC_AUTH_TEMPLATE_PLATFORMS':1014,
  'PM2_CAT_FMG_DEVICE_BLUEPRINT':1012,
  'PM2_CAT_FMG_DEVICE_BLUEPRINT_VDOMS':1017,
  'PM2_CAT_FMG_SASEMGR_SETTINGS':900,
  'PM2_CAT_FMG_SASEMGR_STATUS':901,
  'PM2_CAT_CLOUD_ORCHEST_AWSCONNECTOR':4045,
  'PM2_CAT_CLOUD_ORCHEST_AWSTEMPLATE_AUTOSCALE_TGW_NEW_VPC':4046,
  'PM2_CAT_CLOUD_ORCHEST_AWSTEMPLATE_AUTOSCALE_EXISTING_VPC':4047,
  'PM2_CAT_CLOUD_ORCHEST_AWSTEMPLATE_AUTOSCALE_NEW_VPC':4048,
  'PM2_CAT_CLOUD_ORCHEST_AWS':4044,
  'PM2_CAT_CLOUD_ORCHESTRATION':4043,
  'PM2_CAT_UMS_SETTING':2510,
  'PM2_CAT_MIN':1,
  'PM2_CAT_MAX':4070,
  'PM2_CAT_TOTAL':2051,
  'PM2_OPT_LANG_ENG':0,
  'PM2_OPT_LANG_SCH':1,
  'PM2_OPT_LANG_JAP':2,
  'PM2_OPT_LANG_KOR':3,
  'PM2_OPT_LANG_SPA':4,
  'PM2_OPT_LANG_TCH':5,
  'PM2_OPT_LANG_FRE':6,
  'PM2_OPT_LANG_PTG':7,
  'PM2_OPT_ENABLE':1,
  'PM2_OPT_DISABLE':0,
  'PM2_POLPRILOW':2,
  'PM2_POLPRIMED':1,
  'PM2_POLPRIHIGH':0,
  'PM2_HTTP_OBFUSCATE_NONE':0,
  'PM2_HTTP_OBFUSCATE_BRIEF':1,
  'PM2_HTTP_OBFUSCATE_HEADER_ONLY':2,
  'PM2_HTTP_OBFUSCATE_NO_RESP':3,
  'PM2_KERNEL_OPM_AV':1,
  'PM2_KERNEL_OPM_TH':2,
  'PM2_KERNEL_OPM_SS':3,
  'PM2_AV_FAILOPEN_PASS':1,
  'PM2_AV_FAILOPEN_OFF':0,
  'PM2_AV_FAILOPEN_ONESHOT':3,
  'PM2_AV_FAILOPEN_IDLEDROP':4,
  'PM2_OPT_STRICT':6,
  'PM2_VDOM_MODE_NO_VDOM':0,
  'PM2_VDOM_MODE_SPLIT_VDOM':2,
  'PM2_VDOM_MODE_MULTI_VDOM':1,
  'PM2_CFG_SAVE_AUTO':0,
  'PM2_CFG_SAVE_MANUAL':1,
  'PM2_CFG_SAVE_REVERT':2,
  'PM2_INTERNAL_SW_MODE_SWITCH':0,
  'PM2_INTERNAL_SW_MODE_INTERFACE':1,
  'PM2_INTERNAL_SW_MODE_HUB':2,
  'PM2_DH_PARAM_1024':0,
  'PM2_DH_PARAM_1536':1,
  'PM2_DH_PARAM_2048':2,
  'PM2_DH_PARAM_3072':3,
  'PM2_DH_PARAM_4096':4,
  'PM2_DH_PARAM_6144':5,
  'PM2_DH_PARAM_8192':6,
  'PM2_INTF_SPEED_AUTO':1,
  'PM2_INTF_SPEED_100FULL':8,
  'PM2_INTF_SPEED_100HALF':16,
  'PM2_INTF_SPEED_10FULL':2,
  'PM2_INTF_SPEED_10HALF':4,
  'PM2_INTF_SPEED_1000FULL':32,
  'PM2_INTF_SPEED_1000AUTO':256,
  'PM2_PROTOCOL_CHECK_LOOSE':0,
  'PM2_PROTOCOL_CHECK_STRICT':1,
  'PM2_GLOBAL_ANTI_REPLAY_DISABLE':0,
  'PM2_GLOBAL_ANTI_REPLAY_LOOSE':1,
  'PM2_GLOBAL_ANTI_REPLAY_STRICT':2,
  'PM2_IF_IFACE_T1':4099,
  'PM2_IF_IFACE_E1':4100,
  'PM2_FGD_ALERT_ADVISORY':1,
  'PM2_FGD_ALERT_LATEST_THREAT':2,
  'PM2_FGD_ALERT_LATEST_VIRUS':4,
  'PM2_FGD_ALERT_LATEST_ATTACK':8,
  'PM2_FGD_ALERT_NEW_VIRUS_DB':16,
  'PM2_FGD_ALERT_NEW_ATTACK_DB':32,
  'PM2_WIFI_MODE_AC':0,
  'PM2_WIFI_MODE_CLIENT':1,
  'PM2_WIFI_MODE_WTP':2,
  'PM2_WIFI_MODE_FWFAP':3,
  'PM2_GUI_OPT_ENABLE':0,
  'PM2_GUI_OPT_DISABLE':1,
  'PM2_GUI_OPT_FLOW_ONLY':2,
  'PM2_TRAFFIC_PRI_TOS':0,
  'PM2_TRAFFIC_PRI_DSCP':1,
  'PM2_LOG_UUID_DISABLE':0,
  'PM2_LOG_UUID_POLICY_ONLY':1,
  'PM2_LOG_UUID_EXTENDED':2,
  'PM2_PP_AUTH_NONE':1,
  'PM2_PP_AUTH_PAP':8,
  'PM2_PP_AUTH_CHAP':16,
  'PM2_SSL_VERSION_TLSV1_0':1,
  'PM2_SSL_VERSION_TLSV1_1':2,
  'PM2_SSL_VERSION_TLSV1_2':4,
  'PM2_SSL_VERSION_SSLV3':8,
  'PM2_SSL_VERSION_TLSV1_3':32,
  'PM2_SSL_V_CIPHER_RC4':1,
  'PM2_SSL_V_CIPHER_LOW':2,
  'PM2_GUI_THEME_GREEN':6,
  'PM2_GUI_THEME_RED':8,
  'PM2_GUI_THEME_BLUE':1,
  'PM2_GUI_THEME_MELONGENE':7,
  'PM2_GUI_THEME_MARINER':9,
  'PM2_GUI_THEME_NEUTRINO':10,
  'PM2_GUI_THEME_JADE':11,
  'PM2_GUI_THEME_GRAPHITE':12,
  'PM2_GUI_THEME_DARK_MATTER':13,
  'PM2_GUI_THEME_ONYX':14,
  'PM2_GUI_THEME_ECLIPSE':15,
  'PM2_GUI_THEME_RETRO':16,
  'PM2_GUI_THEME_FPX':17,
  'PM2_GUI_THEME_JET_STREAM':18,
  'PM2_GUI_THEME_SECURITY_FABRIC':19,
  'PM2_DISK_USAGE_LOG':0,
  'PM2_DISK_USAGE_WANOPT':1,
  'PM2_RECUR_NEVER':4,
  'PM2_RECUR_HOURLY':3,
  'PM2_RECUR_DAILY':0,
  'PM2_RECUR_WEEKLY':1,
  'PM2_RECUR_MONTHLY':2,
  'PM2_WDAY_SUNDAY':1,
  'PM2_WDAY_MONDAY':2,
  'PM2_WDAY_TUESDAY':4,
  'PM2_WDAY_WEDNESDAY':8,
  'PM2_WDAY_THURSDAY':16,
  'PM2_WDAY_FRIDAY':32,
  'PM2_WDAY_SATURDAY':64,
  'PM2_AUTH_SESS_LMT_BLOCK_NEW':0,
  'PM2_AUTH_SESS_LMT_LOGOUT_INACT':1,
  'PM2_OPT_REQ_OPTIONAL':0,
  'PM2_OPT_REQ_MANDATORY':1,
  'PM2_OPT_ALL':34,
  'PM2_OPT_NON_CONSOLE_ONLY':81,
  'PM2_PROXY_REAUTH_MODE_SESSION':0,
  'PM2_PROXY_REAUTH_MODE_TRAFFIC':1,
  'PM2_PROXY_REAUTH_MODE_ABSOLUTE':2,
  'PM2_DATE_FMT_YYYY_MM_DD':0,
  'PM2_DATE_FMT_DD_MM_YYYY':1,
  'PM2_DATE_FMT_MM_DD_YYYY':2,
  'PM2_DATE_FMT_YYYY_MM_DD_2':3,
  'PM2_DATE_FMT_DD_MM_YYYY_2':4,
  'PM2_DATE_FMT_MM_DD_YYYY_2':5,
  'PM2_GUI_DTIME_SOURCE_SYSTEM':1,
  'PM2_GUI_DTIME_SOURCE_BROWSER':2,
  'PM2_OPT_AUTO':18,
  'PM2_OPT_FORCED':22,
  'PM2_ALG_PRIMARY_SECONDARY':1,
  'PM2_ALG_ROUND_ROBIN':2,
  'PM2_OPT_BYPASS':17,
  'PM2_OPT_BLOCK':0,
  'PM2_IMG_CACHE_MODE_OPT_STOP':1,
  'PM2_IMG_CACHE_MODE_OPT_ROLLING':2,
  'PM2_SSH_KEX_ALGO_DIFFIE_HELLMAN_GROUP1_SHA1':1,
  'PM2_SSH_KEX_ALGO_DIFFIE_HELLMAN_GROUP14_SHA1':2,
  'PM2_SSH_KEX_ALGO_DIFFIE_HELLMAN_GROUP_EXCHANGE_SHA1':4,
  'PM2_SSH_KEX_ALGO_DIFFIE_HELLMAN_GROUP_EXCHANGE_SHA256':8,
  'PM2_SSH_KEX_ALGO_CURVE25519_SHA256_LIBSSH_ORG':16,
  'PM2_SSH_KEX_ALGO_ECDH_SHA2_NISTP256':32,
  'PM2_SSH_KEX_ALGO_ECDH_SHA2_NISTP384':64,
  'PM2_SSH_KEX_ALGO_ECDH_SHA2_NISTP521':128,
  'PM2_SSH_KEX_ALGO_DIFFIE_HELLMAN_GROUP14_SHA256':256,
  'PM2_SSH_KEX_ALGO_DIFFIE_HELLMAN_GROUP16_SHA512':512,
  'PM2_SSH_KEX_ALGO_DIFFIE_HELLMAN_GROUP18_SHA512':1024,
  'PM2_SSH_ENC_ALGO_CHACHA20_POLY1305_OPENSSH_COM':1,
  'PM2_SSH_ENC_ALGO_AES128_CTR':2,
  'PM2_SSH_ENC_ALGO_AES192_CTR':4,
  'PM2_SSH_ENC_ALGO_AES256_CTR':8,
  'PM2_SSH_ENC_ALGO_ARCFOUR256':16,
  'PM2_SSH_ENC_ALGO_ARCFOUR128':32,
  'PM2_SSH_ENC_ALGO_AES128_CBC':64,
  'PM2_SSH_ENC_ALGO_3DES_CBC':128,
  'PM2_SSH_ENC_ALGO_BLOWFISH_CBC':256,
  'PM2_SSH_ENC_ALGO_CAST128_CBC':512,
  'PM2_SSH_ENC_ALGO_AES192_CBC':1024,
  'PM2_SSH_ENC_ALGO_AES256_CBC':2048,
  'PM2_SSH_ENC_ALGO_ARCFOUR':4096,
  'PM2_SSH_ENC_ALGO_RIJNDAEL_CBC_LYSATOR_LIU_SE':8192,
  'PM2_SSH_ENC_ALGO_AES128_GCM_OPENSSH_COM':16384,
  'PM2_SSH_ENC_ALGO_AES256_GCM_OPENSSH_COM':32768,
  'PM2_SSH_MAC_ALGO_HMAC_MD5':1,
  'PM2_SSH_MAC_ALGO_HMAC_MD5_ETM_OPENSSH_COM':2,
  'PM2_SSH_MAC_ALGO_HMAC_MD5_96':4,
  'PM2_SSH_MAC_ALGO_HMAC_MD5_96_ETM_OPENSSH_COM':8,
  'PM2_SSH_MAC_ALGO_HMAC_SHA1':16,
  'PM2_SSH_MAC_ALGO_HMAC_SHA1_ETM_OPENSSH_COM':32,
  'PM2_SSH_MAC_ALGO_HMAC_SHA2_256':64,
  'PM2_SSH_MAC_ALGO_HMAC_SHA2_256_ETM_OPENSSH_COM':128,
  'PM2_SSH_MAC_ALGO_HMAC_SHA2_512':256,
  'PM2_SSH_MAC_ALGO_HMAC_SHA2_512_ETM_OPENSSH_COM':512,
  'PM2_SSH_MAC_ALGO_HMAC_RIPEMD160':1024,
  'PM2_SSH_MAC_ALGO_HMAC_RIPEMD160_OPENSSH_COM':2048,
  'PM2_SSH_MAC_ALGO_HMAC_RIPEMD160_ETM_OPENSSH_COM':4096,
  'PM2_SSH_MAC_ALGO_UMAC_64_OPENSSH_COM':8192,
  'PM2_SSH_MAC_ALGO_UMAC_128_OPENSSH_COM':16384,
  'PM2_SSH_MAC_ALGO_UMAC_64_ETM_OPENSSH_COM':32768,
  'PM2_SSH_MAC_ALGO_UMAC_128_ETM_OPENSSH_COM':65536,
  'PM2_CIPHERSUITE_TLS_AES_128_GCM_SHA256':1,
  'PM2_CIPHERSUITE_TLS_AES_256_GCM_SHA384':2,
  'PM2_CIPHERSUITE_TLS_CHACHA20_POLY1305_SHA256':4,
  'PM2_CIPHERSUITE_TLS_AES_128_CCM_SHA256':8,
  'PM2_CIPHERSUITE_TLS_AES_128_CCM_8_SHA256':16,
  'PM2_BANNED_CIPHER_RSA':1,
  'PM2_BANNED_CIPHER_DHE':4,
  'PM2_BANNED_CIPHER_ECDHE':16,
  'PM2_BANNED_CIPHER_DSS':32,
  'PM2_BANNED_CIPHER_ECDSA':64,
  'PM2_BANNED_CIPHER_AES':128,
  'PM2_BANNED_CIPHER_AESGCM':256,
  'PM2_BANNED_CIPHER_CAMELLIA':512,
  'PM2_BANNED_CIPHER_3DES':1024,
  'PM2_BANNED_CIPHER_SHA1':2048,
  'PM2_BANNED_CIPHER_SHA256':4096,
  'PM2_BANNED_CIPHER_SHA384':8192,
  'PM2_BANNED_CIPHER_STATIC':16384,
  'PM2_BANNED_CIPHER_CHACHA20':65536,
  'PM2_BANNED_CIPHER_ARIA':131072,
  'PM2_BANNED_CIPHER_AESCCM':262144,
  'PM2_INTERNET_SERVICE_DATABASE_MINI':1,
  'PM2_INTERNET_SERVICE_DATABASE_STANDARD':2,
  'PM2_INTERNET_SERVICE_DATABASE_FULL':3,
  'PM2_INTERNET_SERVICE_DATABASE_ON_DEMAND':4,
  'PM2_PROXY_KEEP_ALIVE_MODE_SESSION':0,
  'PM2_PROXY_KEEP_ALIVE_MODE_TRAFFIC':1,
  'PM2_PROXY_KEEP_ALIVE_MODE_REAUTH':2,
  'PM2_SPLIT_PORT_MODE_DISABLE':0,
  'PM2_SPLIT_PORT_MODE_4X10G':72,
  'PM2_SPLIT_PORT_MODE_4X25G':73,
  'PM2_SPLIT_PORT_MODE_4X50G':74,
  'PM2_SPLIT_PORT_MODE_8X50G':75,
  'PM2_SPLIT_PORT_MODE_4X100G':76,
  'PM2_SPLIT_PORT_MODE_2X200G':77,
  'PM2_SPLIT_PORT_MODE_8X25G':78,
  'PM2_WAD_RESTART_MODE_NONE':0,
  'PM2_WAD_RESTART_MODE_TIME':1,
  'PM2_WAD_RESTART_MODE_MEMORY':2,
  'PM2_OPT_ONCE':67,
  'PM2_SSH_HOSTKEY_ALGO_SSH_RSA':1,
  'PM2_SSH_HOSTKEY_ALGO_ECDSA_SHA2_NISTP521':2,
  'PM2_SSH_HOSTKEY_ALGO_RSA_SHA2_256':4,
  'PM2_SSH_HOSTKEY_ALGO_RSA_SHA2_512':8,
  'PM2_SSH_HOSTKEY_ALGO_SSH_ED25519':16,
  'PM2_SSH_HOSTKEY_ALGO_ECDSA_SHA2_NISTP384':32,
  'PM2_SSH_HOSTKEY_ALGO_ECDSA_SHA2_NISTP256':64,
  'PM2_OPT_SHOW':73,
  'PM2_OPT_HIDE':74,
  'PM2_QUIC_CONGESTION_CONTROL_ALGO_CUBIC':1,
  'PM2_QUIC_CONGESTION_CONTROL_ALGO_BBR':2,
  'PM2_QUIC_CONGESTION_CONTROL_ALGO_BBR2':3,
  'PM2_QUIC_CONGESTION_CONTROL_ALGO_RENO':4,
  'PM2_PURDUE_LEVEL_1':1,
  'PM2_PURDUE_LEVEL_1_5':6,
  'PM2_PURDUE_LEVEL_2':2,
  'PM2_PURDUE_LEVEL_2_5':7,
  'PM2_PURDUE_LEVEL_3':3,
  'PM2_PURDUE_LEVEL_3_5':8,
  'PM2_PURDUE_LEVEL_4':4,
  'PM2_PURDUE_LEVEL_5':5,
  'PM2_PURDUE_LEVEL_5_5':9,
  'PM2_INTERVAL_1MIN':1,
  'PM2_INTERVAL_5MIN':2,
  'PM2_INTERVAL_15MIN':3,
  'PM2_INTERVAL_30MIN':4,
  'PM2_INTERVAL_1HR':5,
  'PM2_ACC_PERM_NONE':0,
  'PM2_ACC_PERM_RD':1,
  'PM2_ACC_PERM_RDWR':3,
  'PM2_ACC_PERM_CUSTOM':4,
  'PM2_ROLE_SA':1,
  'PM2_ROLE_AA':2,
  'PM2_ROLE_CA':4,
  'PM2_DATA_LEAK_PREVENTION_NONE':0,
  'PM2_DATA_LEAK_PREVENTION_READ':1,
  'PM2_DATA_LEAK_PREVENTION_READ_WRITE':2,
  'PM2_SYS_ACC_SCOPE_VDOM':0,
  'PM2_SYS_ACC_SCOPE_GLOBAL':1,
  'PM2_DB_TS_SORT_BYTES':4,
  'PM2_DB_TS_SORT_MESSAGES':5,
  'PM2_DB_TS_SORT_PACKETS':6,
  'PM2_DB_TS_SORT_BANDWIDTH':7,
  'PM2_DB_TS_SORT_SESSION':8,
  'PM2_DB_TS_SORT_SESSIONS':9,
  'PM2_DB_DISPLAY_CHART':0,
  'PM2_DB_DISPLAY_TABLE':1,
  'PM2_DB_DISPLAY_LINE':2,
  'PM2_DB_REPORT_BY_SRC':0,
  'PM2_DB_REPORT_BY_DST':1,
  'PM2_DB_REPORT_BY_DST_PORT':2,
  'PM2_DB_REPORT_BY_DLP':3,
  'PM2_DB_REPORT_BY_POLICY':5,
  'PM2_DB_REPORT_BY_PROTOCOLS':6,
  'PM2_DB_REPORT_BY_SENSOR':7,
  'PM2_DB_REPORT_BY_APPLICATION':9,
  'PM2_DB_REPORT_BY_ALL':10,
  'PM2_DB_REPORT_BY_WEB_CATEGORY':12,
  'PM2_DB_REPORT_BY_WEB_DOMAIN':13,
  'PM2_DLP_PROTO_V_HTTP':8,
  'PM2_DLP_PROTO_V_POP3':2,
  'PM2_DLP_PROTO_V_IMAP':4,
  'PM2_DLP_PROTO_V_SMTP':1,
  'PM2_DLP_PROTO_V_FTP':64,
  'PM2_DLP_PROTO_V_IM':1024,
  'PM2_DLP_PROTO_V_HTTPS':2097152,
  'PM2_DLP_PROTO_V_POP3S':131072,
  'PM2_DLP_PROTO_V_IMAPS':262144,
  'PM2_DLP_PROTO_V_SMTPS':65536,
  'PM2_DB_WIDGET_SYSINFO':0,
  'PM2_DB_WIDGET_LICINFO':1,
  'PM2_DB_WIDGET_SYSOP':2,
  'PM2_DB_WIDGET_SYSRES':3,
  'PM2_DB_WIDGET_ALERT':4,
  'PM2_DB_WIDGET_STATS':5,
  'PM2_DB_WIDGET_JSCONSOLE':6,
  'PM2_DB_WIDGET_SESSIONS':7,
  'PM2_DB_WIDGET_TOP_VIRUSES':9,
  'PM2_DB_WIDGET_TOP_ATTACKS':10,
  'PM2_DB_WIDGET_TR_HISTORY':11,
  'PM2_DB_WIDGET_APP_USAGE':12,
  'PM2_DB_WIDGET_POL_USAGE':13,
  'PM2_DB_WIDGET_DLP_USAGE':14,
  'PM2_DB_WIDGET_PER_IP_USAGE':15,
  'PM2_DB_WIDGET_IM_USAGE':16,
  'PM2_DB_WIDGET_P2P_USAGE':17,
  'PM2_DB_WIDGET_VOIP_USAGE':18,
  'PM2_DB_WIDGET_RAID':8,
  'PM2_DB_WIDGET_STORAGE':19,
  'PM2_DB_WIDGET_PRO_USAGE':20,
  'PM2_DB_WIDGET_SESS_HISTORY':21,
  'PM2_DB_WIDGET_ELBC':23,
  'PM2_DB_WIDGET_DEVICE_OS_DIST':24,
  'PM2_DB_WIDGET_ANALYTICS':25,
  'PM2_DB_WIDGET_SESS_BANDWITH':26,
  'PM2_DB_WIDGET_USB_MODEM':27,
  'PM2_DB_WIDGET_GUI_FEATURES':28,
  'PM2_DB_WIDGET_THREAT_HISTORY':29,
  'PM2_ADDR_TYPE_IPBOTH':11,
  'PM2_ADDR_TYPE_IPV4':7,
  'PM2_ADDR_TYPE_IPV6':8,
  'PM2_VIEW_TYPE_REALTIME':0,
  'PM2_VIEW_TYPE_HISTORICAL':1,
  'PM2_CPU_DISP_TYPE_AVERAGE':0,
  'PM2_CPU_DISP_TYPE_EACH':1,
  'PM2_TWO_FACTOR_DISABLE':0,
  'PM2_TWO_FACTOR_FORTITOKEN':1,
  'PM2_TWO_FACTOR_EMAIL':2,
  'PM2_TWO_FACTOR_SMS':3,
  'PM2_TWO_FACTOR_FORTITOKEN_CLOUD':4,
  'PM2_LOCATION_BOTTOM':0,
  'PM2_LOCATION_SIDE':1,
  'PM2_LOCATION_HIDE':2,
  'PM2_SMS_SERVER_FGD':0,
  'PM2_SMS_SERVER_CUSTOM':1,
  'PM2_DB_WIDGET_FORTICLOUD':30,
  'PM2_DB_WIDGET_CPU_USAGE':31,
  'PM2_DB_WIDGET_MEMORY_USAGE':32,
  'PM2_DB_WIDGET_DISK_USAGE':33,
  'PM2_DB_WIDGET_LOG_RATE':34,
  'PM2_DB_WIDGET_SESSIONS_RATE':35,
  'PM2_DB_WIDGET_ADMINS':36,
  'PM2_DB_WIDGET_SECURITY_FABRIC':37,
  'PM2_DB_WIDGET_SECURITY_AUDIT':38,
  'PM2_DB_WIDGET_SENSOR_INFO':39,
  'PM2_DB_WIDGET_HA_STATUS':40,
  'PM2_DB_WIDGET_VULNERABILITY_SUMMARY':41,
  'PM2_DB_WIDGET_HOST_SCAN_SUMMARY':42,
  'PM2_DB_WIDGET_VMINFO':43,
  'PM2_DB_WIDGET_FORTIVIEW':44,
  'PM2_DB_WIDGET_BOTNET_ACTIVITY':45,
  'PM2_DB_WIDGET_SECURITY_FABRIC_RANKING':46,
  'PM2_DB_WIDGET_FORTIMAIL':47,
  'PM2_DB_WIDGET_FABRIC_DEVICE':48,
  'PM2_DB_WIDGET_ROUTING':49,
  'PM2_DB_WIDGET_FORTICLIENT':50,
  'PM2_DB_WIDGET_DEVICE_INVENTORY':51,
  'PM2_DB_WIDGET_DHCP':52,
  'PM2_DB_WIDGET_VIRTUAL_WAN':53,
  'PM2_DB_WIDGET_FORTIGUARD_QUOTA':54,
  'PM2_DB_WIDGET_IPSEC_VPN':55,
  'PM2_DB_WIDGET_SSL_VPN':56,
  'PM2_DB_WIDGET_LOAD_BALANCE':57,
  'PM2_DB_WIDGET_FIREWALL_USER':58,
  'PM2_DB_WIDGET_QUARANTINE':59,
  'PM2_DB_WIDGET_COLLECTED_EMAIL':60,
  'PM2_DB_WIDGET_AP_STATUS':61,
  'PM2_DB_WIDGET_CHANNEL_UTILIZATION':62,
  'PM2_DB_WIDGET_INTERFERING_APS':63,
  'PM2_DB_WIDGET_CLIENT_SIGNAL_STRENGTH':64,
  'PM2_DB_WIDGET_CLIENTS_BY_AP':65,
  'PM2_DB_WIDGET_HISTORICAL_CLIENTS':66,
  'PM2_DB_WIDGET_WIFI_LOGIN_FAILURES':67,
  'PM2_DB_WIDGET_ROGUE_AP':68,
  'PM2_DB_WIDGET_INTERFERING_SSIDS':69,
  'PM2_DB_WIDGET_WANOPT_CACHE':70,
  'PM2_DB_WIDGET_WANOPT_MONITOR':71,
  'PM2_DB_WIDGET_WANOPT_PEER':72,
  'PM2_DB_WIDGET_USER_USAGE':80,
  'PM2_DB_WIDGET_PROXY_USAGE':81,
  'PM2_DB_WIDGET_NAC_VLANS':79,
  'PM2_DB_WIDGET_IP_POOL_UTILIZATION':74,
  'PM2_DB_WIDGET_IP_POOL_BY_USED_IP':75,
  'PM2_DB_WIDGET_GTP_TUNNEL_NUMBER':76,
  'PM2_DB_WIDGET_GTP_TUNNEL_SETUP_RATE':77,
  'PM2_DB_WIDGET_FORTIVIEW_APP_BANDWIDTH':78,
  'PM2_DB_WIDGET_CLUSTER_STATUS':73,
  'PM2_DB_WIDGET_DNS':82,
  'PM2_DB_WIDGET_IPAM':83,
  'PM2_DB_WIDGET_LIC_SHARE':84,
  'PM2_DB_WIDGET_LOGS_SENT':86,
  'PM2_DB_WIDGET_IDENTITIES':87,
  'PM2_DB_WIDGET_FWDSVR':88,
  'PM2_DB_WIDGET_FORTIVIEW_SHAPER':89,
  'PM2_DB_WIDGET_SANDBOX_FILES':90,
  'PM2_DB_WIDGET_DEVICE_VULNERABILITY':91,
  'PM2_DB_WIDGET_LIC_STATS':92,
  'PM2_DB_WIDGET_REPORT_BY_SRC':0,
  'PM2_DB_WIDGET_REPORT_BY_DST':1,
  'PM2_DB_WIDGET_REPORT_BY_COUNTRY':2,
  'PM2_DB_WIDGET_REPORT_BY_INTFPAIR':3,
  'PM2_DB_WIDGET_REPORT_BY_SRCINTF':4,
  'PM2_DB_WIDGET_REPORT_BY_DSTINTF':5,
  'PM2_DB_WIDGET_REPORT_BY_POLICY':6,
  'PM2_DB_WIDGET_REPORT_BY_WIFICLIENT':7,
  'PM2_DB_WIDGET_REPORT_BY_SHAPER':8,
  'PM2_DB_WIDGET_REPORT_BY_EP_VULN':9,
  'PM2_DB_WIDGET_REPORT_BY_EP_DEV':10,
  'PM2_DB_WIDGET_REPORT_BY_APP':11,
  'PM2_DB_WIDGET_REPORT_BY_CLOUD_APP':12,
  'PM2_DB_WIDGET_REPORT_BY_CLOUD_USER':13,
  'PM2_DB_WIDGET_REPORT_BY_WEB_DOMAIN':14,
  'PM2_DB_WIDGET_REPORT_BY_WEB_CATE':15,
  'PM2_DB_WIDGET_REPORT_BY_WEB_SEARCH':16,
  'PM2_DB_WIDGET_REPORT_BY_THREAT':17,
  'PM2_DB_WIDGET_REPORT_BY_SYSTEM':18,
  'PM2_DB_WIDGET_REPORT_BY_UNAUTH':19,
  'PM2_DB_WIDGET_REPORT_BY_ADMIN':20,
  'PM2_DB_WIDGET_REPORT_BY_VPN':21,
  'PM2_DB_WIDGET_TFRAME_REALTIME':0,
  'PM2_DB_WIDGET_TFRAME_5MIN':1,
  'PM2_DB_WIDGET_TFRAME_HOUR':2,
  'PM2_DB_WIDGET_TFRAME_DAY':3,
  'PM2_DB_WIDGET_TFRAME_WEEK':4,
  'PM2_DB_WIDGET_TFRAME_LATEST':5,
  'PM2_DB_WIDGET_TFRAME_1_MIN':6,
  'PM2_DB_WIDGET_TFRAME_10_MIN':7,
  'PM2_DB_WIDGET_TFRAME_30_MIN':8,
  'PM2_DB_WIDGET_TFRAME_1_HOUR':9,
  'PM2_DB_WIDGET_TFRAME_12_HOUR':10,
  'PM2_DB_WIDGET_TFRAME_24_HOUR':11,
  'PM2_DB_WIDGET_TFRAME_1_WEEK':12,
  'PM2_DB_WIDGET_TFRAME_1_MONTH':13,
  'PM2_DB_WIDGET_TFRAME_5_MIN':14,
  'PM2_DB_WIDGET_VISUAL_TABLE':0,
  'PM2_DB_WIDGET_VISUAL_BUBBLE':1,
  'PM2_DB_WIDGET_VISUAL_COUNTRY':2,
  'PM2_DB_WIDGET_VISUAL_CHORD':3,
  'PM2_OPT_DEFAULT':3,
  'PM2_OPT_CUSTOM':40,
  'PM2_TEMPERATURE_UNIT_CELSIUS':1,
  'PM2_TEMPERATURE_UNIT_FAHRENHEIT':2,
  'PM2_CACHE_VIEW_TRAFFIC':1,
  'PM2_CACHE_VIEW_REQUEST':2,
  'PM2_BANDWIDTH_CHART_STACKED':1,
  'PM2_BANDWIDTH_CHART_OVERLAP':2,
  'PM2_GUI_DASHBOARD_LAYOUT_RESPONSIVE':0,
  'PM2_GUI_DASHBOARD_LAYOUT_FIXED':1,
  'PM2_GUI_DASHBOARD_LAYOUT_STANDALONE':2,
  'PM2_TRUSTHOST_TYPE_IPV4':1,
  'PM2_TRUSTHOST_TYPE_IPV6':2,
  'PM2_VD_LINK_PPP':0,
  'PM2_VD_LINK_ETHERNET':1,
  'PM2_VD_LINK_NPUPAIR':2,
  'PM2_ADDRESSING_MODE_MANUAL':0,
  'PM2_ADDRESSING_MODE_DHCP':1,
  'PM2_ADDRESSING_MODE_PPPOE':2,
  'PM2_ADDRESSING_MODE_PPPOATM':3,
  'PM2_ADDRESSING_MODE_ADSL_ROUTE':4,
  'PM2_ADDRESSING_MODE_ADSL_BRIDGE':5,
  'PM2_DHCP_RELAY_TYPE_REGULAR':0,
  'PM2_DHCP_RELAY_TYPE_IPSEC':1,
  'PM2_ADMACCESS_HTTPS':1,
  'PM2_ADMACCESS_PING':2,
  'PM2_ADMACCESS_SSH':4,
  'PM2_ADMACCESS_SNMP':8,
  'PM2_ADMACCESS_HTTP':16,
  'PM2_ADMACCESS_TELNET':32,
  'PM2_ADMACCESS_FGFM':128,
  'PM2_ADMACCESS_AUTO_IPSEC':256,
  'PM2_ADMACCESS_RADIUS_ACCT':512,
  'PM2_ADMACCESS_PROBE_RESPONSE':1024,
  'PM2_ADMACCESS_CAPWAP':2048,
  'PM2_ADMACCESS_DNP':4096,
  'PM2_ADMACCESS_FTM':8192,
  'PM2_ADMACCESS_FABRIC':16384,
  'PM2_ADMACCESS_SPEED_TEST':32768,
  'PM2_ADMACCESS_ICOND':65536,
  'PM2_ADMACCESS_SCIM':131072,
  'PM2_BFD_MODE_GLOBAL':0,
  'PM2_BFD_MODE_ENABLE':1,
  'PM2_BFD_MODE_DISABLE':2,
  'PM2_INTF_T_PHYSICAL':0,
  'PM2_INTF_T_VLAN':1,
  'PM2_INTF_T_AGGREGATE':2,
  'PM2_INTF_T_REDUNDANT':3,
  'PM2_INTF_T_TUNNEL':4,
  'PM2_INTF_T_WIRELESS':5,
  'PM2_INTF_T_PAIR':6,
  'PM2_INTF_T_LOOPBACK':7,
  'PM2_INTF_T_SWITCH':8,
  'PM2_INTF_T_HARD_SWITCH':9,
  'PM2_INTF_T_HDLC':10,
  'PM2_INTF_T_VAP_SWITCH':11,
  'PM2_INTF_T_WL_MESH':12,
  'PM2_INTF_T_FORTILINK':13,
  'PM2_INTF_T_SWITCH_VLAN':14,
  'PM2_INTF_T_FCTRL_TRUNK':15,
  'PM2_INTF_T_TDM':16,
  'PM2_INTF_T_FEXT_WAN':17,
  'PM2_INTF_T_VXLAN':18,
  'PM2_INTF_T_EMAC_VLAN':19,
  'PM2_INTF_T_GENEVE':20,
  'PM2_INTF_T_SSL':21,
  'PM2_INTF_T_LAN_EXTENSION':22,
  'PM2_PING_PROTO_PING':1,
  'PM2_PING_PROTO_TCPECHO':2,
  'PM2_PING_PROTO_UDPECHO':4,
  'PM2_ADDRESSING_MODE_DELEGATED':6,
  'PM2_DHCP_SERVER_NORMAL':1,
  'PM2_DHCP_CLIENT_RAPID':1,
  'PM2_DHCP_CLIENT_IAPD':2,
  'PM2_DHCP_CLIENT_IANA':4,
  'PM2_DHCP_CLIENT_DNS':8,
  'PM2_DHCP_CLIENT_DNSNAME':16,
  'PM2_RDNSS_SERVICE_DELEGATED':0,
  'PM2_RDNSS_SERVICE_DEFAULT':1,
  'PM2_RDNSS_SERVICE_SPECIFY':2,
  'PM2_ND_MODE_BASIC':0,
  'PM2_ND_MODE_SEND_COMPATIBLE':1,
  'PM2_IP6_PREFIX_MODE_DHCP6':1,
  'PM2_IP6_PREFIX_MODE_RA':2,
  'PM2_CLIENT_OPTIONS_TYPE_HEX':1,
  'PM2_CLIENT_OPTIONS_TYPE_STRING':2,
  'PM2_CLIENT_OPTIONS_TYPE_IP6':3,
  'PM2_CLIENT_OPTIONS_TYPE_FQDN':4,
  'PM2_INTF_ROUTE_PREF_MEDIUM':1,
  'PM2_INTF_ROUTE_PREF_HIGH':2,
  'PM2_INTF_ROUTE_PREF_LOW':3,
  'PM2_PP_AUTH_AUTO':4,
  'PM2_PP_AUTH_MSCHAPv1':32,
  'PM2_PP_AUTH_MSCHAPv2':64,
  'PM2_DDNS_DYNDNS_ORG':2,
  'PM2_DDNS_DYNS_NET':3,
  'PM2_DDNS_ODS_ORG':5,
  'PM2_DDNS_TZO_COM':4,
  'PM2_DDNS_ORAY_NET':7,
  'PM2_DDNS_DIPDNS_NET':9,
  'PM2_DDNS_TODAYISP_COM':8,
  'PM2_DDNS_DHS_ORG':1,
  'PM2_DDNS_EASYDNS_COM':10,
  'PM2_DDNS_GENERIC':11,
  'PM2_INTF_SPEED_1000HALF':64,
  'PM2_INTF_SPEED_10000FULL':128,
  'PM2_INTF_SPEED_10000AUTO':512,
  'PM2_INTF_SPEED_40000FULL':1024,
  'PM2_INTF_SPEED_100GFULL':2048,
  'PM2_INTF_SPEED_40000AUTO':4194304,
  'PM2_INTF_SPEED_25000FULL':1048576,
  'PM2_INTF_SPEED_25000AUTO':8388608,
  'PM2_INTF_SPEED_100GAUTO':16777216,
  'PM2_INTF_SPEED_400GFULL':8589934592,
  'PM2_INTF_SPEED_400GAUTO':17179869184,
  'PM2_INTF_SPEED_2500AUTO':4294967296,
  'PM2_INTF_SPEED_5000AUTO':34359738368,
  'PM2_INTF_SPEED_50000FULL':2097152,
  'PM2_INTF_SPEED_50000AUTO':68719476736,
  'PM2_INTF_SPEED_200GFULL':137438953472,
  'PM2_INTF_SPEED_200GAUTO':274877906944,
  'PM2_INTF_SPEED_100AUTO':70368744177664,
  'PM2_AGG_LACP_MODE_STATIC':0,
  'PM2_AGG_LACP_MODE_PASSIVE':1,
  'PM2_AGG_LACP_MODE_ACTIVE':2,
  'PM2_AGG_LACP_SPEED_SLOW':0,
  'PM2_AGG_LACP_SPEED_FAST':1,
  'PM2_AGG_ALGORITHM_L2':0,
  'PM2_AGG_ALGORITHM_L3':1,
  'PM2_AGG_ALGORITHM_L4':2,
  'PM2_AGG_ALGORITHM_LB':6,
  'PM2_AGG_ALGORITHM_SOURCE_MAC':7,
  'PM2_WL_MAC_ACL_ALLOW':1,
  'PM2_WL_MAC_ACL_DENY':0,
  'PM2_WL_PSK':0,
  'PM2_WL_RADIUS':1,
  'PM2_WL_USERGROUP':2,
  'PM2_WL_ENCRYPT_TKIP':0,
  'PM2_WL_ENCRYPT_AES':1,
  'PM2_WL_SEC_NONE':0,
  'PM2_WL_SEC_WPA_PSK':3,
  'PM2_WL_SEC_WPA_RADIUS':4,
  'PM2_WL_SEC_WPA':5,
  'PM2_WL_SEC_WPA2':6,
  'PM2_WL_SEC_WPA2_AUTO':7,
  'PM2_WL_SEC_OPEN':8,
  'PM2_WL_SEC_WEP_64':1,
  'PM2_WL_SEC_WEP_128':2,
  'PM2_WL_SEC_WPA_PERSONAL':9,
  'PM2_WL_SEC_WPA_ENTERPRISE':10,
  'PM2_WL_SEC_WPA_ONLY_PERSONAL':14,
  'PM2_WL_SEC_WPA_ONLY_ENTERPRISE':15,
  'PM2_WL_SEC_WPA2_ONLY_PERSONAL':16,
  'PM2_WL_SEC_WPA2_ONLY_ENTERPRISE':17,
  'PM2_ADSL_LLC_ENCAPS':0,
  'PM2_ADSL_VC_ENCAPS':1,
  'PM2_MEDIA_INTF_SERDES':0,
  'PM2_MEDIA_INTF_SGMII':1,
  'PM2_MEDIA_INTF_CFP2_SR10':2,
  'PM2_MEDIA_INTF_CFP2_LR4':3,
  'PM2_MEDIA_INTF_SERDES_COPPER_SFP':4,
  'PM2_MEDIA_INTF_QSFP28_SR4':8,
  'PM2_MEDIA_INTF_QSFP28_LR4':9,
  'PM2_MEDIA_INTF_QSFP28_CR4':10,
  'PM2_MEDIA_INTF_SR':5,
  'PM2_MEDIA_INTF_CR':6,
  'PM2_MEDIA_INTF_LR':7,
  'PM2_MEDIA_INTF_SR4':11,
  'PM2_MEDIA_INTF_LR4':13,
  'PM2_MEDIA_INTF_CR4':12,
  'PM2_MEDIA_INTF_NONE':14,
  'PM2_MEDIA_INTF_GMII':15,
  'PM2_MEDIA_INTF_SGMII_':16,
  'PM2_MEDIA_INTF_SR2':17,
  'PM2_MEDIA_INTF_LR2':18,
  'PM2_MEDIA_INTF_CR2':19,
  'PM2_MEDIA_INTF_SR8':20,
  'PM2_MEDIA_INTF_LR8':21,
  'PM2_MEDIA_INTF_CR8':22,
  'PM2_MEDIA_INTF_DR':23,
  'PM2_P_WINNUKE':1,
  'PM2_P_TCP_LAND':2,
  'PM2_P_UDP_LAND':4,
  'PM2_P_ICMP_LAND':8,
  'PM2_P_IP_LAND':16,
  'PM2_P_IP_RR':32,
  'PM2_P_IP_SSRR':64,
  'PM2_P_IP_LSRR':128,
  'PM2_P_IP_STREAM':256,
  'PM2_P_IP_SECURITY':512,
  'PM2_P_IP_TIMESTAMP':1024,
  'PM2_P_IP_UNKNOWN_OPT':2048,
  'PM2_D_WINNUKE':65536,
  'PM2_D_TCP_LAND':131072,
  'PM2_D_UDP_LAND':262144,
  'PM2_D_ICMP_LAND':524288,
  'PM2_D_IP_LAND':1048576,
  'PM2_D_IP_RR':2097152,
  'PM2_D_IP_SSRR':4194304,
  'PM2_D_IP_LSRR':8388608,
  'PM2_D_IP_STREAM':16777216,
  'PM2_D_IP_SECURITY':33554432,
  'PM2_D_IP_TIMESTAMP':67108864,
  'PM2_D_IP_UNKNOWN_OPT':134217728,
  'PM2_P_IP_UNKNOWN_PROT':4096,
  'PM2_P_ICMP_FRAG':8192,
  'PM2_P_TCP_NO_FLAG':16384,
  'PM2_P_TCP_FIN_NOACK':32768,
  'PM2_D_IP_UNKNOWN_PROT':268435456,
  'PM2_D_ICMP_FRAG':536870912,
  'PM2_D_TCP_NO_FLAG':1073741824,
  'PM2_D_TCP_FIN_NOACK':2147483648,
  'PM2_IF_PROTO_CISCO':8194,
  'PM2_CLOCK_EXT':1,
  'PM2_CLOCK_INT':2,
  'PM2_SPEED_RATE_56KB':1,
  'PM2_SPEED_RATE_64KB':2,
  'PM2_INTF_FRAME_MODE_D4':1,
  'PM2_INTF_FRAME_MODE_ESF':3,
  'PM2_INTF_FRAME_MODE_CRC4':5,
  'PM2_INTF_FRAME_MODE_NO_CRC4':4,
  'PM2_INTF_LINE_CODE_AMI':0,
  'PM2_INTF_LINE_CODE_B8ZS':1,
  'PM2_INTF_LINE_CODE_HDB3':2,
  'PM2_ENCODING_DEFAULT':0,
  'PM2_ENCODING_NRZ':1,
  'PM2_ENCODING_NRZI':2,
  'PM2_ENCODING_FM_MARK':3,
  'PM2_ENCODING_FM_SPACE':4,
  'PM2_ENCODING_MANCHESTER':5,
  'PM2_PARITY_DEFAULT':0,
  'PM2_PARITY_NONE':1,
  'PM2_PARITY_CRC16_PR0':2,
  'PM2_PARITY_CRC16_PR1':3,
  'PM2_PARITY_CRC16_PR0_CCITT':4,
  'PM2_PARITY_CRC16_PR1_CCITT':5,
  'PM2_PARITY_CRC32_PR0_CCITT':6,
  'PM2_PARITY_CRC32_PR1_CCITT':7,
  'PM2_DNS_QUERY_DISABLE':0,
  'PM2_DNS_QUERY_RECURSIVE':1,
  'PM2_DNS_QUERY_NON_RECURSIVE':2,
  'PM2_INTF_FDO_DETECTSERVER':1,
  'PM2_INTF_FDO_LINKDOWN':2,
  'PM2_INTF_FAIL_ALERT_SIGNAL':0,
  'PM2_INTF_FAIL_ALERT_LINKDOWN':1,
  'PM2_BR_SPAN_DIR_RX':1,
  'PM2_BR_SPAN_DIR_TX':2,
  'PM2_BR_SPAN_DIR_BOTH':3,
  'PM2_OPT_TSIG':7,
  'PM2_FP_DISABLE_ALL':1,
  'PM2_FP_DISABLE_IPSEC':2,
  'PM2_FP_DISABLE_NONE':4,
  'PM2_SECURITY_MODE_NONE':1,
  'PM2_SECURITY_MODE_CAPTIVE_PORTAL':2,
  'PM2_SECURITY_MODE_802_1X':3,
  'PM2_WL_SEC_WPA3_SAE':26,
  'PM2_WL_SEC_OWE':25,
  'PM2_EAP_METHOD_BOTH':3,
  'PM2_EAP_METHOD_TLS':1,
  'PM2_EAP_METHOD_PEAP':2,
  'PM2_DEDICATED_TO_NONE':0,
  'PM2_DEDICATED_TO_MGMT':1,
  'PM2_INTF_CLOCK_LINE':0,
  'PM2_INTF_CLOCK_INTERNAL':1,
  'PM2_INTF_FRAME_MODE_SF':2,
  'PM2_INTF_FRAME_MODE_UNFRAMED':6,
  'PM2_TDM_LINE_CODE_AMI':0,
  'PM2_TDM_LINE_CODE_B8ZS':1,
  'PM2_TDM_LINE_CODE_HDB3':2,
  'PM2_TDM_LBO_120OH':0,
  'PM2_TDM_LBO_750H':1,
  'PM2_TDM_LBO_0DB':2,
  'PM2_TDM_LBO_75DB':3,
  'PM2_TDM_LBO_15DB':4,
  'PM2_TDM_LBO_225DB':5,
  'PM2_TDM_LBO_0_110FT':6,
  'PM2_TDM_LBO_110_220FT':7,
  'PM2_TDM_LBO_220_330FT':8,
  'PM2_TDM_LBO_330_440FT':9,
  'PM2_TDM_LBO_440_550FT':10,
  'PM2_TDM_LBO_550_660FT':11,
  'PM2_TDM_LBO_133_266FT':12,
  'PM2_TDM_LBO_266_399FT':13,
  'PM2_TDM_LBO_399_533FT':14,
  'PM2_TDM_LBO_533_655FT':15,
  'PM2_TDM_LBO_NEG_7_5DB':16,
  'PM2_TDM_LBO_NEG_15DB':17,
  'PM2_TDM_LBO_NEG_22_5DB':18,
  'PM2_TDM_PROTO_PPP':0,
  'PM2_TDM_PROTO_FRAME_RELAY':1,
  'PM2_TDM_PROTO_CHDLC':2,
  'PM2_PP_AUTH_DISABLE':2,
  'PM2_TDM_FRLMI_AUTO':0,
  'PM2_TDM_FRLMI_ANSI':1,
  'PM2_TDM_FRLMI_Q933A':2,
  'PM2_TDM_FRLMI_LMI':3,
  'PM2_TDM_FRINTF_DTE':0,
  'PM2_TDM_FRINTF_DCE':1,
  'PM2_TDM_PHYSICAL_TYPE_T1':0,
  'PM2_TDM_PHYSICAL_TYPE_E1':1,
  'PM2_ATM_PROTO_NONE':0,
  'PM2_ATM_PROTO_IPOA':1,
  'PM2_FAIL_ACTION_SOFT_RESTART':0,
  'PM2_FAIL_ACTION_HARD_RESTART':1,
  'PM2_FAIL_ACTION_REBOOT':2,
  'PM2_NETFLOW_SAMPLER_DISABLE':0,
  'PM2_NETFLOW_SAMPLER_TX':1,
  'PM2_NETFLOW_SAMPLER_RX':2,
  'PM2_NETFLOW_SAMPLER_BOTH':3,
  'PM2_STPFWD_MODE_RPL_ALL_EXIT_ID':0,
  'PM2_STPFWD_MODE_RPL_BR_EXT_ID':1,
  'PM2_STPFWD_MODE_RPL_NOTHING':2,
  'PM2_LLDP_TRAN_ENABLE':0,
  'PM2_LLDP_TRAN_DISABLE':1,
  'PM2_LLDP_TRAN_VDOM':2,
  'PM2_MIN_LINKS_DOWN_OPER':0,
  'PM2_MIN_LINKS_DOWN_ADMIN':1,
  'PM2_INTF_MEDIA_AUTO':0,
  'PM2_INTF_MEDIA_COPPER':1,
  'PM2_INTF_MEDIA_FIBER':2,
  'PM2_SCAN_BOTNET_CONN_DISABLE':0,
  'PM2_SCAN_BOTNET_CONN_BLOCK':1,
  'PM2_SCAN_BOTNET_CONN_MONITOR':2,
  'PM2_OPT_MAC_AUTH_ONLY':3,
  'PM2_STP_HA_SLAVE_DISABLE':0,
  'PM2_STP_HA_SLAVE_ENABLE':1,
  'PM2_STP_HA_SLAVE_PRI_ADJUST':2,
  'PM2_SYS_INTF_ROLE_LAN':0,
  'PM2_SYS_INTF_ROLE_WAN':1,
  'PM2_SYS_INTF_ROLE_DMZ':2,
  'PM2_SYS_INTF_ROLE_UNDEFINED':3,
  'PM2_SEC_8021X_MODE_DEFAULT':0,
  'PM2_SEC_8021X_MODE_DYNAMIC_VLAN':1,
  'PM2_SEC_8021X_MODE_FALLBACK':2,
  'PM2_SEC_8021X_MODE_SLAVE':3,
  'PM2_IF_MDIX_AUTO':0,
  'PM2_IF_MDIX_NORMAL':1,
  'PM2_IF_MDIX_CROSSOVER':2,
  'PM2_INTF_PHY_MODE_AUTO':0,
  'PM2_INTF_PHY_MODE_ADSL':1,
  'PM2_INTF_PHY_MODE_VDSL':2,
  'PM2_INTF_PHY_MODE_ADSL_AUTO':3,
  'PM2_INTF_PHY_MODE_VDSL2':4,
  'PM2_INTF_PHY_MODE_ADSL2_PLUS':5,
  'PM2_INTF_PHY_MODE_ADSL2':6,
  'PM2_INTF_PHY_MODE_G_DMT':7,
  'PM2_INTF_PHY_MODE_T1_413':8,
  'PM2_INTF_PHY_MODE_G_LITE':9,
  'PM2_INTF_TC_MODE_PTM':0,
  'PM2_INTF_TC_MODE_ATM':1,
  'PM2_INTF_FORWARD_ERROR_CORRECTION_ENABLE':1,
  'PM2_INTF_FORWARD_ERROR_CORRECTION_DISABLE':0,
  'PM2_INTF_FORWARD_ERROR_CORRECTION_RS_FEC':2,
  'PM2_INTF_FORWARD_ERROR_CORRECTION_BASE_R_FEC':3,
  'PM2_INTF_FORWARD_ERROR_CORRECTION_FEC_CL91':4,
  'PM2_INTF_FORWARD_ERROR_CORRECTION_FEC_CL74':5,
  'PM2_INTF_FORWARD_ERROR_CORRECTION_RS_544':6,
  'PM2_INTF_FORWARD_ERROR_CORRECTION_NONE':7,
  'PM2_INTF_FORWARD_ERROR_CORRECTION_CL91_RS_FEC':8,
  'PM2_INTF_FORWARD_ERROR_CORRECTION_CL74_FC_FEC':9,
  'PM2_INTF_FORWARD_ERROR_CORRECTION_AUTO':10,
  'PM2_WIFI_AP_BAND_ANY':0,
  'PM2_WIFI_AP_BAND_5G_PREFERRED':1,
  'PM2_WIFI_AP_BAND_5G_ONLY':2,
  'PM2_OPT_MONITOR':55,
  'PM2_OPT_LLDP_RECEPTION_VDOM':3,
  'PM2_GRESS_COS_DISABLE':0,
  'PM2_GRESS_COS_0':1,
  'PM2_GRESS_COS_1':2,
  'PM2_GRESS_COS_2':3,
  'PM2_GRESS_COS_3':4,
  'PM2_GRESS_COS_4':5,
  'PM2_GRESS_COS_5':6,
  'PM2_GRESS_COS_6':7,
  'PM2_GRESS_COS_7':8,
  'PM2_NEIGHBOR_DETECT_LLDP':0,
  'PM2_NEIGHBOR_DETECT_FORTILINK':1,
  'PM2_DHCP_SVR_OPT_HEX':0,
  'PM2_DHCP_SVR_OPT_STRING':1,
  'PM2_DHCP_SVR_OPT_IP':2,
  'PM2_DHCP_SVR_OPT_FQDN':3,
  'PM2_VLAN_PROTO_8021Q':0,
  'PM2_VLAN_PROTO_8021AD':1,
  'PM2_SWITCH_FEATURE_NONE':0,
  'PM2_SWITCH_FEATURE_DEFAULT_VLAN':1,
  'PM2_SWITCH_FEATURE_QUARANTINE':2,
  'PM2_SWITCH_FEATURE_SNIFFER':3,
  'PM2_SWITCH_FEATURE_VOICE':4,
  'PM2_SWITCH_FEATURE_CAMERA':5,
  'PM2_SWITCH_FEATURE_RSPAN':6,
  'PM2_SWITCH_FEATURE_VIDEO':7,
  'PM2_SWITCH_FEATURE_NAC':8,
  'PM2_SWITCH_FEATURE_NAC_SEGMENT':9,
  'PM2_IP_MANAGED_BY_FORTIIPAM_ENABLE':1,
  'PM2_IP_MANAGED_BY_FORTIIPAM_DISABLE':0,
  'PM2_IP_MANAGED_BY_FORTIIPAM_INHERIT_GLOBAL':2,
  'PM2_INTERFACE_SELECT_METHOD_AUTO':0,
  'PM2_INTERFACE_SELECT_METHOD_SDWAN':1,
  'PM2_INTERFACE_SELECT_METHOD_SPECIFY':2,
  'PM2_SW_CTRL_SRC_IP_OUTBOUND':1,
  'PM2_SW_CTRL_SRC_IP_FIXED':2,
  'PM2_SW_ALGORITHM_L2':1,
  'PM2_SW_ALGORITHM_L3':2,
  'PM2_SW_ALGORITHM_EH':3,
  'PM2_SW_ALGORITHM_DEFAULT':4,
  'PM2_SYSTEM_ID_TYPE_AUTO':1,
  'PM2_SYSTEM_ID_TYPE_USER':2,
  'PM2_PVC_VLAN_TX_OP_PASS_THROUGH':1,
  'PM2_PVC_VLAN_TX_OP_REPLACE':2,
  'PM2_PVC_VLAN_TX_OP_REMOVE':3,
  'PM2_PVC_ATM_QOS_CBR':1,
  'PM2_PVC_ATM_QOS_RT_VBR':2,
  'PM2_PVC_ATM_QOS_NRT_VBR':3,
  'PM2_PVC_ATM_QOS_UBR':4,
  'PM2_SELECT_PROFILE_30A':1,
  'PM2_SELECT_PROFILE_35B':2,
  'PM2_VLAN_OP_MODE_TAG':1,
  'PM2_VLAN_OP_MODE_UNTAG':2,
  'PM2_VLAN_OP_MODE_PASSTHROUGH':3,
  'PM2_DNS_PROTOCOL_CLEARTEXT':1,
  'PM2_DNS_PROTOCOL_DOT':2,
  'PM2_DNS_PROTOCOL_DOH':4,
  'PM2_INTERCONNECT_PROFILE_DEFAULT':1,
  'PM2_INTERCONNECT_PROFILE_PROFILE1':2,
  'PM2_INTERCONNECT_PROFILE_PROFILE2':3,
  'PM2_ANNEX_A':1,
  'PM2_ANNEX_B':2,
  'PM2_ANNEX_J':3,
  'PM2_ANNEX_BJM':4,
  'PM2_ANNEX_I':5,
  'PM2_ANNEX_AL':6,
  'PM2_ANNEX_M':7,
  'PM2_ANNEX_AIJLM':8,
  'PM2_ANNEX_BJ':9,
  'PM2_MEMBER_MODE_SWITCH':1,
  'PM2_MEMBER_MODE_DISABLE':0,
  'PM2_DIRECTION_RX':1,
  'PM2_DIRECTION_TX':2,
  'PM2_DIRECTION_BOTH':3,
  'PM2_INTF_EX_SIG_IOT':1,
  'PM2_INTF_EX_SIG_OT':2,
  'PM2_HA_STANDALONE':0,
  'PM2_HA_AA':1,
  'PM2_HA_AP':2,
  'PM2_HA_CONFIG_SYNC_ONLY':3,
  'PM2_HA_ACTIVE_PASSIVE':4,
  'PM2_HA_SCHEDULE_NONE':0,
  'PM2_HA_SCHEDULE_HUB':1,
  'PM2_HA_SCHEDULE_LEAST_CONNECTION':2,
  'PM2_HA_SCHEDULE_ROUND_ROBIN':3,
  'PM2_HA_SCHEDULE_WEIGHT_ROUND_ROBIN':4,
  'PM2_HA_SCHEDULE_RANDOM':5,
  'PM2_HA_SCHEDULE_IP':6,
  'PM2_HA_SCHEDULE_IPPORT':7,
  'PM2_PORT_DTAG_MODE_PROPRIETARY':0,
  'PM2_PORT_DTAG_MODE_DOUBLE_TAGGING':1,
  'PM2_PORT_OUTER_TPID_8100':0,
  'PM2_PORT_OUTER_TPID_88A8':1,
  'PM2_PORT_OUTER_TPID_9100':2,
  'PM2_HB_INTERVAL_IN_MILLISECONDS_100MS':1,
  'PM2_HB_INTERVAL_IN_MILLISECONDS_10MS':2,
  'PM2_HA_UPGRADE_MODE_SIMULTANEOUS':1,
  'PM2_HA_UPGRADE_MODE_UNINTERRUPTIBLE':2,
  'PM2_HA_UPGRADE_MODE_LOCAL_ONLY':3,
  'PM2_HA_UPGRADE_MODE_SECONDARY_ONLY':4,
  'PM2_HA_IPSEC_PROP2_AES128_SHA1':1,
  'PM2_HA_IPSEC_PROP2_AES128_SHA256':2,
  'PM2_HA_IPSEC_PROP2_AES128_SHA384':4,
  'PM2_HA_IPSEC_PROP2_AES128_SHA512':8,
  'PM2_HA_IPSEC_PROP2_AES192_SHA1':16,
  'PM2_HA_IPSEC_PROP2_AES192_SHA256':32,
  'PM2_HA_IPSEC_PROP2_AES192_SHA384':64,
  'PM2_HA_IPSEC_PROP2_AES192_SHA512':128,
  'PM2_HA_IPSEC_PROP2_AES256_SHA1':256,
  'PM2_HA_IPSEC_PROP2_AES256_SHA256':512,
  'PM2_HA_IPSEC_PROP2_AES256_SHA384':1024,
  'PM2_HA_IPSEC_PROP2_AES256_SHA512':2048,
  'PM2_HA_IPSEC_PROP2_AES128GCM':4096,
  'PM2_HA_IPSEC_PROP2_AES256GCM':8192,
  'PM2_HA_IPSEC_PROP2_CHACHA20POLY1305':16384,
  'PM2_OPT_ENABLE2':27,
  'PM2_DNS_SERVER_SELECT_METHOD_LEAST_RTT':1,
  'PM2_DNS_SERVER_SELECT_METHOD_FAILOVER':2,
  'PM2_DNS_LOG_DISABLE':0,
  'PM2_DNS_LOG_ERROR':1,
  'PM2_DNS_LOG_ALL':2,
  'PM2_CM_HEADER_NONE':1,
  'PM2_CM_HEADER_HTTP':2,
  'PM2_CM_HEADER_8BIT':3,
  'PM2_CM_FORMAT_NONE':1,
  'PM2_CM_FORMAT_TEXT':2,
  'PM2_CM_FORMAT_HTML':3,
  'PM2_CM_FORMAT_WML':4,
  'PM2_ENGINE_ID_TYPE_TEXT':1,
  'PM2_ENGINE_ID_TYPE_HEX':2,
  'PM2_ENGINE_ID_TYPE_MAC':3,
  'PM2_HOST_TYPE_ANY':0,
  'PM2_HOST_TYPE_QUERY':1,
  'PM2_HOST_TYPE_TRAP':2,
  'PM2_SNMP_TRAP_SYS_CPU_HIGH':1,
  'PM2_SNMP_TRAP_SYS_MEM_LOW':2,
  'PM2_SNMP_TRAP_SYS_LOG_FULL':4,
  'PM2_SNMP_TRAP_INTF_IP_CHANGE':8,
  'PM2_SNMP_TRAP_VPN_TUNNEL_UP':16,
  'PM2_SNMP_TRAP_VPN_TUNNEL_DOWN':32,
  'PM2_SNMP_TRAP_HA_SWITCH':64,
  'PM2_SNMP_TRAP_HA_HBFAIL':128,
  'PM2_SNMP_TRAP_IPS_SIGNATURE':256,
  'PM2_SNMP_TRAP_IPS_ANOMALY':512,
  'PM2_SNMP_TRAP_AV_VIRUS':1024,
  'PM2_SNMP_TRAP_AV_OVERSIZE':2048,
  'PM2_SNMP_TRAP_AV_PATTERN':4096,
  'PM2_SNMP_TRAP_AV_FRAGMENTED':8192,
  'PM2_SNMP_TRAP_FM_IF_CHANGE':16384,
  'PM2_SNMP_TRAP_FM_CONF_CHANGE':32768,
  'PM2_SNMP_TRAP_HA_MEMBERUP':262144,
  'PM2_SNMP_TRAP_HA_MEMBERDOWN':524288,
  'PM2_SNMP_TRAP_CONF_CHG':1048576,
  'PM2_SNMP_TRAP_AV_CONSERVE':2097152,
  'PM2_SNMP_TRAP_AV_BYPASS':4194304,
  'PM2_SNMP_TRAP_AV_OVERSIZE_PASS':8388608,
  'PM2_SNMP_TRAP_AV_OVERSIZE_BLOCK':16777216,
  'PM2_SNMP_TRAP_IPS_PKG_UPDATE':33554432,
  'PM2_SNMP_TRAP_POWER_SUPL_FAIL':67108864,
  'PM2_SNMP_TRAP_AMC_BYPASS':134217728,
  'PM2_SNMP_TRAP_FAZ_DISCONNECT':268435456,
  'PM2_SNMP_TRAP_TEMPERATURE_HIGH':65536,
  'PM2_SNMP_TRAP_VOLTAGE_ALERT':131072,
  'PM2_SNMP_TRAP_FAN_FAILURE':536870912,
  'PM2_SNMP_TRAP_BGP_ESTABLISHED':1073741824,
  'PM2_SNMP_TRAP_BGP_BACKWARD_TRANSITION':2147483648,
  'PM2_SNMP_TRAP_WC_AP_UP':4294967296,
  'PM2_SNMP_TRAP_WC_AP_DOWN':8589934592,
  'PM2_SNMP_TRAP_FSWCTL_SESSION_UP':17179869184,
  'PM2_SNMP_TRAP_FSWCTL_SESSION_DOWN':34359738368,
  'PM2_SNMP_TRAP_IPS_FAIL_OPEN':68719476736,
  'PM2_SNMP_TRAP_LDB_REALSVR_DOWN':137438953472,
  'PM2_SNMP_TRAP_DEVICE_NEW':274877906944,
  'PM2_SNMP_TRAP_ENTER_INTF_BYPASS':549755813888,
  'PM2_SNMP_TRAP_EXIT_INTF_BYPASS':1099511627776,
  'PM2_SNMP_TRAP_PER_CPU_HIGH':2199023255552,
  'PM2_SNMP_TRAP_POWER_BLADE_DOWN':4398046511104,
  'PM2_SNMP_TRAP_CONFSYNC_FAILURE':8796093022208,
  'PM2_SNMP_TRAP_DHCP':17592186044416,
  'PM2_SNMP_TRAP_POOL_USAGE':35184372088832,
  'PM2_SNMP_TRAP_POWER_REDUNDANCY_DEGRADE':70368744177664,
  'PM2_SNMP_TRAP_POWER_REDUNDANCY_FAILURE':140737488355328,
  'PM2_SNMP_TRAP_DISK_FAILURE':1125899906842624,
  'PM2_SNMP_TRAP_DISK_OVERLOAD':2251799813685248,
  'PM2_SNMP_TRAP_OSPF_NBR_STATE_CHANGE':281474976710656,
  'PM2_SNMP_TRAP_OSPF_VIRTNBR_STATE_CHANGE':562949953421312,
  'PM2_SNMP_TRAP_FAZ_MAIN_FAILOVER':4503599627370496,
  'PM2_SNMP_TRAP_FAZ_ALT_FAILOVER':9007199254740992,
  'PM2_SNMP_TRAP_SLBC':18014398509481984,
  'PM2_SNMP_TRAP_FAZ':36028797018963968,
  'PM2_SNMP_TRAP_POWER_SUPPLY':72057594037927936,
  'PM2_SNMP_TRAP_IPPOOL':144115188075855872,
  'PM2_SNMP_TRAP_INTERFACE':288230376151711744,
  'PM2_SNMP_TRAP_SECURITY_LEVEL_CHANGE':576460752303423488,
  'PM2_SNMP_TRAP_CERT_EXPIRY':1152921504606846976,
  'PM2_SNMP_TRAP_DIO':4611686018427387904,
  'PM2_UPD_FREQ_EVERY':0,
  'PM2_UPD_FREQ_DAILY':1,
  'PM2_UPD_FREQ_WEEKLY':2,
  'PM2_UPD_FREQ_AUTOMATIC':3,
  'PM2_UPD_SUNDAY':0,
  'PM2_UPD_MONDAY':1,
  'PM2_UPD_TUESDAY':2,
  'PM2_UPD_WEDNESDAY':3,
  'PM2_UPD_THURSDAY':4,
  'PM2_UPD_FRIDAY':5,
  'PM2_UPD_SATURDAY':6,
  'PM2_UF_TYPE_SIMPLE':0,
  'PM2_UF_TYPE_REGEX':1,
  'PM2_UF_TYPE_WILDCARD':2,
  'PM2_UF_ACT_EXEMPT':0,
  'PM2_UF_ACT_DENY':1,
  'PM2_UF_ACT_ALLOW':2,
  'PM2_UF_ACT_PASS':4,
  'PM2_UF_ACT_MONITOR':3,
  'PM2_URL_EXEMPT_AV':1,
  'PM2_URL_EXEMPT_FILEPATTERN':64,
  'PM2_URL_EXEMPT_WEB_CONTENT':2,
  'PM2_URL_EXEMPT_AJC':4,
  'PM2_URL_EXEMPT_DLP':8,
  'PM2_URL_EXEMPT_FORTIGUARD':16,
  'PM2_URL_EXEMPT_PASS':128,
  'PM2_URL_EXEMPT_ALL':32,
  'PM2_URL_EXEMPT_RANGE_BLOCK':256,
  'PM2_URL_EXEMPT_ANTIPHISH':512,
  'PM2_ANTIPHISH_ACTION_BLOCK':0,
  'PM2_ANTIPHISH_ACTION_LOG':1,
  'PM2_CACHE_MODE_TTL':0,
  'PM2_CACHE_MODE_DB_VER':1,
  'PM2_FO_SCOPE_USER':0,
  'PM2_FO_SCOPE_USER_GROUP':1,
  'PM2_FO_SCOPE_IP':2,
  'PM2_FO_SCOPE_PROFILE':3,
  'PM2_FO_SCOPE_IP6':5,
  'PM2_FO_TYPE_DIR':0,
  'PM2_FO_TYPE_DOMAIN':1,
  'PM2_FO_TYPE_RATING':2,
  'PM2_FO_EXT_ALLOW':0,
  'PM2_FO_EXT_DENY':1,
  'PM2_ZONE_RANGE_GLOBAL':0,
  'PM2_ZONE_RANGE_VDOM':1,
  'PM2_CERT_SRC_FATORY':0,
  'PM2_CERT_SRC_USER':1,
  'PM2_CERT_SRC_BUNDLE':2,
  'PM2_CERT_SRC_FORTIGUARD':3,
  'PM2_CA_STRING_TYPE_PRINTABLE':0,
  'PM2_CA_STRING_TYPE_UTF8':1,
  'PM2_IPSEC_IDTYPE_ASN1DN':5,
  'PM2_IPSEC_IDTYPE_FQDN':1,
  'PM2_ENROLL_PROT_NONE':0,
  'PM2_ENROLL_PROT_SCEP':1,
  'PM2_ENROLL_PROT_CMPV2':2,
  'PM2_ENROLL_PROT_ACME2':3,
  'PM2_ENROLL_PROT_EST':4,
  'PM2_REGEN_METHOD_KEYUPDATE':0,
  'PM2_REGEN_METHOD_RENEWAL':1,
  'PM2_CERT_LOCAL_TYPE_NORMAL':1,
  'PM2_CERT_LOCAL_TYPE_HSM':2,
  'PM2_WILDCARD_PATTERN':0,
  'PM2_REGEXP_PATTERN':1,
  'PM2_ASFA_MARK_SPAM':2,
  'PM2_ASFA_MARK_NOT_SPAM':3,
  'PM2_FILTER_EMAIL_SUBJECT':1,
  'PM2_FILTER_EMAIL_BODY':2,
  'PM2_FILTER_EMAIL_ALL':3,
  'PM2_FILTER_ASCII':0,
  'PM2_FILTER_SIMPLIFIED_CHINESE':1,
  'PM2_FILTER_TRADITIONAL_CHINESE':2,
  'PM2_FILTER_JAPANESE':3,
  'PM2_FILTER_KOREAN':4,
  'PM2_FILTER_FRENCH':5,
  'PM2_FILTER_THAI':6,
  'PM2_FILTER_SPANISH':7,
  'PM2_ASFA_SMTP_REJECT':5,
  'PM2_FTGD_PORT_53':53,
  'PM2_FTGD_PORT_8888':8888,
  'PM2_FGTD_PORT_80':80,
  'PM2_FGTD_PORT_443':443,
  'PM2_UPDATE_SERVER_LOC_USA':1,
  'PM2_UPDATE_SERVER_LOC_ANY':0,
  'PM2_UPDATE_SERVER_LOC_AUTOMATIC':2,
  'PM2_UPDATE_SERVER_LOC_EU':3,
  'PM2_FTGD_PROTO_UDP':0,
  'PM2_FTGD_PROTO_HTTP':1,
  'PM2_FTGD_PROTO_HTTPS':2,
  'PM2_FORTIGUARD_ANYCAST_SOURCE_FORTINET':0,
  'PM2_FORTIGUARD_ANYCAST_SOURCE_AWS':1,
  'PM2_FORTIGUARD_ANYCAST_SOURCE_DEBUG':2,
  'PM2_SDNS_OPTIONS_INCLUDE_QUESTION_SECTION':1,
  'PM2_OPT_PASS':1,
  'PM2_OPT_PERIODICAL':8,
  'PM2_OPT_CONTINUOUS':9,
  'PM2_OPT_ACCURATE':10,
  'PM2_OPT_HEURISTIC':11,
  'PM2_IPS_ALG_DEFAULT':0,
  'PM2_IPS_ALG_LINEAR':1,
  'PM2_IPS_ALG_DFA':2,
  'PM2_IPS_ALG_SUPER':3,
  'PM2_IPS_GLOBAL_DB_REGULAR':0,
  'PM2_IPS_GLOBAL_DB_EXTENDED':1,
  'PM2_IPS_HWMODE_ENGINE_PICK':0,
  'PM2_IPS_HWMODE_NONE':1,
  'PM2_IPS_HWMODE_CP_ONLY':2,
  'PM2_IPS_HWMODE_NP_ONLY':3,
  'PM2_IPS_HWMODE_NP_CP':4,
  'PM2_ACCEL_MODE_NONE':0,
  'PM2_ACCEL_MODE_BASIC':1,
  'PM2_ACCEL_MODE_ADVANCED':2,
  'PM2_IPS_EX_SIG_NONE':0,
  'PM2_IPS_EX_SIG_INDUSTRIAL':1,
  'PM2_IPS_EX_SIG_OT':2,
  'PM2_FLG_ADDRESS_STATIC':0,
  'PM2_FLG_ADDRESS_AUTO_DISCOVER':1,
  'PM2_SCHED_DAILY':1,
  'PM2_SCHED_WEEKLY':4,
  'PM2_LOG_FULL_OVERWRITE':0,
  'PM2_LOG_FULL_NOLOG':2,
  'PM2_LOG_KERN':0,
  'PM2_LOG_USER':1,
  'PM2_LOG_MAIL':2,
  'PM2_LOG_DAEMON':3,
  'PM2_LOG_AUTH':4,
  'PM2_LOG_SYSLOG':5,
  'PM2_LOG_LPR':6,
  'PM2_LOG_NEWS':7,
  'PM2_LOG_UUCP':8,
  'PM2_LOG_CRON':9,
  'PM2_LOG_AUTHPRIV':10,
  'PM2_LOG_FTP':11,
  'PM2_LOG_NTP':12,
  'PM2_LOG_AUDIT':13,
  'PM2_LOG_ALERT':14,
  'PM2_LOG_CLOCK':15,
  'PM2_LOG_LOCAL0':16,
  'PM2_LOG_LOCAL1':17,
  'PM2_LOG_LOCAL2':18,
  'PM2_LOG_LOCAL3':19,
  'PM2_LOG_LOCAL4':20,
  'PM2_LOG_LOCAL5':21,
  'PM2_LOG_LOCAL6':22,
  'PM2_LOG_LOCAL7':23,
  'PM2_OPT_SYSLOG_LOG_FMT_DEFAULT':1,
  'PM2_OPT_SYSLOG_LOG_FMT_CSV':2,
  'PM2_OPT_SYSLOG_LOG_FMT_CEF':3,
  'PM2_OPT_SYSLOG_LOG_FMT_RFC5424':4,
  'PM2_OPT_SYSLOG_LOG_FMT_JSON':5,
  'PM2_SYSLOG_MODE_UDP':0,
  'PM2_SYSLOG_MODE_LEGACY':1,
  'PM2_SYSLOG_MODE_RELIABLE':2,
  'PM2_ENC_ALG_HIGH_MEDIUM':7,
  'PM2_ENC_ALG_HIGH':1,
  'PM2_ENC_ALG_LOW':2,
  'PM2_ENC_ALG_DISABLE':3,
  'PM2_SSL_VERSION_DEFAULT':0,
  'PM2_SSL_VERSION_TLSV1':16,
  'PM2_POLPRIDEFAULT':3,
  'PM2_HMAC_ALG_SHA256':0,
  'PM2_HMAC_ALG_SHA1':1,
  'PM2_UPLOAD_OPT_STOREUPLOAD':0,
  'PM2_UPLOAD_OPT_REALTIME':1,
  'PM2_UPLOAD_OPT_1_MINUTE':2,
  'PM2_UPLOAD_OPT_5_MINUTE':3,
  'PM2_SCHED_MONTHLY':7,
  'PM2_ENC_ALG_DEFAULT':0,
  'PM2_ENC_ALG_LOW_MEDIUM':8,
  'PM2_LOG_LEVEL_EMERG':0,
  'PM2_LOG_LEVEL_ALERT':1,
  'PM2_LOG_LEVEL_CRITICAL':2,
  'PM2_LOG_LEVEL_ERROR':3,
  'PM2_LOG_LEVEL_WARNING':4,
  'PM2_LOG_LEVEL_NOTIF':5,
  'PM2_LOG_LEVEL_INFO':6,
  'PM2_LOG_LEVEL_DEBUG':7,
  'PM2_LOGTYPE_TRAFFIC':10,
  'PM2_LOGTYPE_EVENT':5,
  'PM2_LOGTYPE_VIRUS':11,
  'PM2_LOGTYPE_WEBFILTER':13,
  'PM2_LOGTYPE_ATTACK':1,
  'PM2_LOGTYPE_SPAM':15,
  'PM2_LOGTYPE_NETSCAN':14,
  'PM2_LOGTYPE_DLP':3,
  'PM2_LOGTYPE_APPCTRL':0,
  'PM2_LOGTYPE_VOIP':12,
  'PM2_LOGTYPE_ANOMALY':16,
  'PM2_LOGTYPE_WAF':17,
  'PM2_FILTER_TYPE_INC':0,
  'PM2_FILTER_TYPE_EXC':1,
  'PM2_LOG_FILTER_C_TRA':1,
  'PM2_LOG_FILTER_C_EVENT':2,
  'PM2_LOG_FILTER_C_VIRUS':4,
  'PM2_LOG_FILTER_C_WEBFILTER':8,
  'PM2_LOG_FILTER_C_IDS':16,
  'PM2_LOG_FILTER_C_SPAM':32,
  'PM2_LOG_FILTER_C_ANOMALY':131072,
  'PM2_LOG_FILTER_C_VOIP':256,
  'PM2_LOG_FILTER_C_DLP':512,
  'PM2_LOG_FILTER_C_APP_CTRL':1024,
  'PM2_LOG_FILTER_C_WAF':262144,
  'PM2_LOG_FILTER_C_DNS':1048576,
  'PM2_LOG_FILTER_C_SSH':4194304,
  'PM2_LOG_FILTER_C_SSL':8388608,
  'PM2_LOG_FILTER_C_FILE_FILTER':33554432,
  'PM2_LOG_FILTER_C_ICAP':67108864,
  'PM2_LOG_FILTER_C_GTP':524288,
  'PM2_LOG_FILTER_C_ZTNA':134217728,
  'PM2_LOG_FILTER_C_VIRTUAL_PATCH':536870912,
  'PM2_SESS_HELPER_FTP':1,
  'PM2_SESS_HELPER_TFTP':2,
  'PM2_SESS_HELPER_RAS':3,
  'PM2_SESS_HELPER_H232':4,
  'PM2_SESS_HELPER_H245O':5,
  'PM2_SESS_HELPER_H245I':6,
  'PM2_SESS_HELPER_TNS':7,
  'PM2_SESS_HELPER_MMS':8,
  'PM2_SESS_HELPER_SIP':9,
  'PM2_SESS_HELPER_PPTP':10,
  'PM2_SESS_HELPER_RTSP':11,
  'PM2_SESS_HELPER_DNS_UDP':12,
  'PM2_SESS_HELPER_DNS_TCP':13,
  'PM2_SESS_HELPER_PMAP':14,
  'PM2_SESS_HELPER_RSH':15,
  'PM2_SESS_HELPER_DCERPC':16,
  'PM2_SESS_HELPER_MGCP':17,
  'PM2_SESS_HELPER_GTPC':18,
  'PM2_SESS_HELPER_GTPU':19,
  'PM2_SESS_HELPER_GTPB':20,
  'PM2_SESS_HELPER_PFCP':21,
  'PM2_CONSOLE_BATCH':2,
  'PM2_CONSOLE_LINE':1,
  'PM2_CONSOLE_OUTPUT_STANDARD':0,
  'PM2_CONSOLE_OUTPUT_MORE':1,
  'PM2_AV_PROT_IMAP':1,
  'PM2_AV_PROT_SMTP':2,
  'PM2_AV_PROT_POP3':4,
  'PM2_AV_PROT_HTTP':8,
  'PM2_AV_PROT_FTP':16,
  'PM2_AV_PROT_IM':32,
  'PM2_AV_PROT_NNTP':64,
  'PM2_AV_PROT_IMAPS':2048,
  'PM2_AV_PROT_SMTPS':4096,
  'PM2_AV_PROT_POP3S':8192,
  'PM2_AV_PROT_HTTPS':16384,
  'PM2_AV_PROT_MM1':128,
  'PM2_AV_PROT_MM3':256,
  'PM2_AV_PROT_MM4':512,
  'PM2_AV_PROT_MM7':1024,
  'PM2_AV_PROT_FTPS':32768,
  'PM2_APS_FILEPAT_BLOCK':1,
  'PM2_APS_FILEPAT_ALLOW':2,
  'PM2_APS_FILEPAT_INTERCEPT':4,
  'PM2_FILE_FILTER_PATTERN':0,
  'PM2_FILE_FILTER_FILETYPE':1,
  'PM2_AVFT_ARJ':23,
  'PM2_AVFT_CAB':17,
  'PM2_AVFT_LZH':14,
  'PM2_AVFT_RAR':12,
  'PM2_AVFT_TAR':13,
  'PM2_AVFT_ZIP':16,
  'PM2_AVFT_BZIP':19,
  'PM2_AVFT_GZIP':11,
  'PM2_AVFT_BZIP2':18,
  'PM2_AVFT_BAT':5,
  'PM2_AVFT_MSC':32,
  'PM2_AVFT_UUE':26,
  'PM2_AVFT_MIME':21,
  'PM2_AVFT_BASE64':24,
  'PM2_AVFT_BINHEX':25,
  'PM2_AVFT_ELF':4,
  'PM2_AVFT_EXE':2,
  'PM2_AVFT_HTA':9,
  'PM2_AVFT_HTML':8,
  'PM2_AVFT_CUST_JAD':66,
  'PM2_AVFT_CUST_CLASS':67,
  'PM2_AVFT_CUST_COD':68,
  'PM2_AVFT_JAVASCRIPT':7,
  'PM2_AVFT_MSOFFICE':10,
  'PM2_AVFT_FSG':27,
  'PM2_AVFT_UPX':15,
  'PM2_AVFT_PETITE':33,
  'PM2_AVFT_ASPACK':28,
  'PM2_AVFT_CUST_PRC':69,
  'PM2_AVFT_CUST_SIS':70,
  'PM2_AVFT_HLP':22,
  'PM2_AVFT_ACTIVEMIME':20,
  'PM2_AVFT_IGNORE':1,
  'PM2_AVFT_N_A':0,
  'PM2_AVFT_JPEG':39,
  'PM2_AVFT_GIF':40,
  'PM2_AVFT_TIFF':41,
  'PM2_AVFT_PNG':42,
  'PM2_AVFT_BMP':43,
  'PM2_AV_HEUR_PASS':1,
  'PM2_AV_HEUR_BLOCK':2,
  'PM2_AV_HEUR_OFF':0,
  'PM2_QUAR_PROT_IMAP':1,
  'PM2_QUAR_PROT_SMTP':2,
  'PM2_QUAR_PROT_POP3':4,
  'PM2_QUAR_PROT_HTTP':8,
  'PM2_QUAR_PROT_FTP':16,
  'PM2_QUAR_PROT_IM':32,
  'PM2_QUAR_PROT_NNTP':64,
  'PM2_QUAR_PROT_IMAPS':128,
  'PM2_QUAR_PROT_SMTPS':256,
  'PM2_QUAR_PROT_POP3S':512,
  'PM2_QUAR_PROT_HTTPS':1024,
  'PM2_QUAR_PROT_MM1':65536,
  'PM2_QUAR_PROT_MM3':131072,
  'PM2_QUAR_PROT_MM4':262144,
  'PM2_QUAR_PROT_MM7':524288,
  'PM2_QUAR_PROT_FTPS':1048576,
  'PM2_QUAR_PROT_MAPI':2097152,
  'PM2_QUAR_PROT_CIFS':268435456,
  'PM2_QUAR_PROT_SSH':536870912,
  'PM2_QUAR_LOWSPACE_DROP_NEW':1,
  'PM2_QUAR_LOWSPACE_OVERWRITE_OLD':0,
  'PM2_QUAR_SEL_HEURISTICS':1,
  'PM2_QUAR_SEL_BLOCKED':2,
  'PM2_QUAR_SEL_INTERCEPTED':128,
  'PM2_AV_DEST_NULL':0,
  'PM2_AV_DEST_DISK':1,
  'PM2_AV_DEST_FAZ':2,
  'PM2_OP_MODE_NAT':1,
  'PM2_OP_MODE_TRANSPARENT':2,
  'PM2_VPN_STATS_LOG_IPSEC':1,
  'PM2_VPN_STATS_LOG_PPTP':2,
  'PM2_VPN_STATS_LOG_L2TP':4,
  'PM2_VPN_STATS_LOG_SSL':8,
  'PM2_VDOM_V4_ECMP_SOURCE_HASH':1,
  'PM2_VDOM_V4_ECMP_WEIGHT':2,
  'PM2_VDOM_V4_ECMP_USAGE':4,
  'PM2_VDOM_V4_ECMP_SRC_DST_IP':8,
  'PM2_FSD_CHECK_ALL':0,
  'PM2_FSD_CHECK_NEW':1,
  'PM2_FSD_CHECK_POLICY_OPTION':2,
  'PM2_LLDP_TRAN_GLOBAL':3,
  'PM2_VOIP_ALG_MODE_PROXY_BASED':0,
  'PM2_VOIP_ALG_KERNEL_HELPER_BASED':1,
  'PM2_INSPECT_MODE_PROXY':0,
  'PM2_INSPECT_MODE_FLOW_BASED':1,
  'PM2_HTTP_EXT_DEST_FWB':0,
  'PM2_HTTP_EXT_DEST_FCH':1,
  'PM2_OPT_NGFW_PROFILE_BASED':0,
  'PM2_OPT_NGFW_POLICY_BASED':1,
  'PM2_IKE_DN_FORMAT_WITH_SPACE':0,
  'PM2_IKE_DN_FORMAT_NO_SPACE':1,
  'PM2_OPT_LLDP_RECEPTION_GLOBAL':4,
  'PM2_MBOARD_TRAF_FORWARD_ICMP':1,
  'PM2_MBOARD_TRAF_FORWARD_ADMIN':2,
  'PM2_MBOARD_TRAF_FORWARD_AUTH':4,
  'PM2_OFFLOAD_LEVEL_DISABLE':0,
  'PM2_OFFLOAD_LEVEL_DEFAULT':1,
  'PM2_OFFLOAD_LEVEL_DOS_OFFLOAD':2,
  'PM2_OFFLOAD_LEVEL_FULL_OFFLOAD':3,
  'PM2_TRAP_SESSION_FLAG_UDP_BOTH':1,
  'PM2_TRAP_SESSION_FLAG_UDP_REPLY':2,
  'PM2_TRAP_SESSION_FLAG_TCPUDP_BOTH':3,
  'PM2_TRAP_SESSION_FLAG_TCPUDP_REPLY':4,
  'PM2_TRAP_SESSION_FLAG_TRAP_NONE':5,
  'PM2_SYSTEM_SETTINGS_HYPERSCALE_DEFAULT_POLICY_ACTION_DROP_ON_HARDWARE':1,
  'PM2_SYSTEM_SETTINGS_HYPERSCALE_DEFAULT_POLICY_ACTION_FORWARD_TO_HOST':2,
  'PM2_VDOM_TYPE_TRAFFIC':1,
  'PM2_VDOM_TYPE_ADMIN':2,
  'PM2_VDOM_TYPE_LAN_EXTENSION':3,
  'PM2_GUI_ENFORCE_CHANGE_SUMMARY_DISABLE':0,
  'PM2_GUI_ENFORCE_CHANGE_SUMMARY_REQUIRE':1,
  'PM2_GUI_ENFORCE_CHANGE_SUMMARY_OPTIONAL':2,
  'PM2_LOAD_DISTRIBUTION_METHOD_TO_MASTER':8,
  'PM2_LOAD_DISTRIBUTION_METHOD_SRC_IP':2,
  'PM2_LOAD_DISTRIBUTION_METHOD_DST_IP':3,
  'PM2_LOAD_DISTRIBUTION_METHOD_SRC_DST_IP':4,
  'PM2_LOAD_DISTRIBUTION_METHOD_SRC_IP_SPORT':5,
  'PM2_LOAD_DISTRIBUTION_METHOD_DST_IP_DPORT':6,
  'PM2_LOAD_DISTRIBUTION_METHOD_SRC_DST_IP_SPORT_DPORT':7,
  'PM2_LOAD_DISTRIBUTION_METHOD_DERIVED':9,
  'PM2_LOAD_DISTRIBUTION_METHOD_TO_PRIMARY':10,
  'PM2_NONAT_EIF_KEY_SEL_DIP_ONLY':1,
  'PM2_NONAT_EIF_KEY_SEL_DIP_DPORT':2,
  'PM2_NONAT_EIF_KEY_SEL_DIP_DPORT_PROTO':3,
  'PM2_INTREE_SES_BEST_ROUTE_FORCE':1,
  'PM2_INTREE_SES_BEST_ROUTE_DISABLE':2,
  'PM2_REFRESH_DIRECTION_BOTH':1,
  'PM2_REFRESH_DIRECTION_OUTGOING':2,
  'PM2_REFRESH_DIRECTION_INCOMING':3,
  'PM2_DHCP_SERVER_IPSEC':31,
  'PM2_IP_MODE_RANGE':0,
  'PM2_IP_MODE_USRGRP':1,
  'PM2_OPT_SPECIFY':5,
  'PM2_OPT_LOCAL':21,
  'PM2_RESERVED_ADDR_ASSIGN':0,
  'PM2_RESERVED_ADDR_BLOCK':1,
  'PM2_RESERVED_ADDR_RESERVED':2,
  'PM2_OPT_DHCP_SVR_ADDR_TYPE_MAC':1,
  'PM2_OPT_DHCP_SVR_ADDR_TYPE_OPTION82':2,
  'PM2_MAC_ACL_ASSIGN':0,
  'PM2_MAC_ACL_BLOCK':1,
  'PM2_ADDR_TYPE_IPMASK':0,
  'PM2_ADDR_TYPE_IPRANGE':1,
  'PM2_ADDR_TYPE_FQDN':2,
  'PM2_ADDR_TYPE_WILD':3,
  'PM2_ADDR_TYPE_GEOGRAPHY':4,
  'PM2_ADDR_TYPE_URL':5,
  'PM2_ADDR_TYPE_WILDCARD_FQDN':6,
  'PM2_ADDR_TYPE_NSX':13,
  'PM2_ADDR_TYPE_AWS':14,
  'PM2_ADDR_TYPE_DYNAMIC':15,
  'PM2_ADDR_TYPE_INTF_SUBNET':16,
  'PM2_ADDR_TYPE_INTF_MAC':17,
  'PM2_ADDR_TYPE_INTF_FQDN_GROUP':19,
  'PM2_ADDR_TYPE_ROUTE_TAG':20,
  'PM2_ADDR_SUB_TYPE_SDN':0,
  'PM2_ADDR_SUB_TYPE_CP_SPT':1,
  'PM2_ADDR_SUB_TYPE_FSSO':2,
  'PM2_ADDR_SUB_TYPE_EMS_TAG':3,
  'PM2_ADDR_SUB_TYPE_SWC_TAG':4,
  'PM2_ADDR_SUB_TYPE_FORTIVOICE_TAG':5,
  'PM2_ADDR_SUB_TYPE_FORTINAC_TAG':6,
  'PM2_ADDR_SUB_TYPE_FORTIPOLICY_TAG':7,
  'PM2_ADDR_SUB_TYPE_DEVICE_IDENTIFICATION':8,
  'PM2_ADDR_SUB_TYPE_RSSO':9,
  'PM2_ADDR_SUB_TYPE_EXTERNAL_RESOURCE':10,
  'PM2_COUNTRY_AU':5,
  'PM2_COUNTRY_MY':63,
  'PM2_COUNTRY_KR':54,
  'PM2_COUNTRY_CN':20,
  'PM2_COUNTRY_JP':49,
  'PM2_COUNTRY_IN':42,
  'PM2_COUNTRY_TW':96,
  'PM2_COUNTRY_HK':39,
  'PM2_COUNTRY_TH':97,
  'PM2_COUNTRY_AP':1001,
  'PM2_COUNTRY_VN':109,
  'PM2_COUNTRY_FR':33,
  'PM2_COUNTRY_IT':47,
  'PM2_COUNTRY_AE':101,
  'PM2_COUNTRY_IL':46,
  'PM2_COUNTRY_SE':93,
  'PM2_COUNTRY_KZ':51,
  'PM2_COUNTRY_PT':80,
  'PM2_COUNTRY_GR':36,
  'PM2_COUNTRY_SA':85,
  'PM2_COUNTRY_RU':84,
  'PM2_COUNTRY_GB':103,
  'PM2_COUNTRY_DK':26,
  'PM2_COUNTRY_US':104,
  'PM2_COUNTRY_SG':87,
  'PM2_COUNTRY_CA':18,
  'PM2_COUNTRY_PR':81,
  'PM2_COUNTRY_BS':1002,
  'PM2_COUNTRY_VC':1003,
  'PM2_COUNTRY_NL':69,
  'PM2_COUNTRY_AR':3,
  'PM2_COUNTRY_NP':68,
  'PM2_COUNTRY_PH':78,
  'PM2_COUNTRY_ID':43,
  'PM2_COUNTRY_PK':74,
  'PM2_COUNTRY_TK':1004,
  'PM2_COUNTRY_NZ':71,
  'PM2_COUNTRY_KH':1005,
  'PM2_COUNTRY_MO':61,
  'PM2_COUNTRY_PG':76,
  'PM2_COUNTRY_MV':1007,
  'PM2_COUNTRY_AF':1008,
  'PM2_COUNTRY_BD':9,
  'PM2_COUNTRY_ZA':90,
  'PM2_COUNTRY_EG':29,
  'PM2_COUNTRY_NG':1009,
  'PM2_COUNTRY_TZ':1010,
  'PM2_COUNTRY_ZM':1011,
  'PM2_COUNTRY_SN':1012,
  'PM2_COUNTRY_NA':112,
  'PM2_COUNTRY_CI':1013,
  'PM2_COUNTRY_GH':1014,
  'PM2_COUNTRY_SD':1015,
  'PM2_COUNTRY_CM':1016,
  'PM2_COUNTRY_MW':1017,
  'PM2_COUNTRY_AO':1018,
  'PM2_COUNTRY_KE':52,
  'PM2_COUNTRY_GA':1019,
  'PM2_COUNTRY_ML':1020,
  'PM2_COUNTRY_BJ':1021,
  'PM2_COUNTRY_MG':1022,
  'PM2_COUNTRY_TD':1023,
  'PM2_COUNTRY_BW':1024,
  'PM2_COUNTRY_LY':1025,
  'PM2_COUNTRY_CV':1026,
  'PM2_COUNTRY_RW':1027,
  'PM2_COUNTRY_MZ':1028,
  'PM2_COUNTRY_GM':1029,
  'PM2_COUNTRY_LS':1030,
  'PM2_COUNTRY_MU':1031,
  'PM2_COUNTRY_CG':1032,
  'PM2_COUNTRY_UG':1033,
  'PM2_COUNTRY_BF':1034,
  'PM2_COUNTRY_SL':1035,
  'PM2_COUNTRY_SO':1036,
  'PM2_COUNTRY_ZW':111,
  'PM2_COUNTRY_CD':1037,
  'PM2_COUNTRY_NE':1038,
  'PM2_COUNTRY_CF':1039,
  'PM2_COUNTRY_SZ':1040,
  'PM2_COUNTRY_TG':1041,
  'PM2_COUNTRY_GN':1042,
  'PM2_COUNTRY_LR':1043,
  'PM2_COUNTRY_SC':1044,
  'PM2_COUNTRY_MA':67,
  'PM2_COUNTRY_DZ':2,
  'PM2_COUNTRY_MR':1045,
  'PM2_COUNTRY_DJ':1046,
  'PM2_COUNTRY_RE':1047,
  'PM2_COUNTRY_GQ':1048,
  'PM2_COUNTRY_TN':99,
  'PM2_COUNTRY_DE':35,
  'PM2_COUNTRY_TR':100,
  'PM2_COUNTRY_AT':6,
  'PM2_COUNTRY_ES':91,
  'PM2_COUNTRY_PL':79,
  'PM2_COUNTRY_BE':11,
  'PM2_COUNTRY_LV':56,
  'PM2_COUNTRY_UA':102,
  'PM2_COUNTRY_IR':44,
  'PM2_COUNTRY_BY':10,
  'PM2_COUNTRY_CZ':25,
  'PM2_COUNTRY_PS':105,
  'PM2_COUNTRY_IS':41,
  'PM2_COUNTRY_CY':24,
  'PM2_COUNTRY_HU':40,
  'PM2_COUNTRY_SK':88,
  'PM2_COUNTRY_MK':62,
  'PM2_COUNTRY_LU':60,
  'PM2_COUNTRY_JO':50,
  'PM2_COUNTRY_AZ':7,
  'PM2_COUNTRY_RS':1049,
  'PM2_COUNTRY_BG':17,
  'PM2_COUNTRY_OM':73,
  'PM2_COUNTRY_RO':83,
  'PM2_COUNTRY_BH':8,
  'PM2_COUNTRY_GE':34,
  'PM2_COUNTRY_IE':45,
  'PM2_COUNTRY_NO':72,
  'PM2_COUNTRY_AM':4,
  'PM2_COUNTRY_EU':1050,
  'PM2_COUNTRY_SI':89,
  'PM2_COUNTRY_CH':94,
  'PM2_COUNTRY_NC':1051,
  'PM2_COUNTRY_BN':16,
  'PM2_COUNTRY_LK':92,
  'PM2_COUNTRY_ME':1052,
  'PM2_COUNTRY_FI':32,
  'PM2_COUNTRY_EE':31,
  'PM2_COUNTRY_BA':14,
  'PM2_COUNTRY_KW':55,
  'PM2_COUNTRY_LT':59,
  'PM2_COUNTRY_LB':57,
  'PM2_COUNTRY_MT':64,
  'PM2_COUNTRY_IQ':1053,
  'PM2_COUNTRY_UZ':107,
  'PM2_COUNTRY_MD':1054,
  'PM2_COUNTRY_KY':1055,
  'PM2_COUNTRY_TT':98,
  'PM2_COUNTRY_DO':27,
  'PM2_COUNTRY_BB':1056,
  'PM2_COUNTRY_BM':1057,
  'PM2_COUNTRY_JM':48,
  'PM2_COUNTRY_TC':1058,
  'PM2_COUNTRY_VI':1059,
  'PM2_COUNTRY_CO':21,
  'PM2_COUNTRY_AG':1060,
  'PM2_COUNTRY_PM':1061,
  'PM2_COUNTRY_MF':1062,
  'PM2_COUNTRY_VG':1063,
  'PM2_COUNTRY_GD':1064,
  'PM2_COUNTRY_SY':95,
  'PM2_COUNTRY_JE':1065,
  'PM2_COUNTRY_TJ':1066,
  'PM2_COUNTRY_KG':1067,
  'PM2_COUNTRY_HR':23,
  'PM2_COUNTRY_AL':1,
  'PM2_COUNTRY_SM':1068,
  'PM2_COUNTRY_IM':1069,
  'PM2_COUNTRY_GG':1070,
  'PM2_COUNTRY_QA':82,
  'PM2_COUNTRY_BZ':12,
  'PM2_COUNTRY_LI':58,
  'PM2_COUNTRY_FO':1071,
  'PM2_COUNTRY_MC':66,
  'PM2_COUNTRY_YE':110,
  'PM2_COUNTRY_AD':1072,
  'PM2_COUNTRY_GI':1073,
  'PM2_COUNTRY_GL':1074,
  'PM2_COUNTRY_TM':1075,
  'PM2_COUNTRY_WS':1076,
  'PM2_COUNTRY_MN':1077,
  'PM2_COUNTRY_VU':1078,
  'PM2_COUNTRY_FJ':1079,
  'PM2_COUNTRY_LA':1080,
  'PM2_COUNTRY_GU':1081,
  'PM2_COUNTRY_WF':1082,
  'PM2_COUNTRY_MH':1083,
  'PM2_COUNTRY_BT':1084,
  'PM2_COUNTRY_FM':1085,
  'PM2_COUNTRY_PF':1086,
  'PM2_COUNTRY_VE':108,
  'PM2_COUNTRY_MX':65,
  'PM2_COUNTRY_BR':15,
  'PM2_COUNTRY_CL':19,
  'PM2_COUNTRY_EC':28,
  'PM2_COUNTRY_PE':77,
  'PM2_COUNTRY_CR':22,
  'PM2_COUNTRY_UY':106,
  'PM2_COUNTRY_NI':1087,
  'PM2_COUNTRY_BO':13,
  'PM2_COUNTRY_PA':75,
  'PM2_COUNTRY_GT':37,
  'PM2_COUNTRY_SV':30,
  'PM2_COUNTRY_CU':1088,
  'PM2_COUNTRY_KP':53,
  'PM2_COUNTRY_TO':1089,
  'PM2_COUNTRY_TL':1090,
  'PM2_COUNTRY_PY':1091,
  'PM2_COUNTRY_HT':1092,
  'PM2_COUNTRY_HN':38,
  'PM2_COUNTRY_AN':70,
  'PM2_COUNTRY_GY':1093,
  'PM2_COUNTRY_SR':1119,
  'PM2_COUNTRY_AW':1094,
  'PM2_COUNTRY_BI':1095,
  'PM2_COUNTRY_GW':1096,
  'PM2_COUNTRY_ER':1097,
  'PM2_COUNTRY_MS':1098,
  'PM2_COUNTRY_KN':1099,
  'PM2_COUNTRY_GF':1100,
  'PM2_COUNTRY_SB':1101,
  'PM2_COUNTRY_TV':1102,
  'PM2_COUNTRY_KI':1103,
  'PM2_COUNTRY_IO':1104,
  'PM2_COUNTRY_NU':1105,
  'PM2_COUNTRY_CK':1106,
  'PM2_COUNTRY_AS':1107,
  'PM2_COUNTRY_MP':1108,
  'PM2_COUNTRY_PW':1109,
  'PM2_COUNTRY_NF':1110,
  'PM2_COUNTRY_MM':1111,
  'PM2_COUNTRY_NR':1112,
  'PM2_COUNTRY_AI':1113,
  'PM2_COUNTRY_LC':1114,
  'PM2_COUNTRY_GP':1115,
  'PM2_COUNTRY_VA':1116,
  'PM2_COUNTRY_ET':1117,
  'PM2_COUNTRY_AX':1118,
  'PM2_SDN_ACT_ACI':1,
  'PM2_SDN_ACT_AWS':2,
  'PM2_SDN_ACT_NSX':3,
  'PM2_SDN_ACT_NUAGE':4,
  'PM2_SDN_ACT_AZURE':5,
  'PM2_SDN_ACT_OCI':7,
  'PM2_SDN_ACT_GCP':6,
  'PM2_SDN_ACT_OPENSTACK':8,
  'PM2_SDN_ADDR_TYPE_PRIVATE':0,
  'PM2_SDN_ADDR_TYPE_PUBLIC':1,
  'PM2_SDN_ADDR_TYPE_ALL':2,
  'PM2_FWADDR_CLEARPASS_SPT_UNKNOWN':0,
  'PM2_FWADDR_CLEARPASS_SPT_HEALTHY':1,
  'PM2_FWADDR_CLEARPASS_SPT_QUARANTINE':2,
  'PM2_FWADDR_CLEARPASS_SPT_CHECKUP':3,
  'PM2_FWADDR_CLEARPASS_SPT_TRANSITION':4,
  'PM2_FWADDR_CLEARPASS_SPT_INFECTED':5,
  'PM2_FWADDR_CLEARPASS_SPT_TRANSIENT':6,
  'PM2_ADDR_TYPE_IP':9,
  'PM2_ADDR_TYPE_MAC':18,
  'PM2_FW_ADDR_DIRTY_DIRTY':1,
  'PM2_FW_ADDR_DIRTY_CLEAN':2,
  'PM2_IP6_TYPE_PREFIX':0,
  'PM2_IP6_TYPE_RANGE':1,
  'PM2_IP6_TYPE_NSX':2,
  'PM2_IP6_TYPE_DYNAMIC':3,
  'PM2_IP6_TYPE_FQDN':4,
  'PM2_IP6_TYPE_TEMPLATE':5,
  'PM2_IP6_TYPE_GEOGRAPHY':7,
  'PM2_IP6_TYPE_MAC':6,
  'PM2_IP6_TYPE_ROUTE_TAG':8,
  'PM2_OPT_TYPE_ANY':0,
  'PM2_OPT_TYPE_SPECIFIC':1,
  'PM2_DLP_PROTO_PRIVATE':1000,
  'PM2_DLP_PROTO_PUBLIC':1001,
  'PM2_DLP_PROTO_ALL':999,
  'PM2_OPT_ARRAY':49,
  'PM2_OPT_FOLDER':53,
  'PM2_FW_ADDRGRP_CATE_DEFAULT':0,
  'PM2_FW_ADDRGRP_CATE_ZTNA_EMS_TAG':1,
  'PM2_FW_ADDRGRP_CATE_ZTNA_GEO_TAG':2,
  'PM2_FNBAM_AUTO_PAP_MSCHAPv2_CHAP':6,
  'PM2_FNBAM_MSCHAPv2':4,
  'PM2_FNBAM_MSCHAP':3,
  'PM2_FNBAM_CHAP':1,
  'PM2_FNBAM_PAP':0,
  'PM2_DP_FALLBACK_DEFAULT_PROF':1,
  'PM2_DP_FALLBACK_IP_HDR_ADDR':0,
  'PM2_RAD_ATTR_USER_NAME':1,
  'PM2_RAD_ATTR_USER_PASSWORD':2,
  'PM2_RAD_ATTR_CHAP_PASSWORD':3,
  'PM2_RAD_ATTR_NAS_IP_ADDRESS':4,
  'PM2_RAD_ATTR_NAS_PORT':5,
  'PM2_RAD_ATTR_SERVICE_TYPE':6,
  'PM2_RAD_ATTR_FRAMED_PROTOCOL':7,
  'PM2_RAD_ATTR_FRAMED_IP_ADDRESS':8,
  'PM2_RAD_ATTR_FRAMED_IP_NETMASK':9,
  'PM2_RAD_ATTR_FRAMED_ROUTING':10,
  'PM2_RAD_ATTR_FILTER_ID':11,
  'PM2_RAD_ATTR_FRAMED_MTU':12,
  'PM2_RAD_ATTR_FRAMED_COMPRESSION':13,
  'PM2_RAD_ATTR_LOGIN_IP_HOST':14,
  'PM2_RAD_ATTR_LOGIN_SERVICE':15,
  'PM2_RAD_ATTR_LOGIN_TCP_PORT':16,
  'PM2_RAD_ATTR_REPLY_MESSAGE':18,
  'PM2_RAD_ATTR_CALLBACK_NUMBER':19,
  'PM2_RAD_ATTR_CALLBACK_ID':20,
  'PM2_RAD_ATTR_FRAMED_ROUTE':22,
  'PM2_RAD_ATTR_FRAMED_IPX_NETWORK':23,
  'PM2_RAD_ATTR_STATE':24,
  'PM2_RAD_ATTR_CLASS':25,
  'PM2_RAD_ATTR_VENDOR_SPECIFIC':26,
  'PM2_RAD_ATTR_SESSION_TIMEOUT':27,
  'PM2_RAD_ATTR_IDLE_TIMEOUT':28,
  'PM2_RAD_ATTR_TERMINATION_ACTION':29,
  'PM2_RAD_ATTR_CALLED_STATION_ID':30,
  'PM2_RAD_ATTR_CALLING_STATION_ID':31,
  'PM2_RAD_ATTR_NAS_IDENTIFIER':32,
  'PM2_RAD_ATTR_PROXY_STATE':33,
  'PM2_RAD_ATTR_LOGIN_LAT_SERVICE':34,
  'PM2_RAD_ATTR_LOGIN_LAT_NODE':35,
  'PM2_RAD_ATTR_LOGIN_LAT_GROUP':36,
  'PM2_RAD_ATTR_FRAMED_APPLETALK_LINK':37,
  'PM2_RAD_ATTR_FRAMED_APPLETALK_NETWORK':38,
  'PM2_RAD_ATTR_FRAMED_APPLETALK_ZONE':39,
  'PM2_RAD_ATTR_ACCT_STATUS_TYPE':40,
  'PM2_RAD_ATTR_ACCT_DELAY_TIME':41,
  'PM2_RAD_ATTR_ACCT_INPUT_OCTETS':42,
  'PM2_RAD_ATTR_ACCT_OUTPUT_OCTETS':43,
  'PM2_RAD_ATTR_ACCT_SESSION_ID':44,
  'PM2_RAD_ATTR_ACCT_AUTHENTIC':45,
  'PM2_RAD_ATTR_ACCT_SESSION_TIME':46,
  'PM2_RAD_ATTR_ACCT_INPUT_PACKETS':47,
  'PM2_RAD_ATTR_ACCT_OUTPUT_PACKETS':48,
  'PM2_RAD_ATTR_ACCT_TERMINATE_CAUSE':49,
  'PM2_RAD_ATTR_ACCT_MULTI_SESSION_ID':50,
  'PM2_RAD_ATTR_ACCT_LINK_COUNT':51,
  'PM2_RAD_ATTR_CHAP_CHALLENGE':60,
  'PM2_RAD_ATTR_NAS_PORT_TYPE':61,
  'PM2_RAD_ATTR_PORT_LIMIT':62,
  'PM2_RAD_ATTR_LOGIN_LAT_PORT':63,
  'PM2_RAD_LOG_PROTO_ERROR':2,
  'PM2_RAD_LOG_PROF_NOT_FOUND':4,
  'PM2_RAD_LOG_CTX_NOT_FOUND':8,
  'PM2_RAD_LOG_ACCT_STOP_MISSED':16,
  'PM2_RAD_LOG_ACCT_EVENT':32,
  'PM2_RAD_LOG_ENDPOINT_BLOCK':128,
  'PM2_RAD_LOG_OTHER':64,
  'PM2_RAD_LOG_NONE':1,
  'PM2_MT_FALLBACK_POLICY_PROF':1,
  'PM2_MT_FALLBACK_IP_SESS_ADDR':0,
  'PM2_MT_QUERY_SESS_IP':1,
  'PM2_MT_QUERY_EXTRACT_IP':2,
  'PM2_MT_QUERY_EXTRACT_ENDPOINT':3,
  'PM2_ADDR_SRC_HTTP_HEADER':1,
  'PM2_ADDR_SRC_COOKIE':2,
  'PM2_ENC_AUTO':2097152,
  'PM2_ENC_ISO8859_1':65536,
  'PM2_USER_RADIUS_GROUP_OV_ATTR_TYPE_FILTER_ID':1,
  'PM2_USER_RADIUS_GROUP_OV_ATTR_TYPE_CLASS':2,
  'PM2_SW_CTRL_SRV_TYPE_LOGIN':1,
  'PM2_SW_CTRL_SRV_TYPE_FRAMED':2,
  'PM2_SW_CTRL_SRV_TYPE_CALLBACK_LOGIN':4,
  'PM2_SW_CTRL_SRV_TYPE_CALLBACK_FRAMED':8,
  'PM2_SW_CTRL_SRV_TYPE_OUTBOUND':16,
  'PM2_SW_CTRL_SRV_TYPE_ADMINISTRATIVE':32,
  'PM2_SW_CTRL_SRV_TYPE_NAS_PROMPT':64,
  'PM2_SW_CTRL_SRV_TYPE_AUTHENTICATE_ONLY':128,
  'PM2_SW_CTRL_SRV_TYPE_CALLBACK_NAS_PROMPT':256,
  'PM2_SW_CTRL_SRV_TYPE_CALL_CHECK':512,
  'PM2_SW_CTRL_SRV_TYPE_CALLBACK_ADMINISTRATIVE':1024,
  'PM2_DELIMITER_PLUS':1,
  'PM2_DELIMITER_COMMA':2,
  'PM2_MAC_USERNAME_DELIMITER_HYPHEN':1,
  'PM2_MAC_USERNAME_DELIMITER_SINGLE_HYPHEN':2,
  'PM2_MAC_USERNAME_DELIMITER_COLON':3,
  'PM2_MAC_USERNAME_DELIMITER_NONE':4,
  'PM2_MAC_PASSWORD_DELIMITER_HYPHEN':1,
  'PM2_MAC_PASSWORD_DELIMITER_SINGLE_HYPHEN':2,
  'PM2_MAC_PASSWORD_DELIMITER_COLON':3,
  'PM2_MAC_PASSWORD_DELIMITER_NONE':4,
  'PM2_MAC_CASE_UPPERCASE':1,
  'PM2_MAC_CASE_LOWERCASE':2,
  'PM2_NAS_ID_TYPE_LEGACY':1,
  'PM2_NAS_ID_TYPE_CUSTOM':2,
  'PM2_NAS_ID_TYPE_HOSTNAME':3,
  'PM2_TRANSPORT_UDP':1,
  'PM2_TRANSPORT_TCP':2,
  'PM2_TRANSPORT_TLS':4,
  'PM2_CALL_STATION_ID_TYPE_LEGACY':1,
  'PM2_CALL_STATION_ID_TYPE_IP':2,
  'PM2_CALL_STATION_ID_TYPE_MAC':3,
  'PM2_ACCOUNT_KEY_PROCESSING_SAME':1,
  'PM2_ACCOUNT_KEY_PROCESSING_STRIP':2,
  'PM2_ACCOUNT_KEY_UPN_SAN_OTHERNAME':1,
  'PM2_ACCOUNT_KEY_UPN_SAN_RFC822NAME':2,
  'PM2_ACCOUNT_KEY_UPN_SAN_DNSNAME':3,
  'PM2_ACCOUNT_KEY_UPN_SAN_CN':4,
  'PM2_LDAP_AUTH_SIMPLE_TYPE':0,
  'PM2_LDAP_AUTH_ANONYMOUS_TYPE':1,
  'PM2_LDAP_AUTH_REGULAR_TYPE':2,
  'PM2_SSL_SECURE_NONE':0,
  'PM2_SSL_SECURE_STARTTLS':1,
  'PM2_SSL_SECURE_LDAPS':2,
  'PM2_GROUP_MEMBER_CHECK_USER_ATTR':0,
  'PM2_GROUP_MEMBER_CHECK_GROUP_OBJECT':1,
  'PM2_GROUP_MEMBER_CHECK_POSIX_GROUP_OBJECT':2,
  'PM2_SEARCH_TYPE_NESTED':1,
  'PM2_SEARCH_TYPE_RECURSIVE':2,
  'PM2_AUTHLOCAL':1,
  'PM2_AUTHRADIUS':2,
  'PM2_AUTHLDAP':16,
  'PM2_AUTHTAC_PLUS':8,
  'PM2_PEER_CN_TYPE_STRING':1,
  'PM2_PEER_CN_TYPE_EMAIL':2,
  'PM2_PEER_CN_TYPE_FQDN':4,
  'PM2_PEER_CN_TYPE_IPV4':8,
  'PM2_PEER_CN_TYPE_IPV6':16,
  'PM2_LDAP_MODE_PASSWD':0,
  'PM2_LDAP_MODE_PRINAME':1,
  'PM2_MFA_MODE_NONE':0,
  'PM2_MFA_MODE_PASSWORD':2,
  'PM2_MFA_MODE_SUBJECT_IDENTITY':3,
  'PM2_SSLVPN_ALGO_DEFAULT':1,
  'PM2_SSLVPN_ALGO_HIGH':2,
  'PM2_SSLVPN_ALGO_LOW':3,
  'PM2_SSLVPN_ALGO_MEDIUM':4,
  'PM2_SSL_CIPHER_ANY':0,
  'PM2_SSL_CIPHER_HIGH':1,
  'PM2_SSL_CIPHER_MEDIUM':2,
  'PM2_SSL_AUTH_ANY':0,
  'PM2_SSL_AUTH_LOCAL':1,
  'PM2_SSL_AUTH_RAD':2,
  'PM2_SSL_AUTH_LDAP':3,
  'PM2_SSL_AUTH_TAC_PLUS':4,
  'PM2_SSL_AUTH_PEER':5,
  'PM2_WEB_PROXY_ACT_PASS':0,
  'PM2_WEB_PROXY_ACT_ADD':1,
  'PM2_WEB_PROXY_ACT_REMOVE':2,
  'PM2_BANNED_CIPHER_DH':2,
  'PM2_BANNED_CIPHER_ECDH':8,
  'PM2_SSL_VER_TLS_1_0':2,
  'PM2_SSL_VER_TLS_1_1':4,
  'PM2_SSL_VER_TLS_1_2':8,
  'PM2_SSL_VER_TLS_1_3':32,
  'PM2_DTLS_VER_1_0':0,
  'PM2_DTLS_VER_1_2':2,
  'PM2_CLIENT_SIGALGS_NO_RSA_PSS':1,
  'PM2_CLIENT_SIGALGS_ALL':2,
  'PM2_TUNNEL_ADDR_ASSIGNED_METHOD_FIRST_AVAILABLE':1,
  'PM2_TUNNEL_ADDR_ASSIGNED_METHOD_ROUND_ROBIN':2,
  'PM2_APS_AUTHGRP_TYPE_FIREWALL':1,
  'PM2_APS_AUTHGRP_TYPE_DIR_SVC':5,
  'PM2_APS_AUTHGRP_TYPE_FSSO_SVC':6,
  'PM2_APS_AUTHGRP_TYPE_GUEST':7,
  'PM2_APS_AUTHGRP_TYPE_RSSO':8,
  'PM2_SSLVPN_GRP_CLTCHK_FTC':1,
  'PM2_SSLVPN_GRP_CLTCHK_FTC_AV':2,
  'PM2_SSLVPN_GRP_CLTCHK_FTC_FW':4,
  'PM2_SSLVPN_GRP_CLTCHK_AV':8,
  'PM2_SSLVPN_GRP_CLTCHK_FW':16,
  'PM2_OS_PATCH_LEVEL_DENY':2,
  'PM2_OS_PATCH_LEVEL_ANY':0,
  'PM2_OS_PATCH_LEVEL_UP_TO_DATE':1,
  'PM2_CNT_TYPE_EMAIL':0,
  'PM2_CNT_TYPE_AUTOGEN':1,
  'PM2_CNT_TYPE_SPECIFY':2,
  'PM2_CNT_TYPE_DISABLE':3,
  'PM2_OPT_REQ_DISABLED':2,
  'PM2_EXPIRE_TYPE_IMD':0,
  'PM2_EXPIRE_TYPE_FSL':1,
  'PM2_LOGIC_TYPE_OPT_OR':0,
  'PM2_LOGIC_TYPE_OPT_AND':1,
  'PM2_OCSP_ACT_REVOKE':0,
  'PM2_OCSP_ACT_IGNORE':1,
  'PM2_IPSEC_TYPE_STATIC':0,
  'PM2_IPSEC_TYPE_DIALUP':1,
  'PM2_IPSEC_TYPE_DDNS':2,
  'PM2_VPN_IPSEC_DPD_ENABLE':1,
  'PM2_VPN_IPSEC_DPD_DISABLE':0,
  'PM2_VPN_IPSEC_DPD_ON_IDLE':2,
  'PM2_VPN_IPSEC_DPD_ON_DEMAND':3,
  'PM2_IPSEC_DHGRP_1':1,
  'PM2_IPSEC_DHGRP_2':2,
  'PM2_IPSEC_DHGRP_5':4,
  'PM2_IPSEC_DHGRP_14':8,
  'PM2_IPSEC_DHGRP_15':16,
  'PM2_IPSEC_DHGRP_16':32,
  'PM2_IPSEC_DHGRP_17':64,
  'PM2_IPSEC_DHGRP_18':128,
  'PM2_IPSEC_DHGRP_19':256,
  'PM2_IPSEC_DHGRP_20':512,
  'PM2_IPSEC_DHGRP_21':1024,
  'PM2_IPSEC_DHGRP_27':2048,
  'PM2_IPSEC_DHGRP_28':4096,
  'PM2_IPSEC_DHGRP_29':8192,
  'PM2_IPSEC_DHGRP_30':16384,
  'PM2_IPSEC_DHGRP_31':32768,
  'PM2_IPSEC_DHGRP_32':65536,
  'PM2_VPNAUTO_PROP2_DES_MD5':5,
  'PM2_VPNAUTO_PROP2_DES_SHA1':6,
  'PM2_VPNAUTO_PROP2_3DES_MD5':7,
  'PM2_VPNAUTO_PROP2_3DES_SHA1':8,
  'PM2_VPNAUTO_PROP2_AES128_MD5':9,
  'PM2_VPNAUTO_PROP2_AES128_SHA1':10,
  'PM2_VPNAUTO_PROP2_AES192_MD5':11,
  'PM2_VPNAUTO_PROP2_AES192_SHA1':12,
  'PM2_VPNAUTO_PROP2_AES256_MD5':13,
  'PM2_VPNAUTO_PROP2_AES256_SHA1':14,
  'PM2_VPNAUTO_PROP2_DES_SHA256':19,
  'PM2_VPNAUTO_PROP2_3DES_SHA256':20,
  'PM2_VPNAUTO_PROP2_AES128_SHA256':21,
  'PM2_VPNAUTO_PROP2_AES192_SHA256':22,
  'PM2_VPNAUTO_PROP2_AES256_SHA256':23,
  'PM2_VPNAUTO_PROP2_DES_SHA384':24,
  'PM2_VPNAUTO_PROP2_DES_SHA512':25,
  'PM2_VPNAUTO_PROP2_3DES_SHA384':26,
  'PM2_VPNAUTO_PROP2_3DES_SHA512':27,
  'PM2_VPNAUTO_PROP2_AES128_SHA384':28,
  'PM2_VPNAUTO_PROP2_AES128_SHA512':29,
  'PM2_VPNAUTO_PROP2_AES192_SHA384':30,
  'PM2_VPNAUTO_PROP2_AES192_SHA512':31,
  'PM2_VPNAUTO_PROP2_AES256_SHA384':32,
  'PM2_VPNAUTO_PROP2_AES256_SHA512':33,
  'PM2_VPNAUTO_PROP2_ARIA128_MD5':39,
  'PM2_VPNAUTO_PROP2_ARIA128_SHA1':40,
  'PM2_VPNAUTO_PROP2_ARIA128_SHA256':41,
  'PM2_VPNAUTO_PROP2_ARIA128_SHA384':42,
  'PM2_VPNAUTO_PROP2_ARIA128_SHA512':43,
  'PM2_VPNAUTO_PROP2_ARIA192_MD5':45,
  'PM2_VPNAUTO_PROP2_ARIA192_SHA1':46,
  'PM2_VPNAUTO_PROP2_ARIA192_SHA256':47,
  'PM2_VPNAUTO_PROP2_ARIA192_SHA384':48,
  'PM2_VPNAUTO_PROP2_ARIA192_SHA512':49,
  'PM2_VPNAUTO_PROP2_ARIA256_MD5':51,
  'PM2_VPNAUTO_PROP2_ARIA256_SHA1':52,
  'PM2_VPNAUTO_PROP2_ARIA256_SHA256':53,
  'PM2_VPNAUTO_PROP2_ARIA256_SHA384':54,
  'PM2_VPNAUTO_PROP2_ARIA256_SHA512':55,
  'PM2_VPNAUTO_PROP2_SEED_MD5':57,
  'PM2_VPNAUTO_PROP2_SEED_SHA1':58,
  'PM2_VPNAUTO_PROP2_SEED_SHA256':59,
  'PM2_VPNAUTO_PROP2_SEED_SHA384':60,
  'PM2_VPNAUTO_PROP2_SEED_SHA512':61,
  'PM2_VPNAUTO_PROP2_AES128GCM_PRFSHA1':65,
  'PM2_VPNAUTO_PROP2_AES128GCM_PRFSHA256':66,
  'PM2_VPNAUTO_PROP2_AES128GCM_PRFSHA384':67,
  'PM2_VPNAUTO_PROP2_AES128GCM_PRFSHA512':68,
  'PM2_VPNAUTO_PROP2_AES256GCM_PRFSHA1':69,
  'PM2_VPNAUTO_PROP2_AES256GCM_PRFSHA256':70,
  'PM2_VPNAUTO_PROP2_AES256GCM_PRFSHA384':71,
  'PM2_VPNAUTO_PROP2_AES256GCM_PRFSHA512':72,
  'PM2_VPNAUTO_PROP2_CHACHA20POLY1305_PRFSHA1':73,
  'PM2_VPNAUTO_PROP2_CHACHA20POLY1305_PRFSHA256':74,
  'PM2_VPNAUTO_PROP2_CHACHA20POLY1305_PRFSHA384':75,
  'PM2_VPNAUTO_PROP2_CHACHA20POLY1305_PRFSHA512':76,
  'PM2_IPSEC_AUTH_PSK':1,
  'PM2_IPSEC_AUTH_RSASIG':2,
  'PM2_IPSEC_AUTH_SIGNATURE':3,
  'PM2_IPSEC_PEER_OPT_ANY':1,
  'PM2_IPSEC_PEER_OPT_ID':2,
  'PM2_IPSEC_PEER_OPT_DIALUP':4,
  'PM2_IPSEC_PEER_OPT_PEER':8,
  'PM2_IPSEC_PEER_OPT_PEERGRP':16,
  'PM2_IPSEC_XAUTH_DISABLE':1,
  'PM2_IPSEC_XAUTH_CLIENT':2,
  'PM2_IPSEC_XAUTH_SERVER_PAP':4,
  'PM2_IPSEC_XAUTH_SERVER_CHAP':8,
  'PM2_IPSEC_XAUTH_SERVER_AUTO':16,
  'PM2_VM_PHASE1_MODE_AGGR':2,
  'PM2_VM_PHASE1_MODE_MAIN':1,
  'PM2_IPSEC_IPv4':1,
  'PM2_IPSEC_IPv6':2,
  'PM2_IPSEC_IDTYPE_AUTO':0,
  'PM2_IPSEC_IDTYPE_USERFQDN':2,
  'PM2_IPSEC_IDTYPE_KEYID':3,
  'PM2_IPSEC_IDTYPE_ADDRESS':4,
  'PM2_AUTOCONFIG_DISABLE':0,
  'PM2_AUTOCONFIG_CLIENT':1,
  'PM2_AUTOCONFIG_GATEWAY':2,
  'PM2_XAUTHEXPIRE_ON_DISCONNECT':0,
  'PM2_XAUTHEXPIRE_ON_REKEY':1,
  'PM2_EAP_ID_USE_ID_PAYLOAD':0,
  'PM2_EAP_ID_SEND_REQUEST':1,
  'PM2_WIZARD_TYPE_CUSTOM':0,
  'PM2_WIZARD_TYPE_DIALUP_FCT':1,
  'PM2_WIZARD_TYPE_IOS':2,
  'PM2_WIZARD_TYPE_DIALUP_ANDROID':3,
  'PM2_WIZARD_TYPE_DIALUP_CISCO':4,
  'PM2_WIZARD_TYPE_STATIC_FGT':5,
  'PM2_WIZARD_TYPE_STATIC_CISCO':6,
  'PM2_WIZARD_TYPE_DIALUP_WINDOWS':7,
  'PM2_WIZARD_TYPE_DIALUP_FORTIGATE':8,
  'PM2_WIZARD_TYPE_DIALUP_CISCO_FW':9,
  'PM2_WIZARD_TYPE_SIMPLIFIED_STATIC_FORTIGATE':10,
  'PM2_WIZARD_TYPE_HUB_FORTIGATE_AUTO_DISCOVERY':11,
  'PM2_WIZARD_TYPE_SPOKE_FORTIGATE_AUTO_DISCOVERY':12,
  'PM2_WIZARD_TYPE_FABRIC_OVERLAY_ORCHESTRATOR':18,
  'PM2_MESH_SELECTOR_TYPE_DISABLE':0,
  'PM2_MESH_SELECTOR_TYPE_SUBNET':1,
  'PM2_MESH_SELECTOR_TYPE_HOST':2,
  'PM2_VPN_ESN_REQUIRE':1,
  'PM2_VPN_ESN_ALLOW':2,
  'PM2_VPN_ESN_DISABLE':0,
  'PM2_IP_MODE_DHCP':2,
  'PM2_IP_MODE_NAME':4,
  'PM2_VPN_SUITE_B_DISABLE':0,
  'PM2_VPN_SUITE_B_GCM_128':1,
  'PM2_VPN_SUITE_B_GCM_256':2,
  'PM2_BANNED_CIPHER_SHA512':32768,
  'PM2_IPSEC_RSA_FORMAT_PKCS1':0,
  'PM2_IPSEC_RSA_FORMAT_PSS':1,
  'PM2_IPSEC_ENFORCE_UNIQUE_ID_DISABLE':0,
  'PM2_IPSEC_ENFORCE_UNIQUE_ID_KEEP_NEW':1,
  'PM2_IPSEC_ENFORCE_UNIQUE_ID_KEEP_OLD':2,
  'PM2_VPN_PPK_DISABLE':0,
  'PM2_VPN_PPK_ALLOW':1,
  'PM2_VPN_PPK_REQUIRE':2,
  'PM2_FEC_CODEC_RS':1,
  'PM2_FEC_CODEC_XOR':2,
  'PM2_VPN_QKD_DISABLE':0,
  'PM2_VPN_QKD_ALLOW':1,
  'PM2_VPN_QKD_REQUIRE':2,
  'PM2_TRANSPORT_UDP_FALLBACK_TCP':5,
  'PM2_TRANSPORT_AUTO':6,
  'PM2_CERT_TRUST_STORE_LOCAL':1,
  'PM2_CERT_TRUST_STORE_EMS':2,
  'PM2_DIRECTION_ANY':3,
  'PM2_DIRECTION_IPMASK':4,
  'PM2_DIRECTION_IPRANGE':5,
  'PM2_DIRECTION_GEOGRAPHY':6,
  'PM2_DIRECTION_ZTNA':8,
  'PM2_DIRECTION_IPPREFIX':7,
  'PM2_ACCOUNT_KEY_UPN_SAN_NONE':5,
  'PM2_ADDKE_0':1,
  'PM2_ADDKE_1080':2,
  'PM2_ADDKE_1081':4,
  'PM2_ADDKE_1082':8,
  'PM2_ADDKE_1083':16,
  'PM2_ADDKE_1084':32,
  'PM2_ADDKE_1085':64,
  'PM2_ADDKE_1089':128,
  'PM2_ADDKE_1090':256,
  'PM2_ADDKE_1091':512,
  'PM2_ADDKE_1092':1024,
  'PM2_ADDKE_1093':2048,
  'PM2_ADDKE_1094':4096,
  'PM2_ADDKE_35':8192,
  'PM2_ADDKE_36':16384,
  'PM2_ADDKE_37':32768,
  'PM2_IPSEC_MATCH_EXACT':2,
  'PM2_IPSEC_MATCH_SUBSET':1,
  'PM2_IPSEC_MATCH_AUTO':0,
  'PM2_VPNAUTO_PROP2_NULL_MD5':1,
  'PM2_VPNAUTO_PROP2_NULL_SHA1':2,
  'PM2_VPNAUTO_PROP2_DES_NULL':3,
  'PM2_VPNAUTO_PROP2_3DES_NULL':4,
  'PM2_VPNAUTO_PROP2_AES128_NULL':15,
  'PM2_VPNAUTO_PROP2_AES192_NULL':16,
  'PM2_VPNAUTO_PROP2_AES256_NULL':17,
  'PM2_VPNAUTO_PROP2_NULL_SHA256':18,
  'PM2_VPNAUTO_PROP2_NULL_SHA384':34,
  'PM2_VPNAUTO_PROP2_NULL_SHA512':35,
  'PM2_VPNAUTO_PROP2_ARIA128_NULL':38,
  'PM2_VPNAUTO_PROP2_ARIA192_NULL':44,
  'PM2_VPNAUTO_PROP2_ARIA256_NULL':50,
  'PM2_VPNAUTO_PROP2_SEED_NULL':56,
  'PM2_VPNAUTO_PROP2_AES128GCM':62,
  'PM2_VPNAUTO_PROP2_AES256GCM':63,
  'PM2_VPNAUTO_PROP2_CHACHA20POLY1305':64,
  'PM2_VPNKEYLIFE_SEC':1,
  'PM2_VPNKEYLIFE_KB':2,
  'PM2_VPNKEYLIFE_BOTH':3,
  'PM2_IPSEC_ROUTE_OVERLAP_USE_OLD':1,
  'PM2_IPSEC_ROUTE_OVERLAP_USE_NEW':2,
  'PM2_IPSEC_ROUTE_OVERLAP_ALLOW':3,
  'PM2_IPSEC_PROXYID_TYPE_SUBNET':0,
  'PM2_IPSEC_PROXYID_TYPE_RANGE':1,
  'PM2_IPSEC_PROXYID_TYPE_IP':2,
  'PM2_IPSEC_PROXYID_TYPE_NAME':3,
  'PM2_ADD_ROUTE_ENABLE':1,
  'PM2_ADD_ROUTE_DISABLE':0,
  'PM2_ADD_ROUTE_PHASE1':2,
  'PM2_IPSEC_ENCAP_TUNNEL_MODE':0,
  'PM2_IPSEC_ENCAP_TRANSPORT_MODE':1,
  'PM2_OPT_PHASE1':54,
  'PM2_VPNAUTO_PROP_NULL':1,
  'PM2_VPNAUTO_PROP_MD5':4,
  'PM2_VPNAUTO_PROP_SHA1':5,
  'PM2_VPNAUTO_PROP_SHA256':9,
  'PM2_VPNAUTO_PROP_SHA384':10,
  'PM2_VPNAUTO_PROP_SHA512':11,
  'PM2_VPNAUTO_PROP_DES':2,
  'PM2_VPNAUTO_PROP_3DES':3,
  'PM2_VPNAUTO_PROP_AES128':6,
  'PM2_VPNAUTO_PROP_AES192':7,
  'PM2_VPNAUTO_PROP_AES256':8,
  'PM2_VPNAUTO_PROP_ARIA128':12,
  'PM2_VPNAUTO_PROP_ARIA192':13,
  'PM2_VPNAUTO_PROP_ARIA256':14,
  'PM2_VPNAUTO_PROP_SEED':15,
  'PM2_IKE_VERSION_1':1,
  'PM2_IKE_VERSION_2':2,
  'PM2_MONITOR_HOLD_IMMEDIATE':0,
  'PM2_MONITOR_HOLD_DELAY':1,
  'PM2_MONITOR_HOLD_TIME':2,
  'PM2_WDAY_EVERYDAY':128,
  'PM2_WIZARD_TYPE_STATIC_FORTIPROXY':13,
  'PM2_WIZARD_TYPE_DIALUP_FORTIPROXY':14,
  'PM2_WIZARD_TYPE_SIMPLIFIED_STATIC_FORTIPROXY':15,
  'PM2_WIZARD_TYPE_HUB_FORTIPROXY_AUTO_DISCOVERY':16,
  'PM2_WIZARD_TYPE_SPOKE_FORTIPROXY_AUTO_DISCOVERY':17,
  'PM2_IPSEC_ENCAP_NONE':0,
  'PM2_IPSEC_ENCAP_GRE':1,
  'PM2_IPSEC_ENCAP_VXLAN':2,
  'PM2_IPSEC_ENCAP_VPN_ID_IPIP':3,
  'PM2_IPSEC_ENCAP_ADDR_IKE':0,
  'PM2_IPSEC_ENCAP_ADDR_IPV4':1,
  'PM2_IPSEC_ENCAP_ADDR_IPV6':2,
  'PM2_TUNNEL_SEARCH_SELECTORS':0,
  'PM2_TUNNEL_SEARCH_NEXTHOP':1,
  'PM2_IP_FRAGMENTATION_PRE_ENCAPSULATION':0,
  'PM2_IP_FRAGMENTATION_POST_ENCAPSULATION':1,
  'PM2_AUTO_DISCOVERY_SHORTCUTS_INDEPENDENT':1,
  'PM2_AUTO_DISCOVERY_SHORTCUTS_DEPENDENT':2,
  'PM2_IPSEC_TUNNEL_SLOT_MASTER':1,
  'PM2_IPSEC_TUNNEL_SLOT_AUTO':2,
  'PM2_IPSEC_TUNNEL_SLOT_FPC1':3,
  'PM2_IPSEC_TUNNEL_SLOT_FPC2':4,
  'PM2_IPSEC_TUNNEL_SLOT_FPC3':5,
  'PM2_IPSEC_TUNNEL_SLOT_FPC4':6,
  'PM2_IPSEC_TUNNEL_SLOT_FPC5':7,
  'PM2_IPSEC_TUNNEL_SLOT_FPC6':8,
  'PM2_IPSEC_TUNNEL_SLOT_FPC7':9,
  'PM2_IPSEC_TUNNEL_SLOT_FPC8':10,
  'PM2_IPSEC_TUNNEL_SLOT_FPC9':11,
  'PM2_IPSEC_TUNNEL_SLOT_FPC10':12,
  'PM2_IPSEC_TUNNEL_SLOT_FPM3':13,
  'PM2_IPSEC_TUNNEL_SLOT_FPM4':14,
  'PM2_IPSEC_TUNNEL_SLOT_FPM5':15,
  'PM2_IPSEC_TUNNEL_SLOT_FPM6':16,
  'PM2_IPSEC_TUNNEL_SLOT_FPM7':17,
  'PM2_IPSEC_TUNNEL_SLOT_FPM8':18,
  'PM2_IPSEC_TUNNEL_SLOT_FPM9':19,
  'PM2_IPSEC_TUNNEL_SLOT_FPM10':20,
  'PM2_IPSEC_TUNNEL_SLOT_FPM11':21,
  'PM2_IPSEC_TUNNEL_SLOT_FPM12':22,
  'PM2_OPT_ALLOW':57,
  'PM2_EMS_SN_CHECK_USE_GLOBAL':1,
  'PM2_EMS_SN_CHECK_ENABLE':2,
  'PM2_EMS_SN_CHECK_DISABLE':3,
  'PM2_REMOTE_GW_MATCH_ANY':1,
  'PM2_REMOTE_GW_MATCH_IPMASK':2,
  'PM2_REMOTE_GW_MATCH_IPRANGE':3,
  'PM2_REMOTE_GW_MATCH_GEOGRAPHY':4,
  'PM2_REMOTE_GW_MATCH_ZTNA':6,
  'PM2_REMOTE_GW_MATCH_IPPREFIX':5,
  'PM2_IPSEC_PROXYID_TYPE_SUBNET6':4,
  'PM2_IPSEC_PROXYID_TYPE_RANGE6':5,
  'PM2_IPSEC_PROXYID_TYPE_IP6':6,
  'PM2_IPSEC_PROXYID_TYPE_NAME6':7,
  'PM2_SSLVPN_APPTYPE_WEB':1,
  'PM2_SSLVPN_APPTYPE_TELNET':2,
  'PM2_SSLVPN_APPTYPE_SSH':3,
  'PM2_SSLVPN_APPTYPE_FTP':4,
  'PM2_SSLVPN_APPTYPE_SMB':6,
  'PM2_SSLVPN_APPTYPE_VNC':7,
  'PM2_SSLVPN_APPTYPE_RDP':8,
  'PM2_SERVICE_PROTOCOL_TYPE_ICMP':1,
  'PM2_SERVICE_PROTOCOL_TYPE_IP':2,
  'PM2_SERVICE_PROTOCOL_TYPE_TUS':5,
  'PM2_SERVICE_PROTOCOL_TYPE_ICMP6':6,
  'PM2_SERVICE_PROTOCOL_TYPE_HTTP':7,
  'PM2_SERVICE_PROTOCOL_TYPE_FTP':8,
  'PM2_SERVICE_PROTOCOL_TYPE_CONNECT':9,
  'PM2_SERVICE_PROTOCOL_TYPE_SOCKS':10,
  'PM2_SERVICE_PROTOCOL_TYPE_ALL':11,
  'PM2_SERVICE_PROTOCOL_TYPE_SOCKS_TCP':12,
  'PM2_SERVICE_PROTOCOL_TYPE_SOCKS_UDP':13,
  'PM2_SERVICE_PROTOCOL_TYPE_TCP_UDP_UDP_LITE_SCTP':15,
  'PM2_APP_SERVICE_TYPE_DISABLE':0,
  'PM2_APP_SERVICE_TYPE_APP_ID':1,
  'PM2_APP_SERVICE_TYPE_APP_CATEGORY':2,
  'PM2_OPT_CUSTOM_HEPLER_AUTO':1,
  'PM2_OPT_CUSTOM_HEPLER_DISABLE':0,
  'PM2_OPT_CUSTOM_HEPLER_FTP':2,
  'PM2_OPT_CUSTOM_HEPLER_TFTP':3,
  'PM2_OPT_CUSTOM_HEPLER_RAS':4,
  'PM2_OPT_CUSTOM_HEPLER_H323':5,
  'PM2_OPT_CUSTOM_HEPLER_TNS':6,
  'PM2_OPT_CUSTOM_HEPLER_MMS':7,
  'PM2_OPT_CUSTOM_HEPLER_SIP':8,
  'PM2_OPT_CUSTOM_HEPLER_PPTP':9,
  'PM2_OPT_CUSTOM_HEPLER_RTSP':10,
  'PM2_OPT_CUSTOM_HEPLER_DNSUDP':11,
  'PM2_OPT_CUSTOM_HEPLER_DNSTCP':12,
  'PM2_OPT_CUSTOM_HEPLER_PMAP':13,
  'PM2_OPT_CUSTOM_HEPLER_RSH':14,
  'PM2_OPT_CUSTOM_HEPLER_DCERPC':15,
  'PM2_OPT_CUSTOM_HEPLER_MGCP':16,
  'PM2_OPT_CUSTOM_HEPLER_GTPC':17,
  'PM2_OPT_CUSTOM_HEPLER_GTPU':18,
  'PM2_OPT_CUSTOM_HEPLER_GTPB':19,
  'PM2_OPT_CUSTOM_HEPLER_PFCP':20,
  'PM2_WDAY_NONE':256,
  'PM2_IPPOOL_TYPE_OVERLOAD':0,
  'PM2_IPPOOL_TYPE_ONETOONE':1,
  'PM2_IPPOOL_TYPE_FIXED_PORT_RANGE':2,
  'PM2_IPPOOL_TYPE_PORT_BLOCK_ALLOCATION':3,
  'PM2_IPPOOL_TYPE_CGN_RESOURCE_ALLOCATION':4,
  'PM2_VIP_STATIC_NAT':0,
  'PM2_VIP_LOAD_BALANCE':1,
  'PM2_VIP_ADVANCED_LOAD_BALANCE':3,
  'PM2_VIP_DNS_TRANSLATION':4,
  'PM2_VIP_FQDN':5,
  'PM2_VIP_ACCESS_PROXY':6,
  'PM2_VIP_PROTO_TCP':1,
  'PM2_VIP_PROTO_UDP':2,
  'PM2_VIP_PROTO_SCTP':3,
  'PM2_VIP_PROTO_ICMP':4,
  'PM2_APS_LDB_STATIC':0,
  'PM2_APS_LDB_ROUNDROBIN':1,
  'PM2_APS_LDB_WEIGHTED':2,
  'PM2_APS_LDB_LEAST_SESSION':3,
  'PM2_APS_LDB_LEAST_RTT':4,
  'PM2_APS_LDB_FIRST_ALIVE':5,
  'PM2_APS_LDB_HTTP_HOST':6,
  'PM2_APS_VIP_SSL_DH_768':0,
  'PM2_APS_VIP_SSL_DH_1024':1,
  'PM2_APS_VIP_SSL_DH_1536':2,
  'PM2_APS_VIP_SSL_DH_2048':3,
  'PM2_APS_VIP_SSL_DH_3072':4,
  'PM2_APS_VIP_SSL_DH_4096':5,
  'PM2_SSL_VER_SSL_3_0':1,
  'PM2_APS_VIP_SSL_SESSION_STATE_DISABLE':0,
  'PM2_APS_VIP_SSL_SESSION_STATE_TIME':1,
  'PM2_APS_VIP_SSL_SESSION_STATE_COUNT':2,
  'PM2_APS_VIP_SSL_SESSION_STATE_BOTH':3,
  'PM2_APS_VIP_HEALTH_CHECK_DISABLE':0,
  'PM2_APS_VIP_HEALTH_CHECK_ENABLE':1,
  'PM2_APS_VIP_HEALTH_CHECK_VIP':3,
  'PM2_APS_VIP_SVR_ACTIVE':0,
  'PM2_APS_VIP_SVR_STANDBY':1,
  'PM2_APS_VIP_SVR_DISABLE':2,
  'PM2_REALSERVERS_TYPE_IP':1,
  'PM2_REALSERVERS_TYPE_ADDRESS':2,
  'PM2_APS_VIP_SERVER_TYPE_HTTP':1,
  'PM2_APS_VIP_SERVER_TYPE_HTTPS':2,
  'PM2_APS_VIP_SERVER_TYPE_SSL':3,
  'PM2_APS_VIP_SERVER_TYPE_TCP':4,
  'PM2_APS_VIP_SERVER_TYPE_UDP':5,
  'PM2_APS_VIP_SERVER_TYPE_IP':6,
  'PM2_APS_VIP_SERVER_TYPE_IMAPS':7,
  'PM2_APS_VIP_SERVER_TYPE_POP3S':8,
  'PM2_APS_VIP_SERVER_TYPE_SMTPS':9,
  'PM2_APS_VIP_SERVER_TYPE_SSH':10,
  'PM2_APS_VIP_PERSIST_NONE':1,
  'PM2_APS_VIP_PERSIST_HTTP_COOKIE':3,
  'PM2_APS_VIP_PERSIST_SSL_SES_ID':4,
  'PM2_VIP_COOKIE_SHARE_SAME_IP':1,
  'PM2_VIP_COOKIE_SHARE_DISABLE':0,
  'PM2_SSL_MODE_HALF':1,
  'PM2_SSL_MODE_FULL':2,
  'PM2_OPT_SECURE':14,
  'PM2_SSL_CIPHER_LOW':3,
  'PM2_SSL_CIPHER_CUSTOM':4,
  'PM2_SSL_PFS_REQUIRE':0,
  'PM2_SSL_PFS_DENY':1,
  'PM2_SSL_PFS_ALLOW':2,
  'PM2_PORTMAP_TYPE_1TO1':0,
  'PM2_PORTMAP_TYPE_MTON':1,
  'PM2_TLS_RSA_WITH_RC4_128_MD5':1,
  'PM2_TLS_RSA_WITH_RC4_128_SHA':2,
  'PM2_TLS_RSA_WITH_DES_CBC_SHA':3,
  'PM2_TLS_RSA_WITH_3DES_EDE_CBC_SHA':4,
  'PM2_TLS_RSA_WITH_AES_128_CBC_SHA':5,
  'PM2_TLS_RSA_WITH_AES_256_CBC_SHA':6,
  'PM2_TLS_RSA_WITH_AES_128_CBC_SHA256':7,
  'PM2_TLS_RSA_WITH_AES_256_CBC_SHA256':8,
  'PM2_TLS_RSA_WITH_CAMELLIA_128_CBC_SHA':9,
  'PM2_TLS_RSA_WITH_CAMELLIA_256_CBC_SHA':10,
  'PM2_TLS_RSA_WITH_CAMELLIA_128_CBC_SHA256':11,
  'PM2_TLS_RSA_WITH_CAMELLIA_256_CBC_SHA256':12,
  'PM2_TLS_RSA_WITH_SEED_CBC_SHA':13,
  'PM2_TLS_RSA_WITH_ARIA_128_CBC_SHA256':14,
  'PM2_TLS_RSA_WITH_ARIA_256_CBC_SHA384':15,
  'PM2_TLS_DHE_RSA_WITH_DES_CBC_SHA':16,
  'PM2_TLS_DHE_RSA_WITH_3DES_EDE_CBC_SHA':17,
  'PM2_TLS_DHE_RSA_WITH_AES_128_CBC_SHA':18,
  'PM2_TLS_DHE_RSA_WITH_AES_256_CBC_SHA':19,
  'PM2_TLS_DHE_RSA_WITH_AES_128_CBC_SHA256':20,
  'PM2_TLS_DHE_RSA_WITH_AES_256_CBC_SHA256':21,
  'PM2_TLS_DHE_RSA_WITH_CAMELLIA_128_CBC_SHA':22,
  'PM2_TLS_DHE_RSA_WITH_CAMELLIA_256_CBC_SHA':23,
  'PM2_TLS_DHE_RSA_WITH_CAMELLIA_128_CBC_SHA256':24,
  'PM2_TLS_DHE_RSA_WITH_CAMELLIA_256_CBC_SHA256':25,
  'PM2_TLS_DHE_RSA_WITH_SEED_CBC_SHA':26,
  'PM2_TLS_DHE_RSA_WITH_ARIA_128_CBC_SHA256':27,
  'PM2_TLS_DHE_RSA_WITH_ARIA_256_CBC_SHA384':28,
  'PM2_TLS_ECDHE_RSA_WITH_RC4_128_SHA':29,
  'PM2_TLS_ECDHE_RSA_WITH_3DES_EDE_CBC_SHA':30,
  'PM2_TLS_ECDHE_RSA_WITH_AES_128_CBC_SHA':31,
  'PM2_TLS_ECDHE_RSA_WITH_AES_256_CBC_SHA':32,
  'PM2_TLS_ECDHE_RSA_WITH_CHACHA20_POLY1305_SHA256':33,
  'PM2_TLS_ECDHE_ECDSA_WITH_CHACHA20_POLY1305_SHA256':34,
  'PM2_TLS_DHE_RSA_WITH_CHACHA20_POLY1305_SHA256':35,
  'PM2_TLS_DHE_RSA_WITH_AES_128_GCM_SHA256':36,
  'PM2_TLS_DHE_RSA_WITH_AES_256_GCM_SHA384':37,
  'PM2_TLS_DHE_DSS_WITH_AES_128_CBC_SHA':38,
  'PM2_TLS_DHE_DSS_WITH_AES_256_CBC_SHA':39,
  'PM2_TLS_DHE_DSS_WITH_AES_128_CBC_SHA256':40,
  'PM2_TLS_DHE_DSS_WITH_AES_128_GCM_SHA256':41,
  'PM2_TLS_DHE_DSS_WITH_AES_256_CBC_SHA256':42,
  'PM2_TLS_DHE_DSS_WITH_AES_256_GCM_SHA384':43,
  'PM2_TLS_ECDHE_RSA_WITH_AES_128_CBC_SHA256':44,
  'PM2_TLS_ECDHE_RSA_WITH_AES_128_GCM_SHA256':45,
  'PM2_TLS_ECDHE_RSA_WITH_AES_256_CBC_SHA384':46,
  'PM2_TLS_ECDHE_RSA_WITH_AES_256_GCM_SHA384':47,
  'PM2_TLS_ECDHE_ECDSA_WITH_AES_128_CBC_SHA':48,
  'PM2_TLS_ECDHE_ECDSA_WITH_AES_128_CBC_SHA256':49,
  'PM2_TLS_ECDHE_ECDSA_WITH_AES_128_GCM_SHA256':50,
  'PM2_TLS_ECDHE_ECDSA_WITH_AES_256_CBC_SHA384':51,
  'PM2_TLS_ECDHE_ECDSA_WITH_AES_256_GCM_SHA384':52,
  'PM2_TLS_RSA_WITH_AES_128_GCM_SHA256':53,
  'PM2_TLS_RSA_WITH_AES_256_GCM_SHA384':54,
  'PM2_TLS_DHE_DSS_WITH_CAMELLIA_128_CBC_SHA':55,
  'PM2_TLS_DHE_DSS_WITH_CAMELLIA_256_CBC_SHA':56,
  'PM2_TLS_DHE_DSS_WITH_CAMELLIA_128_CBC_SHA256':57,
  'PM2_TLS_DHE_DSS_WITH_CAMELLIA_256_CBC_SHA256':58,
  'PM2_TLS_DHE_DSS_WITH_SEED_CBC_SHA':59,
  'PM2_TLS_DHE_DSS_WITH_ARIA_128_CBC_SHA256':60,
  'PM2_TLS_DHE_DSS_WITH_ARIA_256_CBC_SHA384':61,
  'PM2_TLS_ECDHE_RSA_WITH_ARIA_128_CBC_SHA256':62,
  'PM2_TLS_ECDHE_RSA_WITH_ARIA_256_CBC_SHA384':63,
  'PM2_TLS_ECDHE_ECDSA_WITH_ARIA_128_CBC_SHA256':64,
  'PM2_TLS_ECDHE_ECDSA_WITH_ARIA_256_CBC_SHA384':65,
  'PM2_TLS_DHE_DSS_WITH_3DES_EDE_CBC_SHA':66,
  'PM2_TLS_DHE_DSS_WITH_DES_CBC_SHA':67,
  'PM2_TLS_AES_128_GCM_SHA256':68,
  'PM2_TLS_AES_256_GCM_SHA384':69,
  'PM2_TLS_CHACHA20_POLY1305_SHA256':70,
  'PM2_TLS_TLS_ECDHE_ECDSA_WITH_AES_256_CBC_SHA':71,
  'PM2_ENC_ALG_MEDIUM':4,
  'PM2_ENC_ALG_CUSTOM':5,
  'PM2_ENC_ALG_CLIENT':6,
  'PM2_SSL_VER_CLIENT':16,
  'PM2_SSL_HPKP_DISABLE':0,
  'PM2_SSL_HPKP_ENABLE':1,
  'PM2_SSL_HPKP_REPORT_ONLY':2,
  'PM2_HTTP_SUPPORTED_MAX_VERSION_HTTP1':1,
  'PM2_HTTP_SUPPORTED_MAX_VERSION_HTTP2':2,
  'PM2_EMPTY_CERT_ACTION_ACCEPT':1,
  'PM2_EMPTY_CERT_ACTION_BLOCK':2,
  'PM2_EMPTY_CERT_ACTION_ACCEPT_UNMANAGEABLE':3,
  'PM2_ACTACCEPT':1,
  'PM2_ACTDENY':0,
  'PM2_ACTIPSEC':2,
  'PM2_ACTSSLVPN':3,
  'PM2_ACTREDIRECT':5,
  'PM2_ACTISOLATE':6,
  'PM2_LOGTRAFFIC_ENABLE':1,
  'PM2_LOGTRAFFIC_DISABLE':0,
  'PM2_LOGTRAFFIC_ALL':2,
  'PM2_LOGTRAFFIC_UTM':3,
  'PM2_OPT_DOMAIN':62,
  'PM2_OPT_POLICY':63,
  'PM2_OPT_USER':52,
  'PM2_BUFMED':64,
  'PM2_VM_POL_ALLSITES':1,
  'PM2_VM_POL_SELECTION':2,
  'PM2_PROFILE_T_SINGLE':0,
  'PM2_PROFILE_T_GROUP':1,
  'PM2_TOP10_APP':1,
  'PM2_TOP10_P2P_USER':2,
  'PM2_TOP10_MEDIA_USER':4,
  'PM2_SPOLAFSAE':3,
  'PM2_SPOLANTLM':2,
  'PM2_SPOLABASIC':0,
  'PM2_SPOLADIGEST':1,
  'PM2_SPOLAFORM':4,
  'PM2_SPOLAFSSO':5,
  'PM2_SPOLARSSO':6,
  'PM2_AV_PROT_SSH':65536,
  'PM2_CLI_REP_MODE_LEARN':0,
  'PM2_CLI_REP_MODE_MONITOR':1,
  'PM2_WEBCACHE_HTTPS_DISABLE':0,
  'PM2_WEBCACHE_HTTPS_SSL_SERVER':1,
  'PM2_WEBCACHE_HTTPS_ANY':2,
  'PM2_WEBCACHE_HTTPS_ENABLE':3,
  'PM2_WANOPT_AUTODET_ACTIVE':1,
  'PM2_WANOPT_AUTODET_PASSIVE':2,
  'PM2_WANOPT_AUTODET_OFF':3,
  'PM2_WANOPT_PASSIVE_DEFAULT':0,
  'PM2_WANOPT_PASSIVE_TP':1,
  'PM2_WANOPT_PASSIVE_NON_TP':2,
  'PM2_FW_IDENTITY_FROM_AUTH':0,
  'PM2_FW_IDENTITY_FROM_DEVICE':1,
  'PM2_FCC_DEV_WINDOWS_PC':1,
  'PM2_FCC_DEV_MAC':2,
  'PM2_FCC_DEV_IPHONE_IPAD':4,
  'PM2_FCC_DEV_ANDROID':8,
  'PM2_ACT_AUTH_NTLM':1,
  'PM2_ACT_AUTH_BASIC':2,
  'PM2_ACT_AUTH_DIGEST':3,
  'PM2_ACT_AUTH_FORM':4,
  'PM2_TCP_SESS_NOSYNC_ALL':0,
  'PM2_TCP_SESS_NOSYNC_DATA_ONLY':1,
  'PM2_TCP_SESS_NOSYNC_DISABLE':2,
  'PM2_REPUTATION_DIRECTION_SOURCE':1,
  'PM2_REPUTATION_DIRECTION_DESTINATION':2,
  'PM2_GEOIP_MATCH_PHYSICAL_LOCATION':0,
  'PM2_GEOIP_MATCH_REGISTERED_LOCATION':1,
  'PM2_FW_POLICY_TYPE_EXPLICIT_WEB':0,
  'PM2_FW_POLICY_TYPE_TRANSPARENT':1,
  'PM2_FW_POLICY_TYPE_EXPLICIT_FTP':2,
  'PM2_FW_POLICY_TYPE_SSH_TUNNEL':3,
  'PM2_FW_POLICY_TYPE_SSH':4,
  'PM2_FW_POLICY_TYPE_WANOPT':5,
  'PM2_FW_POLICY_TYPE_FORTIPROXY':6,
  'PM2_OPT_UTM':75,
  'PM2_OPT_SRC':41,
  'PM2_OPT_DST':42,
  'PM2_LOG_TRA_SERV_NUM':0,
  'PM2_LOG_TRA_SERV_NAME':1,
  'PM2_LOG_FULL_BLOCK':1,
  'PM2_LOG_FILTER_C_DLP_ARCHIVE':16384,
  'PM2_LOG_FILTER_C_NETSCAN':8192,
  'PM2_LOG_FILTER_C_IPS':65536,
  'PM2_LOG_FILTER_C_EMAILFILTER':2097152,
  'PM2_LOG_FILTER_C_CIFS':16777216,
  'PM2_LOG_FILTER_C_HTTP':268435456,
  'PM2_UPLOAD_SERVER_TYPE_FTP':0,
  'PM2_UPLOAD_SERVER_TYPE_OFTP':1,
  'PM2_UPLOAD_FORMAT_COMPACT':0,
  'PM2_UPLOAD_FORMAT_TEXT':1,
  'PM2_SQL_MODE_DAEMON':0,
  'PM2_SQL_MODE_SQLITE':1,
  'PM2_ALERTEMAIL_FILTER_MODE_CATEGORY':0,
  'PM2_ALERTEMAIL_FILTER_MODE_THRESHOLD':1,
  'PM2_ACCESS_LIST_ACTION_PERMIT':0,
  'PM2_ACCESS_LIST_ACTION_DENY':1,
  'PM2_ROUTER_ASPATH_DENY':1,
  'PM2_ROUTER_ASPATH_PERMIT':2,
  'PM2_ROUTER_KEY_CHAIN_ALGO_MD5':1,
  'PM2_ROUTER_KEY_CHAIN_ALGO_HMAC_SHA1':2,
  'PM2_ROUTER_KEY_CHAIN_ALGO_HMAC_SHA256':3,
  'PM2_ROUTER_KEY_CHAIN_ALGO_HMAC_SHA384':4,
  'PM2_ROUTER_KEY_CHAIN_ALGO_HMAC_SHA512':5,
  'PM2_ROUTER_KEY_CHAIN_ALGO_CMAC_AES128':6,
  'PM2_ROUTER_COMMUNITY_TYPE_STD':1,
  'PM2_ROUTER_COMMUNITY_TYPE_EXP':2,
  'PM2_ROUTER_COMMUNITY_ACT_DENY':1,
  'PM2_ROUTER_COMMUNITY_ACT_PERMIT':2,
  'PM2_ROUTE_MAP_ORIG_NONE':0,
  'PM2_ROUTE_MAP_ORIG_EGP':1,
  'PM2_ROUTE_MAP_ORIG_IGP':2,
  'PM2_ROUTE_MAP_ORIG_INCOMPLETE':3,
  'PM2_ROUTE_MAP_MATCH_TYPE_EXTERNAL_1':1,
  'PM2_ROUTE_MAP_MATCH_TYPE_EXTERNAL_2':2,
  'PM2_ROUTE_MAP_MATCH_TYPE_NONE':3,
  'PM2_ROUTE_MAP_MATCH_TYPE_EXTERNAL_TYPE1':4,
  'PM2_ROUTE_MAP_MATCH_TYPE_EXTERNAL_TYPE2':5,
  'PM2_ASPATH_ACT_PREPEND':0,
  'PM2_ASPATH_ACT_REPLACE':1,
  'PM2_RIP_VERSION_1':1,
  'PM2_RIP_VERSION_2':2,
  'PM2_RIP_AUTH_MODE_NONE':0,
  'PM2_RIP_AUTH_MODE_MD5':1,
  'PM2_RIP_AUTH_MODE_TEXT':2,
  'PM2_SPLIT_HORIZON_POISONED':0,
  'PM2_SPLIT_HORIZON_REGULAR':1,
  'PM2_DST_TYPE_IPMASK':1,
  'PM2_DST_TYPE_ADDRNAME':2,
  'PM2_DST_TYPE_SERVICE_ID':3,
  'PM2_DST_TYPE_SERVICE_CUSTOM':4,
  'PM2_OSPF_AREA_BORDER_ROUTER_TYPE_CISCO':0,
  'PM2_OSPF_AREA_BORDER_ROUTER_TYPE_IBM':1,
  'PM2_OSPF_AREA_BORDER_ROUTER_TYPE_SHORTCUT':2,
  'PM2_OSPF_AREA_BORDER_ROUTER_TYPE_STANDARD':3,
  'PM2_OSFP_DEFAULT_INFO_ORIGINATE_ENABLE':1,
  'PM2_OSFP_DEFAULT_INFO_ORIGINATE_ALWAYS':2,
  'PM2_OSFP_DEFAULT_INFO_ORIGINATE_DISABLE':0,
  'PM2_OSPF_RESTART_MODE_NONE':0,
  'PM2_OSPF_RESTART_MODE_LLS':1,
  'PM2_OSPF_RESTART_MODE_GRACEFUL_RESTART':2,
  'PM2_OSPF_AREA_SHORTCUT_DISABLE':0,
  'PM2_OSPF_AREA_SHORTCUT_ENABLE':1,
  'PM2_OSPF_AREA_SHORTCUT_DEFAULT':2,
  'PM2_OSPF_AREA_AUTH_NONE':0,
  'PM2_OSPF_AREA_AUTH_TEXT':1,
  'PM2_OSPF_AREA_AUTH_MD5':2,
  'PM2_OSPF_AREA_AUTH_MESSAGE_DIGEST':3,
  'PM2_OSPF_AREA_NSSA_TRANS_ROLE_CANDIDATE':0,
  'PM2_OSPF_AREA_NSSA_TRANS_ROLE_NEVER':1,
  'PM2_OSPF_AREA_NSSA_TRANS_ROLE_ALWAYS':2,
  'PM2_OSPF_AREA_TYPE_REGULAR':0,
  'PM2_OSPF_AREA_TYPE_NSSA':1,
  'PM2_OSPF_AREA_TYPE_STUB':2,
  'PM2_NSSA_D_INFO_ORIG_ENABLE':1,
  'PM2_NSSA_D_INFO_ORIG_DISABLE':0,
  'PM2_NSSA_D_INFO_ORIG_ALWAYS':2,
  'PM2_OSPF_INTERFACE_NETWORK_TYPE_BROADCAST':0,
  'PM2_OSPF_INTERFACE_NETWORK_TYPE_NON_BROADCAST':1,
  'PM2_OSPF_INTERFACE_NETWORK_TYPE_PTP':2,
  'PM2_OSPF_INTERFACE_NETWORK_TYPE_PTM':3,
  'PM2_OSPF_INTERFACE_NETWORK_TYPE_PTMNB':4,
  'PM2_OSPF_BFD_MODE_GLOBAL':0,
  'PM2_OSPF_BFD_MODE_ENABLE':1,
  'PM2_OSPF_BFD_MODE_DISABLE':2,
  'PM2_REDISTRIBUTE_TYPE_CONNECTED':0,
  'PM2_REDISTRIBUTE_TYPE_STATIC':1,
  'PM2_REDISTRIBUTE_TYPE_RIP':2,
  'PM2_UINT_MAX':4294967295,
  'PM2_BGP_NGBR_ATTR_ASPATH':1,
  'PM2_BGP_NGBR_ATTR_MED':2,
  'PM2_BGP_NGBR_ATTR_NEXTHOP':4,
  'PM2_BGP_NGBR_CAP_ORF_NONE':0,
  'PM2_BGP_NGBR_CAP_ORF_RECV':2,
  'PM2_BGP_NGBR_CAP_ORF_SEND':1,
  'PM2_BGP_NGBR_CAP_ORF_BOTH':3,
  'PM2_BGP_NGBR_SEND_COMMUNITY_STD':1,
  'PM2_BGP_NGBR_SEND_COMMUNITY_EXT':2,
  'PM2_BGP_NGBR_SEND_COMMUNITY_BOTH':3,
  'PM2_BGP_NGBR_SEND_COMMUNITY_DISABLE':0,
  'PM2_COND_TYPE_EXIST':0,
  'PM2_COND_TYPE_NON_EXIST':1,
  'PM2_BGP_NGBR_ADDR_PATH_SEND':0,
  'PM2_BGP_NGBR_ADDR_PATH_RECV':1,
  'PM2_BGP_NGBR_ADDR_PATH_BOTH':2,
  'PM2_BGP_NGBR_ADDR_PATH_DISABLE':3,
  'PM2_OPT_GLOBAL':50,
  'PM2_TAG_RESOLVE_MODE_DISABLE':0,
  'PM2_TAG_RESOLVE_MODE_PREFERRED':1,
  'PM2_TAG_RESOLVE_MODE_MERGE':2,
  'PM2_TAG_RESOLVE_MODE_MERGE_ALL':3,
  'PM2_BGP_VRF_ROLE_STANDALONE':1,
  'PM2_BGP_VRF_ROLE_CE':2,
  'PM2_BGP_VRF_ROLE_PE':3,
  'PM2_PIM_REG_SOURCE_AUTO':0,
  'PM2_PIM_REG_SOURCE_INTF':2,
  'PM2_PIM_REG_SOURCE_IP':1,
  'PM2_PIM_SPARSE_MODE':1,
  'PM2_PIM_DENSE_MODE':2,
  'PM2_IGMP_V3':3,
  'PM2_IGMP_V2':2,
  'PM2_IGMP_V1':1,
  'PM2_MODEM_MODE_STANDALONE':0,
  'PM2_MODEM_MODE_REDUNDANT':1,
  'PM2_PEER_MODEM_GENERIC':0,
  'PM2_PEER_MODEM_ACTIONTEC':1,
  'PM2_PEER_MODEM_ASCEND_TNT':2,
  'PM2_MODEM_ACCOUNT_RELATION_EQUAL':0,
  'PM2_MODEM_ACCOUNT_RELATION_FALLBACK':1,
  'PM2_MODEM_DEV_INTERNAL_MODEM':0,
  'PM2_MODEM_DEV_PCMCIA_WIRELESS':1,
  'PM2_PP_AUTH_MSCHAP':128,
  'PM2_WL_MODE_AP':0,
  'PM2_WL_MODE_CLIENT':1,
  'PM2_WL_MODE_SCAN':2,
  'PM2_WL_BAND_11A':2,
  'PM2_WL_BAND_11B':1,
  'PM2_WL_BAND_11G':4,
  'PM2_WL_BAND_11N':8,
  'PM2_WL_BAND_11N_5G':32,
  'PM2_WL_BAND_11G_ONLY':32772,
  'PM2_WL_BAND_11N_ONLY':32776,
  'PM2_WL_BAND_11N_5G_ONLY':32800,
  'PM2_WL_BAND_11NG_ONLY':32780,
  'PM2_WL_BAND_11AC':16,
  'PM2_WL_BAND_11ACN_ONLY':32792,
  'PM2_WL_BAND_11AC_ONLY':32784,
  'PM2_WL_GEO_WORLD':0,
  'PM2_WL_GEO_AMERICAS':1,
  'PM2_WL_GEO_EMEA':2,
  'PM2_WL_GEO_ISRAEL':3,
  'PM2_WL_GEO_JAPAN':4,
  'PM2_BR_SIMPLE_SWITCH':1,
  'PM2_BR_SIMPLE_HUB':2,
  'PM2_BR_HARD_SWITCH':3,
  'PM2_INTRA_SWITCH_IMPLICIT':0,
  'PM2_INTRA_SWITCH_EXPLICIT':1,
  'PM2_IPS_SENSOR_DISABLE':0,
  'PM2_IPS_SENSOR_ENABLE':1,
  'PM2_IPS_SENSOR_DEFAULT':4,
  'PM2_IPS_SENSOR_PASS':2,
  'PM2_IPS_SENSOR_BLOCK':3,
  'PM2_IPS_SENSOR_RESET':5,
  'PM2_IPS_BAN_NONE':0,
  'PM2_IPS_BAN_ATTACKER':1,
  'PM2_IPS_BAN_BOTH':3,
  'PM2_IPS_BAN_PORT':4,
  'PM2_ACTION_PASS':1,
  'PM2_ACTION_BLOCK':2,
  'PM2_ACTION_RESET':4,
  'PM2_ACTION_DEFAULT':5,
  'PM2_RATE_TRACK_NONE':0,
  'PM2_RATE_TRACK_SRC_IP':1,
  'PM2_RATE_TRACK_DEST_IP':2,
  'PM2_RATE_TRACK_DHCP_CLIENT_MAC':3,
  'PM2_RATE_TRACK_DNS_DOMAIN':4,
  'PM2_OPT_DROP':68,
  'PM2_TAC_PLUS_AUTHEN_TYPE_MSCHAP':5,
  'PM2_TAC_PLUS_AUTHEN_TYPE_CHAP':3,
  'PM2_TAC_PLUS_AUTHEN_TYPE_PAP':2,
  'PM2_TAC_PLUS_AUTHEN_TYPE_ASCII':1,
  'PM2_TAC_PLUS_AUTHEN_TYPE_AUTO':0,
  'PM2_AUTHD_HTTP':1,
  'PM2_AUTHD_HTTPS':2,
  'PM2_AUTHD_FTP':4,
  'PM2_AUTHD_TELNET':8,
  'PM2_TIMEOUT_IDLE_TIMEOUT':0,
  'PM2_TIMEOUT_HARD_TIMEOUT':1,
  'PM2_TIMEOUT_NEW_SESSION':2,
  'PM2_TIMEOUT_IGNORE_TIMEOUT':3,
  'PM2_OPT_AUTH_ON_DEMAND_ALWAYS':1,
  'PM2_OPT_AUTH_ON_DEMAND_IMPLICITLY':2,
  'PM2_AUTH_SSL_SIGALGS_NO_RSA_PSS':1,
  'PM2_AUTH_SSL_SIGALGS_ALL':2,
  'PM2_APS_LDB_MT_PING':1,
  'PM2_APS_LDB_MT_TCP':2,
  'PM2_APS_LDB_MT_HTTP':3,
  'PM2_APS_LDB_MT_PASSIVE_SIP':6,
  'PM2_APS_LDB_MT_HTTPS':7,
  'PM2_APS_LDB_MT_DNS':8,
  'PM2_FW_DNS_PROTOCOL_UDP':1,
  'PM2_FW_DNS_PROTOCOL_TCP':2,
  'PM2_DOS_SENSOR_PASS':0,
  'PM2_DOS_SENSOR_BLOCK':1,
  'PM2_DOS_SENSOR_PROXY':2,
  'PM2_BANDWIDTH_100Mbps':100,
  'PM2_BANDWIDTH_200Mbps':200,
  'PM2_BANDWIDTH_300Mbps':300,
  'PM2_BANDWIDTH_400Mbps':400,
  'PM2_BANDWIDTH_500Mbps':500,
  'PM2_BANDWIDTH_600Mbps':600,
  'PM2_BANDWIDTH_700Mbps':700,
  'PM2_BANDWIDTH_800Mbps':800,
  'PM2_BANDWIDTH_900Mbps':900,
  'PM2_BANDWIDTH_1Gbps':1000,
  'PM2_BANDWIDTH_2Gbps':2000,
  'PM2_BANDWIDTH_4Gbps':4000,
  'PM2_BANDWIDTH_8Gbps':8000,
  'PM2_BANDWIDTH_10Gbps':10000,
  'PM2_NPU_HOST_MODE_BIDIR':0,
  'PM2_NPU_HOST_MODE_SHORTCUT':1,
  'PM2_GTP_ENHANCED_CPU_RANGE_0':0,
  'PM2_GTP_ENHANCED_CPU_RANGE_1':1,
  'PM2_GTP_ENHANCED_CPU_RANGE_2':2,
  'PM2_SW_NP_BANDWIDTH_0G':0,
  'PM2_SW_NP_BANDWIDTH_2G':2,
  'PM2_SW_NP_BANDWIDTH_4G':4,
  'PM2_SW_NP_BANDWIDTH_5G':5,
  'PM2_SW_NP_BANDWIDTH_6G':6,
  'PM2_SW_NP_BANDWIDTH_7G':7,
  'PM2_SW_NP_BANDWIDTH_8G':8,
  'PM2_SW_NP_BANDWIDTH_9G':9,
  'PM2_PER_SESSION_ACCNT_DISABLE':1,
  'PM2_PER_SESSION_ACCNT_ENABLE_BY_LOG':2,
  'PM2_PER_SESSION_ACCNT_ALL_ENABLE':3,
  'PM2_PER_SESSION_ACCNT_TRAFFIC_LOG_ONLY':4,
  'PM2_PER_SESSION_ACCNT_ENABLE':0,
  'PM2_OPT_SW_NP_QOS_MODE_NONE':1,
  'PM2_OPT_SW_NP_QOS_MODE_PRIORITY':2,
  'PM2_OPT_SW_NP_QOS_MODE_BANDWIDTH':3,
  'PM2_NPU_QOS_MODE_DISABLE':0,
  'PM2_NPU_QOS_MODE_PRIORITY':1,
  'PM2_NPU_QOS_MODE_ROUND_ROBIN':2,
  'PM2_SYS_NP6_ACT_DROP':1,
  'PM2_SYS_NP6_ACT_TRAP_TO_HOST':2,
  'PM2_SYS_NP6_ACT_ALLOW':0,
  'PM2_GRE_CSUM_ERR_DROP':1,
  'PM2_GRE_CSUM_ERR_TRAP_TO_HOST':2,
  'PM2_SCTP_CRC_ERR_DROP':1,
  'PM2_SCTP_CRC_ERR_TRAP_TO_HOST':2,
  'PM2_SCTP_CLEN_ERR_DROP':1,
  'PM2_SCTP_CLEN_ERR_TRAP_TO_HOST':2,
  'PM2_SCTP_L4LEN_ERR_DROP':1,
  'PM2_SCTP_L4LEN_ERR_TRAP_TO_HOST':2,
  'PM2_UESP_MINLEN_ERR_DROP':1,
  'PM2_UESP_MINLEN_ERR_TRAP_TO_HOST':2,
  'PM2_CAPWAP_MINLEN_ERR_DROP':1,
  'PM2_CAPWAP_MINLEN_ERR_TRAP_TO_HOST':2,
  'PM2_VXLAN_MINLEN_ERR_DROP':1,
  'PM2_VXLAN_MINLEN_ERR_TRAP_TO_HOST':2,
  'PM2_NVGRE_MINLEN_ERR_DROP':1,
  'PM2_NVGRE_MINLEN_ERR_TRAP_TO_HOST':2,
  'PM2_GTPU_PLEN_ERR_DROP':1,
  'PM2_GTPU_PLEN_ERR_TRAP_TO_HOST':2,
  'PM2_TCP_HLENVSL4LEN_ERR_DROP':1,
  'PM2_TCP_HLENVSL4LEN_ERR_TRAP_TO_HOST':2,
  'PM2_SCTP_CSUM_ERR_ALLOW':1,
  'PM2_SCTP_CSUM_ERR_DROP':2,
  'PM2_SCTP_CSUM_ERR_TRAP_TO_HOST':3,
  'PM2_PORT_SELECT_SRC_IP':3,
  'PM2_PORT_SELECT_DST_IP':4,
  'PM2_PORT_SELECT_SRC_DST_IP':5,
  'PM2_MCAST_SESS_ACCOUNT_ENABLE':1,
  'PM2_MCAST_SESS_ACCOUNT_DISABLE':0,
  'PM2_MCAST_SESS_ACCOUNT_TPE_BASED':3,
  'PM2_MCAST_SESS_ACCOUNT_SESSION_BASED':2,
  'PM2_IPSEC_OB_NP_SEL_RR':1,
  'PM2_IPSEC_OB_NP_SEL_PACKET':2,
  'PM2_IPSEC_OB_NP_SEL_HASH':3,
  'PM2_QOS_TYPE_POLICING':1,
  'PM2_QOS_TYPE_SHAPING':2,
  'PM2_QOS_TYPE_POLICING_ENHANCED':3,
  'PM2_NPU_DOS_SYNPROXY_MODE_SYNACK2ACK':1,
  'PM2_NPU_DOS_SYNPROXY_MODE_PASS_SYNACK':2,
  'PM2_NPU_HASH_CONFIG_5_TUPLE':1,
  'PM2_NPU_HASH_CONFIG_SRC_IP':2,
  'PM2_NPU_HASH_CONFIG_SRC_DST_IP':3,
  'PM2_DSW_DTS_PROFILE_ACTION_WAIT':1,
  'PM2_DSW_DTS_PROFILE_ACTION_DROP':2,
  'PM2_DSW_DTS_PROFILE_ACTION_DROP_TMR_0':3,
  'PM2_DSW_DTS_PROFILE_ACTION_DROP_TMR_1':4,
  'PM2_DSW_DTS_PROFILE_ACTION_ENQUE':5,
  'PM2_DSW_DTS_PROFILE_ACTION_ENQUE_0':6,
  'PM2_DSW_DTS_PROFILE_ACTION_ENQUE_1':7,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_EIF0':0,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_EIF1':1,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_EIF2':2,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_EIF3':3,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_EIF4':4,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_EIF5':5,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_EIF6':6,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_EIF7':7,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_HTX0':9,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_HTX1':10,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_SSE0':11,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_SSE1':12,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_SSE2':13,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_SSE3':14,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_RLT':15,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_DFR':16,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_IPSECI':17,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_IPSECO':18,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_IPTI':19,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_IPTO':20,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_VEP0':21,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_VEP2':22,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_VEP4':23,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_VEP6':24,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_IVS':25,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_L2TI1':26,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_L2TO':27,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_L2TI0':28,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_PLE':29,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_SPATH':30,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_QTM':34,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_HRX':8,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_SYNK':31,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_NSS':32,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_TSK':33,
  'PM2_DSW_QUEUE_DTS_PROFILE_IPORT_L2TO_TYPO':35,
  'PM2_NP_QUEUES_PROFILE_TYPE_COS':1,
  'PM2_NP_QUEUES_PROFILE_TYPE_DSCP':2,
  'PM2_NP_QUEUES_PROFILE_COS_QUEUE0':0,
  'PM2_NP_QUEUES_PROFILE_COS_QUEUE1':1,
  'PM2_NP_QUEUES_PROFILE_COS_QUEUE2':2,
  'PM2_NP_QUEUES_PROFILE_COS_QUEUE3':3,
  'PM2_NP_QUEUES_PROFILE_COS_QUEUE4':4,
  'PM2_NP_QUEUES_PROFILE_COS_QUEUE5':5,
  'PM2_NP_QUEUES_PROFILE_COS_QUEUE6':6,
  'PM2_NP_QUEUES_PROFILE_COS_QUEUE7':7,
  'PM2_NP_QUEUES_SCHEDULER_MODE_NONE':0,
  'PM2_NP_QUEUES_SCHEDULER_MODE_PRIORITY':1,
  'PM2_NP_QUEUES_SCHEDULER_MODE_ROUND_ROBIN':2,
  'PM2_NPU_SW_EH_HASH_COMPUTATION_XOR16':1,
  'PM2_NPU_SW_EH_HASH_COMPUTATION_XOR8':2,
  'PM2_NPU_SW_EH_HASH_COMPUTATION_XOR4':3,
  'PM2_NPU_SW_EH_HASH_COMPUTATION_CRC16':4,
  'PM2_CONN_DATA':10,
  'PM2_CONN_IDLE':0,
  'PM2_CONN_DEDICATED':11,
  'PM2_QTM_BUF_MODE_6CH':1,
  'PM2_QTM_BUF_MODE_4CH':2,
  'PM2_ISF_NP_RX_TR_DISTR_PORT_FLOW':1,
  'PM2_ISF_NP_RX_TR_DISTR_ROUND_ROBIN':2,
  'PM2_ISF_NP_RX_TR_DISTR_RANDOMIZED':3,
  'PM2_ULL_PORT_MODE_10G':1,
  'PM2_ULL_PORT_MODE_25G':2,
  'PM2_OPT_FORWARD':47,
  'PM2_PRP_SESSION_CLEAR_MODE_BLOCKING':1,
  'PM2_PRP_SESSION_CLEAR_MODE_NON_BLOCKING':2,
  'PM2_PRP_SESSION_CLEAR_MODE_DO_NOT_CLEAR':3,
  'PM2_NPU_SW_TR_HASH_TCP_UDP_PORT_INCLUDE':1,
  'PM2_NPU_SW_TR_HASH_TCP_UDP_PORT_EXCLUDE':2,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_DISABLE':1,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_32KB':2,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_64KB':3,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_128KB':4,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_256KB':5,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_512KB':6,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_1MB':7,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_2MB':8,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_4MB':9,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_8MB':10,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_16MB':11,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_32MB':12,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_64MB':13,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_128MB':14,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_256MB':15,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_512MB':16,
  'PM2_IPSEC_THROUGHPUT_MSG_FREQUENCY_1GB':17,
  'PM2_NPU_TCAM_TYPE_L2_SRC_TC':1,
  'PM2_NPU_TCAM_TYPE_L2_TGT_TC':2,
  'PM2_NPU_TCAM_TYPE_L2_SRC_MIR':3,
  'PM2_NPU_TCAM_TYPE_L2_TGT_MIR':4,
  'PM2_NPU_TCAM_TYPE_L2_SRC_ACT':5,
  'PM2_NPU_TCAM_TYPE_L2_TGT_ACT':6,
  'PM2_NPU_TCAM_TYPE_IPV4_SRC_TC':7,
  'PM2_NPU_TCAM_TYPE_IPV4_TGT_TC':8,
  'PM2_NPU_TCAM_TYPE_IPV4_SRC_MIR':9,
  'PM2_NPU_TCAM_TYPE_IPV4_TGT_MIR':10,
  'PM2_NPU_TCAM_TYPE_IPV4_SRC_ACT':11,
  'PM2_NPU_TCAM_TYPE_IPV4_TGT_ACT':12,
  'PM2_NPU_TCAM_TYPE_IPV6_SRC_TC':13,
  'PM2_NPU_TCAM_TYPE_IPV6_TGT_TC':14,
  'PM2_NPU_TCAM_TYPE_IPV6_SRC_MIR':15,
  'PM2_NPU_TCAM_TYPE_IPV6_TGT_MIR':16,
  'PM2_NPU_TCAM_TYPE_IPV6_SRC_ACT':17,
  'PM2_NPU_TCAM_TYPE_IPV6_TGT_ACT':18,
  'PM2_NPU_TCAM_DATA_VALID':1,
  'PM2_NPU_TCAM_DATA_INVALID':0,
  'PM2_NSS_THREADS_OPTION_4T_EIF':1,
  'PM2_NSS_THREADS_OPTION_4T_NOEIF':2,
  'PM2_NSS_THREADS_OPTION_2T':3,
  'PM2_SPA_PORT_SELECT_MODE_RANDOM':1,
  'PM2_SPA_PORT_SELECT_MODE_DIRECT':2,
  'PM2_FIPS_CC_STATUS_ENABLE':1,
  'PM2_FIPS_CC_STATUS_DISABLE':0,
  'PM2_FIPS_CC_STATUS_FIPS_CIPHERS':2,
  'PM2_CC_ERR_FLAG_MODE_ENABLE':1,
  'PM2_CC_ERR_FLAG_EXIT_READY':2,
  'PM2_CC_ERR_CAUSE_NONE':0,
  'PM2_CC_ERR_CAUSE_MEMORY':1,
  'PM2_CC_ERR_CAUSE_DISK':2,
  'PM2_CC_ERR_CAUSE_SYSLOG':3,
  'PM2_OPT_DYNAMIC':18,
  'PM2_IP_TYPE_IPV6':1,
  'PM2_IP_TYPE_IPV4':2,
  'PM2_IP_TYPE_BOTH':3,
  'PM2_BANNED_CIPHER_MD5':524288,
  'PM2_AMC_SLOT_OBJ_TYPE_FA4':2,
  'PM2_AMC_SLOT_OBJ_TYPE_NONE':0,
  'PM2_AMC_SLOT_OBJ_TYPE_AUTO':1,
  'PM2_AMC_SLOT_OBJ_TYPE_SCSI_HDD':6,
  'PM2_AMC_SLOT_OBJ_TYPE_FX2':10,
  'PM2_AMC_SLOT_OBJ_TYPE_CX4':11,
  'PM2_AMC_SLOT_OBJ_TYPE_CE4':12,
  'PM2_AMC_SLOT_OBJ_TYPE_ET4':13,
  'PM2_AMC_SLOT_OBJ_TYPE_FB8':3,
  'PM2_AMC_SLOT_OBJ_TYPE_XA2':4,
  'PM2_AMC_SLOT_OBJ_TYPE_FE8':7,
  'PM2_AMC_SLOT_OBJ_TYPE_XE2':8,
  'PM2_AMC_SLOT_OBJ_TYPE_XD4':9,
  'PM2_FORTIMANAGER_TYPE_FM':1,
  'PM2_FORTIMANAGER_TYPE_FDS':2,
  'PM2_FORTIMANAGER_TYPE_NONE':3,
  'PM2_CENT_MGMT_MODE_NORMAL':0,
  'PM2_CENT_MGMT_MODE_BACKUP':1,
  'PM2_SYS_CMGMT_SERVER_TYPE_UPDATE':1,
  'PM2_SYS_CMGMT_SERVER_TYPE_RATING':2,
  'PM2_SYS_CMGMT_SERVER_TYPE_IOT_QUERY':4,
  'PM2_SYS_CMGMT_SERVER_TYPE_IOT_COLLECT':8,
  'PM2_SYS_CMGMT_SERVER_TYPE_VPATCH_QUERY':16,
  'PM2_LTEFW_UPGRADE_FREQ_HOUR':1,
  'PM2_LTEFW_UPGRADE_FREQ_12HOUR':2,
  'PM2_LTEFW_UPGRADE_FREQ_DAY':3,
  'PM2_LTEFW_UPGRADE_FREQ_WEEK':4,
  'PM2_WCCP_FORWARD_GRE':1,
  'PM2_WCCP_FORWARD_L2':2,
  'PM2_WCCP_FORWARD_ANY':3,
  'PM2_WCCP_RETURN_GRE':1,
  'PM2_WCCP_RETURN_L2':2,
  'PM2_WCCP_RETURN_ANY':3,
  'PM2_WCCP_ASSIGNMENT_HASH':1,
  'PM2_WCCP_ASSIGNMENT_MASK':2,
  'PM2_WCCP_ASSIGNMENT_ANY':3,
  'PM2_WCCP2_SERVICE_AUTO':2,
  'PM2_WCCP2_SERVICE_STANDARD':0,
  'PM2_WCCP2_SERVICE_DYNAMIC':1,
  'PM2_WCCP_SERVICE_SRC_IP_HASH':1,
  'PM2_WCCP_SERVICE_DST_IP_HASH':2,
  'PM2_WCCP_SERVICE_SRC_PORT_HASH':4,
  'PM2_WCCP_SERVICE_DST_PORT_HASH':8,
  'PM2_WCCP_SERVICE_PORTS_DEFINED_HASH':16,
  'PM2_WCCP_SERVICE_PORTS_SOURCE_HASH':32,
  'PM2_BUCKET_WCCP_V2':1,
  'PM2_BUCKET_CISCO_IMPL':0,
  'PM2_PORTS_DEFINED_SRC':1,
  'PM2_PORTS_DEFINED_DST':2,
  'PM2_OPT_SYS_WCCP_SERVER_TYPE_FORWARD':1,
  'PM2_OPT_SYS_WCCP_SERVER_TYPE_PROXY':2,
  'PM2_SNMPv3_SEC_LVL_NOAUTHNOPRIV':1,
  'PM2_SNMPv3_SEC_LVL_AUTHNOPRIV':2,
  'PM2_SNMPv3_SEC_LVL_AUTHPRIV':3,
  'PM2_SNMPv3_AUTH_PROTO_MD5':1,
  'PM2_SNMPv3_AUTH_PROTO_SHA':2,
  'PM2_SNMPv3_AUTH_PROTO_SHA224':3,
  'PM2_SNMPv3_AUTH_PROTO_SHA256':4,
  'PM2_SNMPv3_AUTH_PROTO_SHA384':5,
  'PM2_SNMPv3_AUTH_PROTO_SHA512':6,
  'PM2_SNMPv3_RPIV_PROTO_AES':1,
  'PM2_SNMPv3_PRIV_PROTO_DES':2,
  'PM2_SNMPv3_PRIV_PROTO_AES256':3,
  'PM2_SNMPv3_PRIV_PROTO_AES256CISCO':4,
  'PM2_HA_SESSION_PICKUP_CONNECTIVITY':1,
  'PM2_HA_SESSION_PICKUP_SECURITY':2,
  'PM2_APP_POPULARITY_1':1,
  'PM2_APP_POPULARITY_2':2,
  'PM2_APP_POPULARITY_3':4,
  'PM2_APP_POPULARITY_4':8,
  'PM2_APP_POPULARITY_5':16,
  'PM2_P2P_BLACKLIST_SKYPE':1,
  'PM2_P2P_BLACKLIST_EDONKEY':2,
  'PM2_P2P_BLACKLIST_BITTORRENT':4,
  'PM2_APP_LIST_OPT_ALLOW_DNS':1,
  'PM2_APP_LIST_OPT_ALLOW_ICMP':2,
  'PM2_APP_LIST_OPT_ALLOW_HTTP':4,
  'PM2_APP_LIST_OPT_ALLOW_SSL':8,
  'PM2_APP_LIST_OPT_ALLOW_QUIC':16,
  'PM2_NETWORK_SERVICE_HTTP':1,
  'PM2_NETWORK_SERVICE_SSH':2,
  'PM2_NETWORK_SERVICE_TELNET':4,
  'PM2_NETWORK_SERVICE_FTP':8,
  'PM2_NETWORK_SERVICE_DNS':16,
  'PM2_NETWORK_SERVICE_SMTP':32,
  'PM2_NETWORK_SERVICE_POP3':64,
  'PM2_NETWORK_SERVICE_IMAP':128,
  'PM2_NETWORK_SERVICE_SNMP':256,
  'PM2_NETWORK_SERVICE_NNTP':512,
  'PM2_NETWORK_SERVICE_HTTPS':1024,
  'PM2_DLP_PROTO_EMAIL':15,
  'PM2_DLP_PROTO_HTTP':3,
  'PM2_DLP_PROTO_FTP':6,
  'PM2_DLP_PROTO_NNTP':9,
  'PM2_DLP_PROTO_IM':10,
  'PM2_DLP_PROTO_HTTPS':21,
  'PM2_DLP_PROTO_SESSION_CTRL':22,
  'PM2_DLP_PROTO_V_HTTP_GET':32,
  'PM2_DLP_PROTO_V_HTTP_POST':16,
  'PM2_DLP_PROTO_V_FTP_GET':256,
  'PM2_DLP_PROTO_V_FTP_PUT':128,
  'PM2_DLP_PROTO_V_IM_AIM':2048,
  'PM2_DLP_PROTO_V_IM_ICQ':4096,
  'PM2_DLP_PROTO_V_IM_MSN':8192,
  'PM2_DLP_PROTO_V_IM_YM':16384,
  'PM2_DLP_PROTO_V_HTTPS_POST':524288,
  'PM2_DLP_PROTO_V_HTTPS_GET':1048576,
  'PM2_DLP_PROTO_V_SIP':8388608,
  'PM2_DLP_PROTO_V_SIMPLE':16777216,
  'PM2_DLP_PROTO_V_SCCP':33554432,
  'PM2_DLP_PROTO_V_FTPS_GET':67108864,
  'PM2_DLP_PROTO_V_FTPS_PUT':134217728,
  'PM2_DLP_FIELD_BODY':0,
  'PM2_DLP_FIELD_SUBJECT':1,
  'PM2_DLP_FIELD_SENDER':2,
  'PM2_DLP_FIELD_RECEIVER':3,
  'PM2_DLP_FIELD_ATTACHMENT_SIZE':4,
  'PM2_DLP_FIELD_ATTACHMENT_TYPE':5,
  'PM2_DLP_FIELD_URL':6,
  'PM2_DLP_FIELD_TRANSFER_SIZE':7,
  'PM2_DLP_FIELD_COOKIE_CONTENT':8,
  'PM2_DLP_FIELD_CGI_PARAMETERS':9,
  'PM2_DLP_FIELD_HEADER':10,
  'PM2_DLP_FIELD_HOSTNAME':11,
  'PM2_DLP_FIELD_SERVER':12,
  'PM2_DLP_FIELD_FILE_TYPE':13,
  'PM2_DLP_FIELD_FILE_TEXT':14,
  'PM2_DLP_FIELD_FILE_BINARY_PATTERN':15,
  'PM2_DLP_FIELD_USER':16,
  'PM2_DLP_FIELD_USER_GROUP':17,
  'PM2_DLP_FIELD_ATTACHMENT_TEXT':18,
  'PM2_DLP_FIELD_ENCRYPTED':19,
  'PM2_DLP_FIELD_ALWAYS':20,
  'PM2_DLP_FIELD_FILE_BYTES':21,
  'PM2_DLP_FIELD_REGEXP':22,
  'PM2_DLP_FIELD_FILE_SIZE':23,
  'PM2_DLP_FIELD_FINGERPRINT':24,
  'PM2_DLP_OPERATER_EQUAL':0,
  'PM2_DLP_OPERATER_GREATERTHANEQUAL':1,
  'PM2_DLP_OPERATER_LESSTHANEQUAL':2,
  'PM2_DLP_OPERATER_NOTEQUAL':3,
  'PM2_DLP_FILE_SCAN_ARCHIVE_CONTENT':1,
  'PM2_DLP_FILE_SCAN_ARCHIVE_WHOLE':2,
  'PM2_DLP_FILE_SCAN_MS_WORD_CONTENT':4,
  'PM2_DLP_FILE_SCAN_MS_WORD_WHOLE':8,
  'PM2_DLP_FILE_SCAN_PDF_CONTENT':16,
  'PM2_DLP_FILE_SCAN_PDF_WHOLE':32,
  'PM2_OPT_SUMMARY_ONLY':2,
  'PM2_DLP_ACTION_LOG_ONLY':0,
  'PM2_DLP_ACTION_BLOCK':1,
  'PM2_DLP_ACTION_EXEMPT':2,
  'PM2_DLP_ACTION_BAN':3,
  'PM2_DLP_ACTION_BAN_SENDER_ACTION':4,
  'PM2_DLP_ACTION_QUARANTINE_SRC_IP':5,
  'PM2_DLP_ACTION_QUARANTINE_INTF':6,
  'PM2_DLP_FILTER_TYPE_ADVANCED_RULE':0,
  'PM2_DLP_FILTER_TYPE_ADVANCED_CRULE':1,
  'PM2_DLP_FILTER_TYPE_FILE_TYPE':2,
  'PM2_DLP_FILTER_TYPE_FILE_SIZE':3,
  'PM2_DLP_FILTER_TYPE_REGEXP':4,
  'PM2_DLP_FILTER_TYPE_FINGERPRINT':5,
  'PM2_DLP_ACTION_NONE':7,
  'PM2_DLP_ACTION_ALLOW':8,
  'PM2_SEVERITY_INFO':1,
  'PM2_SEVERITY_LOW':2,
  'PM2_SEVERITY_MEDIUM':3,
  'PM2_SEVERITY_HIGH':4,
  'PM2_SEVERITY_CRITICAL':5,
  'PM2_DLP_TYPE_FILE':0,
  'PM2_DLP_TYPE_MESSAGE':1,
  'PM2_QUAR_PROT_AIM':4194304,
  'PM2_QUAR_PROT_ICQ':8388608,
  'PM2_QUAR_PROT_MSN':16777216,
  'PM2_QUAR_PROT_YAHOO':33554432,
  'PM2_QUAR_PROT_HTTP_GET':67108864,
  'PM2_QUAR_PROT_HTTP_POST':134217728,
  'PM2_FILTER_BY_CREDIT_CARD':0,
  'PM2_FILTER_BY_SSN':1,
  'PM2_FILTER_BY_REGEXP':2,
  'PM2_FILTER_BY_FILE_TYPE':3,
  'PM2_FILTER_BY_FILE_SIZE':4,
  'PM2_FILTER_BY_FINGERPRINT':5,
  'PM2_FILTER_BY_WATERMARK':6,
  'PM2_FILTER_BY_ENCRYPTED':7,
  'PM2_FILTER_BY_FILE_TYPE_AND_SIZE':8,
  'PM2_DLP_SENSOR_OPT_STRUCTFILE':1,
  'PM2_DLP_SENSOR_MATCH_TYPE_MATCH_ALL':1,
  'PM2_DLP_SENSOR_MATCH_TYPE_MATCH_ANY':2,
  'PM2_DLP_SENSOR_MATCH_TYPE_MATCH_EVAL':3,
  'PM2_APS_CAUSE_DLP_RULE':0,
  'PM2_APS_CAUSE_DLP_COMPOUND':1,
  'PM2_APS_CAUSE_IPS':2,
  'PM2_APS_CAUSE_AV':3,
  'PM2_APS_CAUSE_DOS':4,
  'PM2_APS_CAUSE_DLP':5,
  'PM2_USB_PROTO_V_SMTP':1,
  'PM2_USB_PROTO_V_POP3':2,
  'PM2_USB_PROTO_V_IMAP':4,
  'PM2_USB_PROTO_V_HTTP_POST':8,
  'PM2_USB_PROTO_V_HTTP_GET':16,
  'PM2_USB_PROTO_V_FTP_PUT':32,
  'PM2_USB_PROTO_V_FTP_GET':64,
  'PM2_USB_PROTO_V_NNTP':128,
  'PM2_USB_PROTO_V_IM_AIM':256,
  'PM2_USB_PROTO_V_IM_ICQ':512,
  'PM2_USB_PROTO_V_IM_MSN':1024,
  'PM2_USB_PROTO_V_IM_YM':2048,
  'PM2_USB_PROTO_V_SMTPS':4096,
  'PM2_USB_PROTO_V_POP3S':8192,
  'PM2_USB_PROTO_V_IMAPS':16384,
  'PM2_USB_PROTO_V_HTTPS_POST':32768,
  'PM2_USB_PROTO_V_HTTPS_GET':65536,
  'PM2_USB_PROTO_V_HTTP':131072,
  'PM2_USB_PROTO_V_FTP':262144,
  'PM2_USB_PROTO_V_HTTPS':524288,
  'PM2_USB_PROTO_V_MM1':1048576,
  'PM2_USB_PROTO_V_MM3':2097152,
  'PM2_USB_PROTO_V_MM4':4194304,
  'PM2_USB_PROTO_V_MM7':8388608,
  'PM2_APS_BAN_QUARANTINE_SRC_IP':0,
  'PM2_APS_BAN_QUARANTINE_DST_IP':1,
  'PM2_APS_BAN_QUARANTINE_SRC_DST_IP':2,
  'PM2_APS_BAN_DLP_USER':3,
  'PM2_APS_BAN_DLP_IP_ADDR':4,
  'PM2_APS_BAN_DLP_SENDER':5,
  'PM2_APS_BAN_DLP_IM':6,
  'PM2_APS_BAN_QUARANTINE_INTF':7,
  'PM2_APS_BAN_QUARANTINE_SRC_IP6':8,
  'PM2_APS_BAN_QUARANTINE_DST_IP6':9,
  'PM2_APS_BAN_QUARANTINE_SRC_DST_IP6':10,
  'PM2_APS_BAN_DLP_IP6':11,
  'PM2_DLP_IM_PROTO_AIM':0,
  'PM2_DLP_IM_PROTO_ICQ':1,
  'PM2_DLP_IM_PROTO_MSN':2,
  'PM2_DLP_IM_PROTO_YAHOO':3,
  'PM2_DD_TYPE_HTTP':1,
  'PM2_DD_TYPE_MAPI':2,
  'PM2_DD_TYPE_CIFS':4,
  'PM2_DD_TYPE_FTP':8,
  'PM2_DD_TYPE_TCP':16,
  'PM2_WANOPT_SETTINGS_DETECT_ALG_SIMPLE':0,
  'PM2_WANOPT_SETTINGS_DETECT_ALG_DIFF_REQ_RESP':1,
  'PM2_TUNNEL_OPTIMIZATION_MEMORY_USAGE':1,
  'PM2_TUNNEL_OPTIMIZATION_BALANCED':2,
  'PM2_TUNNEL_OPTIMIZATION_THROUGHPUT':3,
  'PM2_AUTH_METHOD_CERT':1,
  'PM2_AUTH_METHOD_PSK':2,
  'PM2_PEER_ACCEPT_ANY':1,
  'PM2_PEER_ACCEPT_DEFINED':2,
  'PM2_PEER_ACCEPT_ONE':3,
  'PM2_WANOPT_RULE_MODE_FULL':1,
  'PM2_WANOPT_RULE_MODE_WEBCACHE_ONLY':2,
  'PM2_WANOPT_TUN_MODE_PRIVATE':1,
  'PM2_WANOPT_TUN_MODE_SHARED':2,
  'PM2_WANOPT_TUN_MODE_SHARED_EXP':3,
  'PM2_UNKNOWN_HTTP_VERSION_BEST_EFFORT':1,
  'PM2_UNKNOWN_HTTP_VERSION_REJECT':2,
  'PM2_UNKNOWN_HTTP_VERSION_TUNNEL':3,
  'PM2_SSLVPN_WEB_FLAG_WEB':1,
  'PM2_SSLVPN_WEB_FLAG_FTP':2,
  'PM2_SSLVPN_WEB_FLAG_SMB':8,
  'PM2_SSLVPN_WEB_FLAG_TELNET':4,
  'PM2_SSLVPN_WEB_FLAG_SSH':64,
  'PM2_SSLVPN_WEB_FLAG_VNC':16,
  'PM2_SSLVPN_WEB_FLAG_RDP':32,
  'PM2_SSLVPN_WEB_FLAG_PING':128,
  'PM2_SSLVPN_WEB_FLAG_CITRIX':256,
  'PM2_SSLVPN_WEB_FLAG_RDPNATIVE':512,
  'PM2_SSLVPN_WEB_FLAG_PORTFORWARD':1024,
  'PM2_SSLVPN_FLAG_CLTCHK_NONE':0,
  'PM2_SSLVPN_FLAG_CLTCHK_FTC':1,
  'PM2_SSLVPN_FLAG_CLTCHK_3RD':2,
  'PM2_GUI_THEME_GRAY':0,
  'PM2_GUI_THEME_ORANGE':2,
  'PM2_GUI_THEME_CRIMSON':3,
  'PM2_GUI_THEME_STEELBLUE':4,
  'PM2_GUI_THEME_DARKGREY':5,
  'PM2_SSLVPN_LAYOUT_1_COLS':1,
  'PM2_SSLVPN_LAYOUT_2_COLS':2,
  'PM2_SSLVPN_WIDGET_BOOKMARK':1,
  'PM2_SSLVPN_WIDGET_TOOL':2,
  'PM2_SSLVPN_WIDGET_TUNNEL':3,
  'PM2_SSLVPN_WIDGET_INFO':4,
  'PM2_SSLVPN_WIDGET_HISTORY':5,
  'PM2_SSLVPN_WIDGET_FCT_DOWNLOAD':6,
  'PM2_SSLVPN_WIDGET_COL_1':1,
  'PM2_SSLVPN_WIDGET_COL_2':2,
  'PM2_SSLVPN_APPTYPE_CITRIX':9,
  'PM2_SSLVPN_APPTYPE_RDPNATIVE':10,
  'PM2_SSLVPN_APPTYPE_PORTFORWARD':11,
  'PM2_SSLVPN_SSO_DISABLE':0,
  'PM2_SSLVPN_SSO_STATIC':1,
  'PM2_SSLVPN_SSO_AUTO':2,
  'PM2_KEYBOARD_LAYOUT_AR':0,
  'PM2_KEYBOARD_LAYOUT_DA':1,
  'PM2_KEYBOARD_LAYOUT_DE':2,
  'PM2_KEYBOARD_LAYOUT_DE_CH':3,
  'PM2_KEYBOARD_LAYOUT_EN_GB':4,
  'PM2_KEYBOARD_LAYOUT_EN_UK':5,
  'PM2_KEYBOARD_LAYOUT_EN_US':6,
  'PM2_KEYBOARD_LAYOUT_ES':7,
  'PM2_KEYBOARD_LAYOUT_FI':8,
  'PM2_KEYBOARD_LAYOUT_FR':9,
  'PM2_KEYBOARD_LAYOUT_FR_BE':10,
  'PM2_KEYBOARD_LAYOUT_FR_CA':11,
  'PM2_KEYBOARD_LAYOUT_FR_CH':12,
  'PM2_KEYBOARD_LAYOUT_HR':13,
  'PM2_KEYBOARD_LAYOUT_HU':14,
  'PM2_KEYBOARD_LAYOUT_IT':15,
  'PM2_KEYBOARD_LAYOUT_JA':16,
  'PM2_KEYBOARD_LAYOUT_LT':17,
  'PM2_KEYBOARD_LAYOUT_LV':18,
  'PM2_KEYBOARD_LAYOUT_MK':19,
  'PM2_KEYBOARD_LAYOUT_NO':20,
  'PM2_KEYBOARD_LAYOUT_PL':21,
  'PM2_KEYBOARD_LAYOUT_PT':22,
  'PM2_KEYBOARD_LAYOUT_PT_BR':23,
  'PM2_KEYBOARD_LAYOUT_RU':24,
  'PM2_KEYBOARD_LAYOUT_SL':25,
  'PM2_KEYBOARD_LAYOUT_SV':26,
  'PM2_KEYBOARD_LAYOUT_TK':27,
  'PM2_KEYBOARD_LAYOUT_TR':28,
  'PM2_KEYBOARD_LAYOUT_FR_CA_M':29,
  'PM2_KEYBOARD_LAYOUT_WG':30,
  'PM2_SSLVPN_SSO_CREDENTIAL_LOGIN':0,
  'PM2_SSLVPN_SSO_CREDENTIAL_USE_ALT':1,
  'PM2_SSLVPN_CLTCHK_FLAG_AV':1,
  'PM2_SSLVPN_CLTCHK_FLAG_FW':2,
  'PM2_SSLVPN_HOSTCHK_FLAG_NONE':0,
  'PM2_SSLVPN_HOSTCHK_FLAG_AV':1,
  'PM2_SSLVPN_HOSTCHK_FLAG_FW':2,
  'PM2_SSLVPN_HOSTCHK_FLAG_AV_FW':3,
  'PM2_SSLVPN_HOSTCHK_FLAG_CUSTOM':4,
  'PM2_SSLVPN_APPTYPE_SFTP':12,
  'PM2_KEYBOARD_LAYOUT_AR_101':31,
  'PM2_KEYBOARD_LAYOUT_AR_102':32,
  'PM2_KEYBOARD_LAYOUT_AR_102_AZERTY':33,
  'PM2_KEYBOARD_LAYOUT_CAN_MUL':34,
  'PM2_KEYBOARD_LAYOUT_CZ':35,
  'PM2_KEYBOARD_LAYOUT_CZ_QWERTY':36,
  'PM2_KEYBOARD_LAYOUT_CZ_PR':37,
  'PM2_KEYBOARD_LAYOUT_NL':38,
  'PM2_KEYBOARD_LAYOUT_DE_IBM':39,
  'PM2_KEYBOARD_LAYOUT_EN_UK_EXT':40,
  'PM2_KEYBOARD_LAYOUT_EN_US_DVORAK':41,
  'PM2_KEYBOARD_LAYOUT_ES_VAR':42,
  'PM2_KEYBOARD_LAYOUT_FI_SAMI':43,
  'PM2_KEYBOARD_LAYOUT_HU_101':44,
  'PM2_KEYBOARD_LAYOUT_IT_142':45,
  'PM2_KEYBOARD_LAYOUT_KO':46,
  'PM2_KEYBOARD_LAYOUT_LT_IBM':47,
  'PM2_KEYBOARD_LAYOUT_LT_STD':48,
  'PM2_KEYBOARD_LAYOUT_LAV_STD':49,
  'PM2_KEYBOARD_LAYOUT_LAV_LEG':50,
  'PM2_KEYBOARD_LAYOUT_MK_STD':51,
  'PM2_KEYBOARD_LAYOUT_NO_SAMI':52,
  'PM2_KEYBOARD_LAYOUT_POL_214':53,
  'PM2_KEYBOARD_LAYOUT_POL_PR':54,
  'PM2_KEYBOARD_LAYOUT_PT_BR_ABNT2':55,
  'PM2_KEYBOARD_LAYOUT_RU_MNE':56,
  'PM2_KEYBOARD_LAYOUT_RU_T':57,
  'PM2_KEYBOARD_LAYOUT_SV_SAMI':58,
  'PM2_KEYBOARD_LAYOUT_TUK':59,
  'PM2_KEYBOARD_LAYOUT_TUR_F':60,
  'PM2_KEYBOARD_LAYOUT_TUR_Q':61,
  'PM2_KEYBOARD_LAYOUT_ZH_SYM_SG_US':62,
  'PM2_KEYBOARD_LAYOUT_ZH_SYM_US':63,
  'PM2_KEYBOARD_LAYOUT_ZH_TR_HK':64,
  'PM2_KEYBOARD_LAYOUT_ZH_TR_MO':65,
  'PM2_KEYBOARD_LAYOUT_ZH_TR_US':66,
  'PM2_KEYBOARD_LAYOUT_FR_APPLE':67,
  'PM2_KEYBOARD_LAYOUT_LA_AM':72,
  'PM2_KEYBOARD_LAYOUT_JA_106':73,
  'PM2_SERVER_LAYOUT_EN_US_QWERTY':0,
  'PM2_SERVER_LAYOUT_DE_DE_QWERTY':1,
  'PM2_SERVER_LAYOUT_FR_FR_QWERTY':2,
  'PM2_SERVER_LAYOUT_IT_IT_QWERTY':3,
  'PM2_SERVER_LAYOUT_SV_SE_QWERTY':4,
  'PM2_SERVER_LAYOUT_FAILSAFE':5,
  'PM2_SERVER_LAYOUT_EN_GB_QWERTY':6,
  'PM2_SERVER_LAYOUT_ES_GS_QWERTY':7,
  'PM2_SERVER_LAYOUT_FR_CH_QWERTY':8,
  'PM2_SERVER_LAYOUT_JA_JP_QWERTY':9,
  'PM2_SERVER_LAYOUT_PT_BR_QWERTY':10,
  'PM2_SERVER_LAYOUT_TR_TR_QWERTY':11,
  'PM2_SERVER_LAYOUT_FR_CA_QWERTY':12,
  'PM2_BM_SEC_RDP':0,
  'PM2_BM_SEC_NLA':1,
  'PM2_BM_SEC_TLS':2,
  'PM2_BM_SEC_ANY':3,
  'PM2_KEYBOARD_LAYOUT_DEFAULT':68,
  'PM2_KEYBOARD_LAYOUT_FR_CA_MUL':69,
  'PM2_KEYBOARD_LAYOUT_GD':70,
  'PM2_KEYBOARD_LAYOUT_US_INTL':71,
  'PM2_IP_MODE_NO_IP':5,
  'PM2_SSLVPN_WEB_FLAG_SFTP':2048,
  'PM2_FCT_DNLOAD_METHOD_DIRECT':0,
  'PM2_FCT_DNLOAD_METHOD_SSLVPN':1,
  'PM2_SMB_VERSION_V1':0,
  'PM2_SMB_VERSION_V2':1,
  'PM2_SMB_VERSION_V3':2,
  'PM2_WL_AP_ST_ROGUE':1,
  'PM2_WL_AP_ST_ACCEPTED':2,
  'PM2_WL_AP_ST_SUPPRESSED':3,
  'PM2_DIALINSVR_MODEM_DEV_INTERNAL':0,
  'PM2_DIALINSVR_MODEM_DEV_EXTERNAL':1,
  'PM2_ADDR_TYPE_IPV4_STRICT':22,
  'PM2_ADDR_TYPE_IPV6_STRICT':23,
  'PM2_HTTP_CONNECTION_MODE_STATIC':1,
  'PM2_HTTP_CONNECTION_MODE_MULTIPLEX':2,
  'PM2_HTTP_CONNECTION_MODE_SERVERPOOL':3,
  'PM2_SECURE_WEB_PROXY_DISABLE':0,
  'PM2_SECURE_WEB_PROXY_ENABLE':1,
  'PM2_SECURE_WEB_PROXY_SECURE':2,
  'PM2_ACTION_ACCEPT':7,
  'PM2_ACTION_ACCEPT_UNMANAGEABLE':10,
  'PM2_OPT_TRAFFIC_PROCESS':79,
  'PM2_OPT_LOG_ONLY':80,
  'PM2_ACTION_REPLACE_WITH_SP':13,
  'PM2_ACTION_KEEP':14,
  'PM2_CM_MSG_IMG_TYPE_GIF':1,
  'PM2_CM_MSG_IMG_TYPE_JPG':2,
  'PM2_CM_MSG_IMG_TYPE_TIFF':3,
  'PM2_CM_MSG_IMG_TYPE_PNG':4,
  'PM2_WSP_CHARSET_UTF_8':1,
  'PM2_WSP_CHARSET_US_ASCII':0,
  'PM2_MM1_RSP_STAT_OK':0,
  'PM2_MM1_RSP_STAT_ERR_UNSPEC':1,
  'PM2_MM1_RSP_STAT_ERR_SRV_DENIED':2,
  'PM2_MM1_RSP_STAT_ERR_MSG_FMT_CORRUPT':3,
  'PM2_MM1_RSP_STAT_ERR_SND_ADDR_UNRESOLV':4,
  'PM2_MM1_RSP_STAT_ERR_MSG_NOT_FOUND':5,
  'PM2_MM1_RSP_STAT_ERR_NET_PROB':6,
  'PM2_MM1_RSP_STAT_ERR_CONTENT_NOT_ACCEPT':7,
  'PM2_MM1_RSP_STAT_ERR_UNSUPP_MSG':8,
  'PM2_MMS_CLASS_NO_INCL':4,
  'PM2_MMS_CLASS_PERSONAL':0,
  'PM2_MMS_CLASS_ADV':1,
  'PM2_MMS_CLASS_INFO':2,
  'PM2_MMS_CLASS_AUTO':3,
  'PM2_MMS_PRIO_NO_INCL':3,
  'PM2_MMS_PRIO_LOW':0,
  'PM2_MMS_PRIO_NORM':1,
  'PM2_MMS_PRIO_HIGH':2,
  'PM2_MM1_SNDR_VIS_NO_INCL':2,
  'PM2_MM1_SNDR_VIS_SHOW':1,
  'PM2_MM1_SNDR_VIS_HIDE':0,
  'PM2_MM4_STATUS_OK':0,
  'PM2_MM4_STATUS_ERR_UNSPEC':1,
  'PM2_MM4_STATUS_ERR_SRV_DENIED':2,
  'PM2_MM4_STATUS_ERR_MSG_FMT_CORRUPT':3,
  'PM2_MM4_STATUS_ERR_SND_ADDR_UNRESOLV':4,
  'PM2_MM4_STATUS_ERR_NET_PROB':5,
  'PM2_MM4_STATUS_ERR_CONTENT_NOT_ACCEPT':6,
  'PM2_MM4_STATUS_ERR_UNSUPP_MSG':7,
  'PM2_MM7_ADDR_TYPE_RFC2822':0,
  'PM2_MM7_ADDR_TYPE_NUM':1,
  'PM2_MM7_ADDR_TYPE_SHORT_CODE':2,
  'PM2_MM7_STATUS_SUCCESS':1000,
  'PM2_MM7_STATUS_PART_SUCCESS':1100,
  'PM2_MM7_STATUS_CLIENT_ERR':2000,
  'PM2_MM7_STATUS_OPER_RESTRICT':2001,
  'PM2_MM7_STATUS_ADDR_ERR':2002,
  'PM2_MM7_STATUS_ADDR_NF':2003,
  'PM2_MM7_STATUS_MULTM_CNT_REFUSED':2004,
  'PM2_MM7_STATUS_MSG_ID_NF':2005,
  'PM2_MM7_STATUS_LINK_ID_NF':2006,
  'PM2_MM7_STATUS_MSG_FMT_CORRUPT':2007,
  'PM2_MM7_STATUS_APP_ID_NF':2008,
  'PM2_MM7_STATUS_REPL_APP_ID_NF':2009,
  'PM2_MM7_STATUS_SRV_ERR':3000,
  'PM2_MM7_STATUS_NOT_POSS':3001,
  'PM2_MM7_STATUS_MSG_REJ':3002,
  'PM2_MM7_STATUS_MULTM_ADDR_NOT_SUPP':3003,
  'PM2_MM7_STATUS_APP_ADDR_NOT_SUPP':3004,
  'PM2_MM7_STATUS_GEN_SERVICE_ERR':4000,
  'PM2_MM7_STATUS_IMPROPER_IDENT':4001,
  'PM2_MM7_STATUS_UNSUPP_VER':4002,
  'PM2_MM7_STATUS_UNSUPP_OPER':4003,
  'PM2_MM7_STATUS_VALIDATION_ERR':4004,
  'PM2_MM7_STATUS_SERVICE_ERR':4005,
  'PM2_MM7_STATUS_SERVICE_UNAVAIL':4006,
  'PM2_MM7_STATUS_SERVICE_DENIED':4007,
  'PM2_MM7_STATUS_APP_DENIED':4008,
  'PM2_MSG_GROUP_TYPE_DEFAULT':0,
  'PM2_MSG_GROUP_TYPE_UTM':1,
  'PM2_MSG_GROUP_TYPE_AUTH':2,
  'PM2_MSG_GROUP_TYPE_EC':3,
  'PM2_MSG_GROUP_TYPE_CAPTIVE_PORTAL':4,
  'PM2_MM1_CLASS_NO_INCL':4,
  'PM2_MM1_CLASS_PERSONAL':0,
  'PM2_MM1_CLASS_ADV':1,
  'PM2_MM1_CLASS_INFO':2,
  'PM2_MM1_CLASS_AUTO':3,
  'PM2_MM1_PRIO_NO_INCL':3,
  'PM2_MM1_PRIO_LOW':0,
  'PM2_MM1_PRIO_NORM':1,
  'PM2_MM1_PRIO_HIGH':2,
  'PM2_MMS_E_AVMONITOR':1,
  'PM2_MMS_E_BLOCK':2,
  'PM2_MMS_E_OVERSIZE':4,
  'PM2_MMS_E_QUARANTINE':8,
  'PM2_MMS_E_SCAN':16,
  'PM2_MMS_E_AVQUERY':32,
  'PM2_MMS_E_BANNEDWORD':64,
  'PM2_MMS_E_CHUNKBYPASS':8192,
  'PM2_MMS_E_CLIENTCOMFORT':16384,
  'PM2_MMS_E_SERVERCOMFORT':32768,
  'PM2_MMS_E_NOCONTENTSUM':128,
  'PM2_MMS_E_STRICTFILE':131072,
  'PM2_MMS_E_ARCH_SUMMARY':256,
  'PM2_MMS_E_ARCH_FULL':512,
  'PM2_MMS_E_ENDPOINT_BWL':1024,
  'PM2_MMS_E_REMOVE_INFECTED':2048,
  'PM2_MMS_E_MMS_CHECKSUM':1048576,
  'PM2_MMS_E_FRAGMAIL':262144,
  'PM2_MMS_E_SPLICE':524288,
  'PM2_MMS_PROT_MM1':0,
  'PM2_MMS_PROT_MM3':1,
  'PM2_MMS_PROT_MM4':2,
  'PM2_MMS_PROT_MM7':3,
  'PM2_MM7_M_DELIVER_REQ':1,
  'PM2_MM7_M_SUBMIT_REQ':0,
  'PM2_NF_DAY_SUN':1,
  'PM2_NF_DAY_MON':2,
  'PM2_NF_DAY_TUE':4,
  'PM2_NF_DAY_WED':8,
  'PM2_NF_DAY_THU':16,
  'PM2_NF_DAY_FRI':32,
  'PM2_NF_DAY_SAT':64,
  'PM2_NF_MODE_HOUR':0,
  'PM2_NF_MODE_MIN':1,
  'PM2_NF_PROT_V_ALERT_FLOOD_LVL_1':16,
  'PM2_NF_PROT_V_ALERT_FLOOD_LVL_2':32,
  'PM2_NF_PROT_V_ALERT_FLOOD_LVL_3':64,
  'PM2_NF_PROT_V_ALERT_DUPE_LVL_1':128,
  'PM2_NF_PROT_V_ALERT_DUPE_LVL_2':256,
  'PM2_NF_PROT_V_ALERT_DUPE_LVL_3':512,
  'PM2_APS_PROF_MASS_MSG_BLOCK':8,
  'PM2_APS_PROF_MASS_MSG_INTERCEPT':4,
  'PM2_APS_PROF_MASS_MSG_ARCHIVE':2,
  'PM2_APS_PROF_MASS_MSG_LOG':1,
  'PM2_APS_PROF_MASS_MSG_ARCHIVE_FIRST_ONLY':16,
  'PM2_APS_PROF_MASS_MSG_ALERT_NOTIFICATION':32,
  'PM2_AVDB_DEFAULT':0,
  'PM2_AVDB_NORMAL':1,
  'PM2_AVDB_EXTENDED':2,
  'PM2_AVDB_EXTREME':4,
  'PM2_OUTBREAK_PREVENTION_DISABLED':0,
  'PM2_OUTBREAK_PREVENTION_FILES':1,
  'PM2_OUTBREAK_PREVENTION_FULL_ARCHIVE':2,
  'PM2_GTP_ALLOW':0,
  'PM2_GTP_DENY':1,
  'PM2_E_GTP_APN_MS':1,
  'PM2_E_GTP_APN_NET':2,
  'PM2_E_GTP_APN_VRF':4,
  'PM2_E_GTP_MSG_CREATE_REQ':1,
  'PM2_E_GTP_MSG_CREATE_RES':2,
  'PM2_E_GTP_MSG_UPDATE_REQ':4,
  'PM2_E_GTP_MSG_UPDATE_RES':8,
  'PM2_MAX_APN_REST_ALL':0,
  'PM2_MAX_APN_REST_PUB1':1,
  'PM2_MAX_APN_REST_PUB2':2,
  'PM2_MAX_APN_REST_PRI1':3,
  'PM2_MAX_APN_REST_PRI2':4,
  'PM2_E_GTP_RAT_ANY':1,
  'PM2_E_GTP_RAT_UTRAN':2,
  'PM2_E_GTP_RAT_GERAN':4,
  'PM2_E_GTP_RAT_WLAN':8,
  'PM2_E_GTP_RAT_GAN':16,
  'PM2_E_GTP_RAT_HSPA':32,
  'PM2_E_GTP_RAT_EUTRAN':64,
  'PM2_E_GTP_RAT_VIRTUAL':128,
  'PM2_E_GTP_RAT_NBIOT':256,
  'PM2_E_GTP_REMOVE_IE_APN_RESTRICTION':1,
  'PM2_E_GTP_REMOVE_IE_RAT_TYPE':2,
  'PM2_E_GTP_REMOVE_IE_RAI':4,
  'PM2_E_GTP_REMOVE_IE_ULI':8,
  'PM2_E_GTP_REMOVE_IE_IMEI':16,
  'PM2_NOIP_POLICY_ETSI':0,
  'PM2_NOIP_POLICY_IETF':1,
  'PM2_OPT_VDOM':43,
  'PM2_RATE_LIMIT_PER_PROFILE':0,
  'PM2_RATE_LIMIT_PER_STREAM':1,
  'PM2_RATE_LIMIT_PER_APN':2,
  'PM2_GTP_MSG_CREATE_SES_REQ':1,
  'PM2_GTP_MSG_CREATE_SES_RES':2,
  'PM2_GTP_MSG_MODIFY_BEARER_REQ':4,
  'PM2_GTP_MSG_MODIFY_BEARER_RES':8,
  'PM2_GTP_APN_SEL_MS':1,
  'PM2_GTP_APN_SEL_NET':2,
  'PM2_GTP_APN_SEL_VRF':4,
  'PM2_E_GTP_RAT_LTEM':512,
  'PM2_E_GTP_RAT_NR':1024,
  'PM2_SIMPLE_PATTERN':2,
  'PM2_APS_MMS_ENDPOINT_BLOCK':1,
  'PM2_APS_MMS_ENDPOINT_EXEMPT':2,
  'PM2_APS_MMS_ENDPOINT_EXEMPT_MASS_MMS':16,
  'PM2_APS_MMS_ENDPOINT_ARCHIVE':4,
  'PM2_APS_MMS_ENDPOINT_INTERCEPT':8,
  'PM2_EC_DL_SERVER_FDS':0,
  'PM2_EC_DL_LOCAL_FGT':3,
  'PM2_EC_DL_CUSTOM_LINK':4,
  'PM2_EC_VER_RESTRICT_LATEST':0,
  'PM2_EC_VER_RESTRICT_MINIMUM':1,
  'PM2_DNS_RRTYPE_A':4,
  'PM2_DNS_RRTYPE_NS':1,
  'PM2_DNS_RRTYPE_CNAME':3,
  'PM2_DNS_RRTYPE_MX':2,
  'PM2_DNS_RRTYPE_AAAA':5,
  'PM2_DNS_RRTYPE_PTR':6,
  'PM2_DNS_RRTYPE_PTRV6':7,
  'PM2_DNS_DATABASE_TYPE_MASTER':0,
  'PM2_DNS_DATABASE_TYPE_SLAVE':1,
  'PM2_DNS_DATABASE_TYPE_PRIMARY':2,
  'PM2_DNS_DATABASE_TYPE_SECONDARY':3,
  'PM2_DNS_DATABASE_VIEW_SHADOW':0,
  'PM2_DNS_DATABASE_VIEW_PUBLIC':1,
  'PM2_DNS_DATABASE_VIEW_SHADOW_ZTNA':2,
  'PM2_DNS_DATABASE_VIEW_PROXY':3,
  'PM2_PASSWD_POLICY_APPLYTO_ADMIN':1,
  'PM2_PASSWD_POLICY_APPLYTO_IPSEC':2,
  'PM2_PASSWD_MUST_CONTAIN_UL':1,
  'PM2_PASSWD_MUST_CONTAIN_LL':2,
  'PM2_PASSWD_MUST_CONTAIN_NR':4,
  'PM2_PASSWD_MUST_CONTAIN_NA':8,
  'PM2_WEBFILTER_LANG_WESTERN':0,
  'PM2_WEBFILTER_LANG_CHNS':1,
  'PM2_WEBFILTER_LANG_CHNT':2,
  'PM2_WEBFILTER_LANG_JAPANESE':3,
  'PM2_WEBFILTER_LANG_KOREAN':4,
  'PM2_WEBFILTER_LANG_FRENCH':5,
  'PM2_WEBFILTER_LANG_THAI':6,
  'PM2_WEBFILTER_LANG_SPANISH':7,
  'PM2_WEBFILTER_LANG_CYRILLIC':8,
  'PM2_UF_ACT_WARN':5,
  'PM2_UF_ACT_BLOCK':6,
  'PM2_UF_ACT_BLOCK_LISTENING':7,
  'PM2_UF_ACT_BLOCK_SENDING':8,
  'PM2_EC_STATUS_INSTALLED':0,
  'PM2_EC_STATUS_RUNNING':1,
  'PM2_EC_STATUS_NOT_INSTALLED':2,
  'PM2_EC_STATUS_NOT_RUNNING':3,
  'PM2_OPT_WARN':15,
  'PM2_OPT_SKIP':12,
  'PM2_FCT_UI_OPT_AV':1,
  'PM2_FCT_UI_OPT_WF':2,
  'PM2_FCT_UI_OPT_AF':4,
  'PM2_FCT_UI_OPT_VPN':8,
  'PM2_FCT_UI_OPT_VS':16,
  'PM2_FILE_TYPE_FILE':0,
  'PM2_FILE_TYPE_FOLDER':1,
  'PM2_SCAN_MODE_QUICK':0,
  'PM2_SCAN_MODE_FULL':2,
  'PM2_SCAN_MODE_CUSTOM':3,
  'PM2_OS_TYPE_CUSTOM':0,
  'PM2_OS_TYPE_MAC_OS':1,
  'PM2_OS_TYPE_WIN_10':2,
  'PM2_OS_TYPE_WIN_SVR_10':3,
  'PM2_OS_TYPE_WIN_81':4,
  'PM2_OS_TYPE_WIN_SVR_2012_R2':5,
  'PM2_OS_TYPE_WIN_80':6,
  'PM2_OS_TYPE_WIN_SVR_2012':7,
  'PM2_OS_TYPE_WIN_7':8,
  'PM2_OS_TYPE_WIN_SVR_2008_R2':9,
  'PM2_OS_TYPE_WIN_VISTA':10,
  'PM2_OS_TYPE_WIN_SVR_2003_R2':11,
  'PM2_OS_TYPE_WIN_STO_SVR_2003':12,
  'PM2_OS_TYPE_WIN_HOME_SVR':13,
  'PM2_OS_TYPE_WIN_SVR_2003':14,
  'PM2_OS_TYPE_WIN_XP':15,
  'PM2_OS_TYPE_WIN_2000':16,
  'PM2_OS_TYPE_WIN_SVR_2008':17,
  'PM2_OS_TYPE_LINUX_UBUNTU':19,
  'PM2_OS_TYPE_LINUX_CENTOS':20,
  'PM2_OS_TYPE_LINUX_REDHAT':21,
  'PM2_OS_TYPE_LINUX_FEDORA':22,
  'PM2_APP_RULE_PRESENT':0,
  'PM2_APP_RULE_ABSENT':1,
  'PM2_CPL_ACT_BLOCK':0,
  'PM2_CPL_ACT_WARNING':1,
  'PM2_CPL_ACT_AUTO_UPDATE':2,
  'PM2_UPLOAD_LEVEL_TRAFFIC':1,
  'PM2_UPLOAD_LEVEL_VULNERABILITY':2,
  'PM2_UPLOAD_LEVEL_EVENT':4,
  'PM2_EMS_COMP_ACT_BLOCK':0,
  'PM2_EMS_COMP_ACT_WARNING':1,
  'PM2_ABR_TYPE_CISCO':0,
  'PM2_ABR_TYPE_IBM':1,
  'PM2_ABR_TYPE_STANDARD':2,
  'PM2_AUTH_OPT_NONE':0,
  'PM2_AUTH_OPT_AH':1,
  'PM2_AUTH_OPT_ESP':2,
  'PM2_AUTH_OPT_AREA':3,
  'PM2_IPSEC_AUTH_ALG_MD5':0,
  'PM2_IPSEC_AUTH_ALG_SHA1':2,
  'PM2_IPSEC_AUTH_ALG_SHA256':3,
  'PM2_IPSEC_AUTH_ALG_SHA384':4,
  'PM2_IPSEC_AUTH_ALG_SHA512':5,
  'PM2_IPSEC_ENC_ALG_NULL':0,
  'PM2_IPSEC_ENC_ALG_DES':1,
  'PM2_IPSEC_ENC_ALG_3DES':2,
  'PM2_IPSEC_ENC_ALG_AES128':4,
  'PM2_IPSEC_ENC_ALG_AES192':5,
  'PM2_IPSEC_ENC_ALG_AES256':6,
  'PM2_BANDWIDTH_UNIT_KBPS':0,
  'PM2_BANDWIDTH_UNIT_MBPS':1,
  'PM2_BANDWIDTH_UNIT_GBPS':2,
  'PM2_DSCP_MARKING_MULTI_STAGE':0,
  'PM2_DSCP_MARKING_STATIC':1,
  'PM2_CHK_TYPE_FILE':0,
  'PM2_CHK_TYPE_REGISTRY':1,
  'PM2_CHK_TYPE_PROCESS':2,
  'PM2_OS_TYPE_WINDOWS':18,
  'PM2_FIELD_TYPE_TEXT':0,
  'PM2_FIELD_TYPE_INTEGER':1,
  'PM2_FIELD_TYPE_DOUBLE':2,
  'PM2_RPT_DATASET_TEXT':1,
  'PM2_RPT_DATASET_INT':2,
  'PM2_RPT_DATASET_DOUBLE':3,
  'PM2_RPT_DATASET_LONG_INT':4,
  'PM2_RPT_DATASET_DATE_TIME':5,
  'PM2_CHART_TYPE_GRAPH':0,
  'PM2_CHART_TYPE_TABLE':1,
  'PM2_RPT_STYLE_NONE':3,
  'PM2_RPT_STYLE_BAR':0,
  'PM2_RPT_STYLE_PIE':2,
  'PM2_RPT_STYLE_LINE':1,
  'PM2_RPT_STYLE_FLOW':4,
  'PM2_RPT_STYLE_2D':5,
  'PM2_RPT_STYLE_3D':6,
  'PM2_RPT_STYLE_45DGR':7,
  'PM2_RPT_STYLE_VERT':8,
  'PM2_RPT_STYLE_HORN':9,
  'PM2_TIME_TYPE_DATETIME':7,
  'PM2_TIME_TYPE_MINUTE':1,
  'PM2_TIME_TYPE_HOUR':2,
  'PM2_TIME_TYPE_DAY':3,
  'PM2_TIME_TYPE_MONTH':5,
  'PM2_TIME_TYPE_YEAR':6,
  'PM2_SCALE_TYPE_MAX':0,
  'PM2_SCALE_TYPE_MIN':1,
  'PM2_TIME_TYPE_YMDHM':8,
  'PM2_TIME_TYPE_YMD':9,
  'PM2_TIME_TYPE_H':10,
  'PM2_TIME_TYPE_YM':11,
  'PM2_TIME_TYPE_Y':12,
  'PM2_TIME_TYPE_HM':13,
  'PM2_TIME_TYPE_MD':14,
  'PM2_SCALE_DIR_DECREASE':0,
  'PM2_SCALE_DIR_INCREASE':1,
  'PM2_LG_OP_NONE':0,
  'PM2_LG_OP_GREATER':1,
  'PM2_LG_OP_GE':2,
  'PM2_LG_OP_LESS':3,
  'PM2_LG_OP_LE':4,
  'PM2_LG_OP_EQUAL':5,
  'PM2_LG_OP_BETWEEN':6,
  'PM2_LOG_FILTER_C_MISC':2048,
  'PM2_LOG_FILTER_C_VULNER':4096,
  'PM2_OPT_PERIOD_LAST24H':0,
  'PM2_OPT_PERIOD_LAST7D':1,
  'PM2_AVDB_FLOWBASED':3,
  'PM2_MACHINE_LEARNING_DETECTION_ENABLE':1,
  'PM2_MACHINE_LEARNING_DETECTION_MONITOR':2,
  'PM2_MACHINE_LEARNING_DETECTION_DISABLE':0,
  'PM2_OPT_JOIN':13,
  'PM2_DISCOVERY_TYPE_STATIC':0,
  'PM2_DISCOVERY_TYPE_DHCP':1,
  'PM2_DISCOVERY_TYPE_BROADCAST':2,
  'PM2_DISCOVERY_TYPE_MULTICAST':3,
  'PM2_CTRL_MSG_OFFLOAD_DBP_FRAME':1,
  'PM2_CTRL_MSG_OFFLOAD_AEROSCOUT_TAG':2,
  'PM2_CTRL_MSG_OFFLOAD_AP_LIST':4,
  'PM2_CTRL_MSG_OFFLOAD_STA_LIST':8,
  'PM2_CTRL_MSG_OFFLOAD_STA_CAP_LIST':16,
  'PM2_CTRL_MSG_OFFLOAD_STATS':32,
  'PM2_CTRL_MSG_OFFLOAD_AEROSCOUT_MU':64,
  'PM2_CTRL_MSG_OFFLOAD_STA_HEALTH':128,
  'PM2_CTRL_MSG_OFFLOAD_SPECTRAL_ANALYSIS':256,
  'PM2_SC_TUNNEL_MODE_COMPATIBLE':1,
  'PM2_SC_TUNNEL_MODE_STRICT':2,
  'PM2_WL_SEC_CAPTIVE_PORTAL':13,
  'PM2_WL_SEC_WPA_PERSONAL_CAPTIVE_PORTAL':18,
  'PM2_WL_SEC_WPA_ONLY_PERSONAL_CAPTIVE_PORTAL':19,
  'PM2_WL_SEC_WPA2_ONLY_PERSONAL_CAPTIVE_PORTAL':20,
  'PM2_WL_SEC_OSEN':21,
  'PM2_WL_SEC_WPA3_ENTERPRISE':22,
  'PM2_WL_SEC_SAE':23,
  'PM2_WL_SEC_SAE_TRANSITION':24,
  'PM2_WL_SEC_WPA3_SAE_TRANSITION':27,
  'PM2_WL_SEC_WPA3_ONLY_ENTERPRISE':28,
  'PM2_WL_SEC_WPA3_ENTERPRISE_TRANSITION':29,
  'PM2_WL_PSK_ND':3,
  'PM2_WL_ENCRYPT_TKIP_AES':2,
  'PM2_BS_OPT_DHCP':1,
  'PM2_BS_OPT_ARP':2,
  'PM2_BS_OPT_NETBIOS_NS':16,
  'PM2_BS_OPT_NETBIOS_DS':32,
  'PM2_BS_OPT_DHCP_UP':128,
  'PM2_BS_OPT_DHCP_DOWN':256,
  'PM2_BS_OPT_ARP_KNOWN':512,
  'PM2_BS_OPT_ARP_UNKNOWN':1024,
  'PM2_BS_OPT_ARP_REPLY':2048,
  'PM2_BS_OPT_IPV6':4096,
  'PM2_BS_OPT_DHCP2':4,
  'PM2_BS_OPT_ARP2':8,
  'PM2_BS_OPT_ARP3':64,
  'PM2_BS_OPT_DHCP_STARVATION':8192,
  'PM2_BS_OPT_ARP_POISON':16384,
  'PM2_BS_OPT_ALL_OTHER_MC':32768,
  'PM2_BS_OPT_ALL_OTHER_BC':65536,
  'PM2_BS_OPT_ARP_PROXY':131072,
  'PM2_BS_OPT_DHCP_UCAST':262144,
  'PM2_ADMACCESS_ANY':64,
  'PM2_MULTICAST_RATE_0':0,
  'PM2_MULTICAST_RATE_6000':6000,
  'PM2_MULTICAST_RATE_12000':12000,
  'PM2_MULTICAST_RATE_24000':24000,
  'PM2_PORTAL_TYPE_AUTH':0,
  'PM2_PORTAL_TYPE_AUTH_DISCLAIMER':1,
  'PM2_PORTAL_TYPE_DISCLAIMER':2,
  'PM2_PORTAL_TYPE_EMAIL_COLLECT':3,
  'PM2_PORTAL_TYPE_EMAIL_CMCC':4,
  'PM2_PORTAL_TYPE_CMCC_MACAUTH':5,
  'PM2_PORTAL_TYPE_AUTO_MAC':6,
  'PM2_PORTAL_TYPE_EXTERNAL_AUTH':7,
  'PM2_PORTAL_TYPE_EXTERNAL_MACAUTH':8,
  'PM2_OPT_OPTIONAL':23,
  'PM2_VLAN_POOLING_WTP_GROUP':0,
  'PM2_VLAN_POOLING_RR':1,
  'PM2_VLAN_POOLING_HASH':2,
  'PM2_VLAN_POOLING_DISABLE':3,
  'PM2_RATE_11A_1':1,
  'PM2_RATE_11A_1_BASIC':2,
  'PM2_RATE_11A_2':4,
  'PM2_RATE_11A_2_BASIC':8,
  'PM2_RATE_11A_5_5':16,
  'PM2_RATE_11A_5_5_BASIC':32,
  'PM2_RATE_11A_6':64,
  'PM2_RATE_11A_6_BASIC':128,
  'PM2_RATE_11A_9':256,
  'PM2_RATE_11A_9_BASIC':512,
  'PM2_RATE_11A_12':1024,
  'PM2_RATE_11A_12_BASIC':2048,
  'PM2_RATE_11A_18':4096,
  'PM2_RATE_11A_18_BASIC':8192,
  'PM2_RATE_11A_24':16384,
  'PM2_RATE_11A_24_BASIC':32768,
  'PM2_RATE_11A_36':65536,
  'PM2_RATE_11A_36_BASIC':131072,
  'PM2_RATE_11A_48':262144,
  'PM2_RATE_11A_48_BASIC':524288,
  'PM2_RATE_11A_54':1048576,
  'PM2_RATE_11A_54_BASIC':2097152,
  'PM2_RATE_11A_11':4194304,
  'PM2_RATE_11A_11_BASIC':8388608,
  'PM2_RATE_11BG_1':1,
  'PM2_RATE_11BG_1_BASIC':2,
  'PM2_RATE_11BG_2':4,
  'PM2_RATE_11BG_2_BASIC':8,
  'PM2_RATE_11BG_5_5':16,
  'PM2_RATE_11BG_5_5_BASIC':32,
  'PM2_RATE_11BG_6':64,
  'PM2_RATE_11BG_6_BASIC':128,
  'PM2_RATE_11BG_9':256,
  'PM2_RATE_11BG_9_BASIC':512,
  'PM2_RATE_11BG_12':1024,
  'PM2_RATE_11BG_12_BASIC':2048,
  'PM2_RATE_11BG_18':4096,
  'PM2_RATE_11BG_18_BASIC':8192,
  'PM2_RATE_11BG_24':16384,
  'PM2_RATE_11BG_24_BASIC':32768,
  'PM2_RATE_11BG_36':65536,
  'PM2_RATE_11BG_36_BASIC':131072,
  'PM2_RATE_11BG_48':262144,
  'PM2_RATE_11BG_48_BASIC':524288,
  'PM2_RATE_11BG_54':1048576,
  'PM2_RATE_11BG_54_BASIC':2097152,
  'PM2_RATE_11BG_11':4194304,
  'PM2_RATE_11BG_11_BASIC':8388608,
  'PM2_RATE_11N_SS12_MCS0_1':1,
  'PM2_RATE_11N_SS12_MCS1_1':2,
  'PM2_RATE_11N_SS12_MCS2_1':4,
  'PM2_RATE_11N_SS12_MCS3_1':8,
  'PM2_RATE_11N_SS12_MCS4_1':16,
  'PM2_RATE_11N_SS12_MCS5_1':32,
  'PM2_RATE_11N_SS12_MCS6_1':64,
  'PM2_RATE_11N_SS12_MCS7_1':128,
  'PM2_RATE_11N_SS12_MCS8_1':256,
  'PM2_RATE_11N_SS12_MCS9_1':512,
  'PM2_RATE_11N_SS12_MCS10_1':1024,
  'PM2_RATE_11N_SS12_MCS11_1':2048,
  'PM2_RATE_11N_SS12_MCS12_1':4096,
  'PM2_RATE_11N_SS12_MCS13_1':8192,
  'PM2_RATE_11N_SS12_MCS14_1':16384,
  'PM2_RATE_11N_SS12_MCS15_1':32768,
  'PM2_RATE_11N_SS34_MCS16_3':1,
  'PM2_RATE_11N_SS34_MCS17_3':2,
  'PM2_RATE_11N_SS34_MCS18_3':4,
  'PM2_RATE_11N_SS34_MCS19_3':8,
  'PM2_RATE_11N_SS34_MCS20_3':16,
  'PM2_RATE_11N_SS34_MCS21_3':32,
  'PM2_RATE_11N_SS34_MCS22_3':64,
  'PM2_RATE_11N_SS34_MCS23_3':128,
  'PM2_RATE_11N_SS34_MCS24_4':256,
  'PM2_RATE_11N_SS34_MCS25_4':512,
  'PM2_RATE_11N_SS34_MCS26_4':1024,
  'PM2_RATE_11N_SS34_MCS27_4':2048,
  'PM2_RATE_11N_SS34_MCS28_4':4096,
  'PM2_RATE_11N_SS34_MCS29_4':8192,
  'PM2_RATE_11N_SS34_MCS30_4':16384,
  'PM2_RATE_11N_SS34_MCS21_4':32768,
  'PM2_RATE_11AC_SS12_MCS0_1':1,
  'PM2_RATE_11AC_SS12_MCS1_1':2,
  'PM2_RATE_11AC_SS12_MCS2_1':4,
  'PM2_RATE_11AC_SS12_MCS3_1':8,
  'PM2_RATE_11AC_SS12_MCS4_1':16,
  'PM2_RATE_11AC_SS12_MCS5_1':32,
  'PM2_RATE_11AC_SS12_MCS6_1':64,
  'PM2_RATE_11AC_SS12_MCS7_1':128,
  'PM2_RATE_11AC_SS12_MCS8_1':256,
  'PM2_RATE_11AC_SS12_MCS9_1':512,
  'PM2_RATE_11AC_SS12_MCS0_2':1024,
  'PM2_RATE_11AC_SS12_MCS1_2':2048,
  'PM2_RATE_11AC_SS12_MCS2_2':4096,
  'PM2_RATE_11AC_SS12_MCS3_2':8192,
  'PM2_RATE_11AC_SS12_MCS4_2':16384,
  'PM2_RATE_11AC_SS12_MCS5_2':32768,
  'PM2_RATE_11AC_SS12_MCS6_2':65536,
  'PM2_RATE_11AC_SS12_MCS7_2':131072,
  'PM2_RATE_11AC_SS12_MCS8_2':262144,
  'PM2_RATE_11AC_SS12_MCS9_2':524288,
  'PM2_RATE_11AC_SS12_MCS10_1':1048576,
  'PM2_RATE_11AC_SS12_MCS11_1':2097152,
  'PM2_RATE_11AC_SS12_MCS10_2':4194304,
  'PM2_RATE_11AC_SS12_MCS11_2':8388608,
  'PM2_RATE_11AC_SS34_MCS0_3':1,
  'PM2_RATE_11AC_SS34_MCS1_3':2,
  'PM2_RATE_11AC_SS34_MCS2_3':4,
  'PM2_RATE_11AC_SS34_MCS3_3':8,
  'PM2_RATE_11AC_SS34_MCS4_3':16,
  'PM2_RATE_11AC_SS34_MCS5_3':32,
  'PM2_RATE_11AC_SS34_MCS6_3':64,
  'PM2_RATE_11AC_SS34_MCS7_3':128,
  'PM2_RATE_11AC_SS34_MCS8_3':256,
  'PM2_RATE_11AC_SS34_MCS9_3':512,
  'PM2_RATE_11AC_SS34_MCS0_4':1024,
  'PM2_RATE_11AC_SS34_MCS1_4':2048,
  'PM2_RATE_11AC_SS34_MCS2_4':4096,
  'PM2_RATE_11AC_SS34_MCS3_4':8192,
  'PM2_RATE_11AC_SS34_MCS4_4':16384,
  'PM2_RATE_11AC_SS34_MCS5_4':32768,
  'PM2_RATE_11AC_SS34_MCS6_4':65536,
  'PM2_RATE_11AC_SS34_MCS7_4':131072,
  'PM2_RATE_11AC_SS34_MCS8_4':262144,
  'PM2_RATE_11AC_SS34_MCS9_4':524288,
  'PM2_RATE_11AC_SS34_MCS10_3':1048576,
  'PM2_RATE_11AC_SS34_MCS11_3':2097152,
  'PM2_RATE_11AC_SS34_MCS10_4':4194304,
  'PM2_RATE_11AC_SS34_MCS11_4':8388608,
  'PM2_OPT_ENABLE3':44,
  'PM2_VAP_SECURITY_GROUP_1':2,
  'PM2_VAP_SECURITY_GROUP_2':4,
  'PM2_VAP_SECURITY_GROUP_5':32,
  'PM2_VAP_SECURITY_GROUP_14':16384,
  'PM2_VAP_SECURITY_GROUP_15':32768,
  'PM2_VAP_SECURITY_GROUP_16':65536,
  'PM2_VAP_SECURITY_GROUP_17':131072,
  'PM2_VAP_SECURITY_GROUP_18':262144,
  'PM2_VAP_SECURITY_GROUP_19':524288,
  'PM2_VAP_SECURITY_GROUP_20':1048576,
  'PM2_VAP_SECURITY_GROUP_21':2097152,
  'PM2_VAP_SECURITY_GROUP_27':134217728,
  'PM2_VAP_SECURITY_GROUP_28':268435456,
  'PM2_VAP_SECURITY_GROUP_29':536870912,
  'PM2_VAP_SECURITY_GROUP_30':1073741824,
  'PM2_VAP_SECURITY_GROUP_31':2147483648,
  'PM2_WEB_FORMAT_AUTO_DETECT':0,
  'PM2_WEB_FORMAT_NO_QUERY_STRING':1,
  'PM2_WEB_FORMAT_PARTIAL_QUERY_STRING':2,
  'PM2_IPV6_RULES_DROP_ICMP6RA':1,
  'PM2_IPV6_RULES_DROP_ICMP6RS':2,
  'PM2_IPV6_RULES_DROP_LLMNR6':4,
  'PM2_IPV6_RULES_DROP_ICMP6MLD2':8,
  'PM2_IPV6_RULES_DROP_DHCP6S':16,
  'PM2_IPV6_RULES_DROP_DHCP6C':32,
  'PM2_IPV6_RULES_NDP_PROXY':64,
  'PM2_IPV6_RULES_DROP_NS_DAD':128,
  'PM2_IPV6_RULES_DROP_NS_NONDAD':256,
  'PM2_PORT_MACAUTH_DISABLE':0,
  'PM2_PORT_MACAUTH_RADIUS':1,
  'PM2_PORT_MACAUTH_ADDRESS_GROUP':2,
  'PM2_MBO_CELL_DATA_CONN_PREF_EXCLUDED':1,
  'PM2_MBO_CELL_DATA_CONN_PREF_PREFER_NOT':2,
  'PM2_MBO_CELL_DATA_CONN_PREF_PREFER_USE':3,
  'PM2_ADDITIONAL_AKMS_AKM6':1,
  'PM2__AKM24':2,
  'PM2_VAP_MAC_USERNAME_DELIMITER_HYPHEN':1,
  'PM2_VAP_MAC_USERNAME_DELIMITER_SINGLE_HYPHEN':2,
  'PM2_VAP_MAC_USERNAME_DELIMITER_COLON':3,
  'PM2_VAP_MAC_USERNAME_DELIMITER_NONE':4,
  'PM2_VAP_MAC_CASE_UPPERCASE':1,
  'PM2_VAP_MAC_CASE_LOWERCASE':2,
  'PM2_BEACON_ADVERTISING_NAME':1,
  'PM2_BEACON_ADVERTISING_MODEL':2,
  'PM2_BEACON_ADVERTISING_SERIAL_NUMBER':4,
  'PM2_RATE_11AX_SS12_MCS0_1':1,
  'PM2_RATE_11AX_SS12_MCS1_1':2,
  'PM2_RATE_11AX_SS12_MCS2_1':4,
  'PM2_RATE_11AX_SS12_MCS3_1':8,
  'PM2_RATE_11AX_SS12_MCS4_1':16,
  'PM2_RATE_11AX_SS12_MCS5_1':32,
  'PM2_RATE_11AX_SS12_MCS6_1':64,
  'PM2_RATE_11AX_SS12_MCS7_1':128,
  'PM2_RATE_11AX_SS12_MCS8_1':256,
  'PM2_RATE_11AX_SS12_MCS9_1':512,
  'PM2_RATE_11AX_SS12_MCS10_1':1024,
  'PM2_RATE_11AX_SS12_MCS11_1':2048,
  'PM2_RATE_11AX_SS12_MCS0_2':4096,
  'PM2_RATE_11AX_SS12_MCS1_2':8192,
  'PM2_RATE_11AX_SS12_MCS2_2':16384,
  'PM2_RATE_11AX_SS12_MCS3_2':32768,
  'PM2_RATE_11AX_SS12_MCS4_2':65536,
  'PM2_RATE_11AX_SS12_MCS5_2':131072,
  'PM2_RATE_11AX_SS12_MCS6_2':262144,
  'PM2_RATE_11AX_SS12_MCS7_2':524288,
  'PM2_RATE_11AX_SS12_MCS8_2':1048576,
  'PM2_RATE_11AX_SS12_MCS9_2':2097152,
  'PM2_RATE_11AX_SS12_MCS10_2':4194304,
  'PM2_RATE_11AX_SS12_MCS11_2':8388608,
  'PM2_RATE_11AX_SS34_MCS0_3':1,
  'PM2_RATE_11AX_SS34_MCS1_3':2,
  'PM2_RATE_11AX_SS34_MCS2_3':4,
  'PM2_RATE_11AX_SS34_MCS3_3':8,
  'PM2_RATE_11AX_SS34_MCS4_3':16,
  'PM2_RATE_11AX_SS34_MCS5_3':32,
  'PM2_RATE_11AX_SS34_MCS6_3':64,
  'PM2_RATE_11AX_SS34_MCS7_3':128,
  'PM2_RATE_11AX_SS34_MCS8_3':256,
  'PM2_RATE_11AX_SS34_MCS9_3':512,
  'PM2_RATE_11AX_SS34_MCS10_3':1024,
  'PM2_RATE_11AX_SS34_MCS11_3':2048,
  'PM2_RATE_11AX_SS34_MCS0_4':4096,
  'PM2_RATE_11AX_SS34_MCS1_4':8192,
  'PM2_RATE_11AX_SS34_MCS2_4':16384,
  'PM2_RATE_11AX_SS34_MCS3_4':32768,
  'PM2_RATE_11AX_SS34_MCS4_4':65536,
  'PM2_RATE_11AX_SS34_MCS5_4':131072,
  'PM2_RATE_11AX_SS34_MCS6_4':262144,
  'PM2_RATE_11AX_SS34_MCS7_4':524288,
  'PM2_RATE_11AX_SS34_MCS8_4':1048576,
  'PM2_RATE_11AX_SS34_MCS9_4':2097152,
  'PM2_RATE_11AX_SS34_MCS10_4':4194304,
  'PM2_RATE_11AX_SS34_MCS11_4':8388608,
  'PM2_VAP_ADDRESS_GROUP_POLICY_DISABLE':0,
  'PM2_VAP_ADDRESS_GROUP_POLICY_ALLOW':1,
  'PM2_VAP_ADDRESS_GROUP_POLICY_DENY':2,
  'PM2_L3_ROAMING_MODE_DIRECT':1,
  'PM2_L3_ROAMING_MODE_INDIRECT':2,
  'PM2_CALLED_STATION_ID_TYPE_MAC':1,
  'PM2_CALLED_STATION_ID_TYPE_IP':2,
  'PM2_CALLED_STATION_ID_TYPE_APNAME':3,
  'PM2_WC_ADMIN_DISCOVERY':0,
  'PM2_WC_ADMIN_DISABLE':1,
  'PM2_WC_ADMIN_ENABLE':2,
  'PM2_WC_ADMIN_DISCOVERED':3,
  'PM2_WL_BAND_24G':64,
  'PM2_WL_BAND_5G':128,
  'PM2_AP_SCAN_DISABLE':0,
  'PM2_AP_SCAN_BGSCAN':1,
  'PM2_AP_SCAN_FGSCAN':2,
  'PM2_AP_SCAN_ENABLE':3,
  'PM2_CONN_CONNECTING':1,
  'PM2_CONN_CONNECTED':2,
  'PM2_CONN_IMAGE_DOWNLOADING':3,
  'PM2_CONN_CONNECTED_IMAGE':4,
  'PM2_CW_OPER_MODE_SN':1,
  'PM2_CW_OPER_MODE_LE':2,
  'PM2_CONN_UNKNOWN':9,
  'PM2_WTP_MODE_NORMAL':0,
  'PM2_WTP_MODE_REMOTE':1,
  'PM2_LAN_PORT_OFFLINE':0,
  'PM2_LAN_PORT_BRIDGE_TO_WAN':1,
  'PM2_LAN_PORT_BRIDGE_TO_SSID':2,
  'PM2_LAN_PORT_NAT_TO_WAN':3,
  'PM2_IP_FRAG_PRV_TCP_MSS_ADJUST':1,
  'PM2_IP_FRAG_PRV_ICMP_UNREACHABLE':2,
  'PM2_WL_BAND_11AX_5G':256,
  'PM2_WL_BAND_11AXAC_ONLY':33040,
  'PM2_WL_BAND_11AXACN_ONLY':33072,
  'PM2_WL_BAND_11AX_5G_ONLY':33024,
  'PM2_WL_BAND_11AX':512,
  'PM2_WL_BAND_11AXN_ONLY':33288,
  'PM2_WL_BAND_11AXNG_ONLY':33292,
  'PM2_WL_BAND_11AX_ONLY':33280,
  'PM2_WL_BAND_11AC_2G':1024,
  'PM2_WL_BAND_11AX_6G':2048,
  'PM2_WL_BAND_11N_2G':4096,
  'PM2_WL_BAND_11AC_5G':8192,
  'PM2_WL_BAND_11AX_2G':16384,
  'PM2_WL_BAND_11BE_2G':65536,
  'PM2_WL_BAND_11BE_5G':131072,
  'PM2_WL_BAND_11BE_6G':262144,
  'PM2_WL_BAND_C_11A':1,
  'PM2_WL_BAND_C_11B':2,
  'PM2_WL_BAND_C_11G':4,
  'PM2_WL_BAND_C_11N':8,
  'PM2_WL_BAND_C_11N_5G':16,
  'PM2_WL_BAND_C_11NG_ONLY':32,
  'PM2_WL_BAND_C_11G_ONLY':64,
  'PM2_WL_BAND_C_11N_ONLY':128,
  'PM2_WL_BAND_C_11N_5G_ONLY':256,
  'PM2_WL_BAND_C_11AC':512,
  'PM2_WL_BAND_C_11ACN_ONLY':1024,
  'PM2_WL_BAND_C_11AC_ONLY':2048,
  'PM2_WL_BAND_C_11AX_5G':4096,
  'PM2_WL_BAND_C_11AXAC_ONLY':8192,
  'PM2_WL_BAND_C_11AXACN_ONLY':16384,
  'PM2_WL_BAND_C_11AX_5G_ONLY':32768,
  'PM2_WL_BAND_C_11AX':65536,
  'PM2_WL_BAND_C_11AXN_ONLY':131072,
  'PM2_WL_BAND_C_11AXNG_ONLY':262144,
  'PM2_WL_BAND_C_11AX_ONLY':524288,
  'PM2_WL_BAND_C_11AC_2G':1048576,
  'PM2_WL_BAND_C_11AX_6G':2097152,
  'PM2_WL_BAND_C_11N_2G':4194304,
  'PM2_WL_BAND_C_11AC_5G':8388608,
  'PM2_WL_BAND_C_11AX_2G':16777216,
  'PM2_WL_BAND_C_11BE_2G':33554432,
  'PM2_WL_BAND_C_11BE_5G':67108864,
  'PM2_WL_BAND_C_11BE_6G':134217728,
  'PM2_VAP_ALL_TUNNEL':2,
  'PM2_VAP_ALL_BRIDGE':3,
  'PM2_VAP_ALL_MANUAL':4,
  'PM2_DRMA_MANUAL_MODE_AP':1,
  'PM2_DRMA_MANUAL_MODE_MONITOR':2,
  'PM2_DRMA_MANUAL_MODE_NCF':3,
  'PM2_DRMA_MANUAL_MODE_NCF_PEEK':4,
  'PM2_WTP_RADIO_POWER_MODE_DBM':1,
  'PM2_WTP_RADIO_POWER_MODE_PERCENTAGE':2,
  'PM2_WAN_PORT_MODE_WAN_LAN':0,
  'PM2_WAN_PORT_MODE_WAN_ONLY':1,
  'PM2_SPLIT_TUNNELING_ACL_PATH_TUNNEL':0,
  'PM2_SPLIT_TUNNELING_ACL_PATH_LOCAL':1,
  'PM2_CM_HEADER_OPT_NONE':6,
  'PM2_CM_FORMAT_OPT_NONE':5,
  'PM2_HTTP_E_BANNEDWORD':128,
  'PM2_HTTP_E_URLFILTER':131072,
  'PM2_HTTP_E_RANGEBLOCK':32768,
  'PM2_HTTP_E_FORTIGUARD':4096,
  'PM2_HTTP_E_ACTIVEXFILTER':64,
  'PM2_HTTP_E_COOKIEFILTER':1024,
  'PM2_HTTP_E_JAVAFILTER':8192,
  'PM2_HTTP_E_CONTENT_CHECK':536870912,
  'PM2_HTTP_E_INVALID_URL':268435456,
  'PM2_HTTP_E2_JSCRIPT':1,
  'PM2_HTTP_E2_JS':2,
  'PM2_HTTP_E2_VBS':4,
  'PM2_HTTP_E2_UNKNOWN':8,
  'PM2_HTTP_E2_INTRINSIC':16,
  'PM2_HTTP_E2_WF_REFERER':32,
  'PM2_HTTP_E2_WF_COOKIE':256,
  'PM2_HTTP_POST_NORMAL':1,
  'PM2_HTTP_POST_COMFORT':2,
  'PM2_HTTP_POST_BLOCK':4,
  'PM2_HTTPS_E_URLFILTER':8,
  'PM2_HTTPS_E_INVALID_URL':2,
  'PM2_HTTPS_E_FORTIGUARD':1,
  'PM2_HTTPS_E_BLK_SSL_UNK_SESS_ID':128,
  'PM2_HTTPS_E_BANNEDWORD':32768,
  'PM2_HTTPS_E_RANGEBLOCK':2097152,
  'PM2_HTTPS_E_ACTIVEXFILTER':16384,
  'PM2_HTTPS_E_COOKIEFILTER':262144,
  'PM2_HTTPS_E_JAVAFILTER':1048576,
  'PM2_HTTPS_E_CONTENT_CHECK':67108864,
  'PM2_HTTPS_E2_JSCRIPT':4,
  'PM2_HTTPS_E2_JS':16,
  'PM2_HTTPS_E2_VBS':32,
  'PM2_HTTPS_E2_UNKNOWN':64,
  'PM2_HTTPS_E2_INTRINSIC':2048,
  'PM2_HTTPS_E2_WF_REFERER':256,
  'PM2_HTTPS_E2_WF_COOKIE':4096,
  'PM2_SAFE_SEARCH_GOOGLE':1,
  'PM2_SAFE_SEARCH_YAHOO':2,
  'PM2_SAFE_SEARCH_BING':4,
  'PM2_SAFE_SEARCH_URL':8,
  'PM2_SAFE_SEARCH_HEADER':16,
  'PM2_WHITELIST_EXEMPT_AV':1,
  'PM2_WHITELIST_EXEMPT_WEBCONTENT':2,
  'PM2_WHITELIST_EXEMPT_ACTIVEX_JAVA_COOKIE':4,
  'PM2_WHITELIST_EXEMPT_DLP':8,
  'PM2_WHITELIST_EXEMPT_RANGEBLOCK':16,
  'PM2_WHITELIST_EXEMPT_EXT_LOG_OTHERS':32,
  'PM2_OPT_NONE':25,
  'PM2_OPT_MODERATE':26,
  'PM2_FTGD_OPT_ERROR_BLK':1,
  'PM2_FTGD_OPT_HTTP_ERR_BLK':2,
  'PM2_FTGD_OPT_RATE_IMG':4,
  'PM2_FTGD_OPT_OVRD':8,
  'PM2_FTGD_OPT_STRICT_BLOCK':16,
  'PM2_FTGD_OPT_SRV_IP_RATE':32,
  'PM2_FTGD_OPT_REDIR_BLOCK':128,
  'PM2_FTGD_OPT_CONN_BYPASS':256,
  'PM2_FO_TYPE_ASK':3,
  'PM2_FO_SCOPE_ASK':4,
  'PM2_FO_SCOPE_BROWSER':6,
  'PM2_FO_EXT_ASK':2,
  'PM2_FO_DUR_MODE_CONST':0,
  'PM2_FO_DUR_MODE_ASK':1,
  'PM2_WF_FILTER_BLOCK':0,
  'PM2_WF_FILTER_MONITOR':2,
  'PM2_WF_FILTER_WARNING':3,
  'PM2_WF_FILTER_AUTHENTICATE':4,
  'PM2_WARN_PROMPT_PER_DOMAIN':0,
  'PM2_WARN_PROMPT_PER_CATEGORY':1,
  'PM2_WARN_DUR_SESSION':0,
  'PM2_WARN_DUR_TIMEOUT':1,
  'PM2_FTGD_OPT_LOG_ALL_URL':512,
  'PM2_FTGD_OPT_FTGD_DISABLE':1024,
  'PM2_WF_PROF_FTGD_QUOTA_TYPE_TIME':0,
  'PM2_WF_PROF_FTGD_QUOTA_TYPE_TRAFFIC':1,
  'PM2_WF_PROF_FTGD_QUOTA_UNIT_B':0,
  'PM2_WF_PROF_FTGD_QUOTA_UNIT_KB':1,
  'PM2_WF_PROF_FTGD_QUOTA_UNIT_MB':2,
  'PM2_WF_PROF_FTGD_QUOTA_UNIT_GB':3,
  'PM2_FQ_ENABLE':1,
  'PM2_FQ_DISABLE':0,
  'PM2_FQ_EXEMPT':2,
  'PM2_PROF_TYPE_LIST':0,
  'PM2_PROF_TYPE_RADIUS':1,
  'PM2_HTTPS_E2_WF_HTTPS_SCAN':1024,
  'PM2_HTTPS_E2_PER_USER_BWL':8192,
  'PM2_HTTPS_E2_HTTPS_URL_SCAN':524288,
  'PM2_HTTPS_E_PER_USER_BAL':268435456,
  'PM2_PERMIT_BANNEDWORD_OVERRIDE':1,
  'PM2_PERMIT_URLFILTER_OVERRIDE':2,
  'PM2_PERMIT_FORTIGUARD_WF_OVERRIDE':4,
  'PM2_PERMIT_CONTENTTYPE_CHECK_OVERRIDE':8,
  'PM2_INSPECT_MODE_DNS':2,
  'PM2_SDNS_ACT_BLOCK':0,
  'PM2_SDNS_ACT_REDIRECT':1,
  'PM2_ALG_AUTO_LEARNING':0,
  'PM2_OPT_BLACKLIST':38,
  'PM2_OPT_WHITELIST':39,
  'PM2_FILTER_PROTOCOL_HTTP':1,
  'PM2_FILTER_PROTOCOL_FTP':2,
  'PM2_FILTER_ACTION_LOG':0,
  'PM2_FILTER_ACTION_BLOCK':1,
  'PM2_FILTER_DIR_DOWNLOAD':1,
  'PM2_FILTER_DIR_UPLOAD':2,
  'PM2_FILTER_DIR_ANY':0,
  'PM2_FILTER_ENCRYP_YES':1,
  'PM2_FILTER_ENCRYP_ANY':0,
  'PM2_WF_PATTERN_USERNAME':0,
  'PM2_WF_PATTERN_PASSWORD':1,
  'PM2_ANTIPHISH_PATTERNS_TYPE_REGEX':1,
  'PM2_ANTIPHISH_PATTERNS_TYPE_LITERAL':2,
  'PM2_ANTIPHISH_AUTH_DOMAIN_CONTROLLER':1,
  'PM2_ANTIPHISH_AUTH_LDAP':2,
  'PM2_ENCODING_UTF_8':1,
  'PM2_ENCODING_PUNYCODE':2,
  'PM2_E_AV_SCAN':1,
  'PM2_E_AV_BLOCK':2,
  'PM2_E_AV_AVMONITOR':16,
  'PM2_E_AV_AVQUERY':8,
  'PM2_E_AV_QUARANTINE':4,
  'PM2_E_AV_STRICTFILE':32,
  'PM2_AV_PROF_ENCRYPTED':1,
  'PM2_AV_PROF_CORRUPTED':2,
  'PM2_AV_PROF_MULTIPART':4,
  'PM2_AV_PROF_NESTED':8,
  'PM2_AV_PROF_MAILBOMB':16,
  'PM2_AV_PROF_UNHANDLED':32,
  'PM2_AV_PROF_PARTIALLYCORRUPTED':64,
  'PM2_AV_PROF_FILESLIMIT':128,
  'PM2_AV_PROF_TIMEOUT':256,
  'PM2_OUTBREAK_PREVENTION_DISABLE':3,
  'PM2_OUTBREAK_PREVENTION_BLOCK':4,
  'PM2_OUTBREAK_PREVENTION_MONITOR':5,
  'PM2_OPT_BLOCK2':56,
  'PM2_AV_PROF_FORTINDR_DISABLE':0,
  'PM2_AV_PROF_FORTINDR_BLOCK':1,
  'PM2_AV_PROF_FORTINDR_MONITOR':2,
  'PM2_AV_PROF_FORTISANDBOX_DISABLE':0,
  'PM2_AV_PROF_FORTISANDBOX_BLOCK':1,
  'PM2_AV_PROF_FORTISANDBOX_MONITOR':2,
  'PM2_UNKNOWN_CONTENT_ENCODING_BLOCK':1,
  'PM2_UNKNOWN_CONTENT_ENCODING_INSPECT':2,
  'PM2_UNKNOWN_CONTENT_ENCODING_BYPASS':3,
  'PM2_AV_PROF_EXE_DEFAULT':0,
  'PM2_AV_PROF_EXE_VIRUS':1,
  'PM2_QUAR_NONE':1,
  'PM2_QUAR_SRC_IP_ADDR':2,
  'PM2_QUAR_INTF':3,
  'PM2_FTGD_A_DISABLE':1,
  'PM2_FTGD_A_SUSPICIOUTS':2,
  'PM2_FTGD_A_EVERYTHING':3,
  'PM2_AV_PROF_SCAN_BOTNET_CONN_DISABLE':0,
  'PM2_AV_PROF_SCAN_BOTNET_CONN_BLOCK':1,
  'PM2_AV_PROF_SCAN_BOTNET_CONN_MONITOR':2,
  'PM2_SCAN_MODE_LEGACY':4,
  'PM2_SCAN_MODE_DEFAULT':5,
  'PM2_ORIG_FILE_DEST_FORTISANDBOX':0,
  'PM2_ORIG_FILE_DEST_QUARANTINE':1,
  'PM2_ORIG_FILE_DEST_DISCARD':2,
  'PM2_ACTION_LOG':3,
  'PM2_ACTION_IGNORE':6,
  'PM2_AV_PROF_FORTISANDBOX_MODE_INLINE':1,
  'PM2_AV_PROF_FORTISANDBOX_MODE_ANALYTICS_SUSPICIOUS':2,
  'PM2_AV_PROF_FORTISANDBOX_MODE_ANALYTICS_EVERYTHING':3,
  'PM2_FORTINDR_ACTION_LOG_ONLY':1,
  'PM2_FORTINDR_ACTION_BLOCK':2,
  'PM2_FORTINDR_ACTION_IGNORE':3,
  'PM2_IMAP_E_BANNEDWORD':64,
  'PM2_IMAP_E_SPAMEMAILBWL':1024,
  'PM2_IMAP_E_SPAMFEIP':2048,
  'PM2_IMAP_E_SPAMFSSUBMIT':8192,
  'PM2_IMAP_E_SPAMFSCHKSUM':4096,
  'PM2_IMAP_E_SPAMFSURL':16384,
  'PM2_IMAP_E_SPAMIPBWL':65536,
  'PM2_IMAP_E_SPAMRADDRDNS':131072,
  'PM2_IMAP_E_SPAMRBL':262144,
  'PM2_IMAP_E_SPAMHDRCHECK':32768,
  'PM2_IMAP_E_SPAMFSPHISH':33554432,
  'PM2_SPAM_PASS':1,
  'PM2_SPAM_TAG':2,
  'PM2_SPAM_TAG_SUBJECT':1,
  'PM2_SPAM_TAG_HEADER':2,
  'PM2_SPAM_TAG_SPAMINFO':4,
  'PM2_POP3_E_BANNEDWORD':64,
  'PM2_POP3_E_SPAMEMAILBWL':1024,
  'PM2_POP3_E_SPAMFEIP':2048,
  'PM2_POP3_E_SPAMFSSUBMIT':2097152,
  'PM2_POP3_E_SPAMFSCHKSUM':1048576,
  'PM2_POP3_E_SPAMFSURL':524288,
  'PM2_POP3_E_SPAMIPBWL':4096,
  'PM2_POP3_E_SPAMRADDRDNS':8192,
  'PM2_POP3_E_SPAMRBL':16384,
  'PM2_POP3_E_SPAMHDRCHECK':4194304,
  'PM2_POP3_E_SPAMFSPHISH':33554432,
  'PM2_SMTP_E_BANNEDWORD':64,
  'PM2_SMTP_E_SPAMEMAILBWL':512,
  'PM2_SMTP_E_SPAMFEIP':1024,
  'PM2_SMTP_E_SPAMFSSUBMIT':4096,
  'PM2_SMTP_E_SPAMFSCHKSUM':2048,
  'PM2_SMTP_E_SPAMFSURL':8192,
  'PM2_SMTP_E_SPAMHELODNS':32768,
  'PM2_SMTP_E_SPAMIPBWL':65536,
  'PM2_SMTP_E_SPAMRADDRDNS':131072,
  'PM2_SMTP_E_SPAMRBL':262144,
  'PM2_SMTP_E_SPAMHDRCHECK':16384,
  'PM2_SMTP_E_SPAMFSPHISH':134217728,
  'PM2_SPAM_DISCARD':4,
  'PM2_IMAPS_E_BANNEDWORD':64,
  'PM2_IMAPS_E_SPAMEMAILBWL':512,
  'PM2_IMAPS_E_SPAMFEIP':1024,
  'PM2_IMAPS_E_SPAMFSSUBMIT':4096,
  'PM2_IMAPS_E_SPAMFSCHKSUM':2048,
  'PM2_IMAPS_E_SPAMFSURL':8192,
  'PM2_IMAPS_E_SPAMIPBWL':32768,
  'PM2_IMAPS_E_SPAMRADDRDNS':65536,
  'PM2_IMAPS_E_SPAMRBL':131072,
  'PM2_IMAPS_E_SPAMHDRCHECK':16384,
  'PM2_IMAPS_E_SPAMFSPHISH':8388608,
  'PM2_POP3S_E_BANNEDWORD':64,
  'PM2_POP3S_E_SPAMEMAILBWL':512,
  'PM2_POP3S_E_SPAMFEIP':1024,
  'PM2_POP3S_E_SPAMFSSUBMIT':4096,
  'PM2_POP3S_E_SPAMFSCHKSUM':2048,
  'PM2_POP3S_E_SPAMFSURL':8192,
  'PM2_POP3S_E_SPAMIPBWL':32768,
  'PM2_POP3S_E_SPAMRADDRDNS':65536,
  'PM2_POP3S_E_SPAMRBL':131072,
  'PM2_POP3S_E_SPAMHDRCHECK':16384,
  'PM2_POP3S_E_SPAMFSPHISH':8388608,
  'PM2_SMTPS_E_BANNEDWORD':64,
  'PM2_SMTPS_E_SPAMEMAILBWL':512,
  'PM2_SMTPS_E_SPAMFEIP':1024,
  'PM2_SMTPS_E_SPAMFSSUBMIT':4096,
  'PM2_SMTPS_E_SPAMFSCHKSUM':2048,
  'PM2_SMTPS_E_SPAMFSURL':8192,
  'PM2_SMTPS_E_SPAMHELODNS':32768,
  'PM2_SMTPS_E_SPAMIPBWL':65536,
  'PM2_SMTPS_E_SPAMRADDRDNS':131072,
  'PM2_SMTPS_E_SPAMRBL':262144,
  'PM2_SMTPS_E_SPAMHDRCHECK':16384,
  'PM2_SMTPS_E_SPAMFSPHISH':33554432,
  'PM2_SPAM_FILTER_E_BANNEDWORD':1,
  'PM2_SPAM_FILTER_E_SPAMEMAILBWL':2,
  'PM2_SPAM_FILTER_E_SPAMFEIP':4,
  'PM2_SPAM_FILTER_E_SPAMFSSUBMIT':8,
  'PM2_SPAM_FILTER_E_SPAMFSCHKSUM':16,
  'PM2_SPAM_FILTER_E_SPAMFSURL':32,
  'PM2_SPAM_FILTER_E_SPAMHELODNS':64,
  'PM2_SPAM_FILTER_E_SPAMIPBWL':128,
  'PM2_SPAM_FILTER_E_SPAMRADDRDNS':256,
  'PM2_SPAM_FILTER_E_SPAMRBL':512,
  'PM2_SPAM_FILTER_E_SPAMHDRCHECK':1024,
  'PM2_SPAM_FILTER_E_SPAMFSPHISH':2048,
  'PM2_SPAM_FILTER_E_SPAMBWL':4096,
  'PM2_HTTP_E_CLIENTCOMFORT':512,
  'PM2_HTTP_E_SERVERCOMFORT':1073741824,
  'PM2_HTTP_E_OVERSIZE':4,
  'PM2_HTTP_E_NOCONTENTSUM':16384,
  'PM2_HTTP_E_CHUNKBYPASS':256,
  'PM2_ENC_JISX0201':1,
  'PM2_ENC_JISX0208':2,
  'PM2_ENC_JISX0212':4,
  'PM2_ENC_GB2312':8,
  'PM2_ENC_KSC5601_EX':16,
  'PM2_ENC_EUC_JP':32,
  'PM2_ENC_SJIS':64,
  'PM2_ENC_ISO2022_JP':128,
  'PM2_ENC_ISO2022_JP1':256,
  'PM2_ENC_ISO2022_JP2':512,
  'PM2_ENC_EUC_CN':1024,
  'PM2_ENC_CES_GBK':2048,
  'PM2_ENC_HZ':4096,
  'PM2_ENC_CES_BIG5':8192,
  'PM2_ENC_EUC_KR':16384,
  'PM2_ENC_ISO2022_JP3':32768,
  'PM2_ENC_TIS620':131072,
  'PM2_ENC_CP874':262144,
  'PM2_ENC_CP1252':524288,
  'PM2_ENC_CP1251':1048576,
  'PM2_CLT_CERT_REQ_BYPASS':0,
  'PM2_CLI_CERT_REQ_BLOCK':2,
  'PM2_TCP_WINDOW_TYPE_SYSTEM':0,
  'PM2_TCP_WINDOW_TYPE_STATIC':1,
  'PM2_TCP_WINDOW_TYPE_DYNAMIC':2,
  'PM2_TCP_WINDOW_TYPE_AUTO_TUNING':3,
  'PM2_OPT_INSPECT':61,
  'PM2_ACTION_ALLOW':12,
  'PM2_HTTPS_E_INVALID_SERV_CERT':16777216,
  'PM2_HTTPS_E_NOCONTENTSUM':4,
  'PM2_HTTPS_E_CLIENTCOMFORT':131072,
  'PM2_HTTPS_E_OVERSIZE':1024,
  'PM2_HTTPS_E_CHUNKBYPASS':65536,
  'PM2_HTTPS_E_SSL_CA_LIST':134217728,
  'PM2_CLT_CERT_REQ_INSPECT':1,
  'PM2_FTP_E_CLIENTCOMFORT':4,
  'PM2_FTP_E_OVERSIZE':16,
  'PM2_FTP_E_NOCONTENTSUM':8,
  'PM2_FTP_E_SPLICE':256,
  'PM2_FTP_E_BYPASS_RESET':32768,
  'PM2_FTP_E_BYPASS_MODE':65536,
  'PM2_IMAP_E_FRAGMAIL':256,
  'PM2_IMAP_E_OVERSIZE':4,
  'PM2_IMAP_E_NOCONTENTSUM':512,
  'PM2_POP3_E_FRAGMAIL':256,
  'PM2_POP3_E_OVERSIZE':4,
  'PM2_POP3_E_NOCONTENTSUM':512,
  'PM2_SMTP_E_FRAGMAIL':128,
  'PM2_SMTP_E_OVERSIZE':4,
  'PM2_SMTP_E_NOCONTENTSUM':256,
  'PM2_SMTP_E_SPLICE':524288,
  'PM2_NNTP_E_OVERSIZE':4,
  'PM2_NNTP_E_NOCONTENTSUM':64,
  'PM2_NNTP_E_SPLICE':8192,
  'PM2_IM_E_OVERSIZE':4,
  'PM2_IM_E_NOCONTENTSUM':128,
  'PM2_IMAPS_E_FRAGMAIL':128,
  'PM2_IMAPS_E_OVERSIZE':4,
  'PM2_IMAPS_E_INVALID_SERV_CERT':1048576,
  'PM2_IMAPS_E_NOCONTENTSUM':256,
  'PM2_IMAPS_E_SSL_CA_LIST':4194304,
  'PM2_POP3S_E_FRAGMAIL':128,
  'PM2_POP3S_E_OVERSIZE':4,
  'PM2_POP3S_E_INVALID_SERV_CERT':1048576,
  'PM2_POP3S_E_NOCONTENTSUM':256,
  'PM2_POP3S_E_SSL_CA_LIST':4194304,
  'PM2_SMTPS_E_FRAGMAIL':128,
  'PM2_SMTPS_E_OVERSIZE':4,
  'PM2_SMTPS_E_INVALID_SERV_CERT':4194304,
  'PM2_SMTPS_E_NOCONTENTSUM':256,
  'PM2_SMTPS_E_SPLICE':524288,
  'PM2_SMTPS_E_SSL_CA_LIST':16777216,
  'PM2_FTPS_E_CLIENTCOMFORT':1,
  'PM2_FTPS_E_OVERSIZE':2,
  'PM2_FTPS_E_INVALID_SERV_CERT':4,
  'PM2_FTPS_E_NOCONTENTSUM':8,
  'PM2_FTPS_E_SPLICE':16,
  'PM2_FTPS_E_SSL_CA_LIST':32,
  'PM2_MAPI_E_FRAGMAIL':1,
  'PM2_MAPI_E_OVERSIZE':2,
  'PM2_MAPI_E_NOCONTENTSUM':4,
  'PM2_SERVER_CREDENTIAL_TYPE_NONE':1,
  'PM2_SERVER_CREDENTIAL_TYPE_CREDENTIAL_REPLICATION':2,
  'PM2_SERVER_CREDENTIAL_TYPE_CREDENTIAL_KEYTAB':3,
  'PM2_FILTER_PROTO_CIFS':1,
  'PM2_ISIS_ISTYPE_L1L2':0,
  'PM2_ISIS_ISTYPE_L1':1,
  'PM2_ISIS_ISTYPE_L2':2,
  'PM2_AUTH_MODE_PASSWD':1,
  'PM2_AUTH_MODE_MD5':0,
  'PM2_ISIS_F_OL_SUPPRESS_EXTERNAL':16,
  'PM2_ISIS_F_OL_SUPPRESS_INTERLEVEL':32,
  'PM2_ISIS_IFNET_BROADCAST':1,
  'PM2_ISIS_IFNET_POINTOPOINT':2,
  'PM2_ISIS_IFNET_LOOPBACK':3,
  'PM2_METRIC_EXTERNAL':0,
  'PM2_METRIC_INTERNAL':1,
  'PM2_ISIS_MSTYLE_NARROW':0,
  'PM2_ISIS_MSTYLE_NARROW_TRANS':1,
  'PM2_ISIS_MSTYLE_NARROW_TRANS_L1':2,
  'PM2_ISIS_MSTYLE_NARROW_TRANS_L2':3,
  'PM2_ISIS_MSTYLE_WIDE':4,
  'PM2_ISIS_MSTYLE_WIDE_L1':5,
  'PM2_ISIS_MSTYLE_WIDE_L2':6,
  'PM2_ISIS_MSTYLE_WIDE_TRANS':7,
  'PM2_ISIS_MSTYLE_WIDE_TRANS_L1':8,
  'PM2_ISIS_MSTYLE_WIDE_TRANS_L2':9,
  'PM2_ISIS_MSTYLE_TRANS':10,
  'PM2_ISIS_MSTYLE_TRANS_L1':11,
  'PM2_ISIS_MSTYLE_TRANS_L2':12,
  'PM2_APS_AMC_MODE_FW_IPS':3,
  'PM2_APS_AMC_MODE_FW_ONLY_RR':1,
  'PM2_APS_AMC_MODE_FW_ONLY_HASH':2,
  'PM2_IPS_WEITH_LESS_FW':0,
  'PM2_IPS_WEIGHT_BALANCED':1,
  'PM2_IPS_WEIGHT_ALL_IPS':2,
  'PM2_APS_FP_DISABLE_FW':1,
  'PM2_APS_FP_DISABLE_IPS':2,
  'PM2_APS_FP_DISABLE_IPSEC':4,
  'PM2_APS_FP_DISABLE_MCAST':8,
  'PM2_APS_FP_DISABLE_DOS':16,
  'PM2_APS_FP_DISABLE_NONE':128,
  'PM2_SW_MODE_TRUNK':1,
  'PM2_SW_MODE_MAPPING1':2,
  'PM2_SW_MODE_MAPPING2':3,
  'PM2_OPT_FAIL':32,
  'PM2_DISK_USAGE_MIX':2,
  'PM2_DISK_USAGE_UNUSED':3,
  'PM2_WANOPT_MODE_MIX':0,
  'PM2_WANOPT_MODE_WANOPT':1,
  'PM2_WANOPT_MODE_WEBCACHE':2,
  'PM2_STYLE_FONT':1,
  'PM2_STYLE_TEXT':2,
  'PM2_STYLE_COLOR':4,
  'PM2_STYLE_ALIGN':8,
  'PM2_STYLE_SIZE':16,
  'PM2_STYLE_MARGIN':32,
  'PM2_STYLE_BORDER':64,
  'PM2_STYLE_PADDING':128,
  'PM2_STYLE_COLUMN':256,
  'PM2_FONT_VERDANA':1,
  'PM2_FONT_ARIAL':2,
  'PM2_FONT_HELVETICA':3,
  'PM2_FONT_COURIER':4,
  'PM2_FONT_TIMES':5,
  'PM2_FONT_STYLE_NORMAL':0,
  'PM2_FONT_STYLE_ITALIC':1,
  'PM2_FONT_WEIGHT_NORMAL':0,
  'PM2_FONT_WEIGHT_BOLD':1,
  'PM2_ALIGN_LEFT':1,
  'PM2_ALIGN_CENTER':2,
  'PM2_ALIGN_RIGHT':3,
  'PM2_ALIGN_JUSTIFY':4,
  'PM2_REPORT_LAYOUT_INC_TABLE_OF_CONTENT':2,
  'PM2_REPORT_LAYOUT_AUTO_NUMBER_HEAD':64,
  'PM2_REPORT_LAYOUT_VIEW_CHARTS_HEAD':128,
  'PM2_REPORT_LAYOUT_SHOW_NAV_HEAD':256,
  'PM2_REPORT_LAYOUT_DUMMY_OPTION':512,
  'PM2_PAPER_A4':0,
  'PM2_PAPER_LETTER':1,
  'PM2_SECTION_HEAD_1':16,
  'PM2_SECTION_HEAD_2':32,
  'PM2_SECTION_HEAD_3':64,
  'PM2_HEADER_ON_FIRST_PAGE':1,
  'PM2_FOOTER_ON_FIRST_PAGE':2,
  'PM2_LAYOUT_ITEM_TEXT':0,
  'PM2_LAYOUT_ITEM_IMAGE':1,
  'PM2_COMP_TYPE_HD1':4,
  'PM2_COMP_TYPE_HD2':8,
  'PM2_COMP_TYPE_HD3':16,
  'PM2_REPORT_ITEM_TEXT':1,
  'PM2_REPORT_ITEM_IMAGE':2,
  'PM2_REPORT_ITEM_CHART':3,
  'PM2_REPORT_ITEM_MISC':6,
  'PM2_TEXT_COMP_TEXT':0,
  'PM2_TEXT_COMP_HEADING1':1,
  'PM2_TEXT_COMP_HEADING2':2,
  'PM2_TEXT_COMP_HEADING3':3,
  'PM2_LIST_COMP_BULLET':0,
  'PM2_LIST_COMP_NUMBERED':1,
  'PM2_CHART_OPT_INCLUDE_NO_DATA':1,
  'PM2_CHART_OPT_HIDE_TITLE':2,
  'PM2_CHART_OPT_SHOW_CAPTION':4,
  'PM2_MISC_COMP_HLINE':0,
  'PM2_MISC_COMP_PAGE_BREAK':1,
  'PM2_MISC_COMP_COLUMN_BREAK':2,
  'PM2_MISC_COMP_SECTION_START':3,
  'PM2_RPTFMT_HTML':1,
  'PM2_RPTFMT_PDF':2,
  'PM2_SCHED_DEMAND':6,
  'PM2_SCHED_ONCE':5,
  'PM2_CUTOFF_OPT_RUN_TIME':0,
  'PM2_CUTOFF_OPT_CUSTOM':1,
  'PM2_SPAM_RESPOND':16,
  'PM2_SSL_MODE_OFF':0,
  'PM2_SSL_CLIENT_RENEG_ALLOW':0,
  'PM2_SSL_CLIENT_RENEG_DENY':1,
  'PM2_SSL_CLIENT_RENEG_SECURE':2,
  'PM2_FEATURE_SET_FLOW':1,
  'PM2_FEATURE_SET_PROXY':2,
  'PM2_FEATURE_SET_IPS':3,
  'PM2_FEATURE_SET_VOIPD':4,
  'PM2_ACTION_MONITOR':9,
  'PM2_NETSCAN_ASSETS_MODE_SCAN':0,
  'PM2_NETSCAN_ASSETS_MODE_DISCOVERY':1,
  'PM2_ADDR_TYPE_RANGE':12,
  'PM2_SCAN_MODE_STANDARD':1,
  'PM2_ORIENT_PROTRAIT':0,
  'PM2_ORIENT_LANDSCAPE':1,
  'PM2_FMC_SLOT_OBJ_TYPE_NONE':0,
  'PM2_FMC_SLOT_OBJ_TYPE_XD2':3,
  'PM2_FMC_SLOT_OBJ_TYPE_XG2':4,
  'PM2_FMC_SLOT_OBJ_TYPE_SCSI_HDD':2,
  'PM2_FMC_SLOT_OBJ_TYPE_AUTO':1,
  'PM2_FMC_SLOT_OBJ_TYPE_C20':5,
  'PM2_FMC_SLOT_OBJ_TYPE_F20':6,
  'PM2_FMC_SLOT_OBJ_TYPE_XH0':7,
  'PM2_FMC_SLOT_OBJ_TYPE_XD0':8,
  'PM2_ELBC_MODE_NONE':0,
  'PM2_ELBC_MODE_SERVICE_GROUP':1,
  'PM2_ELBC_FORTI_TRUNK':2,
  'PM2_ELNC_CONTENT_CLUSTER':3,
  'PM2_ELBC_FORTICONTROLLER':4,
  'PM2_ELBC_DUAL_FORTICONTROLLER':5,
  'PM2_OPT_DELEGATED':24,
  'PM2_IP_MODE_DELEGATED':3,
  'PM2_PROTOCOL_HTTP':1,
  'PM2_PROTOCOL_FTP':2,
  'PM2_PROTOCOL_CONNECT':4,
  'PM2_PROTOCOL_SOCKS':8,
  'PM2_PROTOCOL_ALL':16,
  'PM2_WEB_PROXY_AUTH_DISABLED':0,
  'PM2_WEB_PROXY_AUTH_IMMEDIATELY':1,
  'PM2_WEB_PROXY_AUTH_UPON_CHALLENGE':2,
  'PM2_COUNTRY_CS':86,
  'PM2_COUNTRY_ZB':1120,
  'PM2_COUNTRY_CX':1121,
  'PM2_COUNTRY_DM':1122,
  'PM2_COUNTRY_MQ':1123,
  'PM2_COUNTRY_YT':1124,
  'PM2_COUNTRY_BL':1125,
  'PM2_COUNTRY___':1126,
  'PM2_FAKE_SSID_ACTION_LOG':1,
  'PM2_FAKE_SSID_ACTION_SUPPRESS':2,
  'PM2_PLATFORM_30B_50B':1,
  'PM2_PLATFORM_60B':2,
  'PM2_PLATFORM_80CM_81CM':3,
  'PM2_PLATFORM_220A':4,
  'PM2_PLATFORM_220B':5,
  'PM2_PLATFORM_210B':6,
  'PM2_PLATFORM_60C':7,
  'PM2_PLATFORM_222B':8,
  'PM2_PLATFORM_112B':9,
  'PM2_PLATFORM_320B':10,
  'PM2_PLATFORM_11C':11,
  'PM2_PLATFORM_14C':12,
  'PM2_PLATFORM_223B':13,
  'PM2_PLATFORM_28C':14,
  'PM2_PLATFORM_320C':15,
  'PM2_PLATFORM_221C':16,
  'PM2_PLATFORM_25D':17,
  'PM2_PLATFORM_222C':18,
  'PM2_PLATFORM_224D':19,
  'PM2_PLATFORM_214B':20,
  'PM2_PLATFORM_21D':21,
  'PM2_PLATFORM_24D':22,
  'PM2_PLATFORM_112D':23,
  'PM2_PLATFORM_223C':24,
  'PM2_PLATFORM_321C':25,
  'PM2_PLATFORM_C220C':26,
  'PM2_PLATFORM_C225C':27,
  'PM2_PLATFORM_S321C':28,
  'PM2_PLATFORM_S323C':29,
  'PM2_PLATFORM_FWF':30,
  'PM2_PLATFORM_S311C':31,
  'PM2_PLATFORM_S313C':32,
  'PM2_PLATFORM_AP_11N':33,
  'PM2_PLATFORM_S322C':34,
  'PM2_PLATFORM_S321CR':35,
  'PM2_PLATFORM_S322CR':36,
  'PM2_PLATFORM_S323CR':37,
  'PM2_PLATFORM_S421E':38,
  'PM2_PLATFORM_S422E':39,
  'PM2_PLATFORM_S423E':40,
  'PM2_PLATFORM_421E':41,
  'PM2_PLATFORM_423E':42,
  'PM2_PLATFORM_C221E':43,
  'PM2_PLATFORM_C226E':44,
  'PM2_PLATFORM_C23JD':45,
  'PM2_PLATFORM_C24JE':46,
  'PM2_PLATFORM_C21D':47,
  'PM2_PLATFORM_U421E':48,
  'PM2_PLATFORM_U423E':49,
  'PM2_PLATFORM_221E':50,
  'PM2_PLATFORM_222E':51,
  'PM2_PLATFORM_223E':52,
  'PM2_PLATFORM_S221E':53,
  'PM2_PLATFORM_S223E':54,
  'PM2_PLATFORM_U221EV':55,
  'PM2_PLATFORM_U223EV':56,
  'PM2_PLATFORM_U321EV':57,
  'PM2_PLATFORM_U323EV':58,
  'PM2_PLATFORM_224E':59,
  'PM2_PLATFORM_U422EV':60,
  'PM2_PLATFORM_U24JEV':61,
  'PM2_PLATFORM_321E':62,
  'PM2_PLATFORM_U431F':63,
  'PM2_PLATFORM_U433F':64,
  'PM2_PLATFORM_231E':65,
  'PM2_PLATFORM_431F':66,
  'PM2_PLATFORM_433F':67,
  'PM2_PLATFORM_231F':68,
  'PM2_PLATFORM_432F':69,
  'PM2_PLATFORM_234F':70,
  'PM2_PLATFORM_23JF':71,
  'PM2_PLATFORM_U231F':72,
  'PM2_PLATFORM_831F':73,
  'PM2_PLATFORM_U234F':74,
  'PM2_PLATFORM_U432F':75,
  'PM2_PLATFORM_431FL':76,
  'PM2_PLATFORM_432FR':77,
  'PM2_PLATFORM_433FL':78,
  'PM2_PLATFORM_231FL':79,
  'PM2_PLATFORM_231G':80,
  'PM2_PLATFORM_233G':81,
  'PM2_PLATFORM_431G':82,
  'PM2_PLATFORM_433G':83,
  'PM2_PLATFORM_U231G':84,
  'PM2_PLATFORM_U441G':85,
  'PM2_PLATFORM_234G':86,
  'PM2_PLATFORM_432G':87,
  'PM2_PLATFORM_441K':88,
  'PM2_PLATFORM_443K':89,
  'PM2_PLATFORM_241K':90,
  'PM2_PLATFORM_243K':91,
  'PM2_PLATFORM_231K':92,
  'PM2_PLATFORM_23JK':93,
  'PM2_PLATFORM_MODE_DUAL_5G':0,
  'PM2_PLATFORM_MODE_SINGLE_5G':1,
  'PM2_RADIO_MODE_DISABLED':0,
  'PM2_RADIO_MODE_AP':1,
  'PM2_RADIO_MODE_MONITOR':2,
  'PM2_RADIO_MODE_SNIFFER':3,
  'PM2_RADIO_MODE_SAM':5,
  'PM2_CHANNEL_BONDING_DISABLE':0,
  'PM2_CHANNEL_BONDING_ENABLE':1,
  'PM2_CHANNEL_BONDING_80MHZ':2,
  'PM2_CHANNEL_BONDING_40MHZ':3,
  'PM2_CHANNEL_BONDING_20MHZ':4,
  'PM2_CHANNEL_BONDING_160MHZ':5,
  'PM2_CHANNEL_BONDING_320MHZ':6,
  'PM2_CHANNEL_BONDING_240MHZ':7,
  'PM2_PROTECTION_MODE_RTSCTS':0,
  'PM2_PROTECTION_MODE_CTSONLY':1,
  'PM2_PROTECTION_MODE_DISABLE':2,
  'PM2_POWERSAVE_OPT_TIM':1,
  'PM2_POWERSAVE_OPT_AC_VO':2,
  'PM2_POWERSAVE_OPT_NO_OBSS_SCAN':4,
  'PM2_POWERSAVE_OPT_NO_11N_RATE':8,
  'PM2_POWERSAVE_OPT_CLIENT_RATE_FOLLOW':16,
  'PM2_TRANS_OPT_DISABLE':1,
  'PM2_TRANS_OPT_POWER_SAVE':2,
  'PM2_TRANS_OPT_AGGR_LIMIT':4,
  'PM2_TRANS_OPT_RETRY_LIMIT':8,
  'PM2_TRANS_OPT_SEND_BAR':16,
  'PM2_BAND_5G_TYPE_FULL':0,
  'PM2_BAND_5G_TYPE_HIGH':1,
  'PM2_BAND_5G_TYPE_LOW':2,
  'PM2_DRMA_SENSITIVITY_LOW':1,
  'PM2_DRMA_SENSITIVITY_MEDIUM':2,
  'PM2_DRMA_SENSITIVITY_HIGH':3,
  'PM2_SAM_TEST_PING':1,
  'PM2_SAM_TEST_IPERF':2,
  'PM2_IPERF_PROTOCOL_UDP':1,
  'PM2_IPERF_PROTOCOL_TCP':2,
  'PM2_SAM_SERVER_TYPE_IP':1,
  'PM2_SAM_SERVER_TYPE_FQDN':2,
  'PM2_BSS_COLOR_MODE_AUTO':1,
  'PM2_BSS_COLOR_MODE_STATIC':2,
  'PM2_OPTIONAL_ANTENNA_NONE':0,
  'PM2_OPTIONAL_ANTENNA_FANT_04ABGN_0606_O_N':1,
  'PM2_OPTIONAL_ANTENNA_FANT_04ABGN_1414_P_N':2,
  'PM2_OPTIONAL_ANTENNA_FANT_04ABGN_8065_P_N':3,
  'PM2_OPTIONAL_ANTENNA_FANT_04ABGN_0606_O_R':4,
  'PM2_OPTIONAL_ANTENNA_FANT_04ABGN_0606_P_R':5,
  'PM2_OPTIONAL_ANTENNA_FANT_10ACAX_1213_D_N':6,
  'PM2_OPTIONAL_ANTENNA_FANT_08ABGN_1213_D_R':7,
  'PM2_OPTIONAL_ANTENNA_CUSTOM':8,
  'PM2_MIMO_MODE_DEFAULT':1,
  'PM2_MIMO_MODE_1X1':2,
  'PM2_MIMO_MODE_2X2':3,
  'PM2_MIMO_MODE_3X3':4,
  'PM2_MIMO_MODE_4X4':5,
  'PM2_MIMO_MODE_8X8':6,
  'PM2_CHANNEL_BONDING_EXT_320MHZ_1':1,
  'PM2_CHANNEL_BONDING_EXT_320MHZ_2':2,
  'PM2_AP_SNIFFER_CHAN_WIDTH_320MHZ':1,
  'PM2_AP_SNIFFER_CHAN_WIDTH_240MHZ':2,
  'PM2_AP_SNIFFER_CHAN_WIDTH_160MHZ':3,
  'PM2_AP_SNIFFER_CHAN_WIDTH_80MHZ':4,
  'PM2_AP_SNIFFER_CHAN_WIDTH_40MHZ':5,
  'PM2_AP_SNIFFER_CHAN_WIDTH_20MHZ':6,
  'PM2_DTLS_POLICY_CLEAR_TEXT':1,
  'PM2_DTLS_POLICY_DTLS_ENABLED':2,
  'PM2_DTLS_POLICY_IPSEC_VPN':4,
  'PM2_DTLS_POLICY_IPSEC_SN_VPN':8,
  'PM2_OPT_FOREIGN':30,
  'PM2_OPT_BOTH':31,
  'PM2_AEROSCOUT_AP_MAC_BSSID':0,
  'PM2_AEROSCOUT_AP_MAC_BOARD_MAC':1,
  'PM2_POLESTAR_PROTOCOL_WSS':1,
  'PM2_BLE_RTLS_NONE':1,
  'PM2_BLE_RTLS_POLESTAR':2,
  'PM2_BLE_RTLS_EVRESYS':3,
  'PM2_POE_MODE_AUTO':0,
  'PM2_POE_MODE_8023AF':1,
  'PM2_POE_MODE_8023AT':2,
  'PM2_POE_MODE_PWR_ADAPTER':3,
  'PM2_POE_MODE_FULL':4,
  'PM2_POE_MODE_HIGH':5,
  'PM2_POE_MODE_LOW':6,
  'PM2_INDOOR_OUTDOOR_DEPLOYMENT_PLATFORM_DETERMINED':1,
  'PM2_INDOOR_OUTDOOR_DEPLOYMENT_OUTDOOR':2,
  'PM2_INDOOR_OUTDOOR_DEPLOYMENT_INDOOR':3,
  'PM2_ESL_SES_DONGLE_COMPLIANCE_LEVEL_2':1,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_NEG1':2147483647,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_0':0,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_1':1,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_2':2,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_3':3,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_4':4,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_5':5,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_6':6,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_7':7,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_8':8,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_9':9,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_10':10,
  'PM2_ESL_SES_DONGLE_ESL_CHANNEL_127':127,
  'PM2_ESL_SES_DONGLE_OUTPUT_POWER_A':1,
  'PM2_ESL_SES_DONGLE_OUTPUT_POWER_B':2,
  'PM2_ESL_SES_DONGLE_OUTPUT_POWER_C':3,
  'PM2_ESL_SES_DONGLE_OUTPUT_POWER_D':4,
  'PM2_ESL_SES_DONGLE_OUTPUT_POWER_E':5,
  'PM2_ESL_SES_DONGLE_OUTPUT_POWER_F':6,
  'PM2_ESL_SES_DONGLE_OUTPUT_POWER_G':7,
  'PM2_ESL_SES_DONGLE_OUTPUT_POWER_H':8,
  'PM2_ESL_SES_DONGLE_COEX_LEVEL_NONE':0,
  'PM2_WAN_PORT_AUTH_TYPE_NONE':0,
  'PM2_WAN_PORT_AUTH_TYPE_802_1X':1,
  'PM2_WAN_PORT_AUTH_METHODS_ALL':1,
  'PM2_WAN_PORT_AUTH_METHODS_EAP_FAST':2,
  'PM2_WAN_PORT_AUTH_METHODS_EAP_TLS':3,
  'PM2_WAN_PORT_AUTH_METHODS_EAP_PEAP':4,
  'PM2_DESTINATION_FAZ':2,
  'PM2_DESTINATION_FAS':3,
  'PM2_OPT_SERVER':29,
  'PM2_DDNS_FORTIGUARD':12,
  'PM2_DDNS_NOIP_COM':13,
  'PM2_AVFT_MPEG':45,
  'PM2_AVFT_MOV':46,
  'PM2_AVFT_MP3':47,
  'PM2_AVFT_WMA':48,
  'PM2_AVFT_WAV':49,
  'PM2_AVFT_PDF':50,
  'PM2_AVFT_AVI':51,
  'PM2_AVFT_RM':52,
  'PM2_AVFT_TORRENT':53,
  'PM2_AVFT_HIBUN':54,
  'PM2_AVFT_7Z':55,
  'PM2_AVFT_XZ':56,
  'PM2_AVFT_MSOFFICEX':57,
  'PM2_AVFT_MSI':44,
  'PM2_AVFT_MASH_O':58,
  'PM2_AVFT_DMG':59,
  'PM2_AVFT_NET':60,
  'PM2_AVFT_XAR':61,
  'PM2_AVFT_CHM':62,
  'PM2_AVFT_ISO':63,
  'PM2_AVFT_CRX':64,
  'PM2_AVFT_FLAC':65,
  'PM2_AVFT_REGISTRY':71,
  'PM2_AVFT_HWP':72,
  'PM2_AVFT_RPM':73,
  'PM2_AVFT_C_CPP':74,
  'PM2_AVFT_PFILE':75,
  'PM2_AVFT_LZIP':76,
  'PM2_AVFT_WASM':77,
  'PM2_AVFT_SYLK':78,
  'PM2_AVFT_SHELLSCRIPT':30,
  'PM2_SERVER_TYPE_SAMBA':0,
  'PM2_SCHED_NONE':0,
  'PM2_VDOM_OPT_MGMT':0,
  'PM2_VDOM_OPT_CURRENT':1,
  'PM2_OPT_ERROR':16,
  'PM2_PROF_METHOD_DELETE':1,
  'PM2_PROF_METHOD_GET':2,
  'PM2_PROF_METHOD_HEAD':4,
  'PM2_PROF_METHOD_OPTIONS':8,
  'PM2_PROF_METHOD_POST':16,
  'PM2_PROF_METHOD_PUT':32,
  'PM2_PROF_METHOD_TRACE':64,
  'PM2_PROF_METHOD_OTHER':128,
  'PM2_PROF_METHOD_CONNECT':256,
  'PM2_ICAP_HEADERS_SRC_CONTENT':1,
  'PM2_ICAP_HEADERS_SRC_HTTP_HEADER':2,
  'PM2_ICAP_HEADERS_SRC_SESSION':3,
  'PM2_SESSION_INFO_TYPE_CLIENT_IP':1,
  'PM2_SESSION_INFO_TYPE_USER':2,
  'PM2_SESSION_INFO_TYPE_UPN':3,
  'PM2_SESSION_INFO_TYPE_DOMAIN':4,
  'PM2_SESSION_INFO_TYPE_LOCAL_GRP':5,
  'PM2_SESSION_INFO_TYPE_REMOTE_GRP':6,
  'PM2_SESSION_INFO_TYPE_PROXY_NAME':7,
  'PM2_SESSION_INFO_TYPE_AUTH_USER_URI':8,
  'PM2_SESSION_INFO_TYPE_AUTH_GROUP_URI':9,
  'PM2_EXTENSION_FEATURE_SCAN_PROGRESS':1,
  'PM2_LOG_DEVICE_MEMORY':0,
  'PM2_LOG_DEVICE_FAZ':1,
  'PM2_LOG_DEVICE_DISK':2,
  'PM2_LOG_DEVICE_FORTIGUARD':4,
  'PM2_LOG_DEVICE_FORTICLOUD':5,
  'PM2_DB_WIDGET_LOG_MONITOR':22,
  'PM2_DLP_PROTO_V_MAPI':268435456,
  'PM2_DB_MODE_STOP_ADDING':0,
  'PM2_DB_MODE_REMOVE_MODIOLD':1,
  'PM2_DB_MODE_REMOVE_OLDEST':2,
  'PM2_DNS_QUERY_FORWARD_ONLY':3,
  'PM2_DNS_QUERY_RESOLVER':4,
  'PM2_FSSO_TYPE_DEFAULT':0,
  'PM2_FSSO_TYPE_FORTIEMS':1,
  'PM2_FSSO_TYPE_FORTINAC':2,
  'PM2_FSSO_TYPE_FORTIEMS_CLOUD':3,
  'PM2_NPU_SLOT_FMC1':2,
  'PM2_NPU_SLOT_FMC2':3,
  'PM2_NPU_SLOT_FMC3':4,
  'PM2_NPU_SLOT_FMC4':5,
  'PM2_NPU_SLOT_FMC5':6,
  'PM2_NPU_SLOT_IOM':7,
  'PM2_NPU_SLOT_NONE':1,
  'PM2_BYPASS_MODE_OFF':0,
  'PM2_BYPASS_MODE_POWERUP':1,
  'PM2_BYPASS_MODE_POWERDOWN':2,
  'PM2_BYPASS_MODE_BOTH':3,
  'PM2_QOS_OPT_NONE':0,
  'PM2_QOS_OPT_802_1P':1,
  'PM2_SPAM_BWL_TYPE_IP':0,
  'PM2_SPAM_BWL_TYPE_EMAIL':1,
  'PM2_NIDS_SEV_DISABLE':5,
  'PM2_NIDS_SEV_LOW':1,
  'PM2_NIDS_SEV_MEDIUM':2,
  'PM2_NIDS_SEV_HIGH':3,
  'PM2_NIDS_SEV_CRITICAL':4,
  'PM2_OPT_MANDATORY':77,
  'PM2_OPT_CERTIFICATE':28,
  'PM2_OPT_MATCH_SUBSTRING':0,
  'PM2_OPT_MATCH_VALUE':1,
  'PM2_OPT_IGNORE':19,
  'PM2_OPT_REVOKE':58,
  'PM2_IPSEC_MATCH_SUPERSET':3,
  'PM2_WF_SAFESEARCH_DISABLE':0,
  'PM2_WF_SAFESEARCH_URL':1,
  'PM2_WF_SAFESEARCH_HEADER':2,
  'PM2_WF_SAFESEARCH_TRANSLATE':3,
  'PM2_WF_SAFESEARCH_YT_PATTERN':4,
  'PM2_WF_SAFESEARCH_YT_SCAN':5,
  'PM2_WF_SAFESEARCH_YT_VIDEO':6,
  'PM2_WF_SAFESEARCH_YT_CHANNEL':7,
  'PM2_WSP_CHARSET_GB2312':2,
  'PM2_IPPOOL_TYPE_NPTV6':5,
  'PM2_SENSOR_MODE_DISABLE':0,
  'PM2_SENSOR_MODE_FOREIGN':1,
  'PM2_SENSOR_MODE_BOTH':2,
  'PM2_SECURITY_MODE_OPEN':0,
  'PM2_DOT1X_STATUS_AUTHENTICATING':0,
  'PM2_DOT1X_STATUS_AUTHORIZED':1,
  'PM2_INTF_SPEED_1000FIBER':4096,
  'PM2_INTF_SPEED_10000':8192,
  'PM2_INTF_SPEED_40000':16384,
  'PM2_INTF_SPEED_AUTO_MODULE':32768,
  'PM2_INTF_SPEED_100FX_HALF':65536,
  'PM2_INTF_SPEED_100FX_FULL':131072,
  'PM2_INTF_SPEED_100000FULL':262144,
  'PM2_INTF_SPEED_2500FULL':524288,
  'PM2_INTF_SPEED_10000CR':33554432,
  'PM2_INTF_SPEED_10000SR':67108864,
  'PM2_INTF_SPEED_10000SR4':134217728,
  'PM2_INTF_SPEED_10000CR4':268435456,
  'PM2_INTF_SPEED_25000CR4':536870912,
  'PM2_INTF_SPEED_25000SR4':1073741824,
  'PM2_INTF_SPEED_5000FULL':2147483648,
  'PM2_INTF_SPEED_1000FULL_FIBER':549755813888,
  'PM2_INTF_SPEED_40000SR4':1099511627776,
  'PM2_INTF_SPEED_40000CR4':2199023255552,
  'PM2_INTF_SPEED_25000CR':4398046511104,
  'PM2_INTF_SPEED_25000SR':8796093022208,
  'PM2_INTF_SPEED_50000CR':17592186044416,
  'PM2_INTF_SPEED_50000SR':35184372088832,
  'PM2_PORT_TYPE_PHYSICAL':0,
  'PM2_PORT_TYPE_TRUNK':1,
  'PM2_DHCP_SNOOPING_UNTRUSTED':1,
  'PM2_DHCP_SNOOPING_TRUSTED':0,
  'PM2_PORT_SELECT_SRC_MAC':0,
  'PM2_PORT_SELECT_DST_MAC':1,
  'PM2_PORT_SELECT_SRC_DST_MAC':2,
  'PM2_PORT_MODE_STATIC':0,
  'PM2_PORT_MODE_LACP_PASSIVE':1,
  'PM2_PORT_MODE_LACP_ACTIVE':2,
  'PM2_MEMBER_WITHDRAW_FORWARD':0,
  'PM2_MEMBER_WITHDRAW_BLOCK':1,
  'PM2_DISCARD_MODE_NONE':0,
  'PM2_DISCARD_MODE_ALL_UNTAGGED':1,
  'PM2_DISCARD_MODE_ALL_TAGGED':2,
  'PM2_ARP_INSPECTION_UNTRUSTED':0,
  'PM2_ARP_INSPECTION_TRUSTED':1,
  'PM2_ACCESS_MODE_NORMAL':0,
  'PM2_ACCESS_MODE_NAC':1,
  'PM2_ACCESS_MODE_N_DYNAMIC':2,
  'PM2_ACCESS_MODE_N_STATIC':3,
  'PM2_SW_CTRL_PORTS_AGGREGATOR_MODE_BANDWIDTH':1,
  'PM2_SW_CTRL_PORTS_AGGREGATOR_MODE_COUNT':2,
  'PM2_FLOW_CONTROL_DISABLE':0,
  'PM2_FLOW_CONTROL_TX':1,
  'PM2_FLOW_CONTROL_RX':2,
  'PM2_FLOW_CONTROL_BOTH':3,
  'PM2_PAUSE_METER_RESUME_75':75,
  'PM2_PAUSE_METER_RESUME_50':50,
  'PM2_PAUSE_METER_RESUME_25':25,
  'PM2_FEC_STATE_DISABLED':0,
  'PM2_FEC_STATE_CL74':1,
  'PM2_FEC_STATE_CL91':2,
  'PM2_FEC_STATE_DETECT_BY_MODULE':3,
  'PM2_POE_PORT_MODE_IEEE802_3AF':1,
  'PM2_POE_PORT_MODE_IEEE802_3AT':2,
  'PM2_POE_PORT_MODE_IEEE802_3BT':3,
  'PM2_POE_PORT_PRIORITY_CRITICAL_PRIORITY':1,
  'PM2_POE_PORT_PRIORITY_HIGH_PRIORITY':2,
  'PM2_POE_PORT_PRIORITY_LOW_PRIORITY':3,
  'PM2_POE_PORT_PRIORITY_MEDIUM_PRIORITY':4,
  'PM2_POE_PORT_POWER_NORMAL':1,
  'PM2_POE_PORT_POWER_PERPETUAL':2,
  'PM2_POE_PORT_POWER_PERPETUAL_FAST':3,
  'PM2_LINK_DOWN_AUTH_SET_UNAUTH':0,
  'PM2_LINK_DOWN_AUTH_NO_ACTION':1,
  'PM2_SWITCH_TYPE_VIRTUAL':1,
  'PM2_SWITCH_TYPE_PHYSICAL':0,
  'PM2_SW_MIRROR_ACTIVE':1,
  'PM2_SW_MIRROR_INACTIVE':0,
  'PM2_OPT_SWITCH_STATIC_MAC_TYPE_STATIC':1,
  'PM2_OPT_SWITCH_STATIC_MAC_TYPE_STICKY':2,
  'PM2_SNMP_TRAP_L2MAC':2305843009213693952,
  'PM2_IPSEC_AUTH_ALG_SHA':1,
  'PM2_IPSEC_AUTH_ALG_SHA224':6,
  'PM2_IPSEC_ENC_ALG_AES':3,
  'PM2_IPSEC_ENC_ALG_AES192C':7,
  'PM2_IPSEC_ENC_ALG_AES256C':8,
  'PM2_QOS_DROP_POLICY_TAILDROP':0,
  'PM2_QOS_DROP_POLICY_RANDOM':2,
  'PM2_FIRMWARE_PROVISION_LATEST_DISABLE':0,
  'PM2_FIRMWARE_PROVISION_LATEST_ONCE':1,
  'PM2_EMAIL_SERVER_CUSTOM':0,
  'PM2_SSL_SECURE_SMTPS':3,
  'PM2_ROUTER_ACTION_DENY':2,
  'PM2_ROUTER_ACTION_PERMIT':1,
  'PM2_BFD_AUTH_MODE_NONE':0,
  'PM2_BFD_AUTH_MODE_MD5':1,
  'PM2_IP_TRANS_SCTP':0,
  'PM2_PREFER_CHUNKING_DYNAMIC':0,
  'PM2_PREFER_CHUNKING_FIX':1,
  'PM2_WANOPT_HTTP_PROTOCOL':0,
  'PM2_WANOPT_HTTP_TCP':1,
  'PM2_BYTE_CACHE_OPT_MEM_ONLY':0,
  'PM2_BYTE_CACHE_OPT_MEM_DISK':1,
  'PM2_MC_ADDR_TYPE_MCRANGE':0,
  'PM2_MC_ADDR_TYPE_BCMASK':1,
  'PM2_USER_DEV_IPAD':0,
  'PM2_USER_DEV_IPHONE':1,
  'PM2_USER_DEV_GAMING_CONSOLE':2,
  'PM2_USER_DEV_BLACKBERRY_PHONE':3,
  'PM2_USER_DEV_BLACKBERRY_PLAYBOOK':4,
  'PM2_USER_DEV_LINUX_PC':5,
  'PM2_USER_DEV_MAC':6,
  'PM2_USER_DEV_WINDOWS_PC':7,
  'PM2_USER_DEV_ANDROID_PHONE':8,
  'PM2_USER_DEV_ANDROID_TABLET':9,
  'PM2_USER_DEV_MEDIA_STREAMING':10,
  'PM2_USER_DEV_WINDOWS_PHONE':11,
  'PM2_USER_DEV_FORTINET_DEV':12,
  'PM2_USER_DEV_IP_PHONE':13,
  'PM2_USER_DEV_ROUTER_NAT_DEV':14,
  'PM2_USER_DEV_OTHER_NETWORK_DEV':15,
  'PM2_USER_DEV_WINDOWS_TABLET':16,
  'PM2_USER_DEV_PRINTER':17,
  'PM2_USER_DEV_FORTICAM':18,
  'PM2_USER_DEV_FORTIFONE':19,
  'PM2_USER_DEV_UNKNOWN':20,
  'PM2_DEV_CATE_NONE':0,
  'PM2_DEV_CATE_ANDROID':1,
  'PM2_DEV_CATE_BLACKBERRY':2,
  'PM2_DEV_CATE_FORTINET':3,
  'PM2_DEV_CATE_IOS':4,
  'PM2_DEV_CATE_WINDOWS':5,
  'PM2_DEV_CATE_AMAZON':6,
  'PM2_PROBE_PROTOCOL_PING':0,
  'PM2_PROBE_PROTOCOL_HTTP_GET':1,
  'PM2_SYS_PROBE_RESP_NONE':0,
  'PM2_SYS_PROBE_RESP_HTTP_PROBE':1,
  'PM2_SYS_PROBE_RESP_TWAMP':2,
  'PM2_SYS_PROBE_TTL_MODE_REINIT':0,
  'PM2_SYS_PROBE_TTL_MODE_DECREASE':1,
  'PM2_SYS_PROBE_TTL_MODE_RETAIN':2,
  'PM2_SEC_MODE_NONE':0,
  'PM2_SEC_MODE_AUTH':1,
  'PM2_SSH_OPT_X11_FILTER':1,
  'PM2_SSH_OPT_SSH_SHELL':2,
  'PM2_SSH_OPT_EXEC':4,
  'PM2_SSH_OPT_PORT_FORWARD':8,
  'PM2_UF_TYPE_LIST':3,
  'PM2_APS_LDB_ACTIVE_PASSIVE':7,
  'PM2_SSL_SECURE_POP3S':4,
  'PM2_WEB_PROXY_PROF_ADD_TO_REQUEST':0,
  'PM2_WEB_PROXY_PROF_ADD_TO_RESPONSE':1,
  'PM2_WEB_PROXY_PROF_REMOVE_FROM_REQUEST':2,
  'PM2_WEB_PROXY_PROF_REMOVE_FROM_RESPONSE':3,
  'PM2_WEB_PROXY_PROF_MONITOR_REQUEST':4,
  'PM2_WEB_PROXY_PROF_MONITOR_RESPONSE':5,
  'PM2_OPT_WEB_PROXY_PROF_HEADERS_ADD_OPTION_APPEND':1,
  'PM2_OPT_WEB_PROXY_PROF_HEADERS_ADD_OPTION_NEW_ON_NOT_FOUND':2,
  'PM2_OPT_WEB_PROXY_PROF_HEADERS_ADD_OPTION_NEW':3,
  'PM2_OPT_WEB_PROXY_PROF_HEADERS_ADD_OPTION_REPLACE':4,
  'PM2_OPT_WEB_PROXY_PROF_HEADERS_ADD_OPTION_REPLACE_WHEN_MATCH':5,
  'PM2_OPT_WEB_PROXY_PROF_HEADERS_PROTOCOL_HTTPS':1,
  'PM2_OPT_WEB_PROXY_PROF_HEADERS_PROTOCOL_HTTP':2,
  'PM2_OPT_ADD':59,
  'PM2_OPT_REMOVE':60,
  'PM2_EXT_CTRL_MODEM_DEFAULT_SIM_SIM1':1,
  'PM2_EXT_CTRL_MODEM_DEFAULT_SIM_SIM2':2,
  'PM2_EXT_CTRL_MODEM_DEFAULT_SIM_CARRIER':3,
  'PM2_EXT_CTRL_MODEM_DEFAULT_SIM_COST':4,
  'PM2_EXT_CTRL_MODEM_AUTO_SWITCH_BACK_TIME':1,
  'PM2_EXT_CTRL_MODEM_AUTO_SWITCH_BACK_TIMER':2,
  'PM2_DIAL_MODE_DIAL_ON_DEMAND':0,
  'PM2_DIAL_MODE_ALWAYS_CONNECT':1,
  'PM2_REDIAL_NONE':0,
  'PM2_REDIAL_1':1,
  'PM2_REDIAL_2':2,
  'PM2_REDIAL_3':3,
  'PM2_REDIAL_4':4,
  'PM2_REDIAL_5':5,
  'PM2_REDIAL_6':6,
  'PM2_REDIAL_7':7,
  'PM2_REDIAL_8':8,
  'PM2_REDIAL_9':9,
  'PM2_REDIAL_10':10,
  'PM2_MODEM_TYPE_CDMA':0,
  'PM2_MODEM_TYPE_GSM_LTE':1,
  'PM2_MODEM_TYPE_WIMAX':2,
  'PM2_WIMAX_AUTH_TLS':0,
  'PM2_WIMAX_AUTH_TTLS':1,
  'PM2_MULTI_MODE_AUTO':0,
  'PM2_MULTI_MODE_AUTO_3G':1,
  'PM2_MULTI_MODE_FORCE_LTE':2,
  'PM2_MULTI_MODE_FORCE_3G':3,
  'PM2_MULTI_MODE_FORCE_2G':4,
  'PM2_ECE_ROLE_NONE':0,
  'PM2_ECE_ROLE_PRIMARY':1,
  'PM2_ECE_ROLE_SECONDARY':2,
  'PM2_EXTENSION_TYPE_WAN_EXTENSION':1,
  'PM2_EXTENSION_TYPE_LAN_EXTENSION':2,
  'PM2_PING_PROTO_HTTP':8,
  'PM2_VDOM_V4_MEASURED_VOL_BASED':16,
  'PM2_CFG_SAVE_PRIORITY':3,
  'PM2_CFG_SAVE_SLA':4,
  'PM2_CFG_SAVE_LOAD_BALANCE':5,
  'PM2_VWL_CRIT_LATENCY':0,
  'PM2_VWL_CRIT_JITTER':1,
  'PM2_VWL_CRIT_PACKET_LOSS':2,
  'PM2_VWL_CRIT_INBANDWIDTH':4,
  'PM2_VWL_CRIT_OUTBANDWIDTH':5,
  'PM2_VWL_CRIT_BIBANDWIDTH':6,
  'PM2_VWL_CRIT_CUSTOM_PROF_1':7,
  'PM2_ECE_ROLE_STANDALONE':3,
  'PM2_SLA_COMPARE_BY_ORDER':0,
  'PM2_SLA_COMPARE_BY_NUMBER':1,
  'PM2_LDB_QUALITY_NONE':0,
  'PM2_LDB_QUALITY_LATENCY_BASED':1,
  'PM2_LDB_QUALITY_JITTER_BASED':2,
  'PM2_PING_PROTO_TWAMP':16,
  'PM2_PING_PROTO_PING6':32,
  'PM2_PING_PROTO_DNS':64,
  'PM2_VWL_COST_FACTOR_LATENCY':1,
  'PM2_VWL_COST_FACTOR_JITTER':2,
  'PM2_VWL_COST_FACTOR_PACKET_LOSS':4,
  'PM2_IPSEC_HASH_GLOBAL_HASH':0,
  'PM2_IPSEC_HASH_ROUND_ROBIN_GLOBAL':1,
  'PM2_IPSEC_HASH_SWITCH_GROUP_HASH':2,
  'PM2_IPSEC_HASH_GLOBAL_HASH_WEIGHTED':3,
  'PM2_IPSEC_HASH_ROUND_ROBIN_SWITCH_GROUP':4,
  'PM2_SSL_SSH_PROF_STATUS_DIABLE':0,
  'PM2_SSL_SSH_PROF_STATUS_CERT_INSPECT':1,
  'PM2_SSL_SSH_PROF_STATUS_DEEP_INSPECT':2,
  'PM2_UNTRUST_CERT_ALLOW':0,
  'PM2_UNTRUST_CERT_BLOCK':1,
  'PM2_UNTRUST_CERT_IGNORE':2,
  'PM2_CERT_PROBE_FAILURE_ALLOW':1,
  'PM2_CERT_PROBE_FAILURE_BLOCK':0,
  'PM2_SSH_ALGO_COMPATIBLE':0,
  'PM2_SSH_ALGO_HIGHT_ENCRYP':1,
  'PM2_DEEP_INSP_FTGD_CATEGORY':0,
  'PM2_DEEP_INSP_ADDRESS':1,
  'PM2_DEEP_INSP_ADDRESS6':2,
  'PM2_DEEP_INSP_WILDCARD_FQDN':3,
  'PM2_DEEP_INSP_REGEX':4,
  'PM2_DEEP_INSP_FINGER_PRINT':5,
  'PM2_FINGER_PRINT_CAT_UNKNOWN':0,
  'PM2_FINGER_PRINT_CAT_FIREFOX':1,
  'PM2_FINGER_PRINT_CAT_CHROME':2,
  'PM2_FINGER_PRINT_CAT_SAFARI':3,
  'PM2_FINGER_PRINT_CAT_EDGE':4,
  'PM2_FINGER_PRINT_CAT_IE':5,
  'PM2_FINGER_PRINT_CAT_ANDROID':6,
  'PM2_FINGER_PRINT_CAT_IOS':7,
  'PM2_FINGER_PRINT_CAT_WINDOWS':8,
  'PM2_SERVER_CERT_RESIGN':0,
  'PM2_SERVER_CERT_REPLACE':1,
  'PM2_SSL_SSH_PROFILE_SUPPORTED_ALPN_HTTP1_1':1,
  'PM2_SSL_SSH_PROFILE_SUPPORTED_ALPN_HTTP2':2,
  'PM2_SSL_SSH_PROFILE_SUPPORTED_ALPN_ALL':3,
  'PM2_SSL_SSH_PROFILE_SUPPORTED_ALPN_NONE':0,
  'PM2_DOT_DISABLE':0,
  'PM2_DOT_DEEP_INSPECTION':1,
  'PM2_DOT_EXPIRED_SERVER_CERT_ALLOW':1,
  'PM2_DOT_EXPIRED_SERVER_CERT_BLOCK':2,
  'PM2_DOT_EXPIRED_SERVER_CERT_IGNORE':3,
  'PM2_DOT_REVOKED_SERVER_CERT_ALLOW':1,
  'PM2_DOT_REVOKED_SERVER_CERT_BLOCK':2,
  'PM2_DOT_REVOKED_SERVER_CERT_IGNORE':3,
  'PM2_DOT_UNTRUSTED_SERVER_CERT_ALLOW':1,
  'PM2_DOT_UNTRUSTED_SERVER_CERT_BLOCK':2,
  'PM2_DOT_UNTRUSTED_SERVER_CERT_IGNORE':3,
  'PM2_DOT_CERT_VALIDATION_TIMEOUT_ALLOW':1,
  'PM2_DOT_CERT_VALIDATION_TIMEOUT_BLOCK':2,
  'PM2_DOT_CERT_VALIDATION_TIMEOUT_IGNORE':3,
  'PM2_DOT_CERT_VALIDATION_FAILURE_ALLOW':1,
  'PM2_DOT_CERT_VALIDATION_FAILURE_BLOCK':2,
  'PM2_DOT_CERT_VALIDATION_FAILURE_IGNORE':3,
  'PM2_DOT_SNI_SERVER_CERT_CHECK_ENABLE':1,
  'PM2_DOT_SNI_SERVER_CERT_CHECK_STRICT':2,
  'PM2_DOT_SNI_SERVER_CERT_CHECK_DISABLE':3,
  'PM2_SSL_CLIENT_CERT_DO_NOT_OFFER':1,
  'PM2_SSL_CLIENT_CERT_KEYRING_LIST':2,
  'PM2_SSL_CLIENT_CERT_CA_SIGN':3,
  'PM2_DISCLAIMER_DISABLE':0,
  'PM2_DISCLAIMER_DOMAIN':1,
  'PM2_DISCLAIMER_POLICY':2,
  'PM2_DISCLAIMER_USER':3,
  'PM2_FW_EP_POLICY_PROXY_WEB':1,
  'PM2_FW_EP_POLICY_PROXY_FTP':2,
  'PM2_FW_EP_POLICY_PROXY_WANOPT':3,
  'PM2_ACT_AUTH_NONE':5,
  'PM2_ACT_AUTH_NEGOTIATE':7,
  'PM2_SPOLANONE':7,
  'PM2_PING_PROTO_HTTPS':512,
  'PM2_SERVER_CONFIG_DEFAULT':1,
  'PM2_SERVER_CONFIG_INDIVIDUAL':2,
  'PM2_SERVER_LIST_PROTOCOL_PING':1,
  'PM2_SERVER_LIST_PROTOCOL_TCP_ECHO':2,
  'PM2_SERVER_LIST_PROTOCOL_UDP_ECHO':4,
  'PM2_SERVER_LIST_PROTOCOL_HTTP':8,
  'PM2_SERVER_LIST_PROTOCOL_TWAMP':16,
  'PM2_SERVER_LIST_PROTOCOL_HTTPS':32,
  'PM2_SERVER_TYPE_STATIC':1,
  'PM2_SERVER_TYPE_DYNAMIC':2,
  'PM2_RPT_SRC_FORWARD_TRAFFIC':1,
  'PM2_RPT_SRC_SNIFFER_TRAFFIC':2,
  'PM2_RPT_SRC_LOCAL_DENY_TRAFFIC':4,
  'PM2_SEVERITY_DISABLE':0,
  'PM2_PP_AUTH_PAP_CHAP':24,
  'PM2_MODEM_NET_TYPE_CDMA_1X':0,
  'PM2_MODEM_NET_TYPE_EV_DO':1,
  'PM2_MODEM_NET_TYPE_LTE':2,
  'PM2_MODEM_NET_TYPE_AUTO':3,
  'PM2_LTE_MODEM_AUTO':0,
  'PM2_LTE_MODEM_UMTS_3G':1,
  'PM2_LTE_MODEM_LTE':2,
  'PM2_LTE_MODEM_CDMA_HRPD':3,
  'PM2_IMAGE_PREFERENCE_GENERIC':1,
  'PM2_IMAGE_PREFERENCE_ATT':2,
  'PM2_IMAGE_PREFERENCE_VERZION':3,
  'PM2_IMAGE_PREFERENCE_TELUS':4,
  'PM2_IMAGE_PREFERENCE_DOCOMO':5,
  'PM2_IMAGE_PREFERENCE_SOFTBANK':6,
  'PM2_IMAGE_PREFERENCE_SPRINT':7,
  'PM2_IMAGE_PREFERENCE_AUTOSIM':0,
  'PM2_IMAGE_PREFERENCE_NOCHANGE':8,
  'PM2_CONNECTION_HOT_SWAP_5_MINUTES':1,
  'PM2_CONNECTION_HOT_SWAP_10_MINUTES':2,
  'PM2_CONNECTION_HOT_SWAP_NEVER':0,
  'PM2_PDPTYPE_IPV4':1,
  'PM2_PDPTYPE_IPV6':2,
  'PM2_PDPTYPE_IPV4V6':3,
  'PM2_TARGET_SIM_SLOT_1':1,
  'PM2_TARGET_SIM_SLOT_2':2,
  'PM2_ENCODE_TYPE_ASCII':0,
  'PM2_ENCODE_TYPE_BASE64':1,
  'PM2_ENCODE_TYPE_HMAC_MD5':2,
  'PM2_TUNNEL_MODE_GRE':0,
  'PM2_TERM_PARITY_NONE':0,
  'PM2_TERM_PARITY_ODD':1,
  'PM2_TERM_PARITY_EVEN':2,
  'PM2_TERM_FLOW_CTL_NONE':0,
  'PM2_TERM_FLOW_CTL_XON_XOFF':1,
  'PM2_TERM_FLOW_CTL_HARDWARE':2,
  'PM2_KERNEL_TRUST_NONE':0,
  'PM2_KERNEL_TRUST_EXPIRED':1,
  'PM2_KERNEL_TRUST_TEMPORARY':2,
  'PM2_KERNEL_TRUST_PERMANENT':3,
  'PM2_CORE2_HA_LED_OFF':0,
  'PM2_CORE2_HA_LED_YELLOW':1,
  'PM2_CORE2_HA_LED_GREEN':2,
  'PM2_CORE2_BOOT_NONE':0,
  'PM2_CORE2_BOOT_PROMARY':1,
  'PM2_CORE2_BOOT_BACKUP':2,
  'PM2_SVR_TYPE_SERVICE':0,
  'PM2_SVR_TYPE_INTERNET_SERVICE':1,
  'PM2_TRAFFIC_TYPE_FORWARDING':1,
  'PM2_TRAFFIC_TYPE_LOCAL_IN':2,
  'PM2_TRAFFIC_TYPE_LOCAL_OUT':3,
  'PM2_OPT_MASQUERADE':64,
  'PM2_OPT_IPPOOL':65,
  'PM2_SYS_VM_INTF_MODE_BRIDGE':0,
  'PM2_SYS_VM_INTF_MODE_PASSTHROUGH':1,
  'PM2_WAF_PROF_ACTION_ALLOW':0,
  'PM2_WAF_PROF_ACTION_BLOCK':1,
  'PM2_WAF_PROF_ACTION_ERASE':2,
  'PM2_WAF_PROF_SIG_DIR_REQUEST':0,
  'PM2_WAF_PROF_SIG_DIR_RESPONSE':1,
  'PM2_WAF_SIG_T_ARG':1,
  'PM2_WAF_SIG_T_ARG_NAME':2,
  'PM2_WAF_SIG_T_REQ_BODY':4,
  'PM2_WAF_SIG_T_REQ_COOKIE':8,
  'PM2_WAF_SIG_T_REQ_COOKIE_NAME':16,
  'PM2_WAF_SIG_T_REQ_FILENAME':32,
  'PM2_WAF_SIG_T_REQ_HEADER':64,
  'PM2_WAF_SIG_T_REQ_HEADER_NAME':128,
  'PM2_WAF_SIG_T_REQ_RAW_URI':256,
  'PM2_WAF_SIG_T_REQ_URI':512,
  'PM2_WAF_SIG_T_RESP_BODY':1024,
  'PM2_WAF_SIG_T_RESP_HDR':2048,
  'PM2_WAF_SIG_T_RESP_STATUS':4096,
  'PM2_WAF_URL_ACCESS_BYPASS':0,
  'PM2_WAF_URL_ACCESS_PERMIT':1,
  'PM2_WAF_URL_ACCESS_BLOCK':2,
  'PM2_BLOCK_ACT_BLOCK':0,
  'PM2_BLOCK_ACT_REDIRECT':1,
  'PM2_BLOCK_ACT_BLOCK_SEVRFAIL':2,
  'PM2_GUEST_ADMIN_PASSWORD':1,
  'PM2_EXP_ADDR_HOST_REGEX':0,
  'PM2_EXP_ADDR_URL':1,
  'PM2_EXP_ADDR_CATEGORY':2,
  'PM2_EXP_ADDR_METHOD':3,
  'PM2_EXP_ADDR_UA':4,
  'PM2_EXP_ADDR_HEADER':5,
  'PM2_EXP_ADDR_SRC_ADVANCED':6,
  'PM2_EXP_ADDR_DST_ADVANCED':7,
  'PM2_USERAGENT_CHROME':1,
  'PM2_USERAGENT_MS':2,
  'PM2_USERAGENT_FIREFOX':4,
  'PM2_USERAGENT_SAFARI':8,
  'PM2_USERAGENT_OTHER':16,
  'PM2_EXP_ADDRGRP_TYPE_SRC':0,
  'PM2_EXP_ADDRGRP_TYPE_DST':1,
  'PM2_VLAN_OPTIMIZATION_ENABLE':1,
  'PM2_VLAN_OPTIMIZATION_DISABLE':0,
  'PM2_VLAN_OPTIMIZATION_PRUNE':2,
  'PM2_VLAN_OPTIMIZATION_CONFIGURED':3,
  'PM2_VLAN_OPTIMIZATION_NONE':4,
  'PM2_QUARANTINE_MODE_BY_VLAN':0,
  'PM2_QUARANTINE_MODE_BY_REDIRECT':1,
  'PM2_SW_CTRL_UPDATE_USER_DEVICE_MAC_CACHE':1,
  'PM2_SW_CTRL_UPDATE_USER_DEVICE_LLDP':2,
  'PM2_SW_CTRL_UPDATE_USER_DEVICE_DHCP_SNOOPING':4,
  'PM2_SW_CTRL_UPDATE_USER_DEVICE_L2_DB':8,
  'PM2_SW_CTRL_UPDATE_USER_DEVICE_L3_DB':16,
  'PM2_DHCP_OPTION82_FORMAT_ASCII':1,
  'PM2_DHCP_OPTION82_FORMAT_LEGACY':2,
  'PM2_DHCP_OPTION82_CIRCUIT_ID_INTFNAME':1,
  'PM2_DHCP_OPTION82_CIRCUIT_ID_VLAN':2,
  'PM2_DHCP_OPTION82_CIRCUIT_ID_HOSTNAME':4,
  'PM2_DHCP_OPTION82_CIRCUIT_ID_MODE':8,
  'PM2_DHCP_OPTION82_CIRCUIT_ID_DESCRIPTION':16,
  'PM2_DHCP_OPTION82_REMOTE_ID_MAC':1,
  'PM2_DHCP_OPTION82_REMOTE_ID_HOSTNAME':2,
  'PM2_DHCP_OPTION82_REMOTE_ID_IP':4,
  'PM2_DHCP_SNOOP_CLIENT_REQ_DROP_UNTRUSTED':1,
  'PM2_DHCP_SNOOP_CLIENT_REQ_FORWARD_UNTRUSTED':2,
  'PM2_VLAN_IDENTITY_DESCRIPTION':1,
  'PM2_VLAN_IDENTITY_NAME':2,
  'PM2_SWITCH_ON_DEAUTH_NO_OP':1,
  'PM2_SWITCH_ON_DEAUTH_FACTORY_RESET':2,
  'PM2_AUTH_METHOD_SERIAL':3,
  'PM2_FABRIC_DEV_FORTIMAIL':1,
  'PM2_APP_GRP_TYPE_APPLICATION':0,
  'PM2_APP_GRP_TYPE_CATEGORY':1,
  'PM2_APP_GRP_TYPE_FILTER':2,
  'PM2_PRI_TOP':1,
  'PM2_PRI_CRITICAL':2,
  'PM2_PRI_HIGH':3,
  'PM2_PRI_MEDIUM':4,
  'PM2_PRI_LOW':5,
  'PM2_SEVERITY_TOP':6,
  'PM2_SHAPING_PROFILE_TYPE_POLICING':0,
  'PM2_SHAPING_PROFILE_TYPE_QUEUING':1,
  'PM2_DIR_SRC':0,
  'PM2_DIR_DST':1,
  'PM2_DIR_BOTH':2,
  'PM2_INTERNET_SERVICE_DB_ISDB':0,
  'PM2_INTERNET_SERVICE_DB_IRDB':1,
  'PM2_VXLAN_IP_VERSION_4_UNICAST':1,
  'PM2_VXLAN_IP_VERSION_6_UNICAST':2,
  'PM2_VXLAN_IP_VERSION_4_MULTICAST':3,
  'PM2_VXLAN_IP_VERSION_6_MULTICAST':4,
  'PM2_EXP_ADDR_DST_URL_LIST':8,
  'PM2_EXP_ADDR_SAAS':9,
  'PM2_PROF_METHOD_UPDATE':2048,
  'PM2_PROF_METHOD_PATCH':512,
  'PM2_USERAGENT_IE':32,
  'PM2_USERAGENT_EDGE':64,
  'PM2_FW_PROXY_EXPLICIT_WEB':1,
  'PM2_FW_PROXY_TRANSPARENT_WEB':2,
  'PM2_FW_PROXY_FTP':3,
  'PM2_FW_PROXY_WANOPT':4,
  'PM2_FW_PROXY_WANOPT_SSH':5,
  'PM2_FW_PROXY_WANOPT_SSH_TUNNEL':6,
  'PM2_FW_PROXY_ACCESS_PROXY':7,
  'PM2_FW_PROXY_ZTNA_PROXY':8,
  'PM2_FW_PROXY_ACTION_ACCEPT':1,
  'PM2_FW_PROXY_ACTION_DENY':2,
  'PM2_FW_PROXY_ACTION_REDIRECT':3,
  'PM2_FW_PROXY_ACTION_ISOLATE':4,
  'PM2_LOGIC_OR':1,
  'PM2_LOGIC_AND':2,
  'PM2_WIRELESS_CONTROLLER_SERVICES_ALL':2048,
  'PM2_WIRELESS_CONTROLLER_SERVICES_AIRPLAY':1,
  'PM2_WIRELESS_CONTROLLER_SERVICES_AFP':2,
  'PM2_WIRELESS_CONTROLLER_SERVICES_BIT_TORRENT':4,
  'PM2_WIRELESS_CONTROLLER_SERVICES_FTP':8,
  'PM2_WIRELESS_CONTROLLER_SERVICES_ICHAT':16,
  'PM2_WIRELESS_CONTROLLER_SERVICES_ITUNES':32,
  'PM2_WIRELESS_CONTROLLER_SERVICES_PRINTERS':64,
  'PM2_WIRELESS_CONTROLLER_SERVICES_SAMBA':128,
  'PM2_WIRELESS_CONTROLLER_SERVICES_SCANNERS':256,
  'PM2_WIRELESS_CONTROLLER_SERVICES_SSH':512,
  'PM2_WIRELESS_CONTROLLER_SERVICES_CHROMECAST':1024,
  'PM2_WIRELESS_CONTROLLER_SERVICES_MIRACAST':4096,
  'PM2_SECURITY_MODE_802_1X_MAC_BASED':4,
  'PM2_APS_LDB_DISABLE':9,
  'PM2_APS_LDB_LLDP_VOICE':10,
  'PM2_AUTH_ORDER_DOT1X_MAB':1,
  'PM2_AUTH_ORDER_MAB_DOT1X':2,
  'PM2_AUTH_ORDER_MAB':3,
  'PM2_AUTH_ORDER_LEGACY':4,
  'PM2_MGMT_INTF_INTERNAL':1,
  'PM2_MGMT_INTF_MGMT':2,
  'PM2_MED_TLVS_INVENTORY_MGMT':1,
  'PM2_MED_TLVS_NETWORK_POLICY':2,
  'PM2_MED_TLVS_POWER_MGMT':4,
  'PM2_MED_TLVS_LOCATION_ID':8,
  'PM2_AUTO_ISL_AUTH_LEGACY':1,
  'PM2_AUTO_ISL_AUTH_STRICT':2,
  'PM2_AUTO_ISL_AUTH_RELAX':3,
  'PM2_AUTH_ENCRYPT_NONE':0,
  'PM2_AUTH_ENCRYPT_MIXED':1,
  'PM2_AUTH_ENCRYPT_MUST':2,
  'PM2_AUTH_SCHEME_METHOD_NTLM':1,
  'PM2_AUTH_SCHEME_METHOD_BASIC':2,
  'PM2_AUTH_SCHEME_METHOD_DIGEST':4,
  'PM2_AUTH_SCHEME_METHOD_FORM':8,
  'PM2_AUTH_SCHEME_METHOD_NEGOTIATE':16,
  'PM2_AUTH_SCHEME_METHOD_FSSO':32,
  'PM2_AUTH_SCHEME_METHOD_RSSO':64,
  'PM2_AUTH_SCHEME_METHOD_SSH_PUBKEY':128,
  'PM2_AUTH_SCHEME_METHOD_SAML':256,
  'PM2_AUTH_SCHEME_METHOD_CERT':512,
  'PM2_AUTH_SCHEME_METHOD_X_AUTH_USER':1024,
  'PM2_AUTH_SCHEME_METHOD_SMAL_SP':2048,
  'PM2_AUTH_SCHEME_METHOD_ENTRA_SSO':4096,
  'PM2_SERVICE_PROTOCOL_TYPE_SSH':14,
  'PM2_SERVICE_PROTOCOL_TYPE_ZTNA_PORTAL':16,
  'PM2_LOAD_DISTRIBUTION_METHOD_ROUND_ROBIN':1,
  'PM2_DP_IPSEC_SESSION_DISABLE':1,
  'PM2_DP_IPSEC_SESSION_FORWARD_TO_MASTER':2,
  'PM2_LOAD_BALANCE_DEFAULT_SLOT_SHOW_OPT_ALL':1,
  'PM2_LOAD_BALANCE_DEFAULT_SLOT_SHOW_OPT_CURRENT':2,
  'PM2_DP_ICMP_DIST_METHOD_TO_MASTER':0,
  'PM2_DP_ICMP_DIST_METHOD_SRC_IP':1,
  'PM2_DP_ICMP_DIST_METHOD_DST_IP':2,
  'PM2_DP_ICMP_DIST_METHOD_SRC_DST_IP':3,
  'PM2_DP_ICMP_DIST_METHOD_DERIVED':4,
  'PM2_DP_ICMP_DIST_METHOD_TO_PRIMARY':5,
  'PM2_DP_SESSION_TABLE_VDOM_BASED':0,
  'PM2_DP_SESSION_TABLE_VDOM_INTF_VLAN_BASED':1,
  'PM2_NAT_SOURCE_PORT_CHASSIS_SLOTS':1,
  'PM2_NAT_SOURCE_PORT_ENABLED_SLOTS':2,
  'PM2_ETHER_TYPE_ANY':1,
  'PM2_ETHER_TYPE_ARP':2,
  'PM2_ETHER_TYPE_IP':3,
  'PM2_ETHER_TYPE_IPV4':4,
  'PM2_ETHER_TYPE_IPV6':5,
  'PM2_TCP_FLAG_ANY':1,
  'PM2_TCP_FLAG_SYN':2,
  'PM2_TCP_FLAG_FIN':3,
  'PM2_TCP_FLAG_RST':4,
  'PM2_LOAD_BALANCE_ACTION_FORWARD':1,
  'PM2_LOAD_BALANCE_ACTION_MIRROR_INGRESS':2,
  'PM2_LOAD_BALANCE_ACTION_STATS':8,
  'PM2_LOAD_BALANCE_ACTION_DROP':16,
  'PM2_LOAD_BALANCE_ACTION_MIRROR_EGRESS':4,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_MASTER':1,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_ALL':2,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_LOAD_BALANCE':3,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPM3':4,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPM4':5,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPM5':6,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPM6':7,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPC1':8,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPC2':9,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPC3':10,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPC4':11,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPC5':12,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPC6':13,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPC7':14,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPC8':15,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPC9':16,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPC10':17,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPM7':18,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPM8':19,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPM9':20,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPM10':21,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPM11':22,
  'PM2_LOAD_BALANCE_FORWARD_SLOT_FPM12':23,
  'PM2_UE_REGISTRATION_QUERY_ALLOW':1,
  'PM2_UE_REGISTRATION_QUERY_DENY':2,
  'PM2_GTP_DROP':2,
  'PM2_GTP_REJECT':3,
  'PM2_BLE_PROF_AD_IBEACON':1,
  'PM2_BLE_PROF_AD_EDDYSTONE_UID':2,
  'PM2_BLE_PROF_AD_EDDYSTONE_URL':4,
  'PM2_BLE_TXPOWER_0':0,
  'PM2_BLE_TXPOWER_1':1,
  'PM2_BLE_TXPOWER_2':2,
  'PM2_BLE_TXPOWER_3':3,
  'PM2_BLE_TXPOWER_4':4,
  'PM2_BLE_TXPOWER_5':5,
  'PM2_BLE_TXPOWER_6':6,
  'PM2_BLE_TXPOWER_7':7,
  'PM2_BLE_TXPOWER_8':8,
  'PM2_BLE_TXPOWER_9':9,
  'PM2_BLE_TXPOWER_10':10,
  'PM2_BLE_TXPOWER_11':11,
  'PM2_BLE_TXPOWER_12':12,
  'PM2_SCAN_TYPE_ACTIVE':1,
  'PM2_SCAN_TYPE_PASSIVE':2,
  'PM2_PRIORITY_QUEUE_0':0,
  'PM2_PRIORITY_QUEUE_1':1,
  'PM2_PRIORITY_QUEUE_2':2,
  'PM2_PRIORITY_QUEUE_3':3,
  'PM2_PRIORITY_QUEUE_4':4,
  'PM2_PRIORITY_QUEUE_5':5,
  'PM2_PRIORITY_QUEUE_6':6,
  'PM2_PRIORITY_QUEUE_7':7,
  'PM2_DIFF_SERV_CS0':1,
  'PM2_DIFF_SERV_CS1':2,
  'PM2_DIFF_SERV_AF11':4,
  'PM2_DIFF_SERV_AF12':8,
  'PM2_DIFF_SERV_AF13':16,
  'PM2_DIFF_SERV_CS2':32,
  'PM2_DIFF_SERV_AF21':64,
  'PM2_DIFF_SERV_AF22':128,
  'PM2_DIFF_SERV_AF23':256,
  'PM2_DIFF_SERV_CS3':512,
  'PM2_DIFF_SERV_AF31':1024,
  'PM2_DIFF_SERV_AF32':2048,
  'PM2_DIFF_SERV_AF33':4096,
  'PM2_DIFF_SERV_CS4':8192,
  'PM2_DIFF_SERV_AF41':16384,
  'PM2_DIFF_SERV_AF42':32768,
  'PM2_DIFF_SERV_AF43':65536,
  'PM2_DIFF_SERV_CS5':131072,
  'PM2_DIFF_SERV_EF':262144,
  'PM2_DIFF_SERV_CS6':524288,
  'PM2_DIFF_SERV_CS7':1048576,
  'PM2_IP_PRECEDENCE_NETWORK_CONTROL':1,
  'PM2_IP_PRECEDENCE_INTERNETWORK_CONTROL':2,
  'PM2_IP_PRECEDENCE_CRITIC_ECP':4,
  'PM2_IP_PRECEDENCE_FLASHOVERRIDE':8,
  'PM2_IP_PRECEDENCE_FLASH':16,
  'PM2_IP_PRECEDENCE_IMMEDIATE':32,
  'PM2_IP_PRECEDENCE_PRIORITY':64,
  'PM2_IP_PRECEDENCE_ROUTINE':128,
  'PM2_QOS_DROP_POLICY_WEIGHTED_RANDOM':1,
  'PM2_QOS_SCHEDULE_STRICT':0,
  'PM2_QOS_SCHEDULE_ROUNDROBIN':1,
  'PM2_QOS_SCHEDULE_WEIGHTED':2,
  'PM2_BANDWIDTH_UNIT_PERCENT':3,
  'PM2_AUTH_TYPE_ACCEPTANCE_OF_TERMS':0,
  'PM2_AUTH_TYPE_ONLINE_ENROLLMENT':1,
  'PM2_AUTH_TYPE_HTTP_REDIRECTION':2,
  'PM2_AUTH_TYPE_DNS_REDIRECTION':3,
  'PM2_AUTH_PARAM_ID_NON_EAP_INNER_AUTH':0,
  'PM2_AUTH_PARAM_ID_INNER_AUTH_EAP':1,
  'PM2_AUTH_PARAM_ID_CREDENTIAL':2,
  'PM2_AUTH_PARAM_ID_TUNNELED_CREDENTIAL':3,
  'PM2_AUTH_PARAM_VAL_EAP_IDENTITY':0,
  'PM2_AUTH_PARAM_VAL_EAP_MD5':1,
  'PM2_AUTH_PARAM_VAL_EAP_TLS':2,
  'PM2_AUTH_PARAM_VAL_EAP_TTLS':3,
  'PM2_AUTH_PARAM_VAL_EAP_PEAP':4,
  'PM2_AUTH_PARAM_VAL_EAP_SIM':5,
  'PM2_AUTH_PARAM_VAL_EAP_AKA':6,
  'PM2_AUTH_PARAM_VAL_EAP_AKA_PRIME':7,
  'PM2_AUTH_PARAM_VAL_NON_EAP_PAP':8,
  'PM2_AUTH_PARAM_VAL_NON_EAP_CHAP':9,
  'PM2_AUTH_PARAM_VAL_NON_EAP_MSCHAP':10,
  'PM2_AUTH_PARAM_VAL_NON_EAP_MSCHAPV2':11,
  'PM2_AUTH_PARAM_VAL_CRED_SIM':12,
  'PM2_AUTH_PARAM_VAL_CRED_USIM':13,
  'PM2_AUTH_PARAM_VAL_CRED_NFC':14,
  'PM2_AUTH_PARAM_VAL_CRED_HARDWARE_TOKEN':15,
  'PM2_AUTH_PARAM_VAL_CRED_SOFTOKEN':16,
  'PM2_AUTH_PARAM_VAL_CRED_CERTIFICATE':17,
  'PM2_AUTH_PARAM_VAL_CRED_USER_PWD':18,
  'PM2_AUTH_PARAM_VAL_CRED_NONE':19,
  'PM2_AUTH_PARAM_VAL_CRED_VENDOR_SPECIFIC':20,
  'PM2_AUTH_PARAM_VAL_TUN_CRED_SIM':21,
  'PM2_AUTH_PARAM_VAL_TUN_CRED_USIM':22,
  'PM2_AUTH_PARAM_VAL_TUN_CRED_NFC':23,
  'PM2_AUTH_PARAM_VAL_TUN_CRED_HARDWARE_TOKEN':24,
  'PM2_AUTH_PARAM_VAL_TUN_CRED_SOFTOKEN':25,
  'PM2_AUTH_PARAM_VAL_TUN_CRED_CERTIFICATE':26,
  'PM2_AUTH_PARAM_VAL_TUN_CRED_USER_PWD':27,
  'PM2_AUTH_PARAM_VAL_TUN_CRED_ANONYMOUS':28,
  'PM2_AUTH_PARAM_VAL_TUN_CRED_VENDOR_SPECIFIC':29,
  'PM2_EAP_METHOD_EAP_IDENTITY':0,
  'PM2_EAP_METHOD_EAP_MD5':1,
  'PM2_EAP_METHOD_EAP_TLS':2,
  'PM2_EAP_METHOD_EAP_TTLS':3,
  'PM2_EAP_METHOD_EAP_PEAP':4,
  'PM2_EAP_METHOD_EAP_SIM':5,
  'PM2_EAP_METHOD_EAP_AKA':6,
  'PM2_EAP_METHOD_EAP_AKA_PRIME':7,
  'PM2_IP_ADDR_TYPE_NOT_AVAILABLE':0,
  'PM2_IP_ADDR_TYPE_AVAILABLE':1,
  'PM2_IP_ADDR_TYPE_NOT_KNOWN':2,
  'PM2_IP_ADDR_TYPE_PUBLIC':3,
  'PM2_IP_ADDR_TYPE_PORT_RESTRICTED':4,
  'PM2_IP_ADDR_TYPE_SINGLE_NATED_PRIVATE':5,
  'PM2_IP_ADDR_TYPE_DOUBLE_NATED_PRIVATE':6,
  'PM2_IP_ADDR_TYPE_PORT_RESTRICTED_AND_SINGLE_NATED':7,
  'PM2_IP_ADDR_TYPE_PORT_RESTRICTED_AND_DOUBLE_NATED':8,
  'PM2_PORT_STATUS_CLOSED':0,
  'PM2_PORT_STATUS_OPEN':1,
  'PM2_PORT_STATUS_UNKNOWN':2,
  'PM2_ICON_TYPE_BMP':0,
  'PM2_ICON_TYPE_GIF':1,
  'PM2_ICON_TYPE_JPEG':2,
  'PM2_ICON_TYPE_PNG':3,
  'PM2_ICON_TYPE_TIFF':4,
  'PM2_OSU_METHOD_OMA_DM':1,
  'PM2_OSU_METHOD_SOAP_XML_SPP':2,
  'PM2_OSU_METHOD_RESERVED':4,
  'PM2_ACCESS_NETWORK_TYPE_PRIVATE_NETWORK':0,
  'PM2_ACCESS_NETWORK_TYPE_PRIVATE_NETWORK_WITH_GUEST_ACCESS':1,
  'PM2_ACCESS_NETWORK_TYPE_CHARGEABLE_PUBLIC_NETWORK':2,
  'PM2_ACCESS_NETWORK_TYPE_FREE_PUBLIC_NETWORK':3,
  'PM2_ACCESS_NETWORK_TYPE_PERSONAL_DEVICE_NETWORK':4,
  'PM2_ACCESS_NETWORK_TYPE_EMERGENCY_SERVICES_ONLY_NETWORK':5,
  'PM2_ACCESS_NETWORK_TYPE_TEST_OR_EXPERIMENTAL':6,
  'PM2_ACCESS_NETWORK_TYPE_WILDCARD':7,
  'PM2_VENUE_GROUP_UNSPECIFIED':0,
  'PM2_VENUE_GROUP_ASSEMBLY':1,
  'PM2_VENUE_GROUP_BUSINESS':2,
  'PM2_VENUE_GROUP_EDUCATIONAL':3,
  'PM2_VENUE_GROUP_FACTORY':4,
  'PM2_VENUE_GROUP_INSTITUTIONAL':5,
  'PM2_VENUE_GROUP_MERCANTILE':6,
  'PM2_VENUE_GROUP_RESIDENTIAL':7,
  'PM2_VENUE_GROUP_STORAGE':8,
  'PM2_VENUE_GROUP_UTILITY':9,
  'PM2_VENUE_GROUP_VEHICULAR':10,
  'PM2_VENUE_GROUP_OUTDOOR':11,
  'PM2_VENUE_TYPE_UNSPECIFIED':0,
  'PM2_VENUE_TYPE_ARENA':1,
  'PM2_VENUE_TYPE_STADIUM':2,
  'PM2_VENUE_TYPE_PASSENGER_TERMINAL':3,
  'PM2_VENUE_TYPE_AMPHITHEATER':4,
  'PM2_VENUE_TYPE_AMUSEMENT_PARK':5,
  'PM2_VENUE_TYPE_PLACE_OF_WORSHIP':6,
  'PM2_VENUE_TYPE_CONVENTION_CENTER':7,
  'PM2_VENUE_TYPE_LIBRARY':8,
  'PM2_VENUE_TYPE_MUSEUM':9,
  'PM2_VENUE_TYPE_RESTAURANT':10,
  'PM2_VENUE_TYPE_THEATER':11,
  'PM2_VENUE_TYPE_BAR':12,
  'PM2_VENUE_TYPE_COFFEE_SHOP':13,
  'PM2_VENUE_TYPE_ZOO_OR_AQUARIUM':14,
  'PM2_VENUE_TYPE_EMERGENCY_CENTER':15,
  'PM2_VENUE_TYPE_DOCTOR_OFFICE':16,
  'PM2_VENUE_TYPE_BANK':17,
  'PM2_VENUE_TYPE_FIRE_STATION':18,
  'PM2_VENUE_TYPE_POLICE_STATION':19,
  'PM2_VENUE_TYPE_POST_OFFICE':20,
  'PM2_VENUE_TYPE_PROFESSIONAL_OFFICE':21,
  'PM2_VENUE_TYPE_RESEARCH_FACILITY':22,
  'PM2_VENUE_TYPE_ATTORNEY_OFFICE':23,
  'PM2_VENUE_TYPE_PRIMARY_SCHOOL':24,
  'PM2_VENUE_TYPE_SECONDARY_SCHOOL':25,
  'PM2_VENUE_TYPE_UNIVERSITY_OR_COLLEGE':26,
  'PM2_VENUE_TYPE_FACTORY':27,
  'PM2_VENUE_TYPE_HOSPITAL':28,
  'PM2_VENUE_TYPE_LONG_TERM_CARE_FACILITY':29,
  'PM2_VENUE_TYPE_REHAB_CENTER':30,
  'PM2_VENUE_TYPE_GROUP_HOME':31,
  'PM2_VENUE_TYPE_PRISON_OR_JAIL':32,
  'PM2_VENUE_TYPE_RETAIL_STORE':33,
  'PM2_VENUE_TYPE_GROCERY_MARKET':34,
  'PM2_VENUE_TYPE_AUTO_SERVICE_STATION':35,
  'PM2_VENUE_TYPE_SHOPPING_MALL':36,
  'PM2_VENUE_TYPE_GAS_STATION':37,
  'PM2_VENUE_TYPE_PRIVATE':38,
  'PM2_VENUE_TYPE_HOTEL_OR_MOTEL':39,
  'PM2_VENUE_TYPE_DORMITORY':40,
  'PM2_VENUE_TYPE_BOARDING_HOUSE':41,
  'PM2_VENUE_TYPE_AUTOMOBILE':42,
  'PM2_VENUE_TYPE_AIRPLANE':43,
  'PM2_VENUE_TYPE_BUS':44,
  'PM2_VENUE_TYPE_FERRY':45,
  'PM2_VENUE_TYPE_SHIP_OR_BOAT':46,
  'PM2_VENUE_TYPE_TRAIN':47,
  'PM2_VENUE_TYPE_MOTOR_BIKE':48,
  'PM2_VENUE_TYPE_MUNI_MESH_NETWORK':49,
  'PM2_VENUE_TYPE_CITY_PARK':50,
  'PM2_VENUE_TYPE_REST_AREA':51,
  'PM2_VENUE_TYPE_TRAFFIC_CONTROL':52,
  'PM2_VENUE_TYPE_BUS_STOP':53,
  'PM2_VENUE_TYPE_KIOSK':54,
  'PM2_SDN_ACT_KUBERNETES':9,
  'PM2_SDN_ACT_VMWARE':10,
  'PM2_SDN_ACT_ACS':11,
  'PM2_SDN_ACT_ALICLOUD':12,
  'PM2_SDN_ACT_SEPM':13,
  'PM2_SDN_ACT_ACI_DIRECT':14,
  'PM2_SDN_ACT_IBM':15,
  'PM2_SDN_ACT_NUTANIX':16,
  'PM2_SDN_ACT_SAP':17,
  'PM2_REST_INTERFACE_MGMT':0,
  'PM2_REST_INTERFACE_SYNC':1,
  'PM2_AZURE_REGION_GLOBAL':0,
  'PM2_AZURE_REGION_CHINA':1,
  'PM2_AZURE_REGION_GERMANY':2,
  'PM2_AZURE_REGION_USGOV':3,
  'PM2_AZURE_REGION_LOCAL':4,
  'PM2_OCI_REGION_PHOENIX':0,
  'PM2_OCI_REGION_ASHBURN':1,
  'PM2_OCI_REGION_FRANKFURT':2,
  'PM2_OCI_REGION_LONDON':3,
  'PM2_OCI_REGION_TORONTO':4,
  'PM2_NAMELEN128':128,
  'PM2_OCI_REGION_TYPE_COMMERCIAL':0,
  'PM2_OCI_REGION_TYPE_GOVERNMENT':1,
  'PM2_IBM_REGION_US_SOUTH':1,
  'PM2_IBM_REGION_US_EAST':2,
  'PM2_IBM_REGION_GERMANY':3,
  'PM2_IBM_REGION_GREAT_BRITAIN':4,
  'PM2_IBM_REGION_JAPAN':5,
  'PM2_IBM_REGION_AUSTRALIA':6,
  'PM2_IBM_REGION_DALLAS':7,
  'PM2_IBM_REGION_WASHINGTON_DC':8,
  'PM2_IBM_REGION_LONDON':9,
  'PM2_IBM_REGION_FRANKFURT':10,
  'PM2_IBM_REGION_SYDNEY':11,
  'PM2_IBM_REGION_TOKYO':12,
  'PM2_IBM_REGION_OSAKA':13,
  'PM2_IBM_REGION_TORONTO':14,
  'PM2_IBM_REGION_SAO_PAULO':15,
  'PM2_IBM_REGION_DALLAS_PRIVATE':16,
  'PM2_IBM_REGION_WASHINGTON_DC_PRIVATE':17,
  'PM2_IBM_REGION_LONDON_PRIVATE':18,
  'PM2_IBM_REGION_FRANKFURT_PRIVATE':19,
  'PM2_IBM_REGION_SYDNEY_PRIVATE':20,
  'PM2_IBM_REGION_TOKYO_PRIVATE':21,
  'PM2_IBM_REGION_OSAKA_PRIVATE':22,
  'PM2_IBM_REGION_TORONTO_PRIVATE':23,
  'PM2_IBM_REGION_SAO_PAULO_PRIVATE':24,
  'PM2_IBM_REGION_MADRID':25,
  'PM2_IBM_REGION_MADRID_PRIVATE':26,
  'PM2_DOMAIN_CONTROLLER_AD_MODE_NONE':0,
  'PM2_DOMAIN_CONTROLLER_AD_MODE_DS':1,
  'PM2_DOMAIN_CONTROLLER_AD_MODE_LDS':2,
  'PM2_OPT_CLIENT':69,
  'PM2_EXT_RESOURCE_TYPE_CATEGORY':0,
  'PM2_EXT_RESOURCE_TYPE_ADDRESS':1,
  'PM2_EXT_RESOURCE_TYPE_DOMAIN':2,
  'PM2_EXT_RESOURCE_TYPE_MALWARE':3,
  'PM2_EXT_RESOURCE_TYPE_URL':4,
  'PM2_EXT_RESOURCE_TYPE_MAC_ADDRESS':5,
  'PM2_EXT_RESOURCE_TYPE_DATA':6,
  'PM2_EXT_RESOURCE_TYPE_GENERIC_ADDRESS':7,
  'PM2_UPDATE_METHOD_FEED':1,
  'PM2_UPDATE_METHOD_PUSH':2,
  'PM2_OPT_BASIC':70,
  'PM2_OPT_FULL':71,
  'PM2_CDN_RULE_MATCH_TARGET_PATH':0,
  'PM2_CDN_RULE_MATCH_TARGET_PARAMETER':1,
  'PM2_CDN_RULE_MATCH_TARGET_REFERRER':2,
  'PM2_CDN_RULE_MATCH_TARGET_YOUTUBE_MAP':3,
  'PM2_CDN_RULE_MATCH_TARGET_YOUTUBE_ID':4,
  'PM2_CDN_RULE_MATCH_TARGET_YOUKU_ID':5,
  'PM2_CDN_RULE_MATCH_TARGET_HLS_MANIFEST':6,
  'PM2_CDN_RULE_MATCH_TARGET_DASH_MANIFEST':7,
  'PM2_CDN_RULE_MATCH_TARGET_HLS_FRAGMENT':8,
  'PM2_CDN_RULE_MATCH_TARGET_DASH_FRAGMENT':9,
  'PM2_DIR_FORWARD':3,
  'PM2_DIR_BACKWARD':4,
  'PM2_OPT_ANY':33,
  'PM2_CDN_CATE_VCACHE':0,
  'PM2_CDN_CATE_YOUTUBE':1,
  'PM2_CDN_PREFER_SCENARIO_BALANCE':0,
  'PM2_CDN_PREFER_SCENARIO_SPEED':1,
  'PM2_CDN_PREFER_SCENARIO_CACHE':2,
  'PM2_OPT_PEERS':35,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_FAZ_SET':1,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_FAZ_SET_OV':2,
  'PM2_SYS_VDOM_EXCEPT_OBJ_VPN_IPSEC_PH1_INTF':3,
  'PM2_SYS_VDOM_EXCEPT_OBJ_VPN_IPSEC_PH2_INTF':4,
  'PM2_SYS_VDOM_EXCEPT_OBJ_ROUTER_BGP':5,
  'PM2_SYS_VDOM_EXCEPT_OBJ_ROUTER_ROUTE_MAP':6,
  'PM2_SYS_VDOM_EXCEPT_OBJ_ROUTER_PREFIX_LIST':7,
  'PM2_SYS_VDOM_EXCEPT_OBJ_FW_IPPOOL':8,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_FORTIANALYZER2_SETTING':9,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_FORTIANALYZER2_OVERRIDE_SETTING':10,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_FORTIANALYZER3_SETTING':11,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_FORTIANALYZER3_OVERRIDE_SETTING':12,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_FORTIANALYZER_CLOUD_SETTING':13,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_FORTIANALYZER_CLOUD_OVERRIDE_SETTING':14,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_CENTRAL_MANAGEMENT':15,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_CSF':16,
  'PM2_SYS_VDOM_EXCEPT_OBJ_USER_RADIUS':17,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_VIRTUAL_WAN_LINK':18,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_SYSLOGD_SETTING':19,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_SYSLOGD_OVERRIDE_SETTING':20,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_SYSLOGD2_SETTING':21,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_SYSLOGD2_OVERRIDE_SETTING':22,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_SYSLOGD3_SETTING':23,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_SYSLOGD3_OVERRIDE_SETTING':24,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_SYSLOGD4_SETTING':25,
  'PM2_SYS_VDOM_EXCEPT_OBJ_LOG_SYSLOGD4_OVERRIDE_SETTING':26,
  'PM2_SYS_VDOM_EXCEPT_OBJ_FIREWALL_VIP':27,
  'PM2_SYS_VDOM_EXCEPT_OBJ_FIREWALL_VIP6':28,
  'PM2_SYS_VDOM_EXCEPT_OBJ_FIREWALL_VIP46':29,
  'PM2_SYS_VDOM_EXCEPT_OBJ_FIREWALL_VIP64':30,
  'PM2_SYS_VDOM_EXCEPT_OBJ_FIREWALL_IPPOOL6':31,
  'PM2_SYS_VDOM_EXCEPT_OBJ_ROUTER_STATIC':32,
  'PM2_SYS_VDOM_EXCEPT_OBJ_ROUTER_STATIC6':33,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_INTERFACE':34,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_SDWAN':35,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_SAML':36,
  'PM2_SYS_VDOM_EXCEPT_OBJ_ROUTER_POLICY':37,
  'PM2_SYS_VDOM_EXCEPT_OBJ_ROUTER_POLICY6':38,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_GRE_TUNNEL':39,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_CLUSTER_SYNC':40,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_STANDALONE_CLUSTER':41,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_SNMP_SYSINFO':42,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_SNMP_COMMUNITY':43,
  'PM2_SYS_VDOM_EXCEPT_OBJ_SYSTEM_SNMP_USER':44,
  'PM2_SYS_VDOM_EXCEPT_OBJ_FIREWALL_ADDRESS':45,
  'PM2_OPT_INCLUSIVE':36,
  'PM2_OPT_EXCLUSIVE':37,
  'PM2_TRIGGER_TYPE_EVENT_BASED':0,
  'PM2_TRIGGER_TYPE_SCHEDULED':1,
  'PM2_TRIGGER_EVENT_IOC':0,
  'PM2_TRIGGER_EVENT_EVENT_LOG':1,
  'PM2_TRIGGER_EVENT_REBOOT':2,
  'PM2_TRIGGER_EVENT_LOW_MEMORY':3,
  'PM2_TRIGGER_EVENT_HIGH_CPU':4,
  'PM2_TRIGGER_EVENT_LICENSE_EXPIRY':5,
  'PM2_TRIGGER_EVENT_HA_FAILOVER':6,
  'PM2_TRIGGER_EVENT_CONFIG_CHANGE':7,
  'PM2_TRIGGER_EVENT_SECURITY_RATING_SUMMARY':8,
  'PM2_TRIGGER_EVENT_VIRUS_IPS_DB_UPDATED':9,
  'PM2_TRIGGER_EVENT_FAZ_EVENT':10,
  'PM2_TRIGGER_EVENT_INCOMING_WEBHOOK':11,
  'PM2_TRIGGER_EVENT_FABRIC_EVENT':12,
  'PM2_TRIGGER_EVENT_IPS_LOGS':13,
  'PM2_TRIGGER_EVENT_ANOMALY_LOGS':14,
  'PM2_TRIGGER_EVENT_VIRUS_LOGS':15,
  'PM2_TRIGGER_EVENT_SSH_LOGS':16,
  'PM2_TRIGGER_EVENT_WEBFILTER_VIOLATION':17,
  'PM2_TRIGGER_EVENT_TRAFFIC_VIOLATION':18,
  'PM2_TRIGGER_EVENT_LOCAL_CERT_NEAR_EXPIRY':19,
  'PM2_TRIGGER_EVENT_STITCH':20,
  'PM2_TRIGGER_LIC_FORTICARE_SUPPORT':0,
  'PM2_TRIGGER_LIC_FORTIGUARD_WEBFILTER':1,
  'PM2_TRIGGER_LIC_FORTIGUARD_ANTISPAM':2,
  'PM2_TRIGGER_LIC_FORTIGUARD_ANTIVIRUS':3,
  'PM2_TRIGGER_LIC_FORTIGUARD_IPS':4,
  'PM2_TRIGGER_LIC_FORTIGUARD_MANAGEMENT':5,
  'PM2_TRIGGER_LIC_FORTICLOUD':6,
  'PM2_TRIGGER_LIC_ANY':7,
  'PM2_SCHED_HOURLY':8,
  'PM2_REPORT_TYPE_POSTURE':0,
  'PM2_REPORT_TYPE_COVERAGE':1,
  'PM2_REPORT_TYPE_OPTIMIZATION':2,
  'PM2_REPORT_TYPE_ANY':3,
  'PM2_AUTO_ACT_TYPE_EMAIL':0,
  'PM2_AUTO_ACT_TYPE_IOS_NOTIFY':1,
  'PM2_AUTO_ACT_TYPE_ALERT':2,
  'PM2_AUTO_ACT_TYPE_DISABLE_SSID':3,
  'PM2_AUTO_ACT_TYPE_QUARANTINE':4,
  'PM2_AUTO_ACT_TYPE_BAN_IP':5,
  'PM2_AUTO_ACT_TYPE_QUARANTINE_FORTICLIENT':6,
  'PM2_AUTO_ACT_TYPE_AWS_LAMBDA':7,
  'PM2_AUTO_ACT_TYPE_WEBHOOK':8,
  'PM2_AUTO_ACT_TYPE_QUARANTINE_NSX':9,
  'PM2_AUTO_ACT_TYPE_AZURE_FUNCTION':10,
  'PM2_AUTO_ACT_TYPE_CLI_SCRIPT':11,
  'PM2_AUTO_ACT_TYPE_GOOGLE_CLOUD_FUNCTION':12,
  'PM2_AUTO_ACT_TYPE_ALICLOUD_FUNCTION':13,
  'PM2_AUTO_ACT_TYPE_SLACK_NOTIFICATION':14,
  'PM2_AUTO_ACT_TYPE_QUARANTINE_FORTINAC':15,
  'PM2_AUTO_ACT_TYPE_MICROSOFT_TEAMS_NOTIFICATION':16,
  'PM2_AUTO_ACT_TYPE_FORTIEXPLORER_NOTIFICATION':17,
  'PM2_AUTO_ACT_TYPE_SYSTEM_ACTIONS':18,
  'PM2_AUTO_ACT_TYPE_DIAGNOSE_SCRIPT':19,
  'PM2_AUTO_ACT_TYPE_REGULAR_EXPRESSION':20,
  'PM2_CLOUD_FUNCTION_AUTH_ANONYMOUS':1,
  'PM2_CLOUD_FUNCTION_AUTH_FUNCTION':2,
  'PM2_CLOUD_FUNCTION_AUTH_ADMIN':3,
  'PM2_MESSAGE_TYPE_TEXT':1,
  'PM2_MESSAGE_TYPE_JSON':2,
  'PM2_SYSTEM_ACTION_REBOOT':1,
  'PM2_SYSTEM_ACTION_SHUTDOWN':2,
  'PM2_SYSTEM_ACTION_BACKUP_CONFIG':3,
  'PM2_SSH_FILTER_X11':1,
  'PM2_SSH_FILTER_SHELL':2,
  'PM2_SSH_FILTER_EXEC':4,
  'PM2_SSH_FILTER_PORT_FORWARD':8,
  'PM2_SSH_FILTER_TUN_FORWARD':16,
  'PM2_SSH_FILTER_SFTP':32,
  'PM2_SSH_FILTER_UNKNOWN':64,
  'PM2_SSH_FILTER_SCP':128,
  'PM2_FILTER_PROTOCOL_SSH':32,
  'PM2_SC_TUNNEL_MODE_MODERATE':3,
  'PM2_SSH_SOURCE_BUILT_IN':0,
  'PM2_SSH_SOURCE_USER':1,
  'PM2_SSH_HOST_KEY_TRUSTED':0,
  'PM2_SSH_HOST_KEY_REVOKED':1,
  'PM2_SSH_HOST_KEY_TYPE_RSA':0,
  'PM2_SSH_HOST_KEY_TYPE_DSA':1,
  'PM2_SSH_HOST_KEY_TYPE_ECDSA':2,
  'PM2_SSH_HOST_KEY_TYPE_ED25519':3,
  'PM2_SSH_HOST_KEY_TYPE_RSA_CA':4,
  'PM2_SSH_HOST_KEY_TYPE_DSA_CA':5,
  'PM2_SSH_HOST_KEY_TYPE_ECDSA_CA':6,
  'PM2_SSH_HOST_KEY_TYPE_ED25519_CA':7,
  'PM2_SSH_HOST_KEY_NID_256':0,
  'PM2_SSH_HOST_KEY_NID_384':1,
  'PM2_SSH_HOST_KEY_NID_521':2,
  'PM2_SSH_HOST_KEY_USAGE_TRANSPARENT_PROXY':1,
  'PM2_SSH_HOST_KEY_USAGE_ACCESS_PROXY':2,
  'PM2_OCVPN_SUBNETS_TYPE_SUBNET':0,
  'PM2_OCVPN_SUBNETS_TYPE_INTERFACE':1,
  'PM2_VPN_ROLE_SPOKE':0,
  'PM2_VPN_ROLE_PRIMARY_HUB':1,
  'PM2_VPN_ROLE_SECONDARY_HUB':2,
  'PM2_VPN_ROLE_CLIENT':3,
  'PM2_ADMIN_TYPE_WINDOWNS':0,
  'PM2_ADMIN_TYPE_LDAP':1,
  'PM2_AUTO_DEST_FORTIGATE':0,
  'PM2_AUTO_DEST_HA_CLUSTER':1,
  'PM2_AUTO_DEST_FORTIPROXY':2,
  'PM2_BANDWIDTH_UNIT_PPS':4,
  'PM2_AGG_ALGORITHM_ROUND_ROBIN':3,
  'PM2_AGG_ALGORITHM_REDUNDANT':4,
  'PM2_AGG_ALGORITHM_WEIGHTED_ROUND_ROBIN':5,
  'PM2_SYS_PTP_MODE_MULTICAST':1,
  'PM2_SYS_PTP_MODE_HYBRID':2,
  'PM2_SYS_PTP_DELAY_MECHANISM_E2E':1,
  'PM2_SYS_PTP_DELAY_MECHANISM_P2P':2,
  'PM2_TRAFFIC_POLICY_TYPE_INGRESS':1,
  'PM2_TRAFFIC_POLICY_TYPE_EGRESS':2,
  'PM2_AUTO_SCALE_ROLE_MASTER':0,
  'PM2_AUTO_SCALE_ROLE_SLAVE':1,
  'PM2_AUTO_SCALE_ROLE_PRIMARY':2,
  'PM2_AUTO_SCALE_ROLE_SECONDARY':3,
  'PM2_LLDP_POLICY_TAG_NONE':0,
  'PM2_LLDP_POLICY_TAG_DOT1Q':1,
  'PM2_LLDP_POLICY_TAG_DOT1P':2,
  'PM2_COORDINATES_ALT_UNIT_M':0,
  'PM2_COORDINATES_ALT_UNIT_F':1,
  'PM2_COORDINATES_DATUM_WGS84':0,
  'PM2_COORDINATES_DATUM_NAD83':1,
  'PM2_COORDINATES_DATUM_NAD83_MLLW':2,
  'PM2_FILTER_ACTION_SPAM':2,
  'PM2_FILTER_ACTION_CLEAR':3,
  'PM2_FILTER_WHERE_SUBJECT':1,
  'PM2_FILTER_WHERE_BODY':2,
  'PM2_FILTER_WHERE_ALL':0,
  'PM2_FILTER_LANG_WESTERN':0,
  'PM2_FILTER_LANG_SIMCH':1,
  'PM2_FILTER_LANG_TRACH':2,
  'PM2_FILTER_LANG_JAPANESE':3,
  'PM2_FILTER_LANG_KOREAN':4,
  'PM2_FILTER_LANG_FRENCH':5,
  'PM2_FILTER_LANG_THAI':6,
  'PM2_FILTER_LANG_SPANISH':7,
  'PM2_EMAIL_FILTER_TYPE_IP':0,
  'PM2_EMAIL_FILTER_TYPE_EMAIL':1,
  'PM2_FILTER_ACTION_REJECT':4,
  'PM2_FILTER_ADDRTYPE_IPV4':0,
  'PM2_FILTER_ADDRTYPE_IPV6':1,
  'PM2_FILTER_PROTOCOL_SMTP':4,
  'PM2_FILTER_PROTOCOL_IMAP':8,
  'PM2_FILTER_PROTOCOL_POP3':16,
  'PM2_FILTER_OPT_BANNEDWORD':1,
  'PM2_FILTER_OPT_SPAMBWL':2,
  'PM2_FILTER_OPT_SPAMFSIP':4,
  'PM2_FILTER_OPT_SPAMFSSUBMIT':8,
  'PM2_FILTER_OPT_SPAMFSCHKSUM':16,
  'PM2_FILTER_OPT_SPAMFSURL':32,
  'PM2_FILTER_OPT_SPAMHELODNS':64,
  'PM2_FILTER_OPT_SPAMRADDRDNS':128,
  'PM2_FILTER_OPT_SPAMRBL':256,
  'PM2_FILTER_OPT_SPAMHDRCHECK':512,
  'PM2_FILTER_OPT_SPAMFSPHISH':1024,
  'PM2_FILTER_OPT_SPAMBAL':2048,
  'PM2_FILTER_OPT_SPAMFGIP':4096,
  'PM2_FILTER_OPT_SPAMFGSUBMIT':8192,
  'PM2_FILTER_OPT_SPAMFGCHKSUM':16384,
  'PM2_FILTER_OPT_SPAMFGURL':32768,
  'PM2_FILTER_OPT_SPAMFGPHISH':65536,
  'PM2_FILTER_ACTION_PASS':5,
  'PM2_FILTER_ACTION_TAG':6,
  'PM2_FILTER_ACTION_DISCARD':7,
  'PM2_FLOW_TRACK_SAMPLE_NODE_LOCAL':0,
  'PM2_FLOW_TRACK_SAMPLE_NODE_PERIMETER':1,
  'PM2_FLOW_TRACK_SAMPLE_NODE_DEVICE_INGRESS':2,
  'PM2_FLOW_TRACK_FORMAT_NETFLOW1':0,
  'PM2_FLOW_TRACK_FORMAT_NETFLOW5':1,
  'PM2_FLOW_TRACK_FORMAT_NETFLOW9':2,
  'PM2_FLOW_TRACK_FORMAT_IPFIX':3,
  'PM2_FLOW_TRACK_TRANSPORT_UDP':0,
  'PM2_FLOW_TRACK_TRANSPORT_TCP':1,
  'PM2_FLOW_TRACK_TRANSPORT_SCTP':2,
  'PM2_FLOW_TRACK_LEVEL_VLAN':0,
  'PM2_FLOW_TRACK_LEVEL_IP':1,
  'PM2_FLOW_TRACK_LEVEL_PORT':2,
  'PM2_FLOW_TRACK_LEVEL_PROTO':3,
  'PM2_FLOW_TRACK_LEVEL_MAC':4,
  'PM2_TRANSPORT_SCTP':3,
  'PM2_EXCHANGE_PROTO_RPC_OVER_TCP':0,
  'PM2_EXCHANGE_PROTO_RPC_OVER_HTTP':1,
  'PM2_EXCHANGE_PROTO_RPC_OVER_HTTPS':2,
  'PM2_EXCHANGE_AUTH_LEVEL_LOW':0,
  'PM2_EXCHANGE_AUTH_LEVEL_MEDIUM':1,
  'PM2_EXCHANGE_AUTH_LEVEL_NORMAL':2,
  'PM2_EXCHANGE_AUTH_LEVEL_HIGH':3,
  'PM2_EXCHANGE_AUTH_LEVEL_CONNECT':4,
  'PM2_EXCHANGE_AUTH_LEVEL_CALL':5,
  'PM2_EXCHANGE_AUTH_LEVEL_PACKET':6,
  'PM2_EXCHANGE_AUTH_LEVEL_INTEGRITY':7,
  'PM2_EXCHANGE_AUTH_LEVEL_PRIVACY':8,
  'PM2_EXCHANGE_AUTH_TYPE_SPNEGO':0,
  'PM2_EXCHANGE_AUTH_TYPE_NTLM':1,
  'PM2_EXCHANGE_AUTH_TYPE_KERBEROS':2,
  'PM2_SNIFFER_MODE_ERSPAN_AUTO':1,
  'PM2_SNIFFER_MODE_RSPAN':2,
  'PM2_SNIFFER_MODE_NONE':0,
  'PM2_PCAP_STATUS_UNSCHED':0,
  'PM2_PCAP_STATUS_SCHED':1,
  'PM2_PCAP_STATUS_RUNNING':2,
  'PM2_PCAP_STATUS_DONE':3,
  'PM2_PCAP_STATUS_ERROR':4,
  'PM2_BURST_BPS_GRANULARITY_DISABLE':1,
  'PM2_BURST_BPS_GRANULARITY_512_BYTES':2,
  'PM2_BURST_BPS_GRANULARITY_1K_BYTES':3,
  'PM2_BURST_BPS_GRANULARITY_2K_BYTES':4,
  'PM2_BURST_BPS_GRANULARITY_4K_BYTES':5,
  'PM2_BURST_BPS_GRANULARITY_8K_BYTES':6,
  'PM2_BURST_BPS_GRANULARITY_16K_BYTES':7,
  'PM2_BURST_BPS_GRANULARITY_32K_BYTES':8,
  'PM2_BURST_PPS_GRANULARITY_DISABLE':0,
  'PM2_BURST_PPS_GRANULARITY_HALF_PACKET':1,
  'PM2_BURST_PPS_GRANULARITY_1_PACKET':2,
  'PM2_BURST_PPS_GRANULARITY_2_PACKETS':3,
  'PM2_BURST_PPS_GRANULARITY_4_PACKETS':4,
  'PM2_BURST_PPS_GRANULARITY_16_PACKETS':5,
  'PM2_BURST_PPS_GRANULARITY_65_PACKETS':6,
  'PM2_BURST_PPS_GRANULARITY_262_PACKETS':7,
  'PM2_CLOUD_SERVER_TYPE_PRODUCTION':0,
  'PM2_CLOUD_SERVER_TYPE_ALPHA':1,
  'PM2_CLOUD_SERVER_TYPE_BETA':2,
  'PM2_EC_FCTEMS_CAPABILITIES_FABRIC_AUTH':1,
  'PM2_EC_FCTEMS_CAPABILITIES_SILENT_APPROVAL':2,
  'PM2_EC_FCTEMS_CAPABILITIES_WEBSOCKET':4,
  'PM2_EC_FCTEMS_CAPABILITIES_WEBSOCKET_MALWARE':8,
  'PM2_EC_FCTEMS_CAPABILITIES_PUSH_CA_CERTS':16,
  'PM2_EC_FCTEMS_CAPABILITIES_COMMON_TAGS_API':32,
  'PM2_EC_FCTEMS_CAPABILITIES_TENANT_ID':64,
  'PM2_EC_FCTEMS_CAPABILITIES_SINGLE_VDOM_CONNECTOR':128,
  'PM2_EC_FCTEMS_CAPABILITIES_CLIENT_AVATARS':256,
  'PM2_EC_FCTEMS_CAPABILITIES_FGT_SYSINFO_API':512,
  'PM2_EC_FCTEMS_CAPABILITIES_ZTNA_SERVER_INFO':1024,
  'PM2_DIRTY_REASON_NONE':0,
  'PM2_DIRTY_REASON_MISMATCHED_EMS_SN':1,
  'PM2_STORM_CTRL_MODE_GLOBAL':1,
  'PM2_STORM_CTRL_MODE_OVERRIDE':2,
  'PM2_STORM_CTRL_MODE_DISABLED':0,
  'PM2_DIRECTION_INCOMING':1,
  'PM2_DIRECTION_OUTGOING':2,
  'PM2_SYS_SAML_ROLE_IDP':1,
  'PM2_SYS_SAML_ROLE_SP':2,
  'PM2_SYS_SAML_ROLE_IDENTITY_PROVIDER':3,
  'PM2_SYS_SAML_ROLE_SERVICE_PROVIDER':4,
  'PM2_DEFAULT_LOGIN_PAGE_NORMAL':1,
  'PM2_DEFAULT_LOGIN_PAGE_SSO':2,
  'PM2_PROF_METHOD_REDIRECT':1024,
  'PM2_ASSERTION_ATTR_TYPE_USERNAME':1,
  'PM2_ASSERTION_ATTR_TYPE_EMAIL':2,
  'PM2_ASSERTION_ATTR_TYPE_PRIVILEGE':3,
  'PM2_ASSERTION_ATTR_TYPE_PROF_NAME':4,
  'PM2_CONGESTION_HANDLING_MODE_FLOW_CONTROL':1,
  'PM2_CONGESTION_HANDLING_MODE_HEAD_OF_LINE':2,
  'PM2_CERT_TEMPL_DIGEST_SHA1':0,
  'PM2_CERT_TEMPL_DIGEST_SHA256':1,
  'PM2_USER_CLAIM_TYPE_EMAIL':1,
  'PM2_USER_CLAIM_TYPE_GIVEN_NAME':2,
  'PM2_USER_CLAIM_TYPE_NAME':3,
  'PM2_USER_CLAIM_TYPE_UPN':4,
  'PM2_USER_CLAIM_TYPE_COMMON_NAME':5,
  'PM2_USER_CLAIM_TYPE_EMAIL_ADFS_1X':6,
  'PM2_USER_CLAIM_TYPE_GROUP':7,
  'PM2_USER_CLAIM_TYPE_UPN_ADFS_1X':8,
  'PM2_USER_CLAIM_TYPE_ROLE':9,
  'PM2_USER_CLAIM_TYPE_SUR_NAME':10,
  'PM2_USER_CLAIM_TYPE_PPID':11,
  'PM2_USER_CLAIM_TYPE_NAME_IDENTIFIER':12,
  'PM2_USER_CLAIM_TYPE_AUTHENTICATION_METHOD':13,
  'PM2_USER_CLAIM_TYPE_DENY_ONLY_GROUP_SID':14,
  'PM2_USER_CLAIM_TYPE_DENY_ONLY_PRIMARY_SID':15,
  'PM2_USER_CLAIM_TYPE_DENY_ONLY_PRIMARY_GROUP_SID':16,
  'PM2_USER_CLAIM_TYPE_GROUP_SID':17,
  'PM2_USER_CLAIM_TYPE_PRIMARY_GROUP_SID':18,
  'PM2_USER_CLAIM_TYPE_PRIMARY_SID':19,
  'PM2_USER_CLAIM_TYPE_WINDOWS_ACCOUNT_NAME':20,
  'PM2_TUNNEL_MODE_L2TPV3':1,
  'PM2_GENEVE_TYPE_ETHERNET':1,
  'PM2_GENEVE_TYPE_PPP':2,
  'PM2_OPT_PENDING':48,
  'PM2_AC_TYPE_DEFAULT':0,
  'PM2_AC_TYPE_SPECIFY':1,
  'PM2_AC_TYPE_APCFG':2,
  'PM2_AP_FAMILY_FAP':1,
  'PM2_AP_FAMILY_FAP_U':2,
  'PM2_AP_FAMILY_FAP_C':3,
  'PM2_OPT_DEVICE':51,
  'PM2_NAC_POLICY_CATEGORY_DEVICE':51,
  'PM2_NAC_POLICY_CATEGORY_FW_USER':52,
  'PM2_NAC_POLICY_CATEGORY_EMS_TAG':54,
  'PM2_NAC_POLICY_CATEGORY_VULNERABILITY':55,
  'PM2_NAC_POLICY_CATEGORY_FORTIVOICE_TAG':56,
  'PM2_ADDR_TYPE_OVERRIDE':24,
  'PM2_ASYMMETRIC_TRAFFIC_CONTROL_CPS_PREFERRED':1,
  'PM2_ASYMMETRIC_TRAFFIC_CONTROL_STRICT_ANTI_REPLAY':2,
  'PM2_DECRYPTED_TRAFFIC_TYPE_SSL':1,
  'PM2_DECRYPTED_TRAFFIC_TYPE_SSH':2,
  'PM2_DECRYPTED_TRAFFIC_SOURCE_CLIENT':0,
  'PM2_DECRYPTED_TRAFFIC_SOURCE_SERVER':1,
  'PM2_DECRYPTED_TRAFFIC_SOURCE_BOTH':2,
  'PM2_WL_MAC_ACL_DENY_ND':2,
  'PM2_OPT_TEMPLATE_ACT_EDIT':0,
  'PM2_OPT_TEMPLATE_ACT_DELETE':1,
  'PM2_DEV_TEMPLATE_ARG_INT32':1,
  'PM2_DEV_TEMPLATE_ARG_OPTION':2,
  'PM2_DEV_TEMPLATE_ARG_VARCHAR':3,
  'PM2_DEV_TEMPLATE_ARG_DATASRC':4,
  'PM2_DEV_TEMPLATE_ARG_IP4ADDR':5,
  'PM2_DEV_TEMPLATE_ARG_IP4MASK':6,
  'PM2_DEV_TEMPLATE_ARG_PASSWORD':7,
  'PM2_TEMPLATE_OPT_READONLY':1,
  'PM2_TEMPLATE_OPT_SDWAN_OVERLAY':2,
  'PM2_TEMPLATE_OPT_SDWAN_MANAGER':4,
  'PM2_MAXDESCRIPTION':4096,
  'PM2_CLOUD_SERVICE_TEMPLATE_TYPE_NSXT':1,
  'PM2_SDWAN_TOPOLOGY_SINGLE_HUB':0,
  'PM2_SDWAN_TOPOLOGY_DUAL_HUB':1,
  'PM2_SDWAN_TOPOLOGY_DUAL_ACTIVE_HUB':2,
  'PM2_SDWAN_TOPOLOGY_MULTI_HUB':3,
  'PM2_SDWAN_OVERLAY_ADVPN_DISABLE':0,
  'PM2_SDWAN_OVERLAY_ADVPN_ENABLE':1,
  'PM2_SDWAN_OVERLAY_ADVPN_V2':2,
  'PM2_SDWAN_OVERLAY_AUTH_PSK':0,
  'PM2_SDWAN_OVERLAY_AUTH_SIGNATURE':1,
  'PM2_SDWAN_OVERLAY_ROLE_HUB':0,
  'PM2_SDWAN_OVERLAY_ROLE_SPOKE':1,
  'PM2_SDWAN_OVERLAY_HUB_ROLE_STANDALONE':0,
  'PM2_SDWAN_OVERLAY_HUB_ROLE_PRIMARY':1,
  'PM2_SDWAN_OVERLAY_HUB_ROLE_SECONDARY':2,
  'PM2_SDWAN_OVERLAY_ADVERTISEMENT_STATIC':0,
  'PM2_SDWAN_OVERLAY_ADVERTISEMENT_CONNECTED':1,
  'PM2_LOAD_BALANCE_MODE_SOURCE_IP_BASED':1,
  'PM2_LOAD_BALANCE_MODE_WEIGHT_BASED':2,
  'PM2_LOAD_BALANCE_MODE_USAGE_BASED':3,
  'PM2_LOAD_BALANCE_MODE_SOURCE_DEST_IP_BASED':4,
  'PM2_LOAD_BALANCE_MODE_MEASURED_VOLUME_BASED':5,
  'PM2_SERVICE_SLA_TIE_BREAK_CFG_ORDER':1,
  'PM2_SERVICE_SLA_TIE_BREAK_FIB_BEST_MATCH':2,
  'PM2_SERVICE_SLA_TIE_BREAK_INPUT_DEVICE':3,
  'PM2_PING_PROTO_TCP_CONNECT':128,
  'PM2_PING_PROTO_FTP':256,
  'PM2_VWL_COST_FACTOR_MOS':8,
  'PM2_VWL_COST_FACTOR_REMOTE':16,
  'PM2_SDWAN_QUALITY_MEASURED_METHOD_HALF_OPEN':1,
  'PM2_SDWAN_QUALITY_MEASURED_METHOD_HALF_CLOSE':0,
  'PM2_FTP_MODE_PASSIVE':0,
  'PM2_FTP_MODE_PORT':1,
  'PM2_SDWAN_HEALTH_CHECK_DETECT_MODE_ACTIVE':1,
  'PM2_SDWAN_HEALTH_CHECK_DETECT_MODE_PASSIVE':2,
  'PM2_SDWAN_HEALTH_CHECK_DETECT_MODE_PREFER_PASSIVE':3,
  'PM2_SDWAN_HEALTH_CHECK_DETECT_MODE_REMOTE':4,
  'PM2_SDWAN_HEALTH_CHECK_DETECT_MODE_AGENT_BASED':5,
  'PM2_MOS_CODEC_G711':1,
  'PM2_MOS_CODEC_G722':2,
  'PM2_MOS_CODEC_G729':3,
  'PM2_SDWAN_NEIGHBOR_MODE_SLA':1,
  'PM2_SDWAN_NEIGHBOR_MODE_SPEEDTEST':2,
  'PM2_ROUTE_METRIC_PREFERABLE':1,
  'PM2_ROUTE_METRIC_PRIORITY':2,
  'PM2_SDWAN_SRV_HASH_MODE_ROUND_ROBIN':1,
  'PM2_SDWAN_SRV_HASH_MODE_SOURCE_IP_BASED':2,
  'PM2_SDWAN_SRV_HASH_MODE_SOURCE_DEST_IP_BASED':3,
  'PM2_SDWAN_SRV_HASH_MODE_INBANDWIDTH':4,
  'PM2_SDWAN_SRV_HASH_MODE_OUTBANDWIDTH':5,
  'PM2_SDWAN_SRV_HASH_MODE_BIBANDWIDTH':6,
  'PM2_TIE_BREAK_ZONE':1,
  'PM2_TIE_BREAK_CFG_ORDER':2,
  'PM2_TIE_BREAK_FIB_BEST_MATCH':3,
  'PM2_TIE_BREAK_INPUT_DEVICE':4,
  'PM2_SHORTCUT_PRIORITY_ENABLE':1,
  'PM2_SHORTCUT_PRIORITY_DISABLE':0,
  'PM2_SHORTCUT_PRIORITY_AUTO':2,
  'PM2_SDWAN_PACKET_DUP_DISABLE':0,
  'PM2_SDWAN_PACKET_DUP_FORCE':1,
  'PM2_SDWAN_PACKET_DUP_ON_DEMAND':2,
  'PM2_VNE_TUNNEL_MODE_MAP_E':1,
  'PM2_VNE_TUNNEL_MODE_FIXED_IP':2,
  'PM2_VNE_TUNNEL_MODE_DS_LITE':3,
  'PM2_EXT_CTRL_MODEM_ID_MODEM1':1,
  'PM2_EXT_CTRL_MODEM_ID_MODEM2':2,
  'PM2_EXT_CTRL_MODEM_ID_ALL':0,
  'PM2_EXT_CTRL_DATAPLAN_TYPE_CARRIER':1,
  'PM2_EXT_CTRL_DATAPLAN_TYPE_SLOT':2,
  'PM2_EXT_CTRL_DATAPLAN_TYPE_ICCID':3,
  'PM2_EXT_CTRL_DATAPLAN_TYPE_GENERIC':4,
  'PM2_EXT_CTRL_DATAPLAN_SLOT_SIM1':1,
  'PM2_EXT_CTRL_DATAPLAN_SLOT_SIM2':2,
  'PM2_EXT_CTRL_DATAPLAN_PDN_IPV4_ONLY':4,
  'PM2_EXT_CTRL_DATAPLAN_PDN_IPV6_ONLY':6,
  'PM2_EXT_CTRL_DATAPLAN_PDN_IPV4_IPV6':46,
  'PM2_SW_CTRL_PTP_MODE_DISABLE':0,
  'PM2_SW_CTRL_PTP_MODE_TRANSPARENT_E2E':1,
  'PM2_SW_CTRL_PTP_MODE_TRANSPARENT_P2P':2,
  'PM2_MPSK_GROUP_VLAN_TYPE_NO_VLAN':0,
  'PM2_MPSK_GROUP_VLAN_TYPE_FIXED_VLAN':1,
  'PM2_MPSK_KEY_CONCURRENT_CLIENT_LIMIT_TYPE_DEFAULT':0,
  'PM2_MPSK_KEY_CONCURRENT_CLIENT_LIMIT_TYPE_UNLIMITED':1,
  'PM2_MPSK_KEY_CONCURRENT_CLIENT_LIMIT_TYPE_SPECIFIED':2,
  'PM2_WL_SEC_WPA2_PERSONAL':11,
  'PM2_NPU_SERVER_LOG_PROCESSOR_HARDWARE':1,
  'PM2_NPU_SERVER_LOG_PROCESSOR_HOST':2,
  'PM2_NETFLOW_VER_V9':1,
  'PM2_NETFLOW_VER_V10':2,
  'PM2_IP_FAMILY_V4':1,
  'PM2_IP_FAMILY_V6':2,
  'PM2_LOG_MODE_PER_SESSION':1,
  'PM2_LOG_MODE_PER_NAT_MAPPING':2,
  'PM2_LOG_MODE_PER_SESSION_ENDING':3,
  'PM2_LOG_FORMAT_SYSLOG':1,
  'PM2_LOG_FORMAT_NETFLOW':2,
  'PM2_APS_FP_DISABLE_ROUNDROBIN':256,
  'PM2_SW_LOG_FLAGS_TCP_UDP_ONLY':1,
  'PM2_SW_LOG_FLAGS_ENABLE_ALL_LOG':2,
  'PM2_SW_LOG_FLAGS_DISABLE_ALL_LOG':3,
  'PM2_LOG_PROCESSING_MAY_DROP':1,
  'PM2_LOG_PROCESSING_NO_DROP':2,
  'PM2_VIDEOFILTER_TYPE_CATEGORY':1,
  'PM2_VIDEOFILTER_TYPE_CHANNEL':2,
  'PM2_VIDEOFILTER_TYPE_TITLE':3,
  'PM2_VIDEOFILTER_TYPE_DESCRIPTION':4,
  'PM2_EMAIL_FILTER_TYPE_EMAIL_TO':2,
  'PM2_EMAIL_FILTER_TYPE_EMAIL_FROM':3,
  'PM2_EMAIL_FILTER_TYPE_SUBJECT':4,
  'PM2_FEDERATED_UPGRADE_STATUS_DISABLED':0,
  'PM2_FEDERATED_UPGRADE_STATUS_INITIALIZED':1,
  'PM2_FEDERATED_UPGRADE_STATUS_DOWNLOADING':2,
  'PM2_FEDERATED_UPGRADE_STATUS_DOWNLOAD_FAILED':3,
  'PM2_FEDERATED_UPGRADE_STATUS_READY':4,
  'PM2_FEDERATED_UPGRADE_STATUS_CANCELLED':5,
  'PM2_FEDERATED_UPGRADE_STATUS_CONFIRMED':6,
  'PM2_FEDERATED_UPGRADE_STATUS_DONE':7,
  'PM2_FEDERATED_UPGRADE_STATUS_FAILED':8,
  'PM2_FEDERATED_UPGRADE_STATUS_DEVICE_DISCONNECTED':9,
  'PM2_FEDERATED_UPGRADE_STATUS_STAGING':10,
  'PM2_FEDERATED_UPGRADE_STATUS_FINAL_CHECK':11,
  'PM2_FEDERATED_UPGRADE_STATUS_UPGRADE_DEVICES':12,
  'PM2_FEDERATED_UPGRADE_STATUS_COORDINATING':13,
  'PM2_FEDERATED_UPGRADE_STATUS_DRY_RUN_DONE':14,
  'PM2_FEDERATED_UPGRADE_TIMING_IMMEDIATE':1,
  'PM2_FEDERATED_UPGRADE_TIMING_SCHEDULED':2,
  'PM2_FEDERATED_UPGRADE_DEVICE_TYPE_FORTIGATE':1,
  'PM2_FEDERATED_UPGRADE_DEVICE_TYPE_FORTISWITCH':2,
  'PM2_FEDERATED_UPGRADE_DEVICE_TYPE_FORTIAP':3,
  'PM2_FEDERATED_UPGRADE_DEVICE_TYPE_FORTIPROXY':4,
  'PM2_FEDERATED_UPGRADE_DEVICE_TYPE_FORTIEXTENDER':5,
  'PM2_FAILURE_REASON_NONE':0,
  'PM2_FAILURE_REASON_INTERNAL':1,
  'PM2_FAILURE_REASON_TIMEOUT':2,
  'PM2_FAILURE_REASON_DEVICE_TYPE_UNSUPPORTED':3,
  'PM2_FAILURE_REASON_DOWNLOAD_FAILED':4,
  'PM2_FAILURE_REASON_DEVICE_MISSING':5,
  'PM2_FAILURE_REASON_VERSION_UNAVAILABLE':6,
  'PM2_FAILURE_REASON_STAGING_FAILED':7,
  'PM2_FAILURE_REASON_REBOOT_FAILED':8,
  'PM2_FAILURE_REASON_DEVICE_NOT_RECONNECTED':9,
  'PM2_FAILURE_REASON_NODE_NOT_READY':10,
  'PM2_FAILURE_REASON_NO_FINAL_CONFIRMATION':11,
  'PM2_FAILURE_REASON_NO_CONFIRMATION_QUERY':12,
  'PM2_FAILURE_REASON_CONFIG_ERROR_LOG_NONEMPTY':13,
  'PM2_FAILURE_REASON_NODE_FAILED':14,
  'PM2_FAILURE_REASON_CSF_TREE_NOT_SUPPORTED':15,
  'PM2_FAILURE_REASON_FIRMWARE_CHANGED':16,
  'PM2_FEDERATED_UPGRADE_SOURCE_USER':1,
  'PM2_FEDERATED_UPGRADE_SOURCE_AUTO_FIRMWARE_UPGRADE':2,
  'PM2_IKE_DH_MODE_SOFTWARE':1,
  'PM2_IKE_DH_MODE_HARDWARE':2,
  'PM2_IKE_DH_GROUP_MODE_SOFTWARE':1,
  'PM2_IKE_DH_GROUP_MODE_HARDWARE':2,
  'PM2_IKE_DH_GROUP_MODE_GLOBAL':3,
  'PM2_IKE_DH_GROUP_KEYPAIR_CACHE_GLOBAL':1,
  'PM2_IKE_DH_GROUP_KEYPAIR_CACHE_CUSTOM':2,
  'PM2_SPEED_TEST_SCHEDULE_MODE_UDP':1,
  'PM2_SPEED_TEST_SCHEDULE_MODE_TCP':2,
  'PM2_SPEED_TEST_SCHEDULE_MODE_AUTO':3,
  'PM2_UPDATE_SHAPER_DISABLE':0,
  'PM2_UPDATE_SHAPER_LOCAL':1,
  'PM2_UPDATE_SHAPER_REMOTE':2,
  'PM2_UPDATE_SHAPER_BOTH':3,
  'PM2_ACCESS_VLAN_MODE_LEGACY':1,
  'PM2_ACCESS_VLAN_MODE_FAIL_OPEN':2,
  'PM2_ACCESS_VLAN_MODE_FAIL_CLOSE':3,
  'PM2_DYNAMIC_PORT_POLICY_CATEGORY_DEVICE':1,
  'PM2_DYNAMIC_PORT_POLICY_CATEGORY_INTERFACE_TAG':2,
  'PM2_ACCESS_PROXY_VIRTUAL_HOST_SUB_STRING':1,
  'PM2_ACCESS_PROXY_VIRTUAL_HOST_WILDCARD':2,
  'PM2_ACCESS_PROXY_API_GATEWAY_SERVICE_HTTP':1,
  'PM2_ACCESS_PROXY_API_GATEWAY_SERVICE_HTTPS':2,
  'PM2_ACCESS_PROXY_API_GATEWAY_SERVICE_TCP_FORWARDING':3,
  'PM2_ACCESS_PROXY_API_GATEWAY_SERVICE_SAMLSP':4,
  'PM2_ACCESS_PROXY_API_GATEWAY_SERVICE_WEB_PORTAL':6,
  'PM2_ACCESS_PROXY_API_GATEWAY_SERVICE_SAAS':7,
  'PM2_ACCESS_PROXY_API_GATEWAY_URL_MAP_SUB_STRING':1,
  'PM2_ACCESS_PROXY_API_GATEWAY_URL_MAP_WILDCARD':2,
  'PM2_ACCESS_PROXY_API_GATEWAY_URL_MAP_REGEX':3,
  'PM2_ACCESS_PROXY_API_GATEWAY_SERVICE_SSH':5,
  'PM2_CERT_EXTENSION_FIXED':1,
  'PM2_CERT_EXTENSION_USER':2,
  'PM2_GTP_MONITOR':4,
  'PM2_OPT_VIOLATION':66,
  'PM2_OPT_X_VIRUS_ID':1,
  'PM2_OPT_X_INFECTION_FOUND':2,
  'PM2_OPT_X_VIOLATION_FOUND':4,
  'PM2_WEB_PROXY_ERROR_CONNECT_ERROR':1,
  'PM2_WEB_PROXY_ERROR_RECEIVE_ERROR':2,
  'PM2_WEB_PROXY_ERROR_NON_HTTP':4,
  'PM2_WEB_PROXY_ERROR_400':8,
  'PM2_WEB_PROXY_ERROR_401':16,
  'PM2_WEB_PROXY_ERROR_403':32,
  'PM2_WEB_PROXY_ERROR_405':64,
  'PM2_WEB_PROXY_ERROR_406':128,
  'PM2_WEB_PROXY_ERROR_500':256,
  'PM2_WEB_PROXY_ERROR_502':512,
  'PM2_WEB_PROXY_ERROR_503':1024,
  'PM2_WEB_PROXY_ERROR_504':2048,
  'PM2_DIRECT_LOG_MODE_DISABLED':0,
  'PM2_DIRECT_LOG_MODE_UDP':1,
  'PM2_DIRECT_LOG_MODE_FAZ_UDP':2,
  'PM2_ACTION_REPLACE':8,
  'PM2_USER_CERTIFICATE_TYPE_SINGLE_CERTIFICATE':1,
  'PM2_USER_CERTIFICATE_TYPE_TRUSTED_ISSUER':2,
  'PM2_PFCP_MONITOR_MODE_ENABLE':1,
  'PM2_PFCP_MONITOR_MODE_DISABLE':0,
  'PM2_PFCP_MONITOR_MODE_VDOM':2,
  'PM2_EXTENDER_MODEL_FX201E':1,
  'PM2_EXTENDER_MODEL_FX211E':2,
  'PM2_EXTENDER_MODEL_FX200F':3,
  'PM2_EXTENDER_MODEL_FXA11F':4,
  'PM2_EXTENDER_MODEL_FXE11F':5,
  'PM2_EXTENDER_MODEL_FXA21F':6,
  'PM2_EXTENDER_MODEL_FXE21F':7,
  'PM2_EXTENDER_MODEL_FXA22F':8,
  'PM2_EXTENDER_MODEL_FXE22F':9,
  'PM2_EXTENDER_MODEL_FX212F':10,
  'PM2_EXTENDER_MODEL_FX311F':11,
  'PM2_EXTENDER_MODEL_FX312F':12,
  'PM2_EXTENDER_MODEL_FX511F':13,
  'PM2_EXTENDER_MODEL_FVG21F':14,
  'PM2_EXTENDER_MODEL_FVA21F':15,
  'PM2_EXTENDER_MODEL_FVG22F':16,
  'PM2_EXTENDER_MODEL_FVA22F':17,
  'PM2_EXTENDER_MODEL_FX04DA':18,
  'PM2_EXTENDER_MODEL_FX04DN':19,
  'PM2_EXTENDER_MODEL_FX04DI':20,
  'PM2_ALERT_SYSTEM_REBOOT':1,
  'PM2_ALERT_DATA_EXHAUSTED':2,
  'PM2_ALERT_SESSION_DISCONNECT':4,
  'PM2_ALERT_LOW_SIGNAL_STRENGTH':8,
  'PM2_ALERT_MODE_SWITCH':16,
  'PM2_ALERT_OS_IMAGE_FALLBACK':32,
  'PM2_ALERT_FGT_BACKUP_MODE_SWITCH':64,
  'PM2_LINK_LOADBALANCE_ACTIVEBACKUP':1,
  'PM2_LINK_LOADBALANCE_LOADBALANCE':2,
  'PM2_EXT_CTRL_BACKHAUL_PORT_WAN':1,
  'PM2_EXT_CTRL_BACKHAUL_PORT_LTE1':2,
  'PM2_EXT_CTRL_BACKHAUL_PORT_LTE2':3,
  'PM2_EXT_CTRL_BACKHAUL_PORT_PORT1':4,
  'PM2_EXT_CTRL_BACKHAUL_PORT_PORT2':5,
  'PM2_EXT_CTRL_BACKHAUL_PORT_PORT3':6,
  'PM2_EXT_CTRL_BACKHAUL_PORT_PORT4':7,
  'PM2_EXT_CTRL_BACKHAUL_PORT_PORT5':8,
  'PM2_EXT_CTRL_BACKHAUL_PORT_SFP':9,
  'PM2_EXT_CTRL_BACKHAUL_ROLE_PRIMARY':1,
  'PM2_EXT_CTRL_BACKHAUL_ROLE_SECONDARY':2,
  'PM2_LOG_LEVEL_DEBUGGING':8,
  'PM2_SERVER_TYPE_STANDARD':1,
  'PM2_SERVER_TYPE_FORTIANALYZER':2,
  'PM2_AOC_TYPE_TIME_BASED':1,
  'PM2_AOC_TYPE_VOLUME_BASED':2,
  'PM2_AOC_TYPE_TIME_AND_VOLUME_BASED':3,
  'PM2_AOC_TYPE_UNLIMITED':4,
  'PM2_IPAM_SERVER_TYPE_CLOUD':1,
  'PM2_IPAM_SERVER_TYPE_FABRIC_ROOT':2,
  'PM2_IPAM_RULES_ROLE_ANY':1,
  'PM2_IPAM_RULES_ROLE_LAN':2,
  'PM2_IPAM_RULES_ROLE_WAN':3,
  'PM2_IPAM_RULES_ROLE_DMZ':4,
  'PM2_IPAM_RULES_ROLE_UNDEFINED':5,
  'PM2_DSL_POLICY_TYPE_PROCEND':1,
  'PM2_DSL_POLICY_TYPE_PROSCEND':2,
  'PM2_DSL_POLICY_PROFILE_AUTO_30A':1,
  'PM2_DSL_POLICY_PROFILE_AUTO_17A':2,
  'PM2_DSL_POLICY_PROFILE_AUTO_12AB':3,
  'PM2_DSL_POLICY_CS_A43':1,
  'PM2_DSL_POLICY_CS_B43':2,
  'PM2_DSL_POLICY_CS_A43C':4,
  'PM2_DSL_POLICY_CS_V43':8,
  'PM2_DSL_POLICY_CPE_AELE_MODE_ELE_M0':1,
  'PM2_DSL_POLICY_CPE_AELE_MODE_ELE_DS':2,
  'PM2_DSL_POLICY_CPE_AELE_MODE_ELE_PB':3,
  'PM2_DSL_POLICY_CPE_AELE_MODE_ELE_MIN':4,
  'PM2_TAP_DEVICE_FORMAT_DEFAULT':1,
  'PM2_TAP_DEVICE_FORMAT_JSON':2,
  'PM2_DLP_PROTO_V_NNTP':512,
  'PM2_DLP_PROTO_V_SSH':8589934592,
  'PM2_DLP_PROTO_V_CIFS':17179869184,
  'PM2_DLP_FILTER_TYPE_SENSOR':6,
  'PM2_DLP_FILTER_TYPE_ENCRYPTED':7,
  'PM2_DLP_FILTER_TYPE_NONE':8,
  'PM2_DLP_FILTER_TYPE_MIP':9,
  'PM2_EXT_CTRL_AUTH_TYPE_NONE':0,
  'PM2_EXT_CTRL_AUTH_TYPE_PAP':1,
  'PM2_EXT_CTRL_AUTH_TYPE_CHAP':2,
  'PM2_EXTENDER_MODEL_FXR51G':21,
  'PM2_EXTENDER_MODEL_FG':22,
  'PM2_EXTENDER_MODEL_BS10FW':23,
  'PM2_EXTENDER_MODEL_BS20GW':24,
  'PM2_EXTENDER_MODEL_BS20GN':25,
  'PM2_EXTENDER_MODEL_FXN51G':26,
  'PM2_EXTENDER_MODEL_FXW51G':27,
  'PM2_EXTENDER_MODEL_FVG51G':28,
  'PM2_LAN_EXTENSION_DOWNLINKS_TYPE_PORT':1,
  'PM2_LAN_EXTENSION_DOWNLINKS_TYPE_VAP':2,
  'PM2_LAN_EXTENSION_DOWNLINKS_PORT_PORT1':1,
  'PM2_LAN_EXTENSION_DOWNLINKS_PORT_PORT2':2,
  'PM2_LAN_EXTENSION_DOWNLINKS_PORT_PORT3':3,
  'PM2_LAN_EXTENSION_DOWNLINKS_PORT_PORT4':4,
  'PM2_LAN_EXTENSION_DOWNLINKS_PORT_PORT5':5,
  'PM2_LAN_EXTENSION_DOWNLINKS_PORT_LAN1':6,
  'PM2_LAN_EXTENSION_DOWNLINKS_PORT_LAN2':7,
  'PM2_COUNTRY_CLIENT':1127,
  'PM2_INTERFACE_SELECT_METHOD_11A_N_AC_AX':11,
  'PM2_INTERFACE_SELECT_METHOD_11A_N_AC':12,
  'PM2_INTERFACE_SELECT_METHOD_11A_N':13,
  'PM2_INTERFACE_SELECT_METHOD_11A':14,
  'PM2_INTERFACE_SELECT_METHOD_11N_AC_AX':15,
  'PM2_INTERFACE_SELECT_METHOD_11AC_AX':16,
  'PM2_INTERFACE_SELECT_METHOD_11AC':17,
  'PM2_INTERFACE_SELECT_METHOD_11N_AC':18,
  'PM2_INTERFACE_SELECT_METHOD_11B_G_N_AX':19,
  'PM2_INTERFACE_SELECT_METHOD_11B_G_N':20,
  'PM2_INTERFACE_SELECT_METHOD_11B_G':21,
  'PM2_INTERFACE_SELECT_METHOD_11B':22,
  'PM2_INTERFACE_SELECT_METHOD_11G_N_AX':23,
  'PM2_INTERFACE_SELECT_METHOD_11N_AX':24,
  'PM2_INTERFACE_SELECT_METHOD_11AX':25,
  'PM2_INTERFACE_SELECT_METHOD_11G_N':26,
  'PM2_INTERFACE_SELECT_METHOD_11N':27,
  'PM2_INTERFACE_SELECT_METHOD_11G':28,
  'PM2_INTERFACE_SELECT_METHOD_400NS':29,
  'PM2_INTERFACE_SELECT_METHOD_800NS':30,
  'PM2_CHANNEL_CH1':1,
  'PM2_CHANNEL_CH2':2,
  'PM2_CHANNEL_CH3':4,
  'PM2_CHANNEL_CH4':8,
  'PM2_CHANNEL_CH5':16,
  'PM2_CHANNEL_CH6':32,
  'PM2_CHANNEL_CH7':64,
  'PM2_CHANNEL_CH8':128,
  'PM2_CHANNEL_CH9':256,
  'PM2_CHANNEL_CH10':512,
  'PM2_CHANNEL_CH11':1024,
  'PM2_INTERFACE_SELECT_METHOD_20MHZ':31,
  'PM2_INTERFACE_SELECT_METHOD_40MHZ':32,
  'PM2_INTERFACE_SELECT_METHOD_80MHZ':33,
  'PM2_INTERFACE_SELECT_METHOD_HIGHER':34,
  'PM2_INTERFACE_SELECT_METHOD_LOWER':35,
  'PM2_INTERFACE_SELECT_METHOD_STATIC':36,
  'PM2_CHANNEL_CH36':1,
  'PM2_CHANNEL_CH40':2,
  'PM2_CHANNEL_CH44':4,
  'PM2_CHANNEL_CH48':8,
  'PM2_CHANNEL_CH52':16,
  'PM2_CHANNEL_CH56':32,
  'PM2_CHANNEL_CH60':64,
  'PM2_CHANNEL_CH64':128,
  'PM2_CHANNEL_CH100':256,
  'PM2_CHANNEL_CH104':512,
  'PM2_CHANNEL_CH108':1024,
  'PM2_CHANNEL_CH112':2048,
  'PM2_CHANNEL_CH116':4096,
  'PM2_CHANNEL_CH120':8192,
  'PM2_CHANNEL_CH124':16384,
  'PM2_CHANNEL_CH128':32768,
  'PM2_CHANNEL_CH132':65536,
  'PM2_CHANNEL_CH136':131072,
  'PM2_CHANNEL_CH140':262144,
  'PM2_CHANNEL_CH144':524288,
  'PM2_CHANNEL_CH149':1048576,
  'PM2_CHANNEL_CH153':2097152,
  'PM2_CHANNEL_CH157':4194304,
  'PM2_CHANNEL_CH161':8388608,
  'PM2_CHANNEL_CH165':16777216,
  'PM2_OPT_DISCOVERED':72,
  'PM2_BANNED_IP_PERSISTENCY_DISABLED':0,
  'PM2_BANNED_IP_PERSISTENCY_PERMANENT_ONLY':1,
  'PM2_BANNED_IP_PERSISTENCY_ALL':2,
  'PM2_ACTFREEZE':7,
  'PM2_ACTBLOCK':8,
  'PM2_ACTALLOW':9,
  'PM2_DB_WIDGET_SESSION_RATE':85,
  'PM2_ROUTER_RULE_TYPE_RT':1,
  'PM2_ROUTER_RULE_TYPE_SOO':2,
  'PM2_FABRIC_VPN_POLICY_RULE_HEALTH_CHECK':1,
  'PM2_FABRIC_VPN_POLICY_RULE_MANUAL':2,
  'PM2_FABRIC_VPN_POLICY_RULE_AUTO':3,
  'PM2_FABRIC_VPN_VPN_ROLE_HUB':1,
  'PM2_FABRIC_VPN_VPN_ROLE_SPOKE':2,
  'PM2_FABRIC_VPN_ADVERTISED_SUBNETS_ACCESS_INBOUND':1,
  'PM2_FABRIC_VPN_ADVERTISED_SUBNETS_ACCESS_BIDIRECTIONAL':2,
  'PM2_ALLOW_OPCODE_MAP':1,
  'PM2_ALLOW_OPCODE_PEER':2,
  'PM2_ALLOW_OPCODE_ANNOUNCE':4,
  'PM2_SDN_PROXY_TYPE_GENERAL':1,
  'PM2_SDN_PROXY_TYPE_FORTIMANAGER':2,
  'PM2_FCTEMS_DIRTY_REASON_NONE':0,
  'PM2_FCTEMS_DIRTY_REASON_MISMATCHED_EMS_SN':1,
  'PM2_SEVERITY_BIT_LOW':1,
  'PM2_SEVERITY_BIT_MEDIUM':2,
  'PM2_SEVERITY_BIT_HIGH':4,
  'PM2_SEVERITY_BIT_CRITICAL':8,
  'PM2_SEVERITY_BIT_INFO':16,
  'PM2_CONNECTION_MODE_SEQUENTIALLY':1,
  'PM2_CONNECTION_MODE_SIMULTANEOUSLY':2,
  'PM2_FAST_FALLBACK_PROTOCOL_IPV4_FIRST':1,
  'PM2_FAST_FALLBACK_PROTOCOL_IPV6_FIRST':2,
  'PM2_FAST_FALLBACK_PROTOCOL_IPV4_ONLY':3,
  'PM2_FAST_FALLBACK_PROTOCOL_IPV6_ONLY':4,
  'PM2_OPT_USE_DEFAULT_PROFILE':76,
  'PM2_SAAS_APPLICATION_TYPE_BUILT_IN':1,
  'PM2_SAAS_APPLICATION_TYPE_CUSTOMIZED':2,
  'PM2_ATTR_TYPE_TENANT':1,
  'PM2_TYPE_STRING':1,
  'PM2_TYPE_STRING_LIST':2,
  'PM2_TYPE_INTEGER':3,
  'PM2_TYPE_INTEGER_LIST':4,
  'PM2_TYPE_BOOLEAN':5,
  'PM2_USER_ACTIVITY_TYPE_BUILT_IN':1,
  'PM2_USER_ACTIVITY_TYPE_CUSTOMIZED':2,
  'PM2_USER_ACTIVITY_CATEGORY_ACTIVITY_CONTROL':1,
  'PM2_USER_ACTIVITY_CATEGORY_TENANT_CONTROL':2,
  'PM2_USER_ACTIVITY_CATEGORY_DOMAIN_CONTROL':3,
  'PM2_USER_ACTIVITY_CATEGORY_SAFE_SEARCH_CONTROL':4,
  'PM2_USER_ACTIVITY_CATEGORY_OTHER':5,
  'PM2_USER_ACTIVITY_MATCH_RULES_TYPE_DOMAINS':1,
  'PM2_USER_ACTIVITY_MATCH_RULES_TYPE_HOST':2,
  'PM2_USER_ACTIVITY_MATCH_RULES_TYPE_PATH':3,
  'PM2_USER_ACTIVITY_MATCH_RULES_TYPE_HEADER':4,
  'PM2_USER_ACTIVITY_MATCH_RULES_TYPE_HEADER_VALUE':5,
  'PM2_USER_ACTIVITY_MATCH_RULES_TYPE_METHOD':6,
  'PM2_USER_ACTIVITY_MATCH_RULES_TYPE_BODY':7,
  'PM2_MATCH_PATTERN_SIMPLE':1,
  'PM2_MATCH_PATTERN_SUBSTR':2,
  'PM2_MATCH_PATTERN_REGEXP':3,
  'PM2_BODY_TYPE_JSON':1,
  'PM2_TYPE_JSON_QUERY':1,
  'PM2_DIRECTION_REQUEST':1,
  'PM2_DIRECTION_RESPONSE':2,
  'PM2_USER_ACTIVITY_CONTROL_OPTIONS_OPERATIONS_TARGET_HEADER':1,
  'PM2_USER_ACTIVITY_CONTROL_OPTIONS_OPERATIONS_TARGET_PATH':2,
  'PM2_USER_ACTIVITY_CONTROL_OPTIONS_OPERATIONS_TARGET_BODY':3,
  'PM2_USER_ACTIVITY_CONTROL_OPTIONS_OPERATIONS_ACTION_APPEND':1,
  'PM2_USER_ACTIVITY_CONTROL_OPTIONS_OPERATIONS_ACTION_PREPEND':2,
  'PM2_USER_ACTIVITY_CONTROL_OPTIONS_OPERATIONS_ACTION_REPLACE':3,
  'PM2_USER_ACTIVITY_CONTROL_OPTIONS_OPERATIONS_ACTION_NEW':4,
  'PM2_USER_ACTIVITY_CONTROL_OPTIONS_OPERATIONS_ACTION_NEW_ON_NOT_FOUND':5,
  'PM2_USER_ACTIVITY_CONTROL_OPTIONS_OPERATIONS_ACTION_DELETE':6,
  'PM2_USER_ACTIVITY_CONTROL_OPTIONS_OPERATIONS_DIRECTION_REQUEST':1,
  'PM2_BYPASS_AV':1,
  'PM2_BYPASS_DLP':2,
  'PM2_BYPASS_WEB_FILTER':4,
  'PM2_BYPASS_FILE_FILTER':8,
  'PM2_BYPASS_VIDEO_FILTER':16,
  'PM2_ACTION_BYPASS':11,
  'PM2_PTP_PROFILE_C37_238_2017':1,
  'PM2_TRANSPORT_L2_MCAST':1,
  'PM2_INTERVAL_1SEC':1,
  'PM2_INTERVAL_2SEC':2,
  'PM2_INTERVAL_4SEC':4,
  'PM2_INTERVAL_8SEC':8,
  'PM2_INTERVAL_16SEC':16,
  'PM2_INTERVAL_32SEC':32,
  'PM2_SENDER_ID_NONE':1,
  'PM2_SENDER_ID_HOSTNAME':2,
  'PM2_EXTERNAL_IDENTITY_PROVIDER_TYPE_MS_GRAPH':1,
  'PM2_CLOUD_SERVER_TYPE_V1_0':3,
  'PM2_ADDR_TYPE_IPV4V6':21,
  'PM2_STATUS_ONLINE':1,
  'PM2_STATUS_LOW_POWER':2,
  'PM2_INTERFACE_SELECT_METHOD_4G_5G':3,
  'PM2_INTERFACE_SELECT_METHOD_3G_4G':4,
  'PM2_INTERFACE_SELECT_METHOD_3G_5G':5,
  'PM2_INTERFACE_SELECT_METHOD_5G':6,
  'PM2_INTERFACE_SELECT_METHOD_4G':7,
  'PM2_INTERFACE_SELECT_METHOD_3G':8,
  'PM2_INTERFACE_SELECT_METHOD_NONE':9,
  'PM2_INTERFACE_SELECT_METHOD_CUSTOM':10,
  'PM2_CFG_SAVE_AUTO_GCF':6,
  'PM2_CFG_SAVE_AUTO_PTCRB':7,
  'PM2_SIM_SWITCH_SLOT_1':1,
  'PM2_SIM_SWITCH_SLOT_2':2,
  'PM2_IO_INPUT_DETECTION_MODE_DEFAULT':1,
  'PM2_IO_INPUT_DETECTION_MODE_VOLTAGE':2,
  'PM2_TYPE_LOCAL_VAP':1,
  'PM2_TYPE_LAN_EXT_VAP':2,
  'PM2_SECURITY_OPEN':1,
  'PM2_SECURITY_WPA2_PERSONAL':2,
  'PM2_SECURITY_WPA_WPA2_PERSONAL':3,
  'PM2_SECURITY_WPA3_SAE':4,
  'PM2_SECURITY_WPA3_SAE_TRANSITION':5,
  'PM2_SECURITY_WPA2_ENTERPRISE':6,
  'PM2_SECURITY_WPA3_ENTERPRISE_ONLY':7,
  'PM2_SECURITY_WPA3_ENTERPRISE_TRANSITION':8,
  'PM2_SECURITY_WPA3_ENTERPRISE_192_BIT':9,
  'PM2_OUTBREAK_PREVENTION_OPTIONAL':6,
  'PM2_OUTBREAK_PREVENTION_REQUIRED':7,
  'PM2_NETWORK_SERVICE_PING':2048,
  'PM2_OPT_UPDATING':78,
  'PM2_CLOUD_ORCHEST_AWS_LBTP_TCP':3,
  'PM2_CLOUD_ORCHEST_AWS_LBTP_UDP':4,
  'PM2_ICOND_TYPE_IEC101_104':1,
  'PM2_ICOND_TYPE_MODBUS_SERIAL_TCP':2,
  'PM2_ICOND_TYPE_RAW':3,
  'PM2_TTY_FLOWCONTROL_NONE':1,
  'PM2_TTY_FLOWCONTROL_XON_XOFF':2,
  'PM2_TTY_FLOWCONTROL_HW':3,
  'PM2_IPS_UNBALANCED':3,
  'PM2_MODBUS_SERIAL_MODE_RTU':1,
  'PM2_MODBUS_SERIAL_MODE_ASCII':2,
  'PM2_CLIENT_AUTHENTICATION_METHOD_TOKEN':1,
  'PM2_CLIENT_AUTHENTICATION_METHOD_BASE':2,
  'PM2_SSL_CLIENT_RENEGOTIATION_ALLOW':1,
  'PM2_SSL_CLIENT_RENEGOTIATION_DENY':2,
  'PM2_SSL_CLIENT_RENEGOTIATION_SECURE':3,
  'PM2_URL_MAP_TYPE_SUB_STRING':1,
  'PM2_URL_MAP_TYPE_WILDCARD':2,
  'PM2_URL_MAP_TYPE_REGEX':3,
  'PM2_CONDITION_TYPE_CPU':1,
  'PM2_CONDITION_TYPE_MEMORY':2,
  'PM2_CONDITION_TYPE_VPN':3,
  'PM2_CONDITION_TYPE_INPUT':4,
  'PM2_VPN_TUNNEL_STATE_TUNNEL_UP':1,
  'PM2_VPN_TUNNEL_STATE_TUNNEL_DOWN':2,
  'PM2_REMOTE_TYPE_VGW':1,
  'PM2_REMOTE_TYPE_TGW':2,
  'PM2_ROUTING_TYPE_STATIC':1,
  'PM2_ROUTING_TYPE_DYNAMIC':2,
  'PM2_BGP_FROM_UNSET':1,
  'PM2_BGP_FROM_CONFIG':2,
  'PM2_BGP_FROM_CLI':3,
  'PM2_VM_TOPOLOGY_FULLY_MESHED':0,
  'PM2_VM_TOPOLOGY_STAR':1,
  'PM2_VM_TOPOLOGY_DIALUP':2,
  'PM2_VPNMINKEYLIFE':120,
  'PM2_VPNMAXKEYLIFE':172800,
  'PM2_IPSEC_DPD_RETRYCOUNT_MIN':0,
  'PM2_IPSEC_DPD_RETRYCOUNT_MAX':10,
  'PM2_VM_NODE_ROLE_HUB':0,
  'PM2_VM_NODE_ROLE_SPOKE':1,
  'PM2_SYS_META_OPTIONAL':0,
  'PM2_SYS_META_REQUIRED':1,
  'PM2_FROM_NONE':0,
  'PM2_FROM_SDWAN_OVERLAY':1,
  'PM2_DEVPROF_TARGET_NONE':0,
  'PM2_DEVPROF_TARGET_THIS_FMG':2,
  'PM2_DEVPROF_TARGET_MANAGED':3,
  'PM2_DEVPROF_TARGET_OTHERS':4,
  'PM2_DEVPROF_TARGET_DIRECT':1,
  'PM2_CERT_TEMPL_IDTYPE_IP':0,
  'PM2_CERT_TEMPL_IDTYPE_DOMAIN':1,
  'PM2_CERT_TEMPL_IDTYPE_EMAIL':2,
  'PM2_CERT_TEMPL_KEYTYPE_RSA':0,
  'PM2_CERT_TEMPL_KEYTYPE_EC':1,
  'PM2_CERT_TEMPL_KEYSIZE_512':0,
  'PM2_CERT_TEMPL_KEYSIZE_1024':1,
  'PM2_CERT_TEMPL_KEYSIZE_1536':2,
  'PM2_CERT_TEMPL_KEYSIZE_2048':3,
  'PM2_CERT_TEMPL_KEYSIZE_4096':4,
  'PM2_CERT_TEMPL_CURVE_SECP256R1':0,
  'PM2_CERT_TEMPL_CURVE_SECP384R1':1,
  'PM2_CERT_TEMPL_CURVE_SECP521R1':2,
  'PM2_CERT_TEMPL_TYPE_EXTERNAL':0,
  'PM2_CERT_TEMPL_TYPE_LOCAL':1,
  'PM2_FWMPROF_IMG_SRC_FMG':0,
  'PM2_FWMPROF_IMG_SRC_FDS':1,
  'PM2_SCHED_ONBOARD':10,
  'PM2_FWMPROF_PRODUCT_FGT':1,
  'PM2_FWMPROF_PRODUCT_FAP':2,
  'PM2_FWMPROF_PRODUCT_FSW':3,
  'PM2_FWMPROF_PRODUCT_FEX':4,
  'PM2_FWMPROF_PRODUCT_FEM':5,
  'PM2_FWMPROF_UPGPATH_SKIP':0,
  'PM2_FWMPROF_UPGPATH_AUTO':1,
  'PM2_FWMPROF_UPGPATH_SPECIFY':2,
  'PM2_OPT_EAST_WEST':0,
  'PM2_OPT_NORTH_SOUTH':1,
  'PM2_CLIPROF_TYPE_CLI':0,
  'PM2_CLIPROF_TYPE_JINJA':1,
  'PM2_CLI_TEMPLATE_P_POST_VDOM_COPY':0,
  'PM2_CLI_TEMPLATE_P_PREP_VDOM_COPY':1,
  'PM2_IPS_ENTRY_POS_HEADER':1,
  'PM2_IPS_ENTRY_POS_FOOTER':2,
  'PM2_CONTROLLER_T_WTP':1,
  'PM2_CONTROLLER_T_FEX':2,
  'PM2_CONTROLLER_T_FSW':3,
  'PM2_PROV_NONE':0,
  'PM2_PROV_TMPL':1,
  'PM2_PROV_TMPLGRP':2,
  'PM2_SASE_LIC_STD':0,
  'PM2_SASE_LIC_ADV':1,
  'PM2_SASE_LIC_COMP':2,
  'PM2_CLOUD_ORCHEST_AWS_FAZ_INSTANCE_H1_2XLARGE':1,
  'PM2_CLOUD_ORCHEST_AWS_FAZ_INSTANCE_H1_4XLARGE':2,
  'PM2_CLOUD_ORCHEST_AWS_FAZ_INSTANCE_H1_8XLARGE':3,
  'PM2_CLOUD_ORCHEST_AWS_FAZ_INSTANCE_M5_LARGE':4,
  'PM2_CLOUD_ORCHEST_AWS_FAZ_INSTANCE_M5_XLARGE':5,
  'PM2_CLOUD_ORCHEST_AWS_FAZ_INSTANCE_M5_2XLARGE':6,
  'PM2_CLOUD_ORCHEST_AWS_FAZ_INSTANCE_M5_4XLARGE':7,
  'PM2_CLOUD_ORCHEST_AWS_FAZ_INSTANCE_M5_12XLARGE':8,
  'PM2_CLOUD_ORCHEST_AWS_FAZ_INSTANCE_T2_MEDIUM':9,
  'PM2_CLOUD_ORCHEST_AWS_FAZ_INSTANCE_T2_LARGE':10,
  'PM2_CLOUD_ORCHEST_AWS_FAZ_INSTANCE_T2_XLARGE':11,
  'PM2_CLOUD_ORCHEST_AWS_FGT_INSTANCE_T2_SMALL':1,
  'PM2_CLOUD_ORCHEST_AWS_FGT_INSTANCE_C5_LARGE':2,
  'PM2_CLOUD_ORCHEST_AWS_FGT_INSTANCE_C5_XLARGE':3,
  'PM2_CLOUD_ORCHEST_AWS_FGT_INSTANCE_C5_2XLARGE':4,
  'PM2_CLOUD_ORCHEST_AWS_FGT_INSTANCE_C5_4XLARGE':5,
  'PM2_CLOUD_ORCHEST_AWS_FGT_INSTANCE_C5_9XLARGE':6,
  'PM2_CLOUD_ORCHEST_AWS_TGW_CREATE_NEW':1,
  'PM2_CLOUD_ORCHEST_AWS_USE_EXISTING':2,
  'PM2_CLOUD_ORCHEST_AWS_INTERNAL_BALANCING_ADD_NEW':1,
  'PM2_CLOUD_ORCHEST_AWS_INTERNAL_BALANCING_USE_EXISTING':2,
  'PM2_CLOUD_ORCHEST_AWS_INTERNAL_BALANCING_NO_NEED':3,
  'PM2_CLOUD_ORCHEST_AWS_LBTP_HTTPS':1,
  'PM2_CLOUD_ORCHEST_AWS_LBTP_HTTP':2,
  'PM2_CLOUD_ORCHEST_AWS_REGION_US_EAST_1':1,
  'PM2_CLOUD_ORCHEST_AWS_REGION_US_EAST_2':2,
  'PM2_CLOUD_ORCHEST_AWS_REGION_US_WEST_1':3,
  'PM2_CLOUD_ORCHEST_AWS_REGION_US_WEST_2':4,
  'PM2_CLOUD_ORCHEST_AWS_REGION_EU_WEST_1':5,
  'PM2_CLOUD_ORCHEST_AWS_REGION_EU_WEST_2':6,
  'PM2_CLOUD_ORCHEST_AWS_REGION_EU_WEST_3':7,
  'PM2_CLOUD_ORCHEST_AWS_REGION_EU_NORTH_1':8,
  'PM2_CLOUD_ORCHEST_AWS_REGION_EU_SOUTH_1':9,
  'PM2_CLOUD_ORCHEST_AWS_REGION_EU_SOUTH_2':10,
  'PM2_CLOUD_ORCHEST_AWS_REGION_EU_CENTRAL_1':11,
  'PM2_CLOUD_ORCHEST_AWS_REGION_EU_CENTRAL_2':12,
  'PM2_CLOUD_ORCHEST_AWS_REGION_CA_CENTRAL_1':13,
  'PM2_CLOUD_ORCHEST_AWS_REGION_AP_SOUTHEAST_1':14,
  'PM2_CLOUD_ORCHEST_AWS_REGION_AP_SOUTHEAST_2':15,
  'PM2_CLOUD_ORCHEST_AWS_REGION_AP_SOUTHEAST_3':16,
  'PM2_CLOUD_ORCHEST_AWS_REGION_AP_SOUTHEAST_4':17,
  'PM2_CLOUD_ORCHEST_AWS_REGION_AP_SOUTH_1':18,
  'PM2_CLOUD_ORCHEST_AWS_REGION_AP_SOUTH_2':19,
  'PM2_CLOUD_ORCHEST_AWS_REGION_AP_NORTHEAST_1':20,
  'PM2_CLOUD_ORCHEST_AWS_REGION_AP_NORTHEAST_2':21,
  'PM2_CLOUD_ORCHEST_AWS_REGION_AP_NORTHEAST_3':22,
  'PM2_CLOUD_ORCHEST_AWS_REGION_AF_SOUTH_1':23,
  'PM2_CLOUD_ORCHEST_AWS_REGION_ME_CENTRAL_1':24,
  'PM2_CLOUD_ORCHEST_AWS_REGION_ME_SOUTH_1':25,
  'PM2_CLOUD_ORCHEST_AWS_REGION_SA_EAST_1':26,
  'PM2_CLOUD_ORCHEST_AWS_REGION_AP_EAST_1':27,
  'PM2_CLOUD_ORCHEST_AWS_REGION_US_GOV_EAST_1':28,
  'PM2_CLOUD_ORCHEST_AWS_REGION_US_GOV_WEST_1':29,
  'PM2_CLOUD_ORCHEST_TYPE_AWS':1,
  'PM2_CLOUD_ORCHEST_STATUS_NEW':1,
  'PM2_CLOUD_ORCHEST_STATUS_DEPLOYING':2,
  'PM2_CLOUD_ORCHEST_STATUS_FAILED':3,
  'PM2_CLOUD_ORCHEST_STATUS_DEPLOYED':4,
  'PM2_CLOUD_ORCHEST_STATUS_DELETING':5,
  'PM2_UMS_ACT_AWS':0,
  'PM2_UMS_ACT_AZURE':1,
  'PM2_UMS_ACT_GCP':2
};
macros.PO = {
  'PM3_PKG_STATUS_INSTALLED':0,
  'PM3_PKG_STATUS_MODIFIED':1,
  'PM3_PKG_STATUS_IMPORTED':2,
  'PM3_PKG_STATUS_UNKNOWN':3,
  'PM3_PKG_STATUS_SIGNED':4,
  'PM3_PKG_STATUS_PEND_GEN':5,
  'PM3_PKG_STATUS_PEND_SIGN':6,
  'PM3_PKG_STATUS_FAIL_GEN':7,
  'PM3_PKG_STATUS_FAIL_SIGN':8,
  'PM3_PKG_STATUS_OUTOFSYNC':9,
  'PM3_PKG_STATUS_CONFLICT':10,
  'PM3_PKG_FLAGS_CLI_TMPL':1,
  'PM3_PKG_FLAGS_CLI_TMPL_GRP':2,
  'PM3_ENTRY_F_IS_USED':1,
  'PM3_ENTRY_F_GLOBAL_REF':4,
  'PM3_ENTRY_F_DEVOBJ_MAPPING':8,
  'PM3_ENTRY_F_LIMIT_VISIBILITY':16,
  'PM3_ENTRY_F_NO_VISIBILITY_SCOPE':8192,
  'PM3_ENTRY_F_UNDELETABLE':32,
  'PM3_ENTRY_F_WSCACHE_ADD':1024,
  'PM3_ENTRY_F_WSCACHE_MOD':2048,
  'PM3_ENTRY_F_NOT_EXIST':2,
  'PASSWD_POLICY_CHECK_OK':0,
  'PASSWD_POLICY_CHECK_EXPIRE':1,
  'PASSWD_POLICY_CHECK_NOT_CONFORM':2,
  'PASSWD_POLICY_CHECK_FORCE_CHG':3,
  'PASSWD_POLICY_CHECK_NR':4,
  'PM2_OBJ_F_DELETABLE':1,
  'PM2_OBJ_F_LIMIT_VISIBILITY':16,
  'PM2_OBJ_F_CDIRTY_FLAG':32,
  'PM2_OBJ_F_PREDEF_OBJECT':64,
  'PM2_OBJ_F_PREDEF_READONLY':128,
  'PM2_OBJ_F_PREDEF_DELETABLE':256,
  'PM2_OBJ_F_OBJ_ADOM':2,
  'PM2_OBJ_F_DEVOBJ_MAPPING':8,
  'PM2_OBJ_F_GLOBAL_REF':4,
  'PM2_OBJ_F_PREDEF_HIDDEN':512,
  'PM2_OBJ_F_WSCACHE_ADD':1024,
  'PM2_OBJ_F_WSCACHE_MOD':2048,
  'PM2_CDB_MAX_SEQ2_NUM':1072741824,
  'PBLOCK_POLICY_START_ID':1071741825,
  'PBLOCK_POLICY_MAX_ID':1072741824,
  'VPN_POLICY_START_ID':1072741825,
  'VPN_POLICY_MAX_ID':1073741824,
  'GLOBAL_HEAD_POLICY_START_ID':1073741825,
  'GLOBAL_HEAD_POLICY_MAX_ID':1074741824,
  'GLOBAL_FOOTER_POLICY_START_ID':1074741825,
  'GLOBAL_FOOTER_POLICY_MAX_ID':1075741824,
  'GLOBAL_PBLOCK_HEAD_POLICY_START_ID':1075741825,
  'GLOBAL_PBLOCK_HEAD_POLICY_MAX_ID':1076741824,
  'GLOBAL_PBLOCK_FOOTER_POLICY_START_ID':1076741825,
  'GLOBAL_PBLOCK_FOOTER_POLICY_MAX_ID':1077741824,
  'DVM_WORKFLOW_LOG_A_NOTES':0,
  'DVM_WORKFLOW_LOG_A_CREATE':1,
  'DVM_WORKFLOW_LOG_A_SUBMIT':2,
  'DVM_WORKFLOW_LOG_A_APPROVE':3,
  'DVM_WORKFLOW_LOG_A_REJECT':4,
  'DVM_WORKFLOW_LOG_A_START':5,
  'DVM_WORKFLOW_LOG_A_CONTINUE':6,
  'DVM_WORKFLOW_LOG_A_REPAIR':7,
  'DVM_WORKFLOW_LOG_A_SAVE':8,
  'DVM_WORKFLOW_LOG_A_DISCARD':9,
  'DVM_WORKFLOW_LOG_A_DROP':10,
  'DVM_WORKFLOW_LOG_A_REVIEW':11,
  'DVM_WORKFLOW_LOG_A_REVERT':12,
  'DVM_WORKFLOW_LOG_A_DELETE':13,
  'PM3_WSLOCK_FLAGS_DIRTY':1,
  'PM3_WSLOCK_FLAGS_WORKFLOW_REVIEW':2,
  'PM3_WSLOCK_FLAGS_FROM_SC':4,
  'PM3_WSLOCK_FLAGS_FROM_GUI':8,
  'PM3_WSLOCK_FLAGS_POLICY_SEQ':16,
  'PM3_WSLOCK_FLAGS_PER_POLICY':32,
  'PM3_WSLOCK_FLAGS_NO_VERIFY':64
};
macros.WTP = {
  'PM2_CONN_IDLE':0,
  'PM2_CONN_CONNECTING':1,
  'PM2_CONN_CONNECTED':2,
  'PM2_CONN_IMAGE_DOWNLOADING':3,
  'PM2_CONN_CONNECTED_IMAGE':4,
  'PM2_CONN_UNKNOWN':9,
  'CW_RMODE_NODEV':0,
  'CW_RMODE_DISABLED':1,
  'CW_RMODE_WTP':2,
  'CW_RMODE_MONITOR':3,
  'CW_RMODE_SNIFFER':4,
  'PM2_RADIO_CAP_5GLOW':1,
  'PM2_RADIO_CAP_5GHIGH':2,
  'PM2_WL_BAND_11AX_5G':256,
  'PM2_WL_BAND_11AC':16,
  'CW_RADIO_MAX_PER_WTP':5
};
macros.ADMIN = {
  'ADOM_ACCESS_ALL':0,
  'ADOM_ACCESS_SPECIFY':1,
  'ADOM_ACCESS_EXCLUDE':2,
  'ADOM_ACCESS_PER_ADOM_PROF':3,
  'R_ADMIN_ALL_WEB_FILTER':'all_web_filters',
  'R_ADMIN_ALL_APP_FILTER':'all_app_filters',
  'R_ADMIN_ALL_IPS_FILTER':'all_ips_filters'
};
macros.NOTICE = {
  'NAVBAR_NOTICE_COLLECTION_NAME_SET':'navbar_notif_set',
  'NAVBAR_NOTICE_COLLECTION_NAME_UNSET':'navbar_notif_unset',
  'NOTICE_TYPE_DVM_UNREG':'dvm_unreg',
  'NOTICE_TYPE_DVM_OUTOFSYNC':'dvm_outofsync',
  'NOTICE_TYPE_VM_LICENSE':'vm_license',
  'NOTICE_TYPE_VM_METER_BALANCE':'vm_meter_balance',
  'NOTICE_TYPE_VM_METER_LICENSE':'vm_meter_license',
  'NOTICE_TYPE_LOG_INFO':'log_info',
  'NOTICE_TYPE_HA_CONN':'ha_conn',
  'NOTICE_TYPE_IP_CONFLICT':'ip_conflict',
  'NOTICE_TYPE_VM_SIZING':'vm_sizing',
  'NOTICE_TYPE_VM_STORAGE_DISK':'vm_storage_disk',
  'NOTICE_TYPE_CSF_FORMATION':'csf_formation',
  'NOTICE_TYPE_HTTPS_CERT_EXPIRING':'https_cert_expiring',
  'NOTICE_TYPE_ADOM_LICENSE':'vm_adom_license',
  'NOTICE_TYPE_PSIRT_VULNERABLE':'psirt_vulnerable',
  'NOTICE_TYPE_FABRIC_PENDING_MEMBER':'fabric_pending_members',
  'NOTICE_TYPE_UNREAD_OUTBREAK_ALERT':'unread_outbreak_alert',
  'NOTICE_TYPE_FORTISASE_LICENSE_DETECTED':'sase_license_detected',
  'NOTICE_TYPE_FORTIAI':'fortiai_license',
  'NOTICE_TYPE_SOC_FABRIC_MEMBER_NOT_IN_SYNC':'socfabric_member_not_in_sync',
  'NOTICE_TYPE_LOG_SUSTAIN_RATE':'log_sustain_rate',
  'NOTICE_TYPE_LOG_STORAGE_STATICS_DB':'log_storage_statics_db',
  'NOTICE_TYPE_LOG_STORAGE_STATICS_FILE':'log_storage_statics_file',
  'NOTICE_TYPE_LOG_FETCHER_REQUESTS':'log_fetcher_requests',
  'NOTICE_TYPE_LOG_FETCHER_FETCHING':'log_fetcher_fetching',
  'NOTICE_LEVEL_INFORMATION':1,
  'NOTICE_LEVEL_NOTIFICATION':2,
  'NOTICE_LEVEL_WARNING':3,
  'NOTICE_LEVEL_ERROR':4,
  'NOTICE_LEVEL_CRITICAL':5
};
macros.TASK = {
  'TASK_STATE_PEND':0,
  'TASK_STATE_RUN':1,
  'TASK_STATE_CANCELLING':2,
  'TASK_STATE_CANCELLED':3,
  'TASK_STATE_DONE':4,
  'TASK_STATE_ERROR':5,
  'TASK_STATE_ABORTING':6,
  'TASK_STATE_ABORTED':7,
  'TASK_STATE_WARN':8,
  'TASK_STATE_CONT':9,
  'TASK_STATE_QUEUED':10,
  'TASK_SRC_DVM':0,
  'TASK_SRC_SC':1,
  'TASK_SRC_GLOBAL_OBJ':2,
  'TASK_SRC_DPM_INSTCONF':3,
  'TASK_SRC_DPM_INSTSCRIPT':4,
  'TASK_SRC_CHKPOINT':5,
  'TASK_SRC_IMPORT_OBJS':6,
  'TASK_SRC_IMPORT_ZONES':7,
  'TASK_SRC_IMPORT_POLICY':8,
  'TASK_SRC_EMS_POLICY':9,
  'TASK_SRC_POLICY_CHECK':10,
  'TASK_SRC_ASSIGNMENT':11,
  'TASK_SRC_OBJ_ASSIGNMENT':12,
  'TASK_SRC_PKG_CLONE':13,
  'TASK_SRC_AUTO_CERT':14,
  'TASK_SRC_INSTOBJS':15,
  'TASK_SRC_DPM_INST_PREVIEW':16,
  'TASK_SRC_INSTDEV':17,
  'TASK_SRC_FWM':18,
  'TASK_SRC_INTEGRITY_CHECK':19,
  'TASK_SRC_PBLOCK_CLONE':20,
  'TASK_SRC_IMPORT_CONFIG':21
};
macros.FGD = {
  'SUPPORT_LEVEL_DEFAULT':0,
  'SUPPORT_LEVEL_UPGRADE_24X7':3,
  'SUPPORT_LEVEL_RETURN_TO_FACTORY':4,
  'SUPPORT_LEVEL_ADVANCED_HW':5,
  'SUPPORT_LEVEL_WEB_ONLINE':6,
  'SUPPORT_LEVEL_24X7_ND_HW':7,
  'SUPPORT_LEVEL_24X7X4_COURIER_HW':8,
  'SUPPORT_LEVEL_24X7X4_ON_SITE_HW':9,
  'SUPPORT_LEVEL_8X5':10,
  'SUPPORT_LEVEL_ACTEL_8X5':11,
  'SUPPORT_LEVEL_L3_8X5':12,
  'SUPPORT_LEVEL_NO_RETURN':13,
  'SUPPORT_LEVEL_24X7':20,
  'SUPPORT_LEVEL_ACTEL_24X7':21,
  'SUPPORT_LEVEL_L3_24X7':22,
  'SUPPORT_LEVEL_TECHDIRECT_1':24,
  'SUPPORT_LEVEL_TECHDIRECT_2':25,
  'SUPPORT_LEVEL_PREFERRED':26,
  'SUPPORT_LEVEL_TAM_GOLD':27,
  'SUPPORT_LEVEL_TAM_GLOBAL_GOLD':28,
  'SUPPORT_LEVEL_RESIDENT_ENGINEER':29,
  'SUPPORT_LEVEL_PLATINUM':30,
  'SUPPORT_LEVEL_30_DAY_SETUP_INCL_ADV_HW':31,
  'SUPPORT_LEVEL_RESIDENT_ARCHITECT':32,
  'SUPPORT_LEVEL_RESIDENT_TEAM_LEAD':33,
  'SUPPORT_LEVEL_RESIDENT_ENGINEER_TE':34,
  'SUPPORT_LEVEL_ONSITE':35,
  'SUPPORT_LEVEL_REMOTE':36,
  'SUPPORT_LEVEL_100GB':40,
  'SUPPORT_LEVEL_10GB_25MB':41,
  'SUPPORT_LEVEL_25GB_50MB':42,
  'SUPPORT_LEVEL_50GB':43,
  'SUPPORT_LEVEL_ADVANCED_COORDINATOR':44,
  'SUPPORT_LEVEL_ENTERPRISE_PREMIUM':45,
  'SUPPORT_LEVEL_ENTERPRISE_BUSINESS':46,
  'SUPPORT_LEVEL_ENTERPRISE_FIRST':47,
  'SUPPORT_LEVEL_GLOBAL_FIRST':48,
  'SUPPORT_LEVEL_SELECT':49,
  'SUPPORT_LEVEL_ELITE':50,
  'SUPPORT_LEVEL_40GB':51,
  'SUPPORT_LEVEL_80GB':52,
  'SUPPORT_LEVEL_120GB':53,
  'SUPPORT_LEVEL_UP_TO_200G_STORAGE':54,
  'SUPPORT_LEVEL_PREMIUM_SERVICE':55,
  'SUPPORT_LEVEL_STANDARD':56,
  'SUPPORT_LEVEL_DESIGNATED_DELIVERY_MANAGER':57,
  'SUPPORT_LEVEL_TRIAL':99,
  'UM_JSON_INT_UP_TO_DATE':1,
  'UM_JSON_INT_PENDING':2
};
macros.SSO = {
  'SAML_STATUS_DISABLED':0,
  'SAML_STATUS_ENABLED':1,
  'SAML_AUTO_REDIRECT_ENABLE':1,
  'SAML_AUTO_REDIRECT_DISABLE':0,
  'SAML_ROLE_IDP':0,
  'SAML_ROLE_SP':1,
  'SAML_ROLE_FAB_SP':2,
  'SAML_ATTR_USER':0,
  'SAML_ATTR_EMAIL':1,
  'SAML_ATTR_PREVILEGE':2,
  'IDP_ENTITY_ID':'http://%s/saml-idp/%s/metadata/',
  'IDP_METADATA_URL':'https://%s/saml-idp/%s/metadata/',
  'IDP_LOGIN_URL':'https://%s/saml-idp/%s/login/',
  'IDP_LOGOUT_URL':'https://%s/saml-idp/%s/logout/',
  'SP_ENTITY_ID':'http://%s/metadata/',
  'SP_METADATA_URL':'https://%s/metadata/',
  'SP_ACS_URL':'https://%s/saml/?acs',
  'SP_SLS_URL':'https://%s/saml/?sls',
  'FORTICLOUD_ACS_URL':'https://%s/saml/?forticloud-acs',
  'FORTICLOUD_SLS_URL':'https://%s/saml/?forticloud-sls'
};
macros.CLOUD = {

};
macros.PM2_ERR = {
  'PM2_CLI_ER_MAXENTRY':-10003,
  'PM2_CLI_ER_USED':-10015,
  'PM2_CLI_ER_INTF_USED_BY_ZONE':-10154
};
macros.FAZSVC = {
  'FAZSVC_SUCESS':0,
  'FAZSVC_IM_EX_NO_REQ_OBJ':-1,
  'FAZSVC_IM_EX_NO_ACTION':-2,
  'FAZSVC_IM_EX_NO_PARAM':-3,
  'FAZSVC_IM_EX_FAILED_CREATE_TEMPFILE':-4,
  'FAZSVC_IM_EX_FAILED_ZIP':-5,
  'FAZSVC_IM_EX_FAILED_UNZIP':-6,
  'FAZSVC_IM_EX_FILE_FMT_INCORRECT':-7,
  'FAZSVC_IM_EX_FILE_NOT_EXIST':-8,
  'FAZSVC_IM_EX_FAILED_RENAME':-9,
  'FAZSVC_IM_EX_CDB_API':-10,
  'FAZSVC_IM_EX_IMPORT_CFG_OBJ':-11,
  'FAZSVC_IM_EX_EXPORT_CFG_OBJ':-12,
  'FAZSVC_IM_EX_VERSION':-13,
  'FAZSVC_IM_EX_ADOM_MISMATCH':-14,
  'FAZSVC_IM_EX_AUTHORITY':-15,
  'FAZSVC_IM_EX_ERROR_UNKNOWN':-26,
  'FAZSVC_IM_EX_MAX':-27
};
macros.FAZEPEU = {
  'EPEU_INVALID_ID':0,
  'EPEU_NOT_IMPL_DEVTYPE':1,
  'EPEU_NOT_IMPL_LOGTYPE':2,
  'EPEU_NO_ENOUGH_INFO':3,
  'EPEU_CANNOT_GET_UID':4,
  'EPEU_INTERNAL_ERROR':5,
  'EPEU_HA_BACKUP_ASK_FAIL':6,
  'EPEU_HA_REBUILD_THROTTLE':7,
  'EPEU_CLIENT_ASK_FAIL':8,
  'EPEU_NOT_SUPPORT_LOGVER':10,
  'EPEU_ID_LOCAL_HOST':100,
  'EPEU_ID_UNTRACK_IP':101,
  'EPEU_ID_UNTRACK_LOGID':102,
  'EPEU_ID_UNTRACK_TOOMANYIP':103,
  'EPEU_ID_UNTRACK_VPN_IP':104,
  'EPEU_ID_PREDEFINED_MAX':110,
  'EPEU_ID_RESERVED_MAX':1024,
  'DEFAULT_EPEU_GET_LIMIT':10000
};
macros.FGT_LOGFLD_GRP = {
  'FGT_LOGFLD_GRP_ACTION':0,
  'FGT_LOGFLD_GRP_APP_URL':1,
  'FGT_LOGFLD_GRP_DATA_TRANS':2,
  'FGT_LOGFLD_GRP_GENERAL':3,
  'FGT_LOGFLD_GRP_NETWORK_DST':4,
  'FGT_LOGFLD_GRP_NETWORK_SRC':5,
  'FGT_LOGFLD_GRP_SECURITY':6,
  'FGT_LOGFLD_GRP_THREAT':7,
  'FGT_LOGFLD_GRP_TYPE':8,
  'FGT_LOGFLD_GRP_OTHERS':9,
  'FGT_LOGFLD_GRP_MAX':10
};
macros.SIEM_LOGFLD_GRP = {
  'SIEM_LOGFLD_GRP_DATA':0,
  'SIEM_LOGFLD_GRP_EVENT':1,
  'SIEM_LOGFLD_GRP_THREAT':2,
  'SIEM_LOGFLD_GRP_HOST':3,
  'SIEM_LOGFLD_GRP_USER':4,
  'SIEM_LOGFLD_GRP_FILE':5,
  'SIEM_LOGFLD_GRP_APP':6,
  'SIEM_LOGFLD_GRP_NETWORK':7,
  'SIEM_LOGFLD_GRP_OTHERS':8
};
macros.FAZ_ERR = {

};
macros.FAZ_REPORTS_API = {
  'TYPE_PENDING_RUNNING':1,
  'TYPE_GENERATED':2,
  'TYPE_SCHEDULED':3,
  'TYPE_HCACHE_STATUS':4
};
macros.FAZ_IOC_LOGTYPE = {
  'IOC_LOGTYPEMASK_OLOG':1,
  'IOC_LOGTYPEMASK_WLOG':2,
  'IOC_LOGTYPEMASK_TLOG':4,
  'IOC_LOGTYPEMASK_SLOG':16
};
macros.FAZ_DVM = {
  'FAZHA_MODE_MASTER':1,
  'FAZHA_MODE_BACKUP':2,
  'FAZHA_MODE_BACKUP_SYNC':3,
  'DISK_SSD':0,
  'DISK_HDD':1,
  'DEVICE_TYPE_DEV':0,
  'DEVICE_TYPE_GRP':1,
  'DEVICE_TYPE_ALL':2,
  'DEVICE_TYPE_DGP':3,
  'DEVICE_TYPE_FGP':4
};
macros.FAZ_CFG_PARAM = {
  'EVMG_INDICATOR_MIN':1,
  'EVMG_INDICATOR_MAX':10,
  'EVMG_INDICATOR_DFT':3,
  'SOC_FABRIC_CFG_ROLE_MEMBER':0,
  'SOC_FABRIC_CFG_ROLE_SUPERVISOR':1,
  'SOC_FABRIC_CFG_DISABLE':0,
  'SOC_FABRIC_CFG_ENABLE':1
};
macros.FAZ_LOGFWD = {
  'FAZ_LOGFWD_DEV_FILTER_INCLUDE':0,
  'FAZ_LOGFWD_DEV_FILTER_EXCLUDE':1,
  'FAZ_LOGFWD_DEV_FILTER_INCLUDE_LIKE':2,
  'FAZ_LOGFWD_DEV_FILTER_EXCLUDE_LIKE':3
};
macros.REDIS = {

};
macros.FAZ_DB = {
  'SQLTYPE_IP':6
};
macros.FAZ_SYS = {
  'HA_FAZ_MODE_STANDALONE':1,
  'HA_FAZ_MODE_A_P':2,
  'HA_FAZ_MODE_A_A':3,
  'LOG_PCAPF_DL_ZIP_PASSWD':2
};
macros.FAZ_LOG_DETAILS_POS = {
  'NONE':'NONE',
  'RIGHT':'RIGHT',
  'BOTTOM':'BOTTOM'
};
export default macros;
